import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { ErrorOutlined } from '@finn/design-system/icons/error-outlined';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  open: boolean;
  onClose(): void;
  title?: string;
  description?: string;
  extraInfo?: string;
}

export const ErrorModal: FunctionComponent<IProps> = ({
  open,
  onClose,
  title,
  description,
  extraInfo,
}) => {
  const i18n = useIntl();

  const defaultTitle = i18n.formatMessage({
    id: 'userAccount.messageModal.error.title',
  });
  const defaultDescription = i18n.formatMessage({
    id: 'userAccount.messageModal.error.description',
  });

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(nextOpen) => {
        if (!nextOpen) {
          onClose();
        }
      }}
    >
      <ModalContent>
        <ErrorOutlined className="mr-auto min-h-14 min-w-14" />
        <ModalHeader>
          <ModalTitle>{title === undefined ? defaultTitle : title}</ModalTitle>
        </ModalHeader>
        <p className="body-16-light">
          {description ? description : defaultDescription}
        </p>
        {extraInfo ? <p className="body-16-light mt-10">{extraInfo}</p> : null}
        <ModalFooter>
          <Button onClick={onClose}>Ok</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
