import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import set from 'lodash/set';

type NavLinkBlock = {
  href: string;
  label: string;
  // TODO we use any to avoid dependency cycle
  // as we need to import the type from ua-cosmic
  // likely it means that we shoud move this type to a shared lib, not ui-utils
  menu: any;
};

type UpdateNavLinkBlockForB2B = (linkBlock: NavLinkBlock) => NavLinkBlock;

// Adjusting the subscriptions links to take to the b2b product listing page when user is logged in as b2b client (so that b2b clients see personalised prices):
export const updateNavLinkBlockForB2B: UpdateNavLinkBlockForB2B = (
  linkBlock
) => {
  const updatedPLPLink = `/${BASE_SUBSCRIPTION_PATH}`;
  const updatedLinkBlock = { ...linkBlock };

  const navSections = linkBlock?.menu?.metadata?.sections as any[];
  const updatedNavSections = navSections?.map((section) => {
    if (section.metadata.type === 'benefits')
      set(section, 'metadata.content.cta_button.href', updatedPLPLink);

    return section;
  });
  set(updatedLinkBlock, `menu.metadata.sections`, updatedNavSections);
  set(updatedLinkBlock, `href`, updatedPLPLink);

  return updatedLinkBlock;
};
