import { createContext, ReactNode } from 'react';

import { useSetAppReady } from '../app/utils';
import useSetDevMode from '../dev/useSetDevMode';
import useNavigationProgress from './useNavigationProgress';

type App = {
  startProgress: (step?: number) => void;
  endProgress: () => void;
  triggerDevModal: () => void;
};

const AppContext = createContext<App>({
  startProgress: () => {},
  endProgress: () => {},
  triggerDevModal: () => {},
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  useSetAppReady();

  const triggerDevModal = useSetDevMode();
  const { startProgress, endProgress } = useNavigationProgress();

  return (
    <AppContext.Provider
      value={{
        startProgress,
        endProgress,
        triggerDevModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
