import { createContext, useContext } from 'react';

export enum App {
  UA = 'ua',
  B2B = 'b2b',
  CP = 'cp',
}

export const CurrentAppContext = createContext(App.UA);

export const useCurrentApp = () => {
  return useContext(CurrentAppContext);
};
