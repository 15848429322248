/* This component acts as replacment for the Alert component from MUI */
/* It based on top of DS banner module, maybe later we should make it part of DS */
import { Button } from '@finn/design-system/atoms/button';
import { If } from '@finn/design-system/atoms/If';
import { Check } from '@finn/design-system/icons/check';
import { Close } from '@finn/design-system/icons/close';
import { ErrorFilled } from '@finn/design-system/icons/error-filled';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { WarningOutlined } from '@finn/design-system/icons/warning-outlined';
import {
  BannerModule,
  BannerModuleDescription,
  BannerModuleIcon,
} from '@finn/design-system/modules/BannerModule';
import { cn } from '@finn/ui-utils';
import { FunctionComponent, ReactNode } from 'react';

type AlertProps = {
  severity: 'error' | 'warning' | 'info' | 'success' | 'important-info';
  description?: ReactNode;
  cta?: ReactNode;
  Icon?: FunctionComponent;
  className?: string;
  onClose?: () => void;
};

const iconsMap = {
  error: ErrorFilled,
  warning: WarningOutlined,
  info: InfoOutlined,
  'important-info': InfoOutlined,
  success: Check,
};

const colorsMap = {
  error: 'fill-red bg-lightRed',
  'important-info': 'fill-trustedBlue text-black bg-lightBlue',
  success: 'fill-green bg-lightGreen',
  warning: 'fill-orange bg-lightOrange',
  info: 'fill-black text-black bg-snow',
};

export const Alert = ({
  severity,
  Icon,
  description,
  cta,
  className,
  onClose,
}: AlertProps) => {
  const IconToRender = Icon || iconsMap[severity];

  const fallbackCTA = onClose ? (
    <Button
      variant="ghost"
      className="h-max p-0 hover:bg-black/5"
      onClick={onClose}
    >
      <Close aria-label="close-icon" className="max-h-5 max-w-5 fill-black" />
    </Button>
  ) : null;

  return (
    <BannerModule
      className={cn(
        'grid-cols-[min-content,1fr,max-content] items-center !gap-x-4 !gap-y-0 px-6 py-4',
        colorsMap[severity],
        className
      )}
    >
      <BannerModuleIcon>
        <IconToRender />
      </BannerModuleIcon>
      <If condition={Boolean(description)}>
        <BannerModuleDescription className="inlin-flex !body-16-light row-span-3 flex-wrap items-center">
          {description}
        </BannerModuleDescription>
      </If>
      {cta || onClose ? (
        <div className="col-start-3 row-span-3 inline-flex items-center">
          {cta || fallbackCTA}
        </div>
      ) : null}
    </BannerModule>
  );
};
