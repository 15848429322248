import { forwardRef, ReactNode } from 'react';

import { EmptyComponent } from '../../internal/EmptyComponent';
import { CoreInput, CoreInputProps } from './CoreInput';
import { CoreLabel, CoreLabelProps } from './CoreLabel';

export type CoreInputWithLabelProps = {
  startAdornment?: ReactNode;
  // current internal state of the input
  localValue?: string;
} & CoreInputProps &
  Omit<CoreLabelProps, 'children'>;

// this component combines regular label and regular impact for most common case

export const CoreInputWithLabel = forwardRef<
  HTMLInputElement,
  CoreInputWithLabelProps
>(
  (
    {
      id,
      startAdornment,
      label,
      type,
      shouldShowLabelAtTop,
      localValue,
      ...props
    },
    ref
  ) => {
    // if startAdornment present, to be able to animate and position
    // label we need to wrap input and label in a div
    const PostionNode = startAdornment ? 'div' : EmptyComponent;

    return (
      <PostionNode className="relative w-full">
        {type === 'file' && localValue ? (
          <p className="body-16-regular w-full text-nowrap pb-2 pt-6">
            {props.value || localValue}
          </p>
        ) : null}
        <CoreInput id={id} ref={ref} type={type} label={label} {...props} />
        {label ? (
          <CoreLabel
            id={id}
            type={type}
            shouldShowLabelAtTop={shouldShowLabelAtTop}
          >
            {label}
          </CoreLabel>
        ) : null}
      </PostionNode>
    );
  }
);
