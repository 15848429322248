/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@finn/design-system/atoms/button';
import { Close } from '@finn/design-system/icons/close';
import { Logo } from '@finn/design-system/icons/logo';
import { Menu } from '@finn/design-system/icons/menu';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { type HandleMobileHeaderParams, HeaderContext } from '@finn/ua-header';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { NextLinkSingleApp } from '@finn/ui-components';
import { HeaderData } from '@finn/ui-cosmic';
import { App, cn } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { ProfileButton } from '../Components/ProfileButton';

type NavbarProps = {
  handleMobileHeader: ({ isOpen }: HandleMobileHeaderParams) => void;
  isOpen?: boolean;
  isOpenUser?: boolean;
  headerData?: HeaderData;
  className?: string;
};

const Navbar = ({
  handleMobileHeader,
  isOpen,
  isOpenUser,
  headerData,
  className,
}: NavbarProps) => {
  const router = useRouter();
  const { CartButton } = useContext(HeaderContext);
  const shouldDisableLogoLink = headerData?.metadata?.disable_logo_link;
  const customLogo = headerData?.metadata?.custom_logo?.url;
  const customLogoLink = headerData?.metadata?.custom_logo_link;
  const logoHref = customLogoLink ? customLogoLink : '/';

  const LogoElement = () =>
    customLogo ? (
      <img height={56} width="auto" src={customLogo} alt="parner-logo" />
    ) : (
      <Logo />
    );

  return (
    <div
      className={cn(
        'pointer-events-auto relative z-[1300] flex h-[75px] min-h-[75px] w-full items-center bg-white',
        className
      )}
    >
      <div className="container flex items-center justify-between">
        {shouldDisableLogoLink ? (
          <LogoElement />
        ) : (
          <NextLinkSingleApp
            app={App.UA}
            href={logoHref}
            className="flex [&_svg]:h-[18px] [&_svg]:w-[52px] sm:[&_svg]:h-[26px] sm:[&_svg]:w-[75px]"
          >
            <LogoElement />
          </NextLinkSingleApp>
        )}
        <div className="ml-auto">{CartButton && <CartButton />}</div>
        <div className="flex">
          {isOpen ? (
            <Button
              variant="ghost"
              className="!bg-transparent p-4"
              onClick={() => {
                handleMobileHeader({ isOpen: false, isOpenUser: false });
                interactionTrackingEvent(
                  TrackingEventName.MOBILE_NAV_CLOSED,
                  {}
                );
              }}
              data-testid="mobile-menu-close"
            >
              <Close />
            </Button>
          ) : (
            <Button
              variant="ghost"
              className="!bg-transparent p-4"
              onClick={() => {
                handleMobileHeader({ isOpen: true, isOpenUser: false });
                interactionTrackingEvent(
                  TrackingEventName.MOBILE_NAV_OPENED,
                  {}
                );
              }}
              data-testid="mobile-menu-open"
            >
              <Menu />
            </Button>
          )}
          <ProfileButton
            onClick={() => {
              handleMobileHeader({ isOpen: false, isOpenUser: false });
              interactionTrackingEvent(
                isOpenUser
                  ? TrackingEventName.MOBILE_PORTAL_CLOSED
                  : TrackingEventName.MOBILE_PORTAL_OPENED,
                {}
              );

              if (window.location.href.includes('/portal/')) {
                router.push('/mycars');
              } else {
                window.location.href = `/portal/de-DE${SelfServiceRoutes.MY_CARS}`;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
