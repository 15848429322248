// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const addSentryRenderPageBreadcrumb = (..._: string[]) => {
  // noop
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const addSentryScopeTag = (..._: string[]) => {
  // noop
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const addSentryScopeUser = (_: any) => {
  // noop
};

export const captureMessage = (...msg: any) => {
  console.error(msg);
};

export const captureException = (error: any) => {
  console.error(error);
};
