import { Input } from '@finn/design-system/atoms/input';
import { shouldShowFieldError } from '@finn/ui-utils';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  label: string;
  form: UseFormReturn;
  autoFocus?: boolean;
};

export const HookFormInput: React.FC<Props> = ({
  name,
  label,
  form,
  autoFocus,
}) => {
  const i18n = useIntl();

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div style={{ position: 'relative' }}>
          <Input
            label={label}
            autoFocus={autoFocus}
            {...field}
            error={
              shouldShowFieldError(fieldState, form.formState)
                ? i18n.formatMessage({
                    id: fieldState?.error?.message,
                  })
                : false
            }
          />
        </div>
      )}
    />
  );
};
