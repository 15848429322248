import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';
import React, { useCallback, useEffect } from 'react';

export type InfoModalData = {
  title?: string;
  description?: string | any[];
  cta?: CTAData;
  onModalClose?(): void;
  closeButtonText?: string;
};

type Props = {
  open: boolean;
  onClose(): void;
  infoModalData: InfoModalData;
  name?: string;
};

export const InfoModal: React.FC<Props> = ({
  open,
  onClose,
  infoModalData,
  name,
}) => {
  const track = useTrackingStore((state) => state.track);
  const onModalClose = useCallback(() => {
    infoModalData?.onModalClose?.();
    if (name) {
      track(TrackingEventName.MODAL_CLOSED, {
        name,
      });
    }
    onClose();
  }, [infoModalData, name, onClose, track]);

  useEffect(() => {
    if (open && name) {
      track(TrackingEventName.MODAL_OPENED, {
        name,
      });
    }
  }, [name, open, track]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onModalClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{infoModalData?.title}</ModalTitle>
        </ModalHeader>
        <div className="body-16-light">
          {Array.isArray(infoModalData?.description)
            ? infoModalData?.description
            : parseToHtml(infoModalData?.description)}
        </div>
        {infoModalData?.cta ? (
          <ModalFooter>
            <CTA data={infoModalData?.cta?.metadata} />
          </ModalFooter>
        ) : infoModalData?.closeButtonText ? (
          <ModalFooter>
            <Button size="lg" onClick={onModalClose}>
              {infoModalData?.closeButtonText}
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
