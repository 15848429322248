import axios from 'axios';
import useSWR from 'swr';

// todo: this endpoint is visible from the client side, consider moving to the server side,
//       moreover, the response exposes some data which may be sensitive
export const RETOOL_URL =
  'https://one.finn.auto/retool/v1/workflows/b4a3d43c-e956-4839-b881-b123458df491/startTrigger?workflowApiKey=retool_wk_e186d48bfbb641e2a7017fb3dd5c5a6f';
export const DEDUPING_INTERVAL = 300000;

type CarSaleData = {
  data: {
    inventory: {
      vin: string;
      finn_car_id: string;
      aop_rv_presale_target: number;
      aop_stock_rv_damage_equivalent: number;
      aop_stock_rv_km_equivalent: number;
    }[];
  };
};

const fetcher = async ({ url, car_id }: { url: string; car_id: string }) => {
  return axios.post(url, { car_id }, { withCredentials: false });
};

export const useCarSaleInfo = (carId: string) => {
  return useSWR<{ data: CarSaleData }>(
    carId ? { url: RETOOL_URL, car_id: carId } : null,
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
      dedupingInterval: DEDUPING_INTERVAL,
    }
  );
};
