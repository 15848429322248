import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { FilterKey, FILTERS_RESPONSE_SWR_FALLBACK_KEY } from '@finn/ua-vehicle';
import { cn, slugify } from '@finn/ui-utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SWRConfig } from 'swr';

import {
  trackFilterOptionClicked,
  useTrackFilterCategoryClicked,
} from '~/modules/combinedFilters/tracking';
import {
  PathFilterProvider,
  useAvailableFilters,
} from '~/modules/products/filters';
import { CTAData } from '~/types/UIModules';
import CTAs from '~/ui-modules/Hero2/CTAs';

import filters from '../../localization/available.filters.json';

type Props = {
  primaryCTA: CTAData;
  isExpHPHeroFilters?: boolean;
};

const HeroFilterComponent: React.FC<Props> = ({
  primaryCTA,
  isExpHPHeroFilters = false,
}) => {
  const i18n = useIntl();

  const brandPlaceholder = i18n.formatMessage({
    id: 'general.heroFilters.allBrands',
  });
  const modelPlaceholder = i18n.formatMessage({
    id: 'general.heroFilters.allModels',
  });

  const [brand, setBrand] = useState<string>(brandPlaceholder);
  const [model, setModel] = useState<string>(modelPlaceholder);

  const { brands } = useAvailableFilters({
    filters: {},
  });

  const adjustedCTA = useMemo(() => {
    const adjustedBrand = brand === brandPlaceholder ? '' : brand;
    const adjustedModel = model === modelPlaceholder ? '' : model;

    const originalCTAHref = primaryCTA.metadata.href;
    const modelPath = adjustedModel ? `_${slugify(adjustedModel)}` : '';

    const updatedHref = brand
      ? `${originalCTAHref}/${slugify(adjustedBrand)}${modelPath}`
      : originalCTAHref;

    return {
      ...primaryCTA,
      metadata: {
        ...primaryCTA.metadata,
        text: i18n.formatMessage({
          id: 'general.heroFilters.cta',
        }),
        href: updatedHref,
      },
    };
  }, [brand, model, brandPlaceholder, modelPlaceholder]);

  const handleBrandChange = (value: string) => {
    setBrand(value);
    setModel(modelPlaceholder);
    trackFilterOptionClicked(FilterKey.BRANDS, value);
  };

  const handleModelChange = (value: string) => {
    setModel(value);
    trackFilterOptionClicked(FilterKey.MODELS, value);
  };

  return (
    <div
      className={cn('container ml-0 flex flex-col gap-4', {
        'lg:flex-row': !isExpHPHeroFilters,
        'md:flex-row md:gap-4': isExpHPHeroFilters,
      })}
    >
      <div
        className={cn('flex flex-col gap-4', {
          'md:grid md:grid-cols-2': !isExpHPHeroFilters,
          'w-full md:mr-4 md:flex md:flex-row': isExpHPHeroFilters,
        })}
      >
        <Select
          value={brand}
          onValueChange={(value) => handleBrandChange(value)}
          onOpenChange={useTrackFilterCategoryClicked(FilterKey.BRANDS)}
        >
          <SelectTrigger
            label={i18n.formatMessage({ id: 'plp.brand' })}
            className={cn('w-full', {
              'lg:w-40': !isExpHPHeroFilters,
            })}
            wrapperClassName={cn({
              'md:grow min-w-[50%]': isExpHPHeroFilters,
            })}
          >
            <SelectValue
              placeholder={brandPlaceholder}
              className="body-16-regular"
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={brandPlaceholder}>
              <span>{brandPlaceholder}</span>
            </SelectItem>
            {brands.map((option) => (
              <SelectItem key={option.id} value={slugify(option.id)}>
                <span>{option.id}</span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Select
          value={model}
          onValueChange={(value) => handleModelChange(value)}
          onOpenChange={useTrackFilterCategoryClicked(FilterKey.MODELS)}
          disabled={brand === brandPlaceholder}
        >
          <SelectTrigger
            label={i18n.formatMessage({ id: 'plp.model' })}
            className={cn('w-full', {
              'lg:w-40': !isExpHPHeroFilters,
            })}
            wrapperClassName={cn({
              'md:grow min-w-[50%]': isExpHPHeroFilters,
            })}
          >
            <SelectValue
              placeholder={modelPlaceholder}
              className="body-16-regular"
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={modelPlaceholder}>
              <span>{modelPlaceholder}</span>
            </SelectItem>
            {brands
              .find((brandItem) => slugify(brandItem.id) === brand)
              ?.models.map((option) => (
                <SelectItem key={option.id} value={slugify(option.id)}>
                  <span>{option.id}</span>
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>

      <CTAs
        primaryCTA={adjustedCTA}
        fullWidth={true}
        isExpHPHeroFilters={isExpHPHeroFilters}
      />
    </div>
  );
};

const HeroFilters = (props: Props) => {
  const swrConfigValue = useMemo(() => {
    return {
      fallback: {
        [FILTERS_RESPONSE_SWR_FALLBACK_KEY]: filters['de-DE'],
      },
    };
  }, []);

  return (
    <SWRConfig value={swrConfigValue}>
      <PathFilterProvider basePathname={BASE_SUBSCRIPTION_PATH}>
        <HeroFilterComponent {...props} />
      </PathFilterProvider>
    </SWRConfig>
  );
};
export default HeroFilters;
