import { useMediaQuery } from '@finn/design-system/helpers/media';
import { cn } from '@finn/ui-utils';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { getComponentDisplayName } from '../../internal/react';
import { Button } from '../button';
import { ArrowBackIos } from '../icons/generated/arrow-back-ios';
import { Close as CloseIcon } from '../icons/generated/close';

const {
  Root: DrawerRoot,
  Overlay,
  Content,
  Portal,
  Close,
  Trigger,
} = DrawerPrimitive;

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref}
    className={cn(
      // position
      'fixed inset-0 z-[1300] bg-black/45',
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = Overlay.displayName;

const renderDefaultHeader = ({
  onBack,
  title,
}: {
  onBack?: () => void;
  title?: string;
}) => (
  <div
    className={
      'border-pearl h-14 border-0 border-b border-solid bg-white p-4 md:h-[4.5rem] md:px-20 md:py-6'
    }
  >
    <div className="flex justify-between">
      {onBack ? (
        <Button variant="ghost" className="h-max p-0" onClick={onBack}>
          <ArrowBackIos aria-label="back" />
        </Button>
      ) : (
        <p className="body-16-semibold before:hidden after:hidden">{title}</p>
      )}
      <Close asChild>
        <Button variant="ghost" className="h-max p-0">
          <CloseIcon aria-label="close-icon" />
        </Button>
      </Close>
    </div>
  </div>
);

const DrawerContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {
    title?: string;
    direction?: 'right' | 'bottom';
    onBack?: () => void;
    renderHeader?: (props: {
      onBack?: () => void;
      title?: string;
    }) => ReactNode;
    contentClassName?: string;
  }
>(
  (
    {
      className,
      title,
      children,
      direction,
      onBack,
      renderHeader = renderDefaultHeader,
      contentClassName,
      ...props
    },
    ref
  ) => {
    let footer: ReactElement | null = null;
    const restChildren: ReactElement[] = [];

    // we need to render footer separatly from the rest of the children
    Children.forEach(children as ReactElement[], (child) => {
      if (getComponentDisplayName(child) === 'DrawerFooter') {
        footer = child;
      } else {
        restChildren.push(child);
      }
    });

    return (
      <Portal>
        <DialogOverlay />
        <Content
          ref={ref}
          className={cn(
            'fixed bottom-0 z-[1300] flex border border-none bg-white',
            // layout
            'box-border flex flex-col overflow-auto bg-white after:hidden',
            direction === 'right'
              ? 'right-0 top-0 rounded-none md:w-[624px]'
              : 'inset-0 w-full',
            className
          )}
          {...props}
        >
          {renderHeader({ onBack, title })}
          <div
            className={cn(
              'flex max-h-[calc(100%-10rem)] flex-shrink-0 flex-col gap-8 overflow-y-auto p-4 pt-4 md:max-h-[calc(100%-11rem)] md:gap-10 md:px-20',
              {
                '!max-h-full': !footer,
              },
              contentClassName
            )}
          >
            {restChildren}
          </div>
          {footer}
        </Content>
      </Portal>
    );
  }
);
DrawerContent.displayName = 'DrawerContent';

const DrawerFooter = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className="flex h-full w-full items-end">
    <div
      className={cn(
        'border-pearl flex h-[6.5rem] w-full justify-between gap-4 overflow-hidden border-0 border-t border-solid p-4 py-6 md:px-20',
        className
      )}
      {...props}
    >
      {Children.map(children as ReactElement<{ className?: string }>, (child) =>
        cloneElement(child, {
          className: cn('w-full', child?.props?.className),
        })
      )}
    </div>
  </div>
);
DrawerFooter.displayName = 'DrawerFooter';

const Drawer = ({
  direction = 'right',
  children,
  ...props
}: ComponentPropsWithoutRef<typeof DrawerRoot>) => {
  const isMDScreen = useMediaQuery('md');

  return (
    <DrawerRoot direction={direction} {...props} repositionInputs={!isMDScreen}>
      {Children.map(
        children as ReactElement<{ className?: string; direction?: string }>,
        (child) =>
          getComponentDisplayName(child) === 'DrawerContent'
            ? cloneElement(child, { direction })
            : child
      )}
    </DrawerRoot>
  );
};

export { Drawer, Trigger as DrawerTrigger, DrawerContent, DrawerFooter };
