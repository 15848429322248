import { adjustLinkTargetsforWebView } from '../helpers/webViewHelpers';
import {
  CB,
  CreateWebToAppSDK,
  EmitEvent,
  NativeSDK,
  SDKMessage,
} from '../types/general';
import { connectWebToApp } from './connectors';
import { getGlobalObject } from './helpers/globalObject';
import { dom } from './services/dom';
import { tracking } from './services/tracking';

const dummy = () => {
  // empty function used as a placeholder till web overrides it
};

const createWebToAppSDK = (props: CreateWebToAppSDK): NativeSDK => {
  let listeners: { name: string; cb: CB }[] = [];

  const emitEvent: EmitEvent = (message) => {
    window?.ReactNativeWebView?.postMessage?.(JSON.stringify(message));
  };

  const emitSDKEvent = (message: SDKMessage) => {
    switch (message) {
      case 'checkout_ready':
        if (props.platform === 'android') {
          adjustLinkTargetsforWebView();
        }
        break;
    }
  };

  const addListener = (name: string, cb: CB) => {
    const listenerItem = { name, cb };
    listeners.push(listenerItem);

    return () => {
      listeners = listeners.filter((item) => item !== listenerItem);
    };
  };

  const domMethods = dom(emitEvent);
  const trackingMethods = tracking(props);

  const withSlash = (path: string) =>
    path.startsWith('/') ? path : `/${path}`;

  const sdk = {
    init: () => {
      return connectWebToApp({ ...props, addListener, emitEvent });
    },
    navigate: (path: string, app?: 'portal' | string) => {
      const globalObject = getGlobalObject();
      const currentUrl = globalObject.location.href;

      // if path includes http, means that this is an external link and we just change it, for example support links
      if (path.startsWith('http')) {
        globalObject.location.href = path;
        // we are navigating to portal, from not portal, means another app and we need to change location
      } else if (app === 'portal' && !currentUrl?.includes('/portal')) {
        globalObject.location.href = `${props.baseUrl}/portal/${
          props.locale
        }${withSlash(path)}`;
        // we are navigating to not portal, from portal, means another app and we need to change location
      } else if (!app && currentUrl?.includes('/portal')) {
        globalObject.location.href = `${props.baseUrl}/${
          props.locale
        }${withSlash(path)}`;
        // other cases we can just to client side routing as we are inside the same app
      } else {
        globalObject.next?.router.push(path, undefined, { appFirst: true });
      }
    },
    isReady: () => true,
    sendMessageToApp: emitEvent,
    sendMessageToAppSDK: emitSDKEvent,
    on: addListener,
    showContinueFooter: domMethods.pdp.showContinueFooter,
    hideContinueFooter: domMethods.pdp.hideContinueFooter,
    closeTooltip: domMethods.all.closeTooltip,
    closeDialog: domMethods.all.closeDialog,
    closeFullScreenGallery: domMethods.pdp.closeFullScreenGallery,
    fecthCachedZipcode: domMethods.all.fecthCachedZipcode,
    fetchZipcode: domMethods.all.fetchZipcode,
    getTrackingProps: trackingMethods.getTrackingProps,
    setUserTrackingId: trackingMethods.setUserTrackingId,
    updatePLPFilter: dummy,
    fetchFilterValues: dummy,
    getAuthenticationStatus: dummy,
    getCurrentUrl: () =>
      sdk.sendMessageToApp({
        type: 'event:current_url',
        value: window?.location?.href,
      }),

    // method called from app
    callListener: (
      name: string,
      params: { [key: string]: string | number | undefined }
    ) => {
      listeners.find((listener) => listener.name === name)?.cb(params);
    },
    push: (url: string) => {
      getGlobalObject()?.next?.router.push(url, undefined);
    },

    goToURL: (url: string) => {
      const globalObject = getGlobalObject();
      if (globalObject) {
        globalObject.location.href = url;
      }
    },
    verifyEmail: dummy,
    prefetchUrl: (path: string) => {
      const id = `prefetch-link-${path}`;
      if (!document.getElementById(id)) {
        const link = document.createElement('link');
        link.rel = 'prefetch';
        link.href = path;
        link.id = id;
        document.head.appendChild(link);
      }
    },
  };

  return sdk;
};

export { createWebToAppSDK };
