import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';

import { BenefitChip, InfoModalData } from '../../index';

type HowToCollectPointsProps = {
  data?: LoyaltyCosmicMetadata;
  openInfoModal: (infoData: InfoModalData) => void;
  standalone?: boolean;
  className?: string;
};

export const HowToCollectPoints = ({
  data,
  openInfoModal,
  className,
  standalone,
}: HowToCollectPointsProps) => {
  const howToBenefits = data?.how_to_collect_points || [];

  return (
    <div
      className={standalone ? 'mb-0' : 'mb-14 md:mb-32'}
      data-testid="loyalty-benefit-chips"
    >
      <h3
        className="mobile-t3-semibold md:web-t3-semibold flex flex-col gap-8"
        data-testid="how-to-collect-point-title"
      >
        {data?.how_to_collect_points_title}
      </h3>
      <div
        className={cn(
          'grid grid-flow-row grid-cols-[repeat(1,1fr)] gap-4 md:grid-cols-[repeat(3,1fr)] md:gap-6',
          className
        )}
      >
        {howToBenefits.map((benefit) => (
          <BenefitChip
            benefit={benefit?.metadata}
            key={benefit?.id}
            openInfoModal={openInfoModal}
          />
        ))}
      </div>
    </div>
  );
};
