import { ToggleGroup, ToggleItem } from '@finn/design-system/atoms/toggle';
import { useDownPayment } from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import {
  calculateTotalPrice,
  getForBusinessCookie,
  interactionTrackingEvent,
  TrackingEventName,
} from '@finn/ui-modules';
import { cn, getFormattedPrice, trackEvent } from '@finn/ui-utils';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { removeGermanTax } from '~/utils/checkout';

import { useConfigureStore } from '../ConfigurePageStore';

const onTooltipOpenChange = (opened: boolean) => {
  if (opened) {
    interactionTrackingEvent(TrackingEventName.TOOLTIP_HOVERED, {
      name: 'downpayment tooltip',
    });
  }
};

export const DownPayment: React.FC = () => {
  const i18n = useIntl();
  const { isDownPaymentEnabled } = useDownPayment();
  const [isForBusiness, setIsForBusiness] = useState(false);
  const { setDownPayment, downPayment, vehicle, term } = useConfigureStore();

  useEffect(() => {
    const isForBusinessCookie = getForBusinessCookie();
    setIsForBusiness(isForBusinessCookie);
  }, []);

  const downPaymentAmount = isForBusiness
    ? Math.round(
        removeGermanTax(vehicle?.downpayment_prices?.downpayment_fixed_amount)
      )
    : vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const oneTimePrice = getFormattedPrice(i18n, downPaymentAmount);
  const price = calculateTotalPrice({ term, vehicle });
  const downpaymentPrice = calculateTotalPrice({
    term,
    vehicle,
    isDownPayment: true,
    downPaymentAmount,
  });

  const downpaymentDiscount = price - downpaymentPrice;

  if (!vehicle) return null;

  if (!isDownPaymentEnabled) return null;

  const downpaymentToggleDescription = i18n.formatMessage({
    id: 'pdp.downPayment.optionDescriptionWith',
  });
  const regularToggleDescription = `+ ${getFormattedPrice(i18n, downpaymentDiscount, 0)}`;

  return (
    <div>
      <div className={'mb-4 flex flex-row items-center gap-2'}>
        <h6 className="global-t6-semibold">
          {i18n.formatMessage({ id: 'pdp.downPayment.title' })}
        </h6>

        <InfoToolTip
          onOpenChange={onTooltipOpenChange}
          content={i18n.formatMessage({
            id: `pdp.downPayment.tooltip`,
          })}
        />
      </div>

      <ToggleGroup
        className="mt-1"
        cols={2}
        defaultValue={downPayment === true ? 'downpayment' : 'regular'}
        value={downPayment === true ? 'downpayment' : 'regular'}
        onValueChange={(value) => {
          if (value) {
            const withDownpayment = value === 'downpayment';

            trackEvent(`DownPayment Selected`, {
              downPayment: withDownpayment,
            });
            setDownPayment(withDownpayment);
          }
        }}
      >
        {['downpayment', 'regular'].map((value) => (
          <ToggleItem
            key={value}
            variant="regular"
            title={
              value === 'downpayment'
                ? i18n.formatMessage({
                    id: 'pdp.downPayment.optionTitleWith',
                  })
                : i18n.formatMessage({
                    id: 'pdp.downPayment.optionTitleWithout',
                  })
            }
            value={value}
            description={
              value === 'downpayment'
                ? downpaymentToggleDescription
                : regularToggleDescription
            }
            withBadge={value === 'downpayment'}
          />
        ))}
      </ToggleGroup>
      <div
        className={cn(
          'flex h-0 items-center justify-between overflow-hidden opacity-0 transition-all duration-300',
          {
            'h-12 opacity-100': downPayment,
          }
        )}
      >
        <div className="body-14-semibold">
          {i18n.formatMessage({ id: 'pdp.downPayment.oneTimePrice' })}
        </div>
        <div className="body-14-regular">{oneTimePrice}</div>
      </div>
    </div>
  );
};
