import { trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { forwardRef } from 'react';

import { useModuleDefaults } from '../../services/content/helpers';
import { SmoochChat } from './SmoochChat';
import { UltimateChat } from './UltimateChat';

export type ChatRef = {
  openWidget: () => void;
  closeWidget: () => void;
};

type ChatProps = {
  translations?: { [key: string]: string };
};

const shouldRenderZendesk = true;

const handleChatOpen = () => trackEvent('Chat Opened', {});

export const Chat = forwardRef<ChatRef, ChatProps>((props, ref) => {
  const { locale } = useCurrentLocale();
  const [content, isLoading] = useModuleDefaults('chat', locale);

  // in case if chat is used outside of new self service platform
  // we will use default translations, defined in cosmic chat module https://app-v1.cosmicjs.com/finnauto/edit-object/65dcc7a9d9e9da4617dd6719
  const translations =
    props.translations || content?.metadata?.translations || {};

  // if chat used outside platform, we need to wait for translations to be loaded
  if (isLoading && !props.translations) {
    return null;
  }

  return shouldRenderZendesk ? (
    <SmoochChat
      {...props}
      translations={translations}
      onChatOpened={handleChatOpen}
      ref={ref}
    />
  ) : (
    <UltimateChat {...props} ref={ref} onChatOpened={handleChatOpen} />
  );
});
