import { useDeals } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { HeaderContext } from '@finn/ua-header';
import {
  NAVIGATION_TRACKING_EVENTS,
  navigationTrackingEvent,
  TrackingEventName,
} from '@finn/ua-tracking';
import { NavbarData } from '@finn/ui-cosmic';
import {
  cn,
  type HandleClickParams,
  isServer,
  useSession,
} from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ProfileButton } from '../../../Components/ProfileButton';
import { helpCenterIconClicked } from '../../../tracking';
import DropdownLinksList from './DropdownLinksList';

type Props = {
  data: NavbarData['userAccountLinks'];
};

const NavbarUserAccount: React.FunctionComponent<Props> = ({ data }) => {
  const [session] = useSession();
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true);
  const { CartButton } = useContext(HeaderContext);
  const router = useRouter();

  const isVerified = !!session?.user.email_verified;
  const contactId = session?.user?.hs_contact_id;
  const shouldFetch = !!contactId && isVerified;
  const { deals, dealsStatus } = useDeals({ shouldFetch });

  const helpLink = data?.helpLink;
  const customer_id = session?.user?.hs_contact_id;
  helpLink?.href === 'https://support.finn.com/hc/de-de' &&
    (helpLink.href = `${helpLink.href}${
      customer_id ? `?customer_id=${customer_id}` : ''
    }`);

  const [trackHelpCenterClick, setTrackHelpCenterClick] =
    useState<boolean>(false);

  useEffect(() => {
    // Since session is null when user is not logged in and undefined if it is still loading
    if (session || session === null) {
      setIsSessionLoading(false);
    }
  }, [session]);

  useEffect(() => {
    if (
      trackHelpCenterClick &&
      (dealsStatus === 'success' || dealsStatus === 'error')
    ) {
      helpCenterIconClicked({
        is_customer: Boolean(deals?.length) || false,
      });

      setTrackHelpCenterClick(false);
    }
  }, [trackHelpCenterClick, dealsStatus, deals]);

  const onHelpClick = useCallback(({ link, type }: HandleClickParams) => {
    navigationTrackingEvent({
      eventName: TrackingEventName.NAVITEM_CLICKED,
      link,
      type,
    });

    setTrackHelpCenterClick(true);

    if (!isServer()) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.open(link, '_blank').focus();
      }, 200);
    }
  }, []);
  const dropDownOption = Object.keys(data?.dropdownOptions || {});
  const useAccountDropDownList =
    dropDownOption.length > 1 || dropDownOption[0] !== 'sign_out';

  if (isSessionLoading) {
    return <div className="flex items-center justify-between" />;
  }

  return (
    <div className="flex items-center justify-between">
      {helpLink?.href && (
        <a
          href={helpLink.href}
          className={cn(
            'body-16-semibold relative cursor-pointer rounded-sm px-3 py-4',
            'hover:border-pearl hover:bg-snow border border-solid border-white text-black'
          )}
          id="help-center"
          onClick={() =>
            onHelpClick({
              link: helpLink.href,
              type: NAVIGATION_TRACKING_EVENTS.MAIN,
            })
          }
        >
          {helpLink?.label}
        </a>
      )}
      {CartButton && <CartButton />}
      <div className="group ml-1 box-content">
        <ProfileButton
          id="dropdown-button"
          onClick={() => {
            if (window.location.href.includes('/portal/')) {
              router.push('/mycars');
            } else {
              window.location.href = `/portal/de-DE${SelfServiceRoutes.MY_CARS}`;
            }
          }}
        />
        {useAccountDropDownList && session && (
          <div
            id="dropdown"
            className={cn('absolute -right-4 z-[99] box-content hidden pt-2')}
          >
            <div className="box-content w-48 rounded-sm bg-white p-4 drop-shadow-2xl">
              <DropdownLinksList data={data.dropdownOptions} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarUserAccount;
