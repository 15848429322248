import { isServer } from '../helpers/webViewHelpers';
import { CreateWebToAppSDK } from '../types/general';
import { getGlobalObject } from './helpers/globalObject';
import { createWebToAppSDK } from './sdk';

export const run = () => {
  const globalObject = getGlobalObject();
  /**
   * We expose onWebReadyToConnectToApp to garantee SDK back compability for older app versions
   *
   */
  globalObject?.onWebReadyToConnectToApp?.((props: CreateWebToAppSDK) => {
    const webToAppSDK = createWebToAppSDK(props);
    webToAppSDK.init();

    // we expose nativeAppSDK on window to allow native app to use it
    // it also used during e2e tests run
    globalObject.nativeAppSDK = webToAppSDK;
  });
};

if (!isServer()) {
  run();
}
