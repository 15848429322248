import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { twoDecimalPlaces } from '@finn/ui-utils';
import { get } from 'lodash';

import { getKmPackagePriceWithoutVat } from './pdp';

export enum CheckoutStepOrder {
  contact = 1,
  payment = 2,
  confirmation = 3,
  thankYou = 4,
}

export const getVehicleMonthlyPrice = (
  vehicle: GenericVehicleDetails | undefined,
  term: number | undefined,
  kmPackage: number | undefined,
  isForBusiness?: Boolean,
  isDownPayment?: Boolean
): number => {
  const priceKey = isDownPayment ? 'downpayment_prices' : 'price';
  const clientTypeStr = isForBusiness ? 'b2b' : 'b2c';
  const price =
    get(vehicle, `${priceKey}.available_price_list.${clientTypeStr}_${term}`) ||
    get(vehicle, `${priceKey}.${clientTypeStr}_${term}`, 0);
  const kmPackagePrice = get(vehicle, `price.extra_${kmPackage}`, 0);

  const total =
    price + getKmPackagePriceWithoutVat(kmPackagePrice, !!isForBusiness);

  return twoDecimalPlaces(total);
};
