import { EmitEvent } from '../../types/general';
import { getGlobalObject } from '../helpers/globalObject';

const CHECK_COOKIR_BANNER_TIMER = 20;

const setupTrackingPermissions = () => {
  const denyAllConsents = (intervalId?: ReturnType<typeof setInterval>) => {
    const globalObject = getGlobalObject();
    if (globalObject?.UC_UI) {
      globalObject?.UC_UI?.denyAndCloseCcpa();
      clearInterval(intervalId);
    }
  };

  // we try to deny instatly
  denyAllConsents();

  // we will try to deny with interval to wait till appears
  const intervalId = setInterval(() => {
    denyAllConsents(intervalId);
  }, CHECK_COOKIR_BANNER_TIMER);
};

export const setupAnalytics = (emitEvent: EmitEvent) => {
  setupTrackingPermissions();
  const globalObject = getGlobalObject();

  // web relays on UC_Integrations for some conditions
  // but by default we disable them, so we need to put some override
  globalObject.UC_Integrations = {};

  Object.defineProperties(globalObject, {
    analytics: {
      writable: true,
      value: {
        track: (event: string, properties: Object, options: Object) => {
          emitEvent({
            type: 'event:track',
            event,
            properties,
            options,
          });
        },
        page: (path: string, properties: Object, options: Object) => {
          emitEvent({
            type: 'event:page',
            path,
            properties,
            options,
          });
        },
        group: (groupId: string, properties: Object, options: Object) => {
          emitEvent({
            type: 'event:group',
            groupId,
            properties,
            options,
          });
        },
        identify: (...args: any[]) => {
          const data: {
            userId?: string;
            properties: Object;
            options?: Object;
          } = {
            userId: '',
            properties: {},
            options: undefined,
          };
          if (typeof args[0] === 'string') {
            const [userId, properties, options] = args;
            data.userId = userId;
            data.properties = properties;
            data.options = options as Object;
          } else {
            const [properties, options] = args;
            data.properties = properties;
            data.options = options;
          }
          emitEvent({
            type: 'event:identify',
            ...data,
            properties: {
              ...data.properties,
              app_version:
                globalObject.nativeAppSDK?.getTrackingProps()?.app_version,
            },
          });
        },
        load: () => {
          emitEvent({
            type: 'event:load',
          });
        },
      },
    },
  });
};
