'use client';

import { cn } from '@finn/ui-utils';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { Command as CommandPrimitive } from 'cmdk';
import {
  Children,
  ComponentPropsWithoutRef,
  createContext,
  Dispatch,
  ElementRef,
  forwardRef,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { getComponentDisplayName } from '../../internal/react';

type MenuContextType = {
  open: boolean;
  value?: string | string[];
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setValue?: (value: string) => void;
};

export const MenuContext = createContext<MenuContextType>({
  open: false,
});

const MenuTrigger = PopoverPrimitive.Trigger;
MenuTrigger.displayName = 'MenuTrigger';

const MenuItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    highlighted?: boolean;
  }
>(({ className, children, highlighted, ...props }, ref) => {
  const { setOpen, setValue } = useContext(MenuContext);

  return (
    <CommandPrimitive.Item
      ref={ref}
      className={cn(
        'relative grid min-h-14 w-full cursor-default grid-cols-[min_content,1fr] items-center gap-4',
        'body-16-regular px-6 py-4 before:hidden after:hidden',
        'hover:bg-cotton data-[highlighted]:bg-cotton data-[selected=true] aria-selected:bg-cotton',
        'outline-none',
        className
      )}
      onSelect={(nextValue) => {
        setValue?.(nextValue);
        setOpen?.(false);
      }}
      {...props}
    >
      {children}
      {highlighted && (
        <div className="bg-trustedBlue absolute right-6 h-2 w-2 rounded-full" />
      )}
    </CommandPrimitive.Item>
  );
});

MenuItem.displayName = 'MenuItem';

const MenuList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
));

MenuList.displayName = 'MenuList';

const Menu = forwardRef<
  ElementRef<typeof CommandPrimitive>,
  ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, children, onValueChange, ...props }, ref) => {
  const [open, setOpen] = useState(false);

  let trigger = null;
  const restChildren: ReactElement[] = [];

  // we need to render trigger separatly from the rest of the children
  Children.forEach(children as ReactElement[], (child) => {
    if (getComponentDisplayName(child) === 'MenuTrigger') {
      trigger = child;
    } else {
      restChildren.push(child);
    }
  });

  const handleValueChange = (nextValue: string) => {
    onValueChange?.(nextValue);
  };

  return (
    <MenuContext.Provider
      value={{ value: props.value, open, setOpen, setValue: handleValueChange }}
    >
      <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
        {trigger}
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            side="bottom"
            align="start"
            className={cn(
              'relative z-[10000] max-h-60 min-w-60 overflow-hidden rounded-sm bg-white shadow-md',
              // animations
              'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
              'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
              className
            )}
          >
            <CommandPrimitive
              ref={ref}
              tabIndex={0}
              className={cn('relative w-full py-4', className)}
              {...props}
            >
              {restChildren}
            </CommandPrimitive>
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </MenuContext.Provider>
  );
});
Menu.displayName = 'Menu';

export { Menu, MenuTrigger, MenuItem, MenuList };
