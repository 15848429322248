import { useIsCosmicHelperEnabled } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { ReactNode, useState } from 'react';

type Props = {
  data: any;
  isBoxed?: boolean;
  children?: ReactNode;
};

export const ModuleHelperWrapper = ({ children, data, isBoxed }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const isCosmicHelperEnabled = useIsCosmicHelperEnabled();

  if (!isCosmicHelperEnabled) {
    return <>{children}</>;
  }

  return (
    <div
      className={cn('relative', isHovering ? 'ring-lightOrange ring-4' : '')}
    >
      <a
        href={`https://app-v1.cosmicjs.com/finnauto/edit-object/${data?.id}`}
        className={cn(
          'border-iron bg-snow body-12-regular absolute left-4 top-4 rounded-sm border border-solid px-1.5 before:hidden after:hidden',
          { '-left-12 top-0': isBoxed }
        )}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        Edit{isHovering ? ` - ${data?.title || data?.type || data?.slug}` : ''}
      </a>
      {children}
    </div>
  );
};
