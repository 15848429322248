import { CreateWebToAppSDK, NativeSDK } from '../../types/general';

export type NavigationMethod = (
  url: string | { pathname: string },
  as: string | undefined,
  params?: object
) => unknown;

type CustomWindow = {
  ReactNativeWebView?: {
    postMessage?: (msg: string) => void;
  };
  analytics: {
    track: (name: string, props: object, options?: object) => void;
    page: (name: string, props: object, options?: object) => void;
    group: (name: string, props: object, options?: object) => void;
    identify: (...args: any[]) => void;
    load: () => void;
  };
  UC_Integrations?: { [key: string]: boolean | string };
  UC_UI?: {
    denyAndCloseCcpa: () => void;
    acceptAllConsents: () => Promise<void>;
    closeCMP: () => void;
  };
  AF?: () => void;
  next?: {
    router: {
      locale?: string;
      state?: {
        asPath?: string;
      };
      events: {
        on: (name: string, cb: (url: string) => void) => void;
      };
      push: NavigationMethod;
      replace: NavigationMethod;
    };
  };
  innerHeight: number;
  fetch: typeof window.fetch;
  navigator: typeof window.navigator;
  location: typeof window.location;
  console: typeof window.console;
  localStorage: typeof window.localStorage;
  nativeAppSDK?: NativeSDK;
  onWebReadyToConnectToApp?: (cb: (props: CreateWebToAppSDK) => void) => void;
};

export const getGlobalObject = () => window as unknown as CustomWindow;
