import { Button } from '@finn/design-system/atoms/button';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { parseToHtml } from '@finn/ui-utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const SERVER_ERROR_CODES = new Set([
  'wrong_password',
  'user_does_not_exist',
  'user_already_exists',
]);

type CTAButtonProps = {
  label: string;
  onClick: () => void;
  errorCode?: string | null;
  disabled?: boolean;
};

export const CTAButton = ({
  onClick,
  label,
  errorCode,
  disabled,
}: CTAButtonProps) => {
  const i18n = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  let errorMessage = i18n.formatMessage({
    id: 'userAccount.serverErrors.generalError',
  });

  if (errorCode && SERVER_ERROR_CODES.has(errorCode)) {
    errorMessage = i18n.formatMessage({
      id: `userAccount.serverErrors.${errorCode}`,
    });
  }

  return (
    <>
      {errorCode && (
        <div className="mb-4 flex">
          <InfoOutlined className="text-red mr-2 min-h-4 min-w-4" />
          <p className="text-red body-14-regular">{errorMessage}</p>
        </div>
      )}
      <Button
        size="lg"
        className="my-2 h-14 w-full p-0 sm:mb-4"
        loading={isLoading || disabled}
        onClick={() => {
          handleSubmit();
        }}
      >
        <span>{parseToHtml(label)}</span>
      </Button>
    </>
  );
};
