import { CookieKeys, getClientCookie } from './cookies';
import { capitalizeFirstLetter } from './strings';

// formatted utm source to be stored in the cookies
export const formatUtmSourceValue = (dataSource: string): string => {
  let formattedDataSource = dataSource;
  if (formattedDataSource === 'meta') {
    formattedDataSource = 'Facebook';
  }

  return encodeURIComponent(
    `Inbound - ${capitalizeFirstLetter(formattedDataSource)}`
  );
};

export const getB2BFormSource = (formName: string) => {
  return (
    decodeURIComponent(getClientCookie(CookieKeys.UTM_SOURCE) ?? '') ||
    `Inbound - ${formName}`
  );
};
