/* eslint-disable max-lines */
import { OUT_OF_STOCK_LIMIT } from '@finn/auto-ui/modules/products/list';
import {
  getProductListItems,
  GetProductListItemsResponse,
} from '@finn/auto-ui/modules/products/list/api';
import { AdjustedProductListResults } from '@finn/auto-ui/types/productListing';
import {
  BASE_SUBSCRIPTION_PATH,
  BRAND,
  PLP_SEO_HEADER_TEMPLATES,
  VEHICLE_VAT,
} from '@finn/ua-constants';
import {
  DATE_IN_14_DAYS,
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
  GenericVehicleDetails,
  VehicleViewKey,
} from '@finn/ua-vehicle';
import { USPBannerData } from '@finn/ui-cosmic';
import {
  getLocaleFromContext,
  getTermLabel,
  Locale,
  replaceJSRenderTemplate,
  replaceStrKeys,
  slugify,
  withLocaleRedirect,
} from '@finn/ui-utils';
import get from 'lodash/get';
import { Redirect } from 'next/types';
import { IntlShape } from 'react-intl';

import { getFilter } from '~/services/fleet';
import {
  BaseCosmicObject,
  PLPPageData,
  SeoHeaderData,
} from '~/types/cosmicModules';
import {
  Filters,
  InitialFilterKeysMap,
  Option,
  VehicleView,
} from '~/types/filter';
import { NextServerSideContext } from '~/types/nextjs';
import { getInitialFilterKeysMap, getTermUrlName } from '~/utils/filter';
import { isElectricVehicle } from '~/utils/pdp';
import { getCurrentMonthInShort, getCurrentYear } from '~/utils/time';

import { getCachedCosmicObject } from '../cosmic';
import { getContentPieces } from './cosmic';

type FilterArrays = Pick<
  FilterValuesObject,
  | typeof FilterKey.BRANDS
  | typeof FilterKey.MODELS
  | typeof FilterKey.TERMS
  | typeof FilterKey.CAR_TYPES
  | typeof FilterKey.FUELS
>;

export const getSingleSelectItem = (
  filters: FilterArrays,
  filterKey: FilterKey
) => (filters[filterKey]?.length === 1 ? filters[filterKey][0] : '');

export const isSingleSelectItem = (
  filters: FilterArrays,
  filterKey: FilterKey
) => filters[filterKey]?.length === 1;

type SeoInfos = {
  vehiclesCount: number;
  minPrice: string;
  seoHeader: SeoHeaderData;
  finnBrand?: string;
  filters: FilterValuesObject;
  initialFilterKeysMap: InitialFilterKeysMap;
};

// Replace seo keys data with real values
export const getSeoHeader = ({
  vehiclesCount,
  minPrice,
  seoHeader,
  finnBrand = BRAND,
  filters,
}: SeoInfos) => {
  const brand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const model = getSingleSelectItem(filters, FilterKey.MODELS);
  const fuel = getSingleSelectItem(filters, FilterKey.FUELS);
  const cartype = getSingleSelectItem(filters, FilterKey.CAR_TYPES);

  const title = get(seoHeader, 'metadata.title', '');
  const description = get(seoHeader, 'metadata.description', '');
  const seoData = {
    month: getCurrentMonthInShort(),
    year: getCurrentYear(),
    price: minPrice,
    model,
    brand,
    fuel,
    cartype,
    count: vehiclesCount,
    finn: finnBrand,
  };
  const actualTitle = replaceStrKeys(title, seoData);
  const actualDescription = replaceStrKeys(description, seoData);

  return {
    ...seoHeader,
    metadata: {
      ...seoHeader?.metadata,
      title: actualTitle,
      description: actualDescription,
      og_description: actualDescription,
      og_title: actualTitle,
    },
  };
};

const getFilterValue = (
  initialFilterKeysMap: InitialFilterKeysMap,
  filters: FilterValuesObject,
  key: FilterKey
) => {
  if (!isSingleSelectItem(filters, key)) return '';
  const filterValue = get(filters, `${key}[0]`) as string;
  if (!filterValue) return '';

  return initialFilterKeysMap[slugify(filterValue)]?.displayedName || '';
};

// Replace headline keys data with real values
export const getHeadline = async (
  pageData: PLPPageData,
  filters: FilterValuesObject,
  initialFilterKeysMap: InitialFilterKeysMap
) => {
  const { headlines_templates: templates } = pageData.metadata;
  const titleTemplate = templates.title_template;
  const descriptionTemplate = templates.description_template;

  const defaultTitle = templates?.cars || '';
  const hitchText = templates?.hitch || '';
  const youngDriverText = templates?.young_driver || '';
  const dealsText = templates?.has_deals || '';

  const model = getFilterValue(initialFilterKeysMap, filters, FilterKey.MODELS);
  const brand = getFilterValue(initialFilterKeysMap, filters, FilterKey.BRANDS);
  const cartype = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.CAR_TYPES
  );
  const fuel = getFilterValue(initialFilterKeysMap, filters, FilterKey.FUELS);
  const gear = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.GEARSHIFTS
  );
  const color = getFilterValue(initialFilterKeysMap, filters, FilterKey.COLORS);
  const retention = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.RETENTION
  );
  const hitch = filters.has_hitch ? hitchText : '';
  const youngDriver = filters.has_hitch ? youngDriverText : '';
  const has_deals = filters.has_deals ? dealsText : '';

  const data = {
    model,
    brand,
    cartype,
    fuel,
    gear,
    color,
    hitch,
    retention,
    has_deals,
    youngDriver,
  };

  const title = replaceStrKeys(titleTemplate, data).trim() || defaultTitle;

  const description = await replaceJSRenderTemplate(descriptionTemplate, data);

  return { title, description };
};

type USPBannerTemplateData = {
  product_count: number;
  min_price: number;
  brand_list: string[];
  model_list: string[];
  cartype_list: string[];
};

export const getUSPBanner = async (
  pageData: PLPPageData,
  templateData: USPBannerTemplateData,
  campaignName: string
): Promise<string | USPBannerData> => {
  try {
    const uspBannerCosmicContent =
      pageData?.metadata?.usp_banners?.[`campaign_${campaignName}`]?.metadata;

    if (!uspBannerCosmicContent) {
      return '';
    }

    const variant = uspBannerCosmicContent.variant.key;
    const title = await replaceJSRenderTemplate(
      uspBannerCosmicContent.title,
      templateData
    );

    return {
      variant,
      title,
      paid_marketing_campaign: uspBannerCosmicContent.paid_marketing_campaign,
    };
  } catch (e) {
    return '';
  }
};

export const getPromoBannerHtml = (
  pageData: BaseCosmicObject<any>,
  isPdp: boolean,
  isMobile: boolean
) => {
  const bannerSuffix = isMobile ? 'mobile' : 'desktop';
  const path = `metadata.${
    isPdp ? 'content.' : ''
  }promo_banners.promo_${bannerSuffix}.content`;

  return get(pageData, path, '') as string;
};

export const getUSPBannerData = (
  filters: FilterValuesObject,
  totalResults: number,
  minPrice: number,
  initialFilterKeysMap: InitialFilterKeysMap = {}
): USPBannerTemplateData => {
  const brandKey = getSingleSelectItem(filters, FilterKey.BRANDS);
  const brand = initialFilterKeysMap[slugify(brandKey)]?.displayedName || '';

  const modelKey = getSingleSelectItem(filters, FilterKey.MODELS);
  const model = initialFilterKeysMap[slugify(modelKey)]?.displayedName || '';

  const cartypeKey = getSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const cartype =
    initialFilterKeysMap[slugify(cartypeKey)]?.displayedName || '';

  return {
    product_count: totalResults,
    min_price: minPrice,
    brand_list: [brand],
    model_list: [model],
    cartype_list: [cartype],
  };
};

export const getMetaDataKey = (filters: FilterValuesObject): string => {
  const considerBrands = isSingleSelectItem(filters, FilterKey.BRANDS);
  const considerCarTypes = isSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const considerModels = isSingleSelectItem(filters, FilterKey.MODELS);
  const considerElectricFuelType =
    isSingleSelectItem(filters, FilterKey.FUELS) &&
    isElectricVehicle(filters[FilterKey.FUELS][0]);
  const considerFuelType = isSingleSelectItem(filters, FilterKey.FUELS);

  //TODO: clean up combinations, names, and also SEO HEADERS in Cosmic on PLP object
  switch (true) {
    case considerBrands && considerCarTypes:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_CARTYPES;
    case considerBrands && considerModels:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_MODELS;
    case considerBrands && considerFuelType:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_FUELTYPE;
    case considerBrands:
      return PLP_SEO_HEADER_TEMPLATES.BRANDS;
    case considerCarTypes:
      return PLP_SEO_HEADER_TEMPLATES.CARTYPES;
    case considerElectricFuelType:
      return PLP_SEO_HEADER_TEMPLATES.ELECTRIC;
    case !!filters.has_deals:
      return PLP_SEO_HEADER_TEMPLATES.DEALS;
    case !!filters.is_young_driver:
      return PLP_SEO_HEADER_TEMPLATES.YOUNG_DRIVER;
    case !!filters.has_hitch:
      return PLP_SEO_HEADER_TEMPLATES.HITCH;
    default:
      return PLP_SEO_HEADER_TEMPLATES.FALLBACK;
  }
};

export const getPaidMarketingCampaignSlug = (campaign): string => {
  const result = `paid-marketing-campaign-banner-${campaign}`;

  return slugify(result);
};

export const isAdvisoryContent = (
  filters: Filters,
  filterKeys: (keyof Filters)[]
): boolean => {
  // Check that each specified key has exactly one item and is an array
  const singleItemFilters = filterKeys.every((key) => {
    const value = filters[key];

    return Array.isArray(value) && value.length === 1;
  });

  // Consider the available_to filter if present without the available_from filter
  const quickAvailabilityFilter =
    Object.keys(filters).includes(FilterKey.AVAILABLE_TO) &&
    !Object.keys(filters).includes(FilterKey.AVAILABLE_FROM);

  // Ensure no extra filters are present beyond what's specified in filterKeys
  const onlySpecifiedFilters = Object.keys(filters).every((key) =>
    filterKeys.includes(key as keyof Filters)
  );

  return (singleItemFilters || quickAvailabilityFilter) && onlySpecifiedFilters;
};

const getSlugSegment = (filterValue: string[] | undefined): string => {
  return filterValue ? `-${filterValue[0]}` : '';
};

export const getAdvisoryContentSlug = (filters: Filters): string => {
  const slugBase = 'plp-advisory';
  let slugSuffix = '-fallback';

  const FilterKeys = {
    Fuels: 'fuels',
    Terms: 'terms',
    Deals: 'deals',
    Hitch: 'has_hitch',
    YoungDriver: 'is_young_driver',
    Brands: 'brands',
    Models: 'models',
    CarTypes: 'cartypes',
    Gearshifts: 'gearshifts',
    Availability: 'available_to',
  } as const;

  const advisoryChecks = [
    [FilterKeys.Brands, FilterKeys.CarTypes],
    [FilterKeys.Brands, FilterKeys.Models],
    [FilterKeys.Brands, FilterKeys.Fuels],
    [FilterKeys.Brands],
    [FilterKeys.CarTypes],
    [FilterKeys.Gearshifts],
    [FilterKeys.Fuels, FilterKeys.CarTypes],
    [FilterKeys.Fuels],
  ];

  for (const check of advisoryChecks) {
    if (isAdvisoryContent(filters, check)) {
      slugSuffix = check
        .map((key: string | number) => getSlugSegment(filters[key]))
        .join('');
      break;
    }
  }

  // if the deals filter is present, we need to append the deals slug
  if (filters.has_deals) {
    slugSuffix = getSlugSegment([FilterKeys.Deals]);
  }

  if (filters.is_young_driver) {
    slugSuffix = getSlugSegment([FilterKeys.YoungDriver]);
  }

  if (filters.has_hitch) {
    slugSuffix = getSlugSegment([FilterKeys.Hitch]);
  }

  if (slugSuffix === '-fallback') {
    // If the terms filter is present, we need to append the terms slug
    if (isAdvisoryContent(filters, [FilterKeys.Terms])) {
      slugSuffix = `-${getTermUrlName(Number(filters.terms![0]))}`;
    }

    // If the 14-day availability filter is present, we need to append the 14-days slug
    if (isAdvisoryContent(filters, [FilterKeys.Availability])) {
      if (filters[FilterKeys.Availability] === DATE_IN_14_DAYS) {
        slugSuffix = `-14-days`;
      }
    }
  }

  return slugify(`${slugBase}${slugSuffix}`);
};

export const getTermsFilterData = (
  terms: Array<number>,
  intl: IntlShape,
  flexTermLabel: string
): Option[] =>
  terms?.map((term: number) => ({
    label: getTermLabel(term, flexTermLabel, intl),
    value: String(term),
  }));

export const getFeaturesFilterData = (
  features: Array<string>,
  intl: IntlShape
): Option[] => {
  return (
    features?.map((feature: string) => ({
      label: intl.formatMessage(
        { id: `plp.features.filters.${feature}` },
        { defaultMessage: '' }
      ),
      value: feature,
    })) || []
  );
};

export const removeSubscribeFromURLPathIfBrandPresent = (
  ctx: NextServerSideContext,
  filters: Filters
) => {
  const { url } = ctx?.req || {};
  const shouldRemoveSubscribe = filters.brands?.length;

  if (shouldRemoveSubscribe) {
    const nextUrl = url.replace('/subscribe', '');
    ctx?.res?.writeHead(301, {
      Location: withLocaleRedirect(nextUrl, getLocaleFromContext(ctx)),
    });
    ctx?.res?.end();
  }
};

export const getMinGrossPrice = (minPrice: number, isForBusiness: boolean) => {
  if (isForBusiness) {
    return Math.ceil(minPrice * VEHICLE_VAT);
  }

  return minPrice;
};

export const getPLPContent = async ({
  isOutOfStock,
  locale,
  filtersResponse,
  filterValues,
  totalResults,
}: {
  isOutOfStock: boolean;
  locale: string;
  filtersResponse: FiltersResponse;
  filterValues: FilterValuesObject;
  totalResults: number;
}) => {
  const { getCosmicObject } = await import('~/services/server/cosmic');
  const advisoryContentCosmicSlug = getAdvisoryContentSlug(
    filterValues as unknown as Filters
  );

  const hasAdvisoryContent = !advisoryContentCosmicSlug.includes('fallback');

  const [nextAvailableFilters, pageData, advisoryContentObject] =
    await Promise.all([
      getFilter(
        {
          ...(filterValues as unknown as Filters),
          view: isOutOfStock
            ? VehicleView.DISPLAYED_CARS
            : locale === Locale.ENGLISH_USA
              ? VehicleView.AVAILABLE_AND_COMING_SOON
              : undefined,
        },
        locale
      ),
      getCachedCosmicObject({ slug: 'static-plp', locale }),
      hasAdvisoryContent
        ? getCosmicObject({
            slug: advisoryContentCosmicSlug,
            showMetafields: true,
            locale,
          })
        : Promise.resolve(null),
    ]);

  const initialFilterKeysMap = getInitialFilterKeysMap(filtersResponse);

  const { seoHeader, headline } = await getContentPieces({
    pageData,
    initialFilter: filterValues,
    totalResults,
    minPriceInFilter: nextAvailableFilters.min_price,
    initialFilterKeysMap,
  });

  return {
    advisoryContentObject,
    seoHeader,
    headline,
    nextAvailableFilters,
    pageData,
  };
};

async function fetchAdjustedProductListItemsResponse(
  filterValues: FilterValuesObject,
  locale: string
): Promise<GetProductListItemsResponse> {
  return await getProductListItems({
    filters: {
      ...filterValues,
      [FilterKey.VIEW]: VehicleViewKey.DISPLAYED_CARS,
    },
    limit: OUT_OF_STOCK_LIMIT,
    locale,
  });
}

const areAllItemsOutOfStock = (results: GenericVehicleDetails[]): boolean =>
  results.every((item) => item.availability === null);

const createRedirect = (localePrefix: string): Redirect => ({
  permanent: false,
  destination: `/${localePrefix}/${BASE_SUBSCRIPTION_PATH}`,
});

export const handleProductList = async (
  locale: string,
  totalResults: number,
  isOutOfStock: boolean,
  localePrefix: string,
  filterValues: FilterValuesObject
): Promise<AdjustedProductListResults | { redirect: Redirect }> => {
  if (!totalResults && !isOutOfStock) {
    const { results: adjustedVehicles, total_results: adjustedTotalResults } =
      await fetchAdjustedProductListItemsResponse(filterValues, locale);

    if (areAllItemsOutOfStock(adjustedVehicles)) {
      return {
        adjustedIsOutOfStock: true,
        adjustedVehicles,
        adjustedTotalResults,
      };
    } else {
      return { redirect: createRedirect(localePrefix) };
    }
  }
};
