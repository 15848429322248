import { cn } from '@finn/ui-utils';
import omit from 'lodash/omit';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';

import { useMediaQuery } from '../../helpers/media';
import { getComponentDisplayName } from '../../internal/react';
import * as Dialog from './dialog';
import * as Drawer from './drawer';

export const ModalContent = forwardRef<
  ElementRef<(typeof Dialog)['DialogContent']>,
  ComponentPropsWithoutRef<(typeof Dialog)['DialogContent']> & {
    drawer?: boolean;
    renderHeader?: ({ onBack }: { onBack?: () => void }) => ReactNode;
  }
>(({ drawer, children, renderHeader, ...props }, ref) => {
  const Component = drawer ? Drawer.DrawerContent : Dialog.DialogContent;

  // TODO sadly outside click does not trigger this function
  // so to test it we need to do smth smarter
  // istanbul ignore next
  const handleOutsideClick = useCallback((event: any) => {
    // we take care of the race condition where the usercentrics modal
    // can close the dialog below it
    // istanbul ignore next
    if (
      (event?.target as HTMLDivElement)?.closest?.('#usercentrics-root') ||
      (event?.target as HTMLDivElement)?.closest?.('[aria-label="toast-close"]')
    ) {
      event.preventDefault();
    }
  }, []);

  return (
    <Component
      ref={ref}
      onInteractOutside={handleOutsideClick}
      {...props}
      renderHeader={renderHeader}
    >
      {Children.map(children as ReactElement, (child) =>
        getComponentDisplayName(child)?.startsWith('Modal')
          ? // TODO fix any
            cloneElement(child, { drawer, variant: props.variant } as any)
          : child
      )}
    </Component>
  );
});

ModalContent.displayName = 'ModalContent';

export const ModalHeader = ({
  drawer,
  children,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogHeader']> & {
  drawer?: boolean;
}) => {
  const Component = drawer ? Drawer.DrawerHeader : Dialog.DialogHeader;

  return (
    <Component {...props}>
      {Children.map(children as ReactElement, (child) =>
        getComponentDisplayName(child)?.startsWith('Modal')
          ? // TODO fix as any
            cloneElement(child, { drawer } as any)
          : child
      )}
    </Component>
  );
};

ModalHeader.displayName = 'ModalHeader';

export const ModalFooter = ({
  drawer,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogFooter']> & {
  drawer?: boolean;
}) => {
  const Component = drawer ? Drawer.DrawerFooter : Dialog.DialogFooter;

  return <Component {...props} />;
};

ModalFooter.displayName = 'ModalFooter';

export const ModalTitle = ({
  drawer,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogTitle']> & {
  drawer?: boolean;
}) => {
  const Component = drawer ? Drawer.DrawerTitle : Dialog.DialogTitle;

  return <Component {...props} />;
};

ModalTitle.displayName = 'ModalTitle';

export const ModalSubtitle = ({
  drawer,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogSubtitle']> & {
  drawer?: boolean;
}) => {
  const Component = drawer ? Drawer.DrawerSubtitle : Dialog.DialogSubtitle;

  return <Component {...props} />;
};

ModalSubtitle.displayName = 'ModalSubtitle';

// TODO replace custom div with proper label
export const ModalBadge = forwardRef<
  ElementRef<(typeof Dialog)['DialogSubtitle']>,
  ComponentPropsWithoutRef<(typeof Dialog)['DialogSubtitle']>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('body-12-regular bg-snow w-max rounded-sm p-2', className)}
    {...omit(props, 'drawer')}
  />
));

ModalBadge.displayName = 'ModalBadge';

export const ModalTrigger = ({
  drawer,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogTrigger']> & {
  drawer?: boolean;
}) => {
  const Component = drawer ? Drawer.DrawerTrigger : Dialog.DialogTrigger;

  return <Component {...props} />;
};

ModalTrigger.displayName = 'ModalTrigger';

export const Modal = ({
  children,
  variant,
  ...props
}: ComponentPropsWithoutRef<(typeof Dialog)['DialogRoot']> & {
  variant?: 'large' | 'small';
  onClose?: () => void;
}) => {
  const isMDScreen = useMediaQuery('md');
  const drawer = isMDScreen && variant === 'small';

  const Component = drawer ? Drawer.DrawerRoot : Dialog.DialogRoot;

  return (
    <Component {...props} repositionInputs={!isMDScreen}>
      {Children.map(children as ReactElement, (child) =>
        getComponentDisplayName(child)?.startsWith('Modal')
          ? // TODO fix as any
            cloneElement(child, { drawer, variant } as any)
          : child
      )}
    </Component>
  );
};

Modal.displayName = 'Modal';
