import { getAppSDK } from '../../helpers';
import { getGlobalObject } from '../helpers/globalObject';
import { Pages } from './constants';

const handleTelephoneLinkPress =
  (element: HTMLAnchorElement) => (event: MouseEvent) => {
    event.preventDefault();
    getAppSDK()?.sendMessageToApp({
      type: 'event:tel_link_pressed',
      value: element.href,
    });
  };

const handleEmailLinkPress =
  (element: HTMLAnchorElement) => (event: MouseEvent) => {
    event.preventDefault();
    getAppSDK()?.sendMessageToApp({
      type: 'event:email_link_pressed',
      value: element.href,
    });
  };

const handleHelplinePress = (divId: string) => {
  const telephoneLink = document.querySelector<HTMLAnchorElement>(
    `[data-appid=${divId}] a[href^=tel]`
  );

  if (telephoneLink) {
    telephoneLink.onclick = handleTelephoneLinkPress(telephoneLink);
  }
};

const handleHelpEmailPress = () => {
  const emailLink = document.querySelector<HTMLAnchorElement>(
    "[data-appid='flipper-module-content'] > p:nth-child(6) > a:nth-child(1)"
  );

  if (emailLink) {
    emailLink.onclick = handleEmailLinkPress(emailLink);
  }
};

export const PageHandlersWhenInteractive = {
  [Pages.PDP]: () => {
    handleHelplinePress('flipper-module-content');
    handleHelpEmailPress();
  },

  [Pages.Checkout]: () => {
    handleHelplinePress('checkout-header');
  },
};

// fallback to hide close modal button for the browsers, which don't support :has() pseudo-class
const hideCloseDialogButton = () => {
  const dialog = document.querySelector("[role='dialog']");
  if (dialog) {
    const closeIcon = document.querySelectorAll('.menu-close-icon');
    // if modal is open and the close icon is present
    if (closeIcon.length > 0) {
      const button = dialog.querySelectorAll(
        "button:not([type='submit'])"
      )[0] as HTMLButtonElement;
      button.style.visibility = 'hidden';
    }
  }
};

const setupPDPHandler = () => {
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      const containerDivEl = mutation.addedNodes?.[0];

      const childElement = containerDivEl?.childNodes?.[0] as HTMLElement;

      if (childElement) {
        hideCloseDialogButton();
      }
    }
  });

  // Start observing the target node for configured mutations
  const bodyEl = document.querySelector('body');
  if (bodyEl) {
    observer.observe(bodyEl, { childList: true });
  }
};

const setupPaymentHandler = () => {
  const MAX_DEPTH = 5;
  const isContinueButtuonClicked = (
    target: HTMLElement,
    depth: number = 0
  ): boolean => {
    if (target.dataset?.cy === 'submit') {
      return true;
    }
    if (depth > MAX_DEPTH || !target.parentElement) {
      return false;
    }

    return isContinueButtuonClicked(target.parentElement, depth + 1);
  };

  document.addEventListener('click', (e) => {
    if (e.target && isContinueButtuonClicked(e.target as HTMLElement)) {
      const isACHSelected =
        [
          ...document.querySelectorAll(
            '[data-cy="payment-method-stripe_debit_ach"] circle'
          ),
        ].length > 1;
      if (isACHSelected) {
        e.stopPropagation();
        e.preventDefault();
        const globalObject = getGlobalObject();

        getAppSDK()?.sendMessageToApp({
          type: 'event:continue_payment',
          value: 'ACH',
          href: globalObject.location?.href,
        });
      }
    }
  });
};

let containerDivElRef: Node;
let iframeTypeRef: 'ACH' | '3DS';

const getIframeType = (url: string) => {
  if (url.match(/.*stripe.com.*linked-accounts-inner.*/)) {
    return 'ACH' as const;
  }
  if (url.match(/.*stripe.com.*three-ds-2.*/)) {
    return '3DS' as const;
  }

  return 'unknown';
};

const setupCheckoutHandler = () => {
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      const containerDivEl = mutation.addedNodes?.[0];

      const childElement = containerDivEl?.childNodes?.[0] as HTMLIFrameElement;
      if (childElement) {
        hideCloseDialogButton();
      }

      if (childElement?.nodeName === 'IFRAME') {
        const iframeType = getIframeType(childElement.src);

        if (iframeType === 'unknown') {
          return;
        }

        containerDivElRef = containerDivEl;
        iframeTypeRef = iframeType;

        getAppSDK()?.sendMessageToApp({
          type: 'event:iframe_opened',
          value: iframeType,
        });
      }

      const removedDivEl = mutation.removedNodes?.[0];
      if (removedDivEl && removedDivEl === containerDivElRef) {
        getAppSDK()?.sendMessageToApp({
          type: 'event:iframe_closed',
          value: iframeTypeRef,
        });
      }
    }
  });

  // Start observing the target node for configured mutations
  const bodyEl = document.querySelector('body');
  if (bodyEl) {
    observer.observe(bodyEl, { childList: true });
    setupPaymentHandler();
  }
};

export const PageHandlersWhenComplete = {
  [Pages.PDP]: () => {
    setupPDPHandler();
  },
  [Pages.Checkout]: () => {
    setupCheckoutHandler();
  },
};
