import { NextRouter } from 'next/router';

import { getSlugsWithoutStep } from './pathHelper';

export const getHubspotDealInfo = (router: NextRouter) => {
  const { query } = router;
  if (query.contactId) {
    // should be a dead code block when all checkout pages are converted to CSR
    return {
      contactId: parseInt(query.contactId as string),
      dealId: parseInt(query.dealId as string),
      hash: query.dealHash as string,
    };
  } else {
    const [contactId, dealId, hash] = getSlugsWithoutStep(query?.slug);

    return {
      contactId: parseInt(contactId),
      dealId: parseInt(dealId),
      hash,
    };
  }
};
