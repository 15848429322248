import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { isMobileApp, trackEvent, useSession } from '@finn/ui-utils';
import { signIn, SignInOptions } from 'next-auth/react';
import { useEffect, useState } from 'react';

interface OneTapSignInOptions {
  parentContainerId?: string;
}

// this is public and doesn't change based in evn so hardcoding as constant
const GoogleClientId =
  '88912289849-93l2n1lk8vsuub4pf0b99hu27bujsvtr.apps.googleusercontent.com';
const maxRetryCount = 5;

export const useOneTapSignIn = (
  opt?: OneTapSignInOptions & Pick<SignInOptions, 'redirect' | 'callbackUrl'>
) => {
  const [session] = useSession();
  const isGuest = session === null;
  const { parentContainerId } = opt || {};
  const track = useTrackingStore((state) => state.track);
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (isMobileApp()) {
      return;
    }
    if (!isLoading && isGuest && retryCount < maxRetryCount) {
      const { google } = window as any;
      if (google) {
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: async (response: any) => {
            setIsLoading(true);

            track(TrackingEventName.LOGIN_METHOD_CLICKED, {
              additionalProps: {
                loginMethod: 'googleonetap',
              },
            });

            // Here we call our Provider with the token provided by google
            await signIn('googleonetap', {
              credential: response.credential,
              redirect: true,
              ...opt,
            });
            trackEvent('Account Logged In', { loginMethod: 'googleonetap' });
            setIsLoading(false);
          },
          prompt_parent_id: parentContainerId,
        });

        // Here we track some error situations and reason why the Google one tap is not displayed.
        google.accounts.id.prompt((notification: any) => {
          if (notification.isDismissedMoment()) {
            track(TrackingEventName.GOOGLE_ONE_TAP_LOGIN_MESSAGE, {
              additionalProps: {
                type: 'dismissed',
                message: notification.getDismissedReason(),
              },
            });
          }
        });
      } else {
        setTimeout(() => {
          setRetryCount((prev) => prev + 1);
        }, 2000);
      }
    }
  }, [isLoading, isGuest, opt, parentContainerId, retryCount, track]);

  return { isLoading };
};
