import { EmitEvent } from '../../types/general';
import { getGlobalObject } from '../helpers/globalObject';

export const setupLogging = (emitEvent: EmitEvent) => {
  const globalObject = getGlobalObject();
  const originalConsoleLog = globalObject.console.log;
  const originalConsoleError = globalObject.console.error;
  const originalConsoleWarn = globalObject.console.warn;
  const originalConsoleInfo = globalObject.console.info;

  // /de-DE/mobile/checkout/payment/386674901/11943934902/72c74682cba79de2c2bad6e7597dcc69
  globalObject.console.log = function log(...obj) {
    emitEvent({
      type: 'log',
      message: JSON.stringify(obj),
    });
    originalConsoleLog(...obj);
  };

  globalObject.console.error = function log(...obj) {
    emitEvent({
      type: 'log:error',
      message: JSON.stringify(obj),
    });
    originalConsoleError(...obj);
  };

  globalObject.console.warn = function log(...obj) {
    emitEvent({
      type: 'log:warn',
      message: JSON.stringify(obj),
    });
    originalConsoleWarn(...obj);
  };

  globalObject.console.info = function log(...obj) {
    emitEvent({
      type: 'log:info',
      message: JSON.stringify(obj),
    });
    originalConsoleInfo(...obj);
  };
};
