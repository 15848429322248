import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@finn/design-system/atoms/tooltip';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { cn, parseToHtml } from '@finn/ui-utils';
import React from 'react';

type Props = {
  content: string;
  breakpointDownFrom?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  applyEndForLowerBreakpoint?: boolean;
  Icon?: React.ElementType;
  className?: string;
  iconClassName?: string;
  isSmallVariant?: boolean;
  onOpenChange?: (opened: boolean) => void;
};

const InfoToolTip: React.FunctionComponent<Props> = ({
  content,
  Icon,
  className,
  iconClassName,
  onOpenChange,
  isSmallVariant = false,
}) => {
  return (
    <Tooltip onOpenChange={onOpenChange}>
      <TooltipTrigger>
        {Icon ? (
          <Icon className={cn('cursor-pointer', 'text-black', iconClassName)} />
        ) : (
          <InfoOutlined
            className={cn('cursor-pointer', {
              'h-4 w-4': isSmallVariant,
            })}
          />
        )}
      </TooltipTrigger>
      <TooltipContent>
        <span className={cn(className, 'body-14-light text-white')}>
          {parseToHtml(content)}
        </span>
      </TooltipContent>
    </Tooltip>
  );
};

export default InfoToolTip;
