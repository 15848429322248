import { Locale } from '../types/localization';

export const SUPPORTED_LOCALES = {
  de: ['DE'],
  en: ['DE', 'US'],
};

type Country = keyof typeof SUPPORTED_LOCALES;

export const getCorrectLocale = (locale: string | undefined) => {
  locale = locale || Locale.GERMAN_GERMANY;

  let [lang, region] = locale.split('-');

  if (!lang) {
    lang = 'de';
  }

  if (lang in SUPPORTED_LOCALES) {
    if (!SUPPORTED_LOCALES[lang as Country].includes(region)) {
      region = SUPPORTED_LOCALES[lang as Country][0];
    }
  } else {
    lang = 'de';
    region = 'DE';
  }

  return { lang, region };
};
