import { EmitEvent } from '../../types/general';

export const notifyAboutStripeModals = (emitEvent: EmitEvent) => {
  const stripeIframes = [...document.querySelectorAll('iframe')].filter(
    (iframe) =>
      iframe?.src?.includes('stripe') && iframe?.name?.includes('Frame')
  );

  emitEvent({
    type: 'event:stripe_modal',
    value: stripeIframes.length > 0,
  });
};
