import { useOneTapSignIn } from './useOneTapSignIn';

export const GoogleOneTap = () => {
  useOneTapSignIn({
    redirect: false,
    parentContainerId: 'oneTap',
  });

  return <div id="oneTap" className="absolute right-0 top-0" />;
};
