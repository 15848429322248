import { LoyaltyBenefitsData, LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import React from 'react';

import { InfoModalData } from '../modals/InfoModal';
import { BenefitChip } from './BenefitChip';

type Props = {
  data?: LoyaltyCosmicMetadata;
  levelIndex: number;
  openInfoModal: (infoData: InfoModalData) => void;
  className?: string;
};

const isBenefitApplicableForCurrentLevel = (
  benefit: LoyaltyBenefitsData,
  levelIndex: number
) => {
  const minLevelIndex = Number(benefit?.metadata?.minimum_level?.value) - 1;
  const isCorrectLevel = minLevelIndex <= levelIndex;
  const isOnlyForCurrentLevel = benefit?.metadata?.only_for_current_level;
  const isCurrentLevel = minLevelIndex === levelIndex;

  return isCorrectLevel && (!isOnlyForCurrentLevel || isCurrentLevel);
};

export const LoyaltyBenefits = ({
  data,
  levelIndex,
  className,
  openInfoModal,
}: Props) => {
  const allBenefits = data?.loyalty_benefits_all || [];
  const applicableBenefits = allBenefits.filter((benefit) =>
    isBenefitApplicableForCurrentLevel(benefit, levelIndex)
  );

  return (
    <div className="mb-14 md:mb-32" data-testid="loyalty-benefit-chips">
      <h6
        className="global-t5-semibold mb-4 md:mb-8"
        data-testid="my-benefits-title"
      >
        {data?.your_benefits_title}
      </h6>
      <div
        className={cn(
          'grid grid-flow-row grid-cols-[repeat(1,1fr)] gap-4 md:grid-cols-[repeat(3,1fr)] md:gap-6',
          className
        )}
      >
        {applicableBenefits.map((benefit) => (
          <BenefitChip
            benefit={benefit?.metadata}
            key={benefit?.id}
            openInfoModal={openInfoModal}
          />
        ))}
      </div>
    </div>
  );
};
