import { Button } from '@finn/design-system/atoms/button';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  onClick: () => void;
  isLoading?: boolean;
  testId?: string;
  location?: string;
  isInPDP?: boolean;
}
export const ContinueButton: React.FunctionComponent<IProps> = ({
  onClick,
  location,
  isLoading,
  testId,
  isInPDP,
}) => {
  const i18n = useIntl();

  const handleClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location,
    });

    onClick();
  }, [onClick]);

  const title = i18n.formatMessage({
    id: 'pdp.continueToBooking',
  });

  return (
    <>
      <Button
        loading={isLoading}
        testId={testId || 'submit'}
        className={cn(!isInPDP && 'mt-10', 'w-full')}
        disabled={isLoading}
        onClick={handleClick}
      >
        {title}
      </Button>
    </>
  );
};
