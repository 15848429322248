import { getClosedUserGroupId } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { SWR_CACHE_TIME } from '@finn/ua-constants';
import { Features } from '@finn/ua-featureflags';
import {
  FilterValuesObject,
  GenericVehicleDetails,
  PRICING_TYPE,
} from '@finn/ua-vehicle';
import {
  createFetcher,
  FetcherRequestPayload,
  generateLocalizationHeaders,
  isServer,
  Locale,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import useSWR from 'swr';

import config from '~/config';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';

// TODO: Remove all this switching logic after roll-out is complete
const oldFetcher = createFetcher({
  baseURL: config.FLEET_API_URL,
  withCredentials: false,
});

const fetcher = createFetcher({
  baseURL: config.PRODUCT_API_URL,
  withCredentials: false,
});

const dynamicFetcher = <Response>(request: FetcherRequestPayload) => {
  const locale = request.headers['X-Language-Tag'];

  if (locale === Locale.ENGLISH_USA) {
    return oldFetcher<Response>(request);
  } else {
    return fetcher<Response>(request);
  }
};

export type GetVehicleParams = {
  vehicleId?: string;
  locale?: string;
  zipCode?: string;
  cug_id?: string;
  hide_related?: boolean;
};

type GetVehicleResponse = GenericVehicleDetails;

/** Create a payload object for fetching a car */
export function makeGetVehicleRequestPayload(
  params: GetVehicleParams
): FetcherRequestPayload {
  const cugId = getClosedUserGroupId();
  const { vehicleId, locale, zipCode, hide_related } = params;
  if (!vehicleId) return null;

  return {
    url: `/cars/${encodeURIComponent(vehicleId)}`,
    headers: generateLocalizationHeaders(locale),
    query: {
      zipcode: zipCode,
      cug_id: cugId || null,
      hide_related,
    },
  };
}

/**
 * Fetch a single car.
 * Works in SSR/SSG as well as on the client.
 *
 * @param params
 * @returns a promise for fetching a car
 */
export function getVehicle(
  params: GetVehicleParams
): Promise<GetVehicleResponse> {
  return dynamicFetcher<GetVehicleResponse>(
    makeGetVehicleRequestPayload(params)
  );
}

/**
 * An SWR hook for fetching a car.
 * Works on the client, requires a fallback data object for SSR/SSG.
 *
 * @param params
 * @param fallbackData
 */
export function useGetVehicle(
  params: GetVehicleParams,
  fallbackData?: GetVehicleResponse
) {
  const { locale } = useCurrentLocale();

  const hide_related =
    !isServer() &&
    Cookies.get(`${locale}_${Features.ExpRelatedConfigs}`) === 'b';

  const isLocaleProvided = 'locale' in params;

  const requestParams = {
    ...params,
    hide_related,
    zipCode: undefined,
    locale: isLocaleProvided ? params.locale : locale,
  };

  return useSWR<GetVehicleResponse>(
    requestParams?.vehicleId
      ? makeGetVehicleRequestPayload(requestParams)
      : null,
    dynamicFetcher,
    {
      fallbackData,
      dedupingInterval: SWR_CACHE_TIME,
    }
  );
}

export type GetVehiclesParams = {
  locale: string;
  zipCode?: string;
  filters?: FilterValuesObject;
  offset?: number;
  limit?: number;
};

export type GetVehiclesResponse = {
  offset: number;
  total_results: number;
  results: GenericVehicleDetails[];
};

function makeGetVehiclesRequestPayload({
  filters = {},
  offset,
  limit,
  locale,
  zipCode,
}: GetVehiclesParams): FetcherRequestPayload {
  const hideDownpayment =
    !isServer() && Cookies.get(`${locale}_${Features.HideDownPayment}`) === 'b';
  const cugId = getClosedUserGroupId();
  const hide_related =
    !isServer() &&
    Cookies.get(`${locale}_${Features.ExpRelatedConfigs}`) === 'b';

  const pricingType = hideDownpayment
    ? PRICING_TYPE.NORMAL
    : PRICING_TYPE.DOWNPAYMENT;

  return {
    url: '/cars',
    headers: generateLocalizationHeaders(locale),
    query: {
      ...filters,
      offset,
      limit,
      zipcode: zipCode,
      cug_id: cugId,
      pricing_type: pricingType,
      hide_related,
    },
  };
}

export function getVehicles(params: GetVehiclesParams) {
  return dynamicFetcher<GetVehiclesResponse>(
    makeGetVehiclesRequestPayload(params)
  );
}
