import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

/** Generate a random unique-enough string */
const generateId = () => Math.random().toString(36).substring(2);

/** Necessary data to create a toast */
type ToastParams = {
  /** A toast status */
  status: 'success' | 'error' | 'warning' | 'info';

  /** An optional title */
  title?: string;

  /** Content of a toast */
  description?: string;
};

/** Internal toast object structure */
type ToastData = ToastParams & {
  /** A unique identifier of a toast that can be used to dismiss it */
  id: string;

  /** Toast creation timestamp */
  createdAt: number;
};

type ToastManagerStore = {
  /** A map of ids to toast objects */
  toastsById: Record<string, ToastData>;

  /** An array of toast ids that preserves the insertion order */
  toastIds: string[];

  /**
   * Pushes a new toast into the list
   *
   * @param toastData - data necessary to open a toast
   * @returns the id of a created toast, which can be used to dismiss the toast
   */
  showToast(toastData: ToastParams): string;

  /**
   * Dismisses a toast from the list
   *
   * @param toastId - the id of a toast to be dismissed
   */
  dismissToast(toastId: string): void;
};

export const toastManagerStore = create<ToastManagerStore>()(
  devtools(
    (set) => ({
      toastsById: {},
      toastIds: [],

      showToast(toastData) {
        const id = generateId();

        set(
          (prevState) => {
            const toast = { ...toastData, id, createdAt: Date.now() };

            return {
              toastsById: { ...prevState.toastsById, [id]: toast },
              toastIds: [...prevState.toastIds, id],
            };
          },
          false,
          'toastManager/showToast'
        );

        return id;
      },

      dismissToast(toastId) {
        set(
          (prevState) => {
            const nextToastsById = { ...prevState.toastsById };
            delete nextToastsById[toastId];

            const nextToastIds = prevState.toastIds.filter(
              (id) => id !== toastId
            );

            return {
              toastsById: nextToastsById,
              toastIds: nextToastIds,
            };
          },
          false,
          'toastManager/dismissToast'
        );
      },
    }),
    { name: 'toastManager' }
  )
);

export const showToast = (toastData: ToastParams): string =>
  toastManagerStore.getState().showToast(toastData);
export const dismissToast = (toastId: string): void =>
  toastManagerStore.getState().dismissToast(toastId);
