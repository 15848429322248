import { compareVersions } from 'compare-versions';

import { EmitEvent, PageType, RouterPush } from '../../types/general';
import {
  getCurrentPagePath,
  getCurrentPageType,
} from '../connectors/constants';
import { getGlobalObject } from '../helpers/globalObject';
import { isColorChangedFromTheSameVehicle } from '../helpers/pdp';

let originalNextJSRouterPush: RouterPush | undefined;
let originalNextJSRouterReplace: RouterPush | undefined;

// remove after release of version 1.47.x
const isDirectCheckoutConfirmationURL = (url: string) =>
  url.includes('is_direct_checkout=true') &&
  url.includes('checkout/confirmation');

export const getNextUrl = ({
  url,
  screenName,
  version,
}: {
  url: string;
  screenName: string;
  version: string;
}) => {
  if (
    compareVersions(version, '1.47.0') < 0 &&
    !url.includes(getCurrentPagePath(screenName))
  ) {
    return url;
  }

  if (url && !url.includes('/mobile')) {
    const urlArr = url.split('/');
    if (urlArr.includes('de-DE') || url.includes('en-US')) {
      urlArr.splice(2, 0, 'mobile');
    } else {
      urlArr.splice(1, 0, 'mobile');
    }

    return urlArr.join('/');
  }

  return url;
};

export const getWebInternalPageType = (
  params: string | { pathname: string }
): PageType => {
  const url = typeof params === 'string' ? params : params.pathname;

  const isHome =
    url.endsWith('/mobile') ||
    url.endsWith('/mobile/') ||
    url.endsWith('/mobile?') ||
    url.endsWith('/mobile/?') ||
    url.includes('/home');

  if (isHome) {
    return 'home' as const;
  }

  const isPDP = url.includes('pdp') && !url.includes('[vehicleId]');
  if (isPDP) {
    return 'pdp' as const;
  }

  const isThankYou = url.includes('thank_you');
  if (isThankYou) {
    return 'thank_you' as const;
  }

  const isOrderStatus = url.includes('order-status');
  if (isOrderStatus) {
    return 'order_status';
  }

  const isCheckout = url.includes('checkout');
  if (isCheckout) {
    return 'checkout' as const;
  }

  const isContactDetails = url.includes('contactdetails');
  if (isContactDetails) {
    return 'contactdetails' as const;
  }

  const isChangePassword = url.includes('changepassword');
  if (isChangePassword) {
    return 'changepassword' as const;
  }

  const isCompanyDetails = url.includes('companydetails');
  if (isCompanyDetails) {
    return 'companydetails' as const;
  }

  const isContact = url.includes('contact');
  if (isContact) {
    return 'contact' as const;
  }

  const isMyAccount = url.includes('myaccount');
  if (isMyAccount) {
    return 'myaccount' as const;
  }

  const isVeriff = url.includes('veriff');
  if (isVeriff) {
    return 'veriff';
  }

  const [myCarDealId, myCarPagePath] = url.split('/').reverse() || [];
  const isMyCar = myCarDealId && myCarPagePath === 'mycars';
  if (isMyCar) {
    return 'my_car';
  }

  const [returnPagePath, returnDealId] = url.split('/').reverse() || [];
  const isReturn = returnDealId && returnPagePath === 'return';
  if (isReturn) {
    return 'return';
  }

  const isError = url.includes('/404');
  if (isError) {
    return 'error' as const;
  }

  const [servicePagePath, dealId] =
    url.split('?')?.[0]?.split('/')?.reverse() || [];
  const isSchedule = dealId && servicePagePath === 'schedule-service';
  if (isSchedule) {
    return 'schedule_service';
  }

  const isReportDamage = dealId && servicePagePath === 'report-damage';

  if (isReportDamage) {
    return 'report_damage';
  }

  const isPLP = url.includes('subscribe');
  const isLanding =
    !isPLP && !url.includes('[vehicleId]') && !url.includes('home');

  if (isLanding) {
    return 'landing' as const;
  }

  if (isPLP) {
    return 'plp' as const;
  }

  return 'unknown' as const;
};

type SetupWebRoutingProps = {
  screenName: string;
  version: string;
  withWebPDPColorChange?: boolean;
  allowSamePageWebNavigation?: boolean;
};

export const isDealsLanding = (url: string) => {
  const dealsRegex = /\/[(\w\-)]*deals/;

  if (
    dealsRegex.test(url) ||
    (url.includes('[...slug]') && url.includes('deals'))
  ) {
    return true;
  }

  if (url.includes('partnerships/paypal')) {
    return true;
  }

  return false;
};

export const isRedirectOnTheSameLanding = ({
  currentURL,
  url,
}: {
  currentURL: string;
  url: string;
}) => {
  if (
    url.includes('[...slug]') ||
    (url.includes('[slug]') && url.includes('/mobile')) ||
    isDealsLanding(url)
  ) {
    return true;
  }

  const singleSlashCurrentURL = currentURL.replace(/\/\//g, '/');

  if (url.endsWith('?')) {
    return singleSlashCurrentURL.includes(url.replace('?', ''));
  }

  return false;
};

const isLandingNavigationAllowed = (params: string | { pathname: string }) => {
  const url = typeof params === 'string' ? params : params.pathname;

  return isRedirectOnTheSameLanding({ currentURL: window.location.href, url });
};

export const isWebNavigationAllowed = (
  params: string | { pathname: string },
  props: SetupWebRoutingProps
) => {
  const type = getWebInternalPageType(params);

  if (type === 'error') {
    return true;
  }

  // in case of PDP and in case of withWebPDPColorChange
  // we want to do color change web only(no native navigation in the app)
  if (
    type === 'pdp' &&
    props.withWebPDPColorChange &&
    isColorChangedFromTheSameVehicle(params)
  ) {
    return true;
  }

  if (getCurrentPageType(props.screenName) === 'landing') {
    return isLandingNavigationAllowed(params);
  }

  if (
    props.allowSamePageWebNavigation &&
    getCurrentPageType(props.screenName) === type
  ) {
    return true;
  }

  return type === 'unknown';
};

const getRedirectAwareLink = (url: string) =>
  url.includes('loginredirect') ? `${window.origin}${url}` : url;

export const setupWebRouting = (
  emitEvent: EmitEvent,
  props: SetupWebRoutingProps
) => {
  const globalObject = getGlobalObject();

  if (!originalNextJSRouterPush && globalObject.next) {
    originalNextJSRouterPush = globalObject?.next?.router.push;
    originalNextJSRouterReplace = globalObject?.next?.router.replace;

    globalObject.next.router.events.on('routeChangeStart', (url) => {
      emitEvent({
        type: 'navigation',
        // remove ternary operator and just use "url" after release of version 1.47.x
        url: isDirectCheckoutConfirmationURL(url)
          ? url.replace('checkout/confirmation', 'checkout/contact')
          : getRedirectAwareLink(url),
        page_type: (url.includes('loginredirect')
          ? 'pdf'
          : getWebInternalPageType(url)) as PageType,
      });
    });

    globalObject.next.router.push = function push(
      route: string | { pathname: string },
      as: string | undefined,
      params: any
    ) {
      const url = typeof route === 'string' ? route : route.pathname;
      // we do not allow pdp screen, instead we send message to web
      if (!isWebNavigationAllowed(url, props)) {
        emitEvent({
          type: 'navigation',
          // remove ternary operator and just use "url" after release of version 1.47.x
          url: isDirectCheckoutConfirmationURL(url)
            ? url.replace('checkout/confirmation', 'checkout/contact')
            : getRedirectAwareLink(url),
          page_type: (url.includes('loginredirect')
            ? 'pdf'
            : getWebInternalPageType(url)) as PageType,
        });

        if (compareVersions(props.version, '1.47.0') < 0) {
          return Promise.resolve();
        }
      }

      if (!url.includes('/mobile/')) {
        return originalNextJSRouterPush?.bind(this)(
          getNextUrl({
            url,
            screenName: props.screenName,
            version: props.version,
          }),
          as
            ? getNextUrl({
                url: as,
                screenName: props.screenName,
                version: props.version,
              })
            : as,
          params
        );
      }

      return originalNextJSRouterPush?.bind(this)(url, as, params);
    };

    globalObject.next.router.replace = function replace(
      route: string | { pathname: string },
      as: string | undefined,
      params: any
    ) {
      const url = typeof route === 'string' ? route : route.pathname;
      // we do not allow pdp screen, instead we send message to web
      if (!isWebNavigationAllowed(url, props)) {
        emitEvent({
          type: 'navigation',
          // remove ternary operator and just use "url" after release of version 1.47.x
          url: isDirectCheckoutConfirmationURL(url)
            ? url.replace('checkout/confirmation', 'checkout/contact')
            : getRedirectAwareLink(url),
          page_type: (url.includes('loginredirect')
            ? 'pdf'
            : getWebInternalPageType(url)) as PageType,
        });

        if (compareVersions(props.version, '1.47.0') < 0) {
          return Promise.resolve();
        }
      }

      if (!url.includes('/mobile')) {
        return originalNextJSRouterReplace?.bind(this)(
          getNextUrl({
            url,
            screenName: props.screenName,
            version: props.version,
          }),
          as,
          params
        );
      }

      return originalNextJSRouterReplace?.bind(this)(url, as, params);
    };
  }

  return globalObject?.next?.router;
};
