import { Image } from '@finn/ui-components';
import { FooterData } from '@finn/ui-cosmic';
import get from 'lodash/get';

type Props = {
  data: FooterData;
};

const AppStoresBadges = ({ data }: Props) => {
  const appleStoreBadge = get(data, 'metadata.apple_store_badge');
  const googlePlayBadge = get(data, 'metadata.google_play_badge');

  if (!appleStoreBadge || !googlePlayBadge) {
    return null;
  }

  return (
    <div className="h-10">
      <a
        href={appleStoreBadge.url}
        className="mr-4 inline-block"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          variant="lazy-loading"
          alt={appleStoreBadge.alt}
          className="w-30"
          src={appleStoreBadge.image.url}
        />
      </a>
      <a
        href={googlePlayBadge.url}
        target="_blank"
        rel="noreferrer"
        className="inline-block"
      >
        <Image
          variant="lazy-loading"
          alt={googlePlayBadge.alt}
          className="w-30 h-9"
          src={googlePlayBadge.image.url}
        />
      </a>
    </div>
  );
};

export default AppStoresBadges;
