import { useSession } from '@finn/ui-utils';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { Deal } from './useDeal';
import { useRemoteData } from './useRemoteData';

const isActiveDeal = (deal: Deal) => ['CREATED', 'ACTIVE'].includes(deal.state);

// ongoing means that they didn't reach the end of the lifecycle state machine (CANCELED or ENDED)
const isOngoingDeal = (deal: Deal) =>
  ['CREATED', 'ACTIVE', 'STOPPED', 'INACTIVE'].includes(deal.state);

// we use function as useRemoteData may pass some params for us
// temporary added refresh id to ensure cache reset on netlify side
const dealsEndpoint = (refreshId?: number) => () =>
  `portal/api/bff/deals?refreshId=${refreshId || uuid()}`;

export const useDeals = (
  { shouldFetch = true }: { shouldFetch?: boolean } = { shouldFetch: true }
) => {
  const [session, isSessionLoading] = useSession();
  const contactId = session?.user?.hs_contact_id;
  const { data, status } = useRemoteData<Deal[]>(
    shouldFetch && contactId ? 'deals' : null,
    dealsEndpoint(contactId ? Number(contactId) : undefined),
    []
  );

  return useMemo(
    () => ({
      deals: data,
      dealsStatus: isSessionLoading ? 'loading' : status,
      activeDeals: data?.filter(isActiveDeal) || [],
      // if there are any in here, we still expect things to happen for which the customer requires portal access
      dealsPreventingAccountDeletion: data?.filter(isOngoingDeal) || [],
    }),
    [data, status, isSessionLoading]
  );
};
