import { useMediaQuery } from '@finn/design-system/helpers/media';
import { cn } from '@finn/ui-utils';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoContent from './PromoContent';
import PromoImage from './PromoImage';

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoMobile: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const isMobile = useMediaQuery('sm');

  const containerRef = useRef(null);
  const { enterCount } = useInViewport(
    containerRef,
    {
      threshold: 0.2,
    },
    { disconnectOnLeave: true },
    {}
  );

  const visible = enterCount >= 1;

  return (
    <div
      className="flex w-full flex-wrap sm:min-h-[448px]"
      style={{ backgroundColor: data.color_hex }}
    >
      <div
        ref={containerRef}
        className={cn(
          'order-1 flex min-h-[400px] translate-y-[50px] flex-col px-4 py-14 opacity-0 transition-all sm:w-[53%] sm:p-14',
          {
            'text-black': data.dark_text,
            'text-white': !data.dark_text,
            'order-2': data.reverse || (data.reverse_mobile && isMobile),
            'translate-y-0 opacity-100': visible,
          }
        )}
      >
        <PromoContent data={data} slug={slug} />
      </div>
      <div
        className={cn(
          'order-2 h-[440px] w-full overflow-hidden bg-black object-cover sm:h-auto sm:w-[47%] [&_picture]:h-full',
          {
            'order-1': data.reverse || (data.reverse_mobile && isMobile),
          }
        )}
      >
        <PromoImage
          src={data.image?.url}
          altText={data?.image_alt_text || data.title}
        />
      </div>
    </div>
  );
};

export default PromoMobile;
