import { EmitEvent } from '../../types/general';
import { getGlobalObject } from '../helpers/globalObject';

const MonitoredKeys: Record<string, boolean> = {
  location: true,
};

const createSetItemProxy = (
  setItemFn: Storage['setItem'],
  emitEvent: EmitEvent
) =>
  new Proxy(setItemFn, {
    apply: (target, thisArg, args: [string, string]) => {
      const [key, value] = args;

      if (MonitoredKeys[key]) {
        emitEvent({
          type: 'event:storage_set_item',
          payload: { key, value },
        });
      }

      return target.apply(thisArg, args);
    },
  });

const proxyHandler = (emitEvent: EmitEvent) => ({
  get(target: Storage, prop: string) {
    if (typeof target[prop] !== 'function') {
      return target[prop];
    }

    if (prop === 'setItem') {
      const setItemFn = target[prop];

      return createSetItemProxy(setItemFn, emitEvent).bind(target);
    }

    return target[prop].bind(target);
  },
});

type LocalState = Array<{ key: string; value: string }>;

const hydrateLocalStorage = (localState?: string) => {
  const globalObject = getGlobalObject();

  let state: LocalState;
  try {
    state = localState ? JSON.parse(localState) : [];
  } catch (_) {
    state = [];
  }

  state.forEach?.(({ key, value }) =>
    globalObject?.localStorage.setItem(key, value)
  );
};

export const setupStorage = (emitEvent: EmitEvent, localState?: string) => {
  const globalObject = getGlobalObject();

  Object.defineProperty(globalObject, 'localStorage', {
    value: new Proxy(globalObject.localStorage, proxyHandler(emitEvent)),
    writable: true,
  });

  hydrateLocalStorage(localState);
};
