import {
  hashStr,
  identifyAnonymousUserEvent,
  identifyEvent,
  isFullStoryLoaded,
  useSession,
} from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getHubspotDealInfo } from './hubspot';

export const useUserIdentifierGeneralPages = () => {
  const router = useRouter();
  const [session, loading] = useSession();

  useEffect(() => {
    // session changes from undefined to null causing a duplicate
    if (session === undefined && loading) return;
    // router.asPath changes causing re-render and duplicate, need to check router.isReady
    if (!router.isReady) return;
    // Check if the user is logged in and override the userId with the new logged-in user
    if (session?.user) {
      const userId = session.user.hs_contact_id;
      if (userId) {
        identifyEvent(String(userId), {
          name: session.user.name,
          email: session.user.email,
          loggedIn: !!session,
        });
      } else {
        identifyAnonymousUserEvent({
          name: session.user.name,
          email: session.user.email,
          loggedIn: !!session,
        });
      }

      if (isFullStoryLoaded() && !!userId) {
        window.FS.identify(String(userId));
      }

      return;
    }

    identifyAnonymousUserEvent({
      loggedIn: !!session,
    });

    // Check if it's deal related page, if so then do nothing
    // Basically we try to see the URL if it's contain contactId which only exist on deal related pages (self-service, Checkout)
    const dealInfo = getHubspotDealInfo(router);
    if (dealInfo.contactId) {
      return;
    }

    // Will use it as the user id from auth, since our auth doesn't return a user ID
    let hashedEmail = '';
    if (session?.user) {
      hashedEmail = hashStr(session.user.email);
    }

    // Add User ID to FullStory session recording
    if (isFullStoryLoaded() && !!hashedEmail) {
      window.FS.identify(hashedEmail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user?.email, loading, router.isReady]);
};
