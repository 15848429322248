import { useEffect } from 'react';

import { useAppStore } from './store';

/** Sets `isReady` after hydration is over */
export function useSetAppReady(): void {
  const { setReady } = useAppStore();

  useEffect(() => {
    setReady();

    // Should fire only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/** Returns `true` if it's client side and the hydration is over */
export function useIsAppReady(): boolean {
  return useAppStore().isReady;
}

/** Returns the first value if the app is ready (hydration has finished) or a right one if not (e.g. SSR/SSG) */
export function useReadyValue<T>(value: T, defaultValue: T): T {
  const { isReady } = useAppStore();

  if (isReady) {
    return value;
  } else {
    return defaultValue;
  }
}
