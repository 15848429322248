import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { CookieKeys, sha256 } from '@finn/ui-utils';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';

const DEV_HOT_KEY = 'Control+F6';
// type dev in a non-input field in a short time
const DEV_HOT_KEY_2 = 'd e v';
// cmd+F6 or window+F6
const DEV_HOT_KEY_3 = 'Meta+F6';

const TAP_COUNT_TO_OPEN_MODAL = 6;
const TAP_COUNT_TIMEOUT = 2000;

const SavedPassHash =
  '8075cc17eaf2b1fd73e527dcdb3e914ac10f81f30e48a1dc32c7dfa87b6c3a1d';

const useSetDevMode = () => {
  const [devPass, setDevPass] = useState(Cookies.get(CookieKeys.DEV_PASS));
  const openModal = useOpenModal();
  const [isDevAuthenticated, setIsDevAuthenticated] = useState(false);
  const [tapCount, setTapCount] = useState(0);

  const triggerDevModal = useCallback(() => {
    setTapCount((oldVal) => oldVal + 1);
  }, []);

  useEffect(() => {
    const generateHash = async () => {
      const hash = await sha256(devPass || '');
      if (hash === SavedPassHash) {
        setIsDevAuthenticated(true);
      } else {
        setIsDevAuthenticated(false);
      }
    };
    generateHash();
  }, [devPass]);

  const openDevModal = useCallback(async () => {
    if (isDevAuthenticated) {
      openModal(ModalKey.DEV_MODAL);
    } else {
      const pass = prompt('Please enter Dev Password', '');
      const hash = await sha256(pass || '');
      if (hash === SavedPassHash) {
        openModal(ModalKey.DEV_MODAL);
      }
      Cookies.set(CookieKeys.DEV_PASS, pass);
      setDevPass(pass);
    }
  }, [isDevAuthenticated, openModal]);

  useEffect(() => {
    let timeoutHandle;
    if (tapCount >= TAP_COUNT_TO_OPEN_MODAL) {
      openDevModal();
    } else if (tapCount > 0) {
      timeoutHandle = setTimeout(() => {
        setTapCount(0);
      }, TAP_COUNT_TIMEOUT);
    }

    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [openDevModal, tapCount]);

  useHotkeys([DEV_HOT_KEY, DEV_HOT_KEY_2, DEV_HOT_KEY_3], openDevModal);

  return triggerDevModal;
};

export default useSetDevMode;
