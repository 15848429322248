import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { Badge } from '@finn/design-system/atoms/badge';
import { Button } from '@finn/design-system/atoms/button';
import {
  buildCosmicPageObjectTree,
  useIsCosmicHelperEnabled,
} from '@finn/ui-cosmic';
import {
  DefaultLocale,
  FakeDefaultLocale,
  getLocaleMetaData,
} from '@finn/ui-utils';
import replace from 'lodash/replace';
import { useRouter } from 'next/router';

export const useCurrentLocale = () => {
  const router = useRouter();

  return getLocaleMetaData(
    router.locale === FakeDefaultLocale
      ? DefaultLocale
      : router.locale || DefaultLocale
  );
};

const ModuleTitleLine = ({
  tree,
  preview,
}: {
  tree: any;
  preview?: boolean;
}) => {
  const { locale } = useCurrentLocale();
  const localeString = locale === 'default' ? '' : `/${locale}`;

  return (
    <div className="flex gap-5">
      <span>{tree.title || tree.slug}</span>
      <Badge>{tree.type}</Badge>
      <Button
        href={`https://app-v1.cosmicjs.com/finnauto/edit-object/${tree.id}`}
        variant="link"
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Edit
      </Button>

      {tree.type === 'web-pages' && !preview && (
        <>
          <span>-</span>
          <Button
            variant="link"
            href={`${localeString}/preview/${replace(tree.slug, 'page-', '')}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Preview realtime changes
          </Button>
        </>
      )}
    </div>
  );
};

const CosmicHelper = ({
  tree,
  preview,
  vehicle,
}: {
  tree: any;
  preview?: boolean;
  vehicle?: any;
}) => {
  const { isDE } = useCurrentLocale();
  const hasChildren =
    (Array.isArray(tree) && tree.length) || tree?.children?.length;

  return (
    <div className="bg-lightBlue">
      {preview && (
        <div className="bg-red body-12-regular container">
          This is a real-time preview of <em>{tree.title}</em> - to reflect any
          changes on the live version, a new deployment might be necessary
        </div>
      )}
      <div className="container flex items-center gap-1 pb-0.5 pt-1">
        {hasChildren ? (
          <Accordion className="w-full" type="multiple">
            <AccordionItem value="tree">
              <AccordionTrigger>
                {tree?.id ? (
                  <ModuleTitleLine tree={tree} preview={preview} />
                ) : (
                  <>Modules ({tree?.length})</>
                )}
              </AccordionTrigger>
              <AccordionContent>
                {Array.isArray(tree) &&
                  tree.map((item, index) => (
                    <CosmicHelper tree={item} key={`${item?.id}-${index}`} />
                  ))}
                {/* 
                we should not use index as part of key, but sadly 
                we can not relay on id, as it generated by cosmic
                and will be the same if you use the same object twice but with different
                configurations
              */}
                {tree?.children &&
                  Array.isArray(tree?.children) &&
                  tree.children.map((item: any, index: number) => (
                    <CosmicHelper tree={item} key={`${item?.id}-${index}`} />
                  ))}
                {tree?.children && !Array.isArray(tree?.children) && (
                  <CosmicHelper tree={tree.children} key={tree.children.id} />
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ) : tree.id ? (
          <div className="m-4">
            <ModuleTitleLine tree={tree} />
          </div>
        ) : null}
        {vehicle && vehicle.source === 'blue_dragon' && (
          <Accordion className="w-full" type="multiple">
            <AccordionItem value="blue-dragon">
              <AccordionTrigger>Config Options</AccordionTrigger>
              <AccordionContent>
                <>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/becd7c06-eb52-11ed-a5c5-f7082001c703/UA/UA%20Website%20Product%20Info%20Editor?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Growth - Website Info
                  </Button>

                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/cdcc7e88-97a4-11ee-91bc-d7e5e3b41873/UA/Retention%20Deal%20Booking%20Tool?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Growth - Retention Booking
                  </Button>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/0e8589dc-238a-11ed-b908-438e0770309e/Fleet/Configurations?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Fleet - Config Info
                  </Button>

                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/b9b6cf7a-2775-11ed-88e3-23625276b848/Fleet/Pricings?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Fleet - Pricing
                  </Button>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/e30c3d5a-2771-11ed-b3a4-6f6189442733/Fleet/Operational%20Data?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Fleet - Cars
                  </Button>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/80269dec-a625-11ed-97ba-975f482ec246/Fleet/ETA?configId=${
                      vehicle.uid
                    }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Fleet - ETA
                  </Button>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/f6359f04-a24e-11ed-a41f-2389123a5e65/B2B/B2B-config-pricing-v1?configId=${vehicle.uid}`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    B2B - Pricing
                  </Button>
                  <Button
                    variant="link"
                    target="_blank"
                    href={`https://one.finn.auto/apps/1e9460d0-a92a-11ec-8c78-2333a0aec55c/Operations%20-%20OpsDev/Cars#carId=&color=&compound=&model=&orderId=&productId=${vehicle.uid}&rows_per_page=20&sa_number_received&supplier=&variant=&vin=`}
                    className="border-pearl block border-0 border-b border-solid p-2.5"
                    rel="noreferrer"
                  >
                    Ops - Cars
                  </Button>
                </>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export const CosmicHelperWrapper = ({
  data,
  preview,
  localizationObjects,
  vehicle,
}: {
  data: any;
  preview: boolean;
  localizationObjects?: any;
  vehicle?: any;
}) => {
  const isCosmicHelperEnabled = useIsCosmicHelperEnabled();
  const { locale } = useCurrentLocale();
  if (!isCosmicHelperEnabled) return null;
  const tree = buildCosmicPageObjectTree(data);
  console.log({ CosmicRawData: data });
  if (localizationObjects && Array.isArray(tree.children)) {
    tree.children.push(localizationObjects[locale]);
  }

  return (
    <div id="cosmic-helper-container">
      <CosmicHelper tree={tree} preview={preview} vehicle={vehicle} />
    </div>
  );
};
