import { FUEL, GenericVehicleDetails } from '@finn/ua-vehicle';
import { SeoHeaderData } from '@finn/ui-cosmic';
import {
  getKmPackagePriceWithoutVat,
  getLongestTerm,
  kmOptions,
} from '@finn/ui-modules';
import {
  capitalizeFirstLetter,
  replaceStrKeys,
  twoDecimalPlaces,
} from '@finn/ui-utils';
import get from 'lodash/get';

type Equipment = {
  [ket: string]: string;
};

export type EquipmentItem = {
  key: string;
  items: {
    id: number;
    title: string;
    key: string;
  }[];
};

export const getVehicleMinPrice = (vehicle: GenericVehicleDetails) => {
  const longestTerm = getLongestTerm(vehicle);
  const price =
    get(
      vehicle,
      `downpayment_prices.available_price_list.b2c_${longestTerm}`
    ) || 0;

  return twoDecimalPlaces(price);
};

export const parseEquipmentData = (
  equipment: Equipment,
  delimiter
): EquipmentItem[] => {
  return Object.entries(equipment)
    .filter((equ: string[]) => equ[1])
    .map((equ: string[], equIndex: number) => ({
      id: equIndex + 1,
      title: capitalizeFirstLetter(equ[0]),
      key: equ[0],
      items: equ[1].split(delimiter).map((item: string, itemIndex: number) => ({
        id: itemIndex + 1,
        title: item,
        key: item.toLowerCase(),
      })),
    }));
};

// Constants for mileage keys, used to identify pricing tiers for different distances.
export const HALF_MILEAGE_KEY = 'extra_500';
export const BASE_MILEAGE_KEY = 'extra_1000';
export const USA_BASE_MILEAGE_KEY = 'extra_850';

// Default distance options for different regions.
export const defaultKmOption = 1000;
export const defaultMileageOption = 850;

type DistancePrice = {
  price: number;
  distance: number;
  duration: number;
};

export const mapToDistancePrice = (
  key: string,
  value: number,
  isBusinessClient: boolean
): DistancePrice => ({
  duration: 0,
  distance: parseInt(key.substring(6), 10),
  price: getKmPackagePriceWithoutVat(value, isBusinessClient),
});

export const shouldIncludePriceOption = (
  key: string,
  distanceOptions: number[]
) => {
  // regex to check for strings of the form extra_500, extra_1000, etc.
  if (!/^extra_\d+$/g.test(key)) {
    return false;
  }

  const distanceValue = parseInt(key.split('_')[1], 10);

  return distanceOptions.includes(distanceValue);
};

type PricingList = {
  pricingTiers: Record<string, number | null>;
};

export function filterPricingList({ pricingTiers }: PricingList) {
  const mileages = [BASE_MILEAGE_KEY, HALF_MILEAGE_KEY, USA_BASE_MILEAGE_KEY];

  const list = Object.fromEntries(
    Object.entries(pricingTiers).filter(
      ([key, value]) => mileages.includes(key) || value !== null
    )
  );

  return list;
}

export const getDistancePrices = (
  pricingTiers: Record<string, number | null>,
  isBusinessClient: boolean
): DistancePrice[] => {
  // We only want to show the 500 km option if the 1000 km option is available

  // Remove null values that are not the base mileage options
  const validPricingTiers = filterPricingList({ pricingTiers });

  // If 1000 is not set, thats the base-milage, and we hide the extra_500 option.
  if (!validPricingTiers[BASE_MILEAGE_KEY]) {
    delete validPricingTiers[HALF_MILEAGE_KEY];
  }

  const distanceOptions = kmOptions;

  /*
    Filter the pricing tiers to only include the distance options we want to show
    Map the pricing tiers to the DistancePrice type
    Sort the distance options by price in ascending order
  */
  const priceData = Object.entries(validPricingTiers)
    .filter(([key]) => shouldIncludePriceOption(key, distanceOptions))
    .map(([key, value]) => mapToDistancePrice(key, value, isBusinessClient))
    .sort((first, second) => first.price - second.price);

  return priceData;
};

export const getShortestKmOption = (vehicle: GenericVehicleDetails) => {
  if (vehicle.price.extra_1000) {
    return 500;
  }

  return defaultKmOption;
};

export const getVehicleSeoHeader = (
  seoHeader: SeoHeaderData,
  vehicle: GenericVehicleDetails
) => {
  const titleTemplate = get(seoHeader, 'metadata.title', '');
  const descriptionTemplate = get(seoHeader, 'metadata.description', '');
  const seoData = {
    brand: get(vehicle, 'brand.id') || '',
    model: get(vehicle, 'model') || '',
    label: get(vehicle, 'label') || '',
    fuel: get(vehicle, 'fuel') || '',
    color: get(vehicle, 'color.specific') || '',
    carType: get(vehicle, 'cartype') || '',
    cheapest_price: getVehicleMinPrice(vehicle),
    equipment_line: get(vehicle, 'equipment_line') || '',
    model_year: get(vehicle, 'model_year') || '',
  };

  const actualTitle = replaceStrKeys(titleTemplate, seoData);
  const actualDescription = replaceStrKeys(descriptionTemplate, seoData);

  return {
    ...seoHeader,
    metadata: {
      ...seoHeader?.metadata,
      og_image: { url: get(vehicle, 'picture.url', '') },
      title: actualTitle,
      description: actualDescription,
      og_description: actualDescription,
      og_title: actualTitle,
    },
  };
};

export const getTermsPrices = (
  vehicle: GenericVehicleDetails,
  mode: string,
  isDownPayment?: boolean
) => {
  return vehicle.available_terms.map((term) => ({
    duration: term,
    price: get(
      vehicle,
      isDownPayment
        ? `downpayment_prices.available_price_list.${mode}_${term}`
        : `price.available_price_list.${mode}_${term}`,
      0
    ),
  }));
};

export { isElectricVehicle, isVehicleComingSoon } from '@finn/ua-vehicle';

export const isPlugInHybridVehicle = (fuel: string) =>
  fuel === FUEL.PlugInHybrid;
