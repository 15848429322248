import { Deal } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';

export const getActionItem = (
  deal: Partial<Deal>,
  correctCustomerId?: string
) => {
  const { id: dealId, checkout_hash, state, variant } = deal;

  const helperUrl = `${correctCustomerId || deal?.contact_id}/${dealId}/${checkout_hash}?linksrc=action-item`;
  const displayTextId = `mycars.action_texts.${state}`;

  if (variant === 'LEAD' && checkout_hash) {
    return {
      displayTextId,
      url: `checkout/thank_you/${helperUrl}`,
    };
  }

  switch (state) {
    case 'CREATED':
      return {
        displayTextId,
        url: `${SelfServiceRoutes.MY_CARS}/${dealId}`,
        textClass: 'text-orange',
      };
    case 'ACTIVE':
      return {
        displayTextId,
        url: `${SelfServiceRoutes.MY_CARS}/${dealId}`,
        textClass: 'text-green',
      };
    case 'INACTIVE':
    case 'ENDED':
      return {
        displayTextId,
        url: `${SelfServiceRoutes.MY_CARS}/${dealId}`,
      };

    default:
      if (checkout_hash) {
        return {
          displayTextId,
          url: `checkout/thank_you/${helperUrl}`,
        };
      } else {
        return {
          displayTextId,
          url: `${SelfServiceRoutes.MY_CARS}/${dealId}`,
        };
      }
  }
};
