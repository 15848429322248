// Source https://www.figma.com/design/KUvHhOhlCXF8FbspinF2Z6/Components?node-id=3748-806&t=yspLU69TTRAqMBN0-4
// last sync: 31.07.2024

module.exports = {
  // Primary Black
  black: '#191919',
  iron: '#707070',
  steel: '#979797',
  pewter: '#D7D7D7',
  pearl: '#E9EAEC',
  cotton: '#F3F3F3',
  snow: '#F8F8F8',
  white: '#FFFFFF',

  // Action Blue
  trustedBlue: '#0086EA',
  trustedBluePressed: '#007AD4',
  lightBlue: '#D9EDFC',

  // Subscription Statuses/Errors/Banners
  green: '#26BC58',
  lightGreen: '#DEF5E6',
  orange: '#E88F3D',
  lightOrange: '#FCEEE2',
  red: '#E71D3F',
  lightRed: '#FCDFE4',

  transparent: 'transparent',
};
