export * from '@finn/ua-tracking';
import { errorMessages, TEST_EMAIL_SUFFIX } from '@finn/ua-constants';
import {
  B2B_FORM_SUBMISSION_EVENT,
  B2B_LEAD_GEN_FORM_SUBMISSION_EVENT,
  CHATBOT_SESSION_STARTED,
  CONTACT_US_FORM_SUBMISSION_EVENT,
  CONTACT_US_PAGE,
  ECCOMMERCE_EVENTS_PROPERTIES,
  interactionTrackingEvent,
  TRACKING_DESTINATIONS,
  trackingErrorHandler,
} from '@finn/ua-tracking';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { getLongestTerm } from '@finn/ui-modules';
import {
  config,
  CountryCode,
  CURRENCIES,
  toString,
  trackEvent,
} from '@finn/ui-utils';
import debounce from 'lodash/debounce';

import { getVehicleMonthlyPrice } from './checkout';
import { kmOptions, mileageOptions } from './pdp';
import {
  EcommerceCheckout,
  EcommerceCheckoutStep,
  EcommerceOrderingEvent,
  EcommerceProduct,
  ReferralModalEvent,
  ReferralOptionEvent,
  TrackingEventName,
} from './tracking-types';

// no need to use this function on checkout flow after contact step
// as ajs_user_traits gets populated on contact submission, and we skip it anyway from the track function
export const skipTrackingTestEmail = (
  email: string,
  cb: () => void,
  fallback: () => void = () => {}
) => {
  const hostName = window.location.hostname;
  const isProduction =
    hostName === 'www.finn.auto' || hostName === 'www.finn.com';
  if (
    !!email &&
    (email === config.TRACKING_TEST_EMAIL ||
      !isProduction ||
      !email.includes(TEST_EMAIL_SUFFIX))
  ) {
    cb();
  } else {
    // intentional console log to see if a track call was skipped because of a test email
    console.log('Test email detected. Skipping a tracking call');
    fallback();
  }
};

export const checkoutErrorEvent = (
  message?: string,
  step?: string,
  error?: string | object,
  statusCode?: string | number,
  requestId?: string
) => {
  interactionTrackingEvent(TrackingEventName.CHECKOUT_ERROR, {
    step,
    label: message || errorMessages.SERVER_ERROR,
    error: toString(error),
    statusCode,
    requestId,
  });
};

export const ecommerceTrackingEvent = <T>(
  eventName: TrackingEventName,
  properties: T,
  integrations = {},
  region: string = CountryCode.DE
) => {
  const isUSA = region === CountryCode.US;
  const eventProperties = ECCOMMERCE_EVENTS_PROPERTIES[eventName] ?? {};
  trackingErrorHandler(() => {
    trackEvent(
      eventName,
      {
        ...properties,
        currency: isUSA ? CURRENCIES.USD : CURRENCIES.EURO,
        ...eventProperties,
      },
      integrations
    );
  });
};

export const getEcommerceProduct = (
  vehicle: GenericVehicleDetails,
  productInfo: {
    preDiscountPrice?: string;
    term?: number;
    kmPackage?: number;
    position?: number;
  } = {}
): EcommerceProduct => {
  const { position, preDiscountPrice } = productInfo;
  const term = productInfo.term ?? getLongestTerm(vehicle);
  const kmPackage = productInfo.kmPackage;

  // Get Selected monthly price
  const price = getVehicleMonthlyPrice(vehicle, term, kmPackage);

  // Handle optional fields
  const positionValue = position ? { position } : { position: 1 };

  return {
    product_id: vehicle.id,
    sku: vehicle.vehicle_identification_number,
    category: vehicle.cartype,
    name: `${vehicle.brand.id} ${vehicle.model}`,
    brand: vehicle.brand.id,
    make: vehicle.brand.id,
    model: vehicle.model,
    variant: vehicle.color.specific,
    price,
    quantity: 1,
    related_configs: vehicle.related_configs?.length || 0,
    url: `${config.FINN_WEB_URL}/pdp/${vehicle.id}`,
    image_url: vehicle.picture.url,
    preDiscountPrice,
    ...positionValue,
  };
};

export const referralModalTrackingEvent = <T>(
  eventName: TrackingEventName,
  properties: T
) => {
  trackingErrorHandler(() => {
    trackEvent(eventName, {
      ...properties,
    });
  });
};

export const triggerReferralTrackingEvent = ({
  eventName,
}: ReferralModalEvent) => {
  referralModalTrackingEvent(eventName, null);
};

export const triggerReferralOptionEvent = ({
  eventName,
  channel,
}: ReferralOptionEvent) => {
  referralModalTrackingEvent(eventName, { channel });
};

export const triggerEcommerceOrderingTrackingEvent = ({
  eventName,
  vehicle,
  productInfo,
  coupon,
  ...rest
}: EcommerceOrderingEvent) => {
  ecommerceTrackingEvent(eventName, {
    ...getEcommerceProduct(vehicle, productInfo),
    ...(coupon && { coupon }),
    productId: vehicle.id,
    offerType: vehicle.offer_type,
    coming_soon: vehicle.coming_soon,
    label: vehicle.product_label?.label ?? null,
    fuelType: vehicle.fuel,
    ...rest,
  });
};

export const triggerEcommerceCheckoutStepEvent = ({
  eventName,
  ...properties
}: EcommerceCheckoutStep) => {
  ecommerceTrackingEvent(eventName, {
    ...properties,
    dealId: properties.checkout_id,
  });
};

// We need this to handle checkout complete for GTM as Segment destination
export const triggerEcommerceCheckoutCompleteEvent = ({
  vehicles,
  productInfo,
  ...rest
}: EcommerceCheckout) => {
  const products = vehicles.map((vehicle) =>
    getEcommerceProduct(vehicle, productInfo)
  );
  const price = rest.value;
  const tax = rest.tax;
  const revenue = price - tax;

  const properties = {
    ...rest,
    products,
    revenue,
    dealId: rest.order_id,
    tax,
  };
  ecommerceTrackingEvent(TrackingEventName.ORDER_COMPLETED, properties);
};

const ecommerceProductListView = () => {
  const impressions = {
    products: [] as Array<EcommerceProduct>,
    set: new Set(),
    listId: '',
    partnerDiscount: 0,
  };

  const triggerProductListViewEvent = debounce(() => {
    ecommerceTrackingEvent(
      TrackingEventName.PRODUCT_LIST_VIEWED,
      {
        eventName: TrackingEventName.PRODUCT_LIST_VIEWED,
        products: impressions.products,
        listId: impressions.listId,
        category: impressions.listId,
        partnerDiscount: impressions.partnerDiscount,
      },
      { [TRACKING_DESTINATIONS.FB]: false } // Because Segment map PRODUCT_LIST_VIEWED to ViewContent which is wrong in our case because we only consider PRODUCT_VIEW as ViewContent, also we can handle it from Segment destination dashboard because we are using the device mode.
    );
  }, 1000);

  return ({
    vehicle,
    list,
    region = CountryCode.DE,
    partnerDiscount = 0,
  }: {
    vehicle: GenericVehicleDetails;
    list: string;
    region?: string;
    partnerDiscount?: number;
  }) => {
    const isUSA = region === CountryCode.US;
    if (impressions.set.has(vehicle.id)) return;
    impressions.products.push(
      getEcommerceProduct(vehicle, {
        term: getLongestTerm(vehicle),
        kmPackage: isUSA ? mileageOptions[0] : kmOptions[0],
        position: impressions.products.length + 1,
      })
    );
    impressions.set.add(vehicle.id);
    impressions.listId = list;
    impressions.partnerDiscount = partnerDiscount;
    triggerProductListViewEvent();
  };
};

export const triggerB2BFormSubmissionEvent = (
  fleetSize: number,
  email: string
) => {
  interactionTrackingEvent(B2B_FORM_SUBMISSION_EVENT.name, {
    category: B2B_FORM_SUBMISSION_EVENT.category,
    action: B2B_FORM_SUBMISSION_EVENT.action,
    label: `Count of cars: ${fleetSize}`,
    email,
    value: fleetSize,
  });
};

export const triggerB2BLeadGenFormSubmissionEvent = (
  fleetSize: number,
  email: string
) => {
  interactionTrackingEvent(B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.name, {
    category: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.category,
    action: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.action,
    label: `Count of cars: ${fleetSize}`,
    email,
    value: fleetSize,
  });
};

export const triggerContactFormSubmissionEvent = (
  email: string,
  requestSubject: string
) => {
  interactionTrackingEvent(CONTACT_US_FORM_SUBMISSION_EVENT.name, {
    category: CONTACT_US_FORM_SUBMISSION_EVENT.category,
    action: CONTACT_US_FORM_SUBMISSION_EVENT.action,
    label: `Contact Us Form Submitted`,
    email,
    request_subject: requestSubject,
  });
};

export const trackLogInBannerC2AClicked = () => {
  trackEvent(CONTACT_US_PAGE.LOGIN_BANNER_C2A_CLICKED, {});
};

export const trackRecommendedLinkClicked = (linkText: string) => {
  trackEvent(CONTACT_US_PAGE.RECOMMENDATION_LINK_CLICKED, {
    link_text: linkText,
  });
};

export const trackContactFormChatbotSessionStarted = (
  ultimateAiChatId?: string
) => {
  trackEvent(CONTACT_US_PAGE.CONTACT_FORM_CHATBOT_SESSION_STARTED, {
    ultimateAiChatId,
  });
};

export const trackChatbotSessionStarted = (ultimateAiChatId?: string) => {
  trackEvent(CHATBOT_SESSION_STARTED, { ultimateAiChatId });
};

export const addNewProductListView = ecommerceProductListView();

export const trackFaqButtonClicked = () => {
  trackEvent(CONTACT_US_PAGE.FAQ_BUTTON_CLICKED, {});
};

export const trackContactUsButtonClicked = () => {
  trackEvent(CONTACT_US_PAGE.CONTACT_US_BUTTON_CLICKED, {});
};
