import { useCurrentLocale } from '@finn/ui-utils';

import { useModuleDefaults } from '../services/content/helpers';

// TODO refactor to support proper SSR
export const useCosmicModule = (slug: string) => {
  const { locale } = useCurrentLocale();
  const [content] = useModuleDefaults(slug, locale);

  // we have no idea what type is in cosmic
  // so it is any (actually it is JSON)
  return content?.metadata as any;
};
