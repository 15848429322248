import { Button } from '@finn/design-system/atoms/button';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { cn, type HandleClickFn, parseToHtml } from '@finn/ui-utils';
import { MouseEventHandler } from 'react';

type IntroBlock = {
  title: string;
  type: string;
  cta_button: {
    href: string;
    title: string;
  };
  description: string;
};

const B2BMenuIntro = ({
  data,
  handleClick,
}: {
  data: IntroBlock;
  handleClick: HandleClickFn;
}) => {
  const hasDescription = data.description !== '';

  return (
    <div className={cn('mx-4 mb-10 mt-8 md:m-0')}>
      <div className="global-t5-semibold mb-5">{data.title}</div>
      {data.description && (
        <p className="body-16-regular mt-2">{data.description}</p>
      )}
      {data.cta_button && (
        <Button
          href={data.cta_button.href}
          className={hasDescription ? 'mt-6' : ''}
          onClick={
            ((event) =>
              handleClick({
                link: data.cta_button.href,
                type: NAVIGATION_TRACKING_EVENTS.CTA,
                e: event,
              })) as MouseEventHandler<HTMLButtonElement>
          }
        >
          <span>{parseToHtml(data.cta_button.title)}</span>
        </Button>
      )}
    </div>
  );
};

export default B2BMenuIntro;
