import { cn } from '@finn/ui-utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';

export type SwitchProps = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  description?: string;
  label?: string;
  testId?: string;
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, testId, ...props }, ref) => {
  const id = props.id ?? `switch-${props.label}`;

  return (
    <div
      className={cn('grid grid-cols-[32px,1fr] items-center gap-x-2 gap-y-4', {
        'grid-rows-[16px,min-content]': props.description,
      })}
      data-testid={testId}
    >
      <SwitchPrimitives.Root
        className={cn(
          'focus-visible:ring-ring focus-visible:ring-trustedBlue data-[state=checked]:bg-green data-[state=unchecked]:bg-iron peer inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent pe-px ps-px transition-colors focus-visible:outline-none focus-visible:ring-2',
          className
        )}
        {...props}
        ref={ref}
        id={id}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block h-[10px] w-[10px] rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0'
          )}
        />
      </SwitchPrimitives.Root>
      <label
        className={cn(
          'cursor-pointer',
          props.description ? 'body-16-semibold' : 'body-16-regular'
        )}
        htmlFor={id}
      >
        {props.label}
      </label>
      {props.description ? (
        <p className="body-14-regular col-span-2">{props.description}</p>
      ) : null}
    </div>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
