import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useCallback, useEffect } from 'react';

NProgress.configure({ showSpinner: false });

const useNavigationProgress = () => {
  const router = useRouter();

  const startProgress = useCallback((step: number = 0) => {
    NProgress.start();
    NProgress.set(step);
  }, []);

  const endProgress = useCallback(() => {
    NProgress.done();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', startProgress);
    router.events.on('routeChangeComplete', endProgress);
    router.events.on('routeChangeError', endProgress);

    return () => {
      router.events.off('routeChangeStart', startProgress);
      router.events.off('routeChangeComplete', endProgress);
      router.events.off('routeChangeError', endProgress);
    };
  }, [router.events]);

  return { startProgress, endProgress };
};

export default useNavigationProgress;
