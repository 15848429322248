import { GenericVehicleDetails } from '@finn/ua-vehicle';

export interface VehicleAttribute {
  key: string;
  transform?: (value: string | number) => string | number;
}

export interface FormattedAttribute {
  key: string;
  text: string | number;
}

/**
 * Extracts and formats attributes from a vehicle object based on the provided attribute definitions.
 * @param vehicle - The vehicle object from which to extract attributes.
 * @param attributes - An array of attribute definitions to extract
 * @returns An array of formatted attribute objects, excluding any that are null, undefined, or should be skipped based on special logic.
 */
export function extractVehicleAttributes(
  vehicle: GenericVehicleDetails,
  attributes: VehicleAttribute[]
): FormattedAttribute[] {
  return attributes
    .map(({ key }): FormattedAttribute | null => {
      const value = vehicle[key];

      if (value === null || value === undefined) {
        return null;
      }

      return { key, text: value };
    })
    .filter(Boolean);
}
