import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';

import { BenefitItemList } from './BenefitItemList';

type LevelSlideProps = {
  levelIndex: number;
  data?: LoyaltyCosmicMetadata;
  blur?: boolean;
};

export const LevelSlide = ({ levelIndex, data, blur }: LevelSlideProps) => {
  const currentLevel = data?.levels?.[levelIndex];
  const iconUrl = currentLevel?.icon?.url;
  const levelTitle = currentLevel?.title;
  const levelSubtitle = currentLevel?.subtitle;

  return (
    <div
      className="bg-snow flex h-full min-w-[300px] flex-col gap-8 rounded px-6 py-8 sm:min-w-[400px]"
      data-testid={`level-slide-${levelIndex}`}
    >
      <div className="flex flex-col gap-4">
        <div className="flex">
          <img src={iconUrl} alt="levelIcon" className="h-10 w-10" />
        </div>
        <h5 className="global-t5-semibold">{levelTitle}</h5>
        {levelSubtitle && <div className="body-14-light">{levelSubtitle}</div>}
      </div>
      <BenefitItemList levelIndex={levelIndex} data={data} blur={blur} />
    </div>
  );
};
