import { TrackingEventName } from '@finn/ua-tracking';
import { identifyEvent, trackEvent } from '@finn/ui-utils';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { humanize } from '../humanize';
import { getEventProperties } from './getEventProperties';

type TrackParams = {
  name?: string;
  fieldName?: string;
  location?: string;
  once?: boolean;
  additionalProps?: Record<string, any>;
};

export type TrackingStoreState = {
  properties: Record<string, any>;
  setProperty: (key: string, value: any) => void;
  track: (
    eventName: TrackingEventName,
    { name, fieldName, location, once }: TrackParams
  ) => void;
  identify: (
    userId: string,
    traits: Record<string, any>,
    location: string,
    once: boolean
  ) => void;
  resetTrackedCount: () => void;
  trackedCount: Record<string, number>;
};

// the idea is to separate setting the properties and tracking the event
// most of the time the property we track with an event are set somewhere/sometime else
// we have context and prop drilling for solving this, but it can be simplified with store

export const useTrackingStore = create<TrackingStoreState>()(
  devtools(
    immer((set, get) => ({
      properties: {},
      trackedCount: {},
      setProperty: (key, value) => {
        set((state) => {
          state.properties[key] = value;
        });
      },
      resetTrackedCount: () => {
        set((state) => {
          state.trackedCount = {};
        });
      },
      identify: (
        userId: string,
        traits: Record<string, any>,
        location: string,
        once: boolean
      ) => {
        set((state) => {
          const eventKey = `identify-${location || ''}`;
          if (once && state.trackedCount[eventKey]) {
            return;
          }
          try {
            identifyEvent(userId, traits);
          } catch (e) {
            console.error(e);
          }
          if (once) {
            state.trackedCount[eventKey] =
              (state.trackedCount[eventKey] || 0) + 1;
          }
        });
      },
      track: (
        eventName,
        { fieldName, name, location, once, additionalProps }
      ) => {
        set((state) => {
          const nameOrFieldName = fieldName || name;
          const eventKey = `${eventName}${nameOrFieldName || ''}${location || ''}`;
          if (once && state.trackedCount[eventKey]) {
            return;
          }
          try {
            trackEvent(eventName, {
              name: humanize(nameOrFieldName),
              location,
              ...getEventProperties(eventName, get().properties),
              ...(additionalProps || {}),
            });
          } catch (e) {
            console.error(e);
          }
          if (once) {
            state.trackedCount[eventKey] =
              (state.trackedCount[eventKey] || 0) + 1;
          }
        });
      },
    })),
    { name: 'CheckoutTrackingStore' }
  )
);
