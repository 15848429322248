import { Button } from '@finn/design-system/atoms/button';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { Image } from '@finn/ui-components';
import { FearAwayItem } from '@finn/ui-cosmic';
import {
  cn,
  getCloudinaryImgUrl,
  type HandleClickFn,
  parseToHtml,
} from '@finn/ui-utils';
import { MouseEventHandler } from 'react';

type BenefitsBlock = {
  title: string;
  type: string;
  cta_button: {
    href: string;
    title: string;
  };
  benefits: FearAwayItem[];
};

const migrationToENHrefMap: { [key: string]: string } = {
  '/fahrzeuge': '/subscribe',
  '/kaufen': '/buy',
};

const MenuBlockBenefits = ({
  data,
  handleClick,
}: {
  data: BenefitsBlock;
  handleClick: HandleClickFn;
}) => {
  return (
    <>
      <div className="global-t6-semibold mb-5 mt-1 h-8">{data.title}</div>
      {data.benefits.map(({ metadata }: FearAwayItem, idx) => (
        <div
          key={metadata.title + idx}
          className="mb-4 flex items-center last:mb-0"
        >
          <Image
            src={getCloudinaryImgUrl(metadata.icon.url, {
              w: 296,
              dpr: 2,
            })}
            alt={metadata.title}
            className="mr-4 h-4 w-4"
          />
          <p className="body-16-regular">{metadata.title}</p>
        </div>
      ))}
      {data.cta_button && (
        <Button
          size="lg"
          className={cn('my-2 mt-4 w-full')}
          href={
            migrationToENHrefMap[data.cta_button.href] || data.cta_button.href
          }
          onClick={
            ((event) =>
              handleClick({
                link: data.cta_button.href,
                type: NAVIGATION_TRACKING_EVENTS.CTA,
                e: event,
              })) as MouseEventHandler<HTMLButtonElement>
          }
        >
          <span>{parseToHtml(data.cta_button.title)}</span>
        </Button>
      )}
    </>
  );
};

export default MenuBlockBenefits;
