import { Badge } from '@finn/design-system/atoms/badge';
import { cn, getFormattedPrice, getTermLabel } from '@finn/ui-utils';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  selectedTerm: number;
  currentTerm: number;
  originalPrice?: number;
  price: number;
  setSelectedTerm: (term: number) => void;
  isDownpayment?: boolean;
};

export const ProlongationTerm: React.FC<Props> = ({
  selectedTerm,
  currentTerm,
  originalPrice,
  price,
  setSelectedTerm,
  isDownpayment,
}) => {
  const i18n = useIntl();

  const hasDiscount = originalPrice && originalPrice > price;
  const discountPercentage = hasDiscount
    ? Math.round(((originalPrice - price) / originalPrice) * 100)
    : 0;

  return (
    <div
      className={cn({
        'border-pearl hover:border-iron flex shrink-0 grow-0 basis-1/2 cursor-pointer flex-row items-center justify-between rounded-sm border-2 border-solid p-4':
          true,
        'border-black hover:border-black': selectedTerm === currentTerm,
      })}
      role="button"
      tabIndex={0}
      key={currentTerm}
      data-testid={`prolongation-term-${currentTerm}`}
      onClick={() => {
        setSelectedTerm(currentTerm);
      }}
    >
      <div className="flex gap-2">
        <span className="body-14-semibold inline-flex">
          {getTermLabel(currentTerm, '', i18n)}
        </span>
        {discountPercentage > 0 && (
          <Badge
            variant="monochromeBlue"
            withStroke
            className="body-12-medium h-[19px]"
          >
            {`${discountPercentage}% Rabatt`}
          </Badge>
        )}
      </div>
      <div className="flex gap-2">
        {!isDownpayment && hasDiscount && (
          <span className={cn('body-16-light inline-flex line-through')}>
            {getFormattedPrice(i18n, originalPrice ?? 0, 0)}
          </span>
        )}
        <span className="body-16-light inline-flex">
          {getFormattedPrice(i18n, price, 0)}
        </span>
      </div>
    </div>
  );
};
