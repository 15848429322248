export type AllFeatures = Record<string, Record<string, IFeature>>;

export interface IFeature {
  name: string;
  buckets: string[];
  pages?: string[];
  enabled?: boolean;
}

const DISABLED = 'disabled';

const getVariant = (buckets: string[], isSearchBot: boolean) => {
  const index = isSearchBot ? 0 : Math.floor(Math.random() * buckets.length);

  return buckets[index];
};

/**
 * Internal method for setting & updating the current users feature values
 * @param allFeatures all the features enabled for this application, grouped by locale
 * @param currentLocale the current locale
 * @param getCookie method to use to get existing customer value
 * @param userAgent identifying the current browser
 * @returns all current bucket values
 */
export const computeFeatureValues = (
  allFeatures: AllFeatures,
  currentLocale: string,
  getCookie: (val: string) => string | undefined,
  userAgent?: string
) => {
  const updatedBuckets: Record<string, string> = {};
  const bucketValues: Record<string, string> = {};
  const featuresForLocale = allFeatures[currentLocale] || {};
  const isSearchBot = userAgent
    ? /bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent)
    : false;

  for (const name of Object.keys(featuresForLocale)) {
    const rawBucketValue = getCookie(`${currentLocale}_${name}`);
    const { buckets: variants, enabled } = featuresForLocale[name];
    let hasValidCookie = variants.includes(String(rawBucketValue));

    let bucketValue =
      hasValidCookie && rawBucketValue
        ? rawBucketValue
        : getVariant(variants, isSearchBot);

    // Set the value 'disabled' before a test is started
    if (!enabled && !rawBucketValue) {
      bucketValue = DISABLED;
      hasValidCookie = true;
    }

    bucketValues[name] = bucketValue;

    if (!hasValidCookie && bucketValue) {
      updatedBuckets[name] = bucketValue;
    }
  }

  return { updatedBuckets, bucketValues };
};
