import { cn } from '@finn/ui-utils';
import { memo } from 'react';

type Props = {
  highlight?: boolean;
  children: string;
};

const Label = ({ children, highlight }: Props) => {
  // unfortunately, we need to do some hardcoded checks here
  // Reduziert\
  // -5% mit "DEAL5"
  const highlightLabel =
    highlight ||
    children.includes('Reduziert') ||
    children.includes('DEAL5') ||
    children.includes('FINN30');

  return (
    <p
      className={cn('body-12-semibold', {
        'text-iron': !highlightLabel,
        'text-trustedBlue': highlightLabel,
      })}
    >
      {children}
    </p>
  );
};

export default memo(Label);
