import { IncomingMessage } from 'http';
import absoluteUrl from 'next-absolute-url';

const secureLocalhostAddr = 'localhost:3001';

export const getOrigin = (req?: IncomingMessage) => {
  const host = req?.headers ? req.headers.host : window.location.host;
  // built in next absoluteUrl changes the protocol to http if it's localhost
  // we have ssl proxy, so we need to keep it https
  if (host === secureLocalhostAddr) {
    return `https://${host}`;
  }
  const { origin } = absoluteUrl(req);

  return origin;
};
