import { cn } from '@finn/ui-utils';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { cva } from 'class-variance-authority';
import * as React from 'react';

const borderStyles =
  "border-pearl data-[state='on']:!border-black border border-solid hover:shadow-steel data-[state='on']:!shadow-black data-[state='on']:!shadow-[0_0_0_1px] hover:shadow-[0_0_0_1px] active:shadow-black active:shadow-[0_0_0_1px] hover:border-steel active:border-black ";
const accessabilityClassNames =
  'focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-0';

const toggleVariants = cva(
  [
    'group bg-transparent rounded-sm cursor-pointer data-[disabled]:pointer-events-none p-3',
    borderStyles,
  ],
  {
    variants: {
      variant: {
        regular: 'h-16 flex flex-col items-center',
        extended: 'min-h-[68px] w-full',
      },
    },
    defaultVariants: {
      variant: 'regular',
    },
  }
);

type DescriptionAsArray = {
  description?: string[];
  descriptionDirection?: 'horizontal' | 'vertical';
};

type DescriptionAsString = {
  description?: string;
};

type DescriptionAsNode = {
  description?: React.ReactNode;
};

type ExtendedToggleItemProps = {
  variant: 'extended';
  col?: 1 | 2;
  title: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  badge?: string;
  href?: string;
  target?: string;
} & (DescriptionAsString | DescriptionAsArray | DescriptionAsNode);

type RegularToggleItemProps = {
  variant: 'regular';
  title: string;
  description?: string;
  withBadge?: boolean;
};

type VariantDependentProps = RegularToggleItemProps | ExtendedToggleItemProps;

type ToggleItemProps = React.ComponentPropsWithoutRef<
  typeof ToggleGroupPrimitive.Item
> &
  VariantDependentProps;

const RegularToggleItem = React.forwardRef<
  HTMLButtonElement,
  RegularToggleItemProps & { className?: string }
>(({ title, description, withBadge, ...restProps }, ref) => {
  return (
    <button
      ref={ref}
      {...restProps}
      className={cn(restProps.className, 'group justify-center')}
    >
      <p className="body-14-semibold group-data-[disabled]:text-iron">
        {title}
      </p>
      {description ? (
        <p
          className={cn(
            'mt-2',
            withBadge
              ? 'body-12-medium text-trustedBlue border-trustedBlue rounded-sm border border-solid p-1.5'
              : 'body-14-light group-data-[disabled]:text-iron'
          )}
        >
          {description}
        </p>
      ) : null}
    </button>
  );
});

const hasDescriptionDirection = (
  props: DescriptionAsString | DescriptionAsArray
): props is DescriptionAsArray => 'descriptionDirection' in props;

const ExtendedToggleItem = React.forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  Omit<ExtendedToggleItemProps, 'variant'> & { className?: string }
>(
  (
    props: Omit<ExtendedToggleItemProps, 'variant'> & { className?: string },
    ref
  ) => {
    const {
      col,
      startAdornment,
      endAdornment,
      title,
      description,
      badge,
      href,
      target,
      className,
      ...restProps
    } = props;

    const Wrapper = href ? 'a' : 'button';

    return (
      <Wrapper
        ref={ref}
        href={href}
        target={target}
        {...restProps}
        className={cn(
          href ? 'text-decoration-none cursor-auto text-black' : '',
          col === 2
            ? 'grid h-max w-full grid-cols-[56px,1fr] grid-rows-1 items-start justify-items-start gap-x-1'
            : 'grid w-full grid-cols-1 grid-rows-[min-content,1fr]',
          {
            'cursor-pointer !text-inherit !no-underline': href,
          },
          className,
          !description ? 'flex flex-col justify-center' : ''
        )}
      >
        {col === 2 && startAdornment ? (
          <div className="row-span-2 flex max-h-10 w-full max-w-14 items-start justify-center">
            {startAdornment}
          </div>
        ) : null}
        <div
          className={cn(
            'grid h-full w-full grid-cols-1 gap-y-2',
            accessabilityClassNames
          )}
        >
          <div
            className={cn('flex w-full justify-between', {
              'items-center': !description,
            })}
          >
            <div className="flex items-center">
              {col === 1 && startAdornment ? startAdornment : null}
              <p className="body-14-semibold group-data-[disabled]:text-iron group-data-[disabled]:border-pearl text-left">
                {title}
              </p>
              {badge ? (
                <p className="body-12-medium text-trustedBlue border-trustedBlue ml-2 rounded-sm border border-solid p-1.5">
                  {badge}
                </p>
              ) : null}
            </div>
            {endAdornment ? endAdornment : null}
          </div>
          {Array.isArray(description) ? (
            <div
              className={cn('flex items-center', {
                'flex-col items-start':
                  hasDescriptionDirection(restProps) &&
                  restProps.descriptionDirection === 'vertical',
              })}
            >
              {description.flatMap((item, index) =>
                index < description.length - 1 &&
                hasDescriptionDirection(restProps) &&
                restProps.descriptionDirection === 'horizontal'
                  ? [
                      <p key={item} className="body-14-light text-left">
                        {item}
                      </p>,
                      <span
                        key={`dot-${index}`}
                        aria-hidden
                        className="bg-pewter m-2 h-1 w-1 rounded-sm"
                      />,
                    ]
                  : [
                      <p key={item} className="body-14-light my-1 text-left">
                        {item}
                      </p>,
                    ]
              )}
            </div>
          ) : null}
          {description && typeof description === 'string' ? (
            <p className="body-14-light text-left">{description}</p>
          ) : null}
          {description && React.isValidElement(description)
            ? description
            : null}
        </div>
      </Wrapper>
    );
  }
);

const ToggleItem = React.forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  ToggleItemProps
>(({ className, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      className={cn(
        toggleVariants({
          variant: props.variant,
        }),
        className
      )}
      {...props}
      asChild
    >
      {props.variant === 'regular' ? (
        <RegularToggleItem ref={ref} {...props} />
      ) : (
        <ExtendedToggleItem ref={ref} {...props} />
      )}
    </ToggleGroupPrimitive.Item>
  );
});

ToggleItem.displayName = TogglePrimitive.Root.displayName;

export { ToggleItem, type ToggleItemProps, RegularToggleItem };
