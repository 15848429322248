import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { LoyaltyBenefitsMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import React, { useCallback } from 'react';

import { InfoModalData } from '../modals/InfoModal';

type Props = {
  benefit?: LoyaltyBenefitsMetadata;
  openInfoModal: (infoData: InfoModalData) => void;
};

export const BenefitChip: React.FC<Props> = ({ benefit, openInfoModal }) => {
  const hasModal = benefit?.modal_title;
  const subtitle = benefit?.display_subtitle;
  const onChipClick = useCallback(() => {
    if (!hasModal) {
      return;
    }
    interactionTrackingEvent(TrackingEventName.LOYALTY_ITEM_CLICKED, {
      item: benefit?.display_title,
    });
    openInfoModal({
      title: hasModal,
      description: benefit?.modal_description,
      cta: benefit?.modal_cta,
      onModalClose: () => {
        interactionTrackingEvent(TrackingEventName.LOYALTY_ITEM_CLOSED, {
          item: benefit?.display_title,
        });
      },
    });
  }, [benefit, hasModal, openInfoModal]);

  return (
    <div
      className={cn('flex items-center', hasModal ? 'cursor-pointer' : '')}
      onClick={onChipClick}
    >
      <div
        className={cn(
          'bg-pewter flex items-center justify-center overflow-hidden rounded-bl rounded-tl'
        )}
      >
        <img src={benefit?.icon?.url} alt={benefit?.display_title} />
      </div>
      <div
        className={cn(
          'bg-snow flex h-full max-w-[calc(100%-76px)] flex-1 items-center gap-4 rounded-br rounded-tr px-4'
        )}
      >
        <div className={subtitle ? 'flex flex-col gap-4' : ''}>
          <div className="body-14-semibold">{benefit?.display_title}</div>
          {!!subtitle && <span className="body-12-semibold">{subtitle}</span>}
        </div>
        <div className="ml-auto">
          {hasModal ? <KeyboardArrowRight /> : null}
        </div>
      </div>
    </div>
  );
};
