import { VEHICLE_VAT } from '@finn/ua-constants';
import { GenericVehicleDetails } from '@finn/ua-vehicle';

export const getKmPackagePriceWithoutVat = (
  price: number,
  isForBusiness: Boolean
) => (isForBusiness ? Math.round(Number(price) / VEHICLE_VAT) : Number(price));

export const isOnStock = (vehicle: GenericVehicleDetails) => {
  return !!vehicle.availability;
};

// Available distance options in miles and kilometers.
export const mileageOptions = [850, 1000, 1250, 1500];
export const kmOptions = [500, 1000, 1500, 2000, 2500, 3000, 4000, 5000];
