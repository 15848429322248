import { cn } from '@finn/ui-utils';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import { Button } from '../button';
import { ArrowBackIos } from '../icons/generated/arrow-back-ios';
import { Close as CloseIcon } from '../icons/generated/close';

const {
  Root: DrawerRoot,
  Overlay,
  Content,
  Portal,
  Close,
  Title,
  Description,
  Trigger,
} = DrawerPrimitive;

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref}
    className={cn(
      // position
      'fixed inset-0 z-[1300] bg-black/45',
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = Overlay.displayName;

const renderDefaultHeader = ({ onBack }: { onBack?: () => void }) => (
  <div className="flex justify-between">
    {onBack ? (
      <Button variant="ghost" className="h-max p-0" onClick={onBack}>
        <ArrowBackIos aria-label="back" />
      </Button>
    ) : (
      <div />
    )}
    <Close asChild>
      <Button variant="ghost" className="h-max p-0">
        <CloseIcon aria-label="close-icon" />
      </Button>
    </Close>
  </div>
);

const DrawerContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {
    onBack?: () => void;
    renderHeader?: ({ onBack }: { onBack?: () => void }) => ReactNode;
  }
>(
  (
    {
      className,
      children,
      onBack,
      renderHeader = renderDefaultHeader,
      ...props
    },
    ref
  ) => (
    <Portal>
      <DialogOverlay />
      <Content
        ref={ref}
        className={cn(
          'fixed inset-x-0 bottom-0 z-[1300] mt-24 flex !touch-pan-y rounded-t bg-white',
          // layout
          'box-border flex max-h-[calc(100%-1.5rem)] flex-col gap-8 overflow-auto bg-white p-4 after:hidden',
          className
        )}
        {...props}
      >
        {renderHeader({ onBack })}
        {children}
      </Content>
    </Portal>
  )
);
DrawerContent.displayName = Content.displayName;

const DrawerHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col space-y-4', className)} {...props} />
);
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex h-full w-full flex-col items-center justify-end gap-2',
      className
    )}
    {...props}
  >
    {Children.map(children as ReactElement<{ className?: string }>, (child) =>
      cloneElement(child, {
        className: cn(child?.props?.className, 'w-full'),
      })
    )}
  </div>
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title
    ref={ref}
    className={cn('mobile-t3-semibold md:web-t4-semibold', className)}
    {...props}
  />
));

DrawerTitle.displayName = Title.displayName;

const DrawerSubtitle = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref}
    className={cn('body-16-light', className)}
    {...props}
  />
));
DrawerSubtitle.displayName = Description.displayName;

export {
  DrawerRoot,
  Trigger as DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerSubtitle,
};
