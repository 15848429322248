'use client';

import { cn } from '@finn/ui-utils';
import * as SelectPrimitive from '@radix-ui/react-select';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';

import { getComponentDisplayName } from '../../internal/react';
import { Check } from '../icons/generated/check';
import { ExpandMore } from '../icons/generated/expand-more';
import { CoreLabel } from '../input/CoreLabel';

// we want to consuder null/empty string as undefined to still show
// placeholder with them
const Select = ({
  value,
  ...props
}: ComponentPropsWithoutRef<typeof SelectPrimitive.Root>) => (
  <SelectPrimitive.Root value={value || undefined} {...props} />
);

const SelectValue = forwardRef<
  ElementRef<typeof SelectPrimitive.Value>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Value> & {
    forcePlaceholder?: boolean;
  }
>(({ children, className, forcePlaceholder, ...props }, ref) => (
  <span className={cn('w-full text-start', className)}>
    <SelectPrimitive.Value ref={ref} {...props}>
      {forcePlaceholder ? (
        <span className="body-16-regular">{props.placeholder}</span>
      ) : (
        children
      )}
    </SelectPrimitive.Value>
  </span>
));

SelectValue.displayName = 'SelectValue';

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    size?: 'lg' | 'md';
    error?: boolean | string;
    icon?: ReactNode;
    wrapperClassName?: string;
    label?: ReactNode;
  }
>(
  (
    {
      className,
      children,
      wrapperClassName,
      icon,
      label,
      size = 'lg',
      error,
      ...props
    },
    ref
  ) => (
    <div className={wrapperClassName}>
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          'group relative flex w-max cursor-pointer items-center justify-between gap-1',
          'border-pearl hover:bg-cotton rounded-sm border border-solid bg-white before:hidden after:hidden',
          'disabled:fill-iron disabled:bg-snow disabled:text-iron px-3 py-2.5 disabled:pointer-events-none',
          'fill-black data-[state=open]:border-black',
          'active:bg-pearl',
          'focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
          'disable:pointer-events-none before:hidden after:hidden data-[placeholder]:before:hidden data-[placeholder]:after:hidden',
          {
            // according to design we have 2 primary size variant for select
            'body-16-semibold data-[placeholder]:body-16-regular h-14':
              size === 'lg',
            'body-14-semibold data-[placeholder]:body-14-regular h-10':
              size === 'md',
            'border-red border-2': error,
          },
          className
        )}
        {...props}
      >
        {Children.map(children as ReactElement[], (child) => {
          if (getComponentDisplayName(child) === 'SelectValue') {
            return cloneElement(child, {
              className: cn(
                label ? 'pt-4' : '',
                (child as ReactElement<{ className?: string }>).props.className
              ),
              // TODO fix any
            } as any);
          }

          return child;
        })}
        <SelectPrimitive.Icon
          asChild
          className="min-h-[18px] min-w-[18px] transition-transform group-data-[state=open]:rotate-180"
        >
          {icon || <ExpandMore />}
        </SelectPrimitive.Icon>
        {label ? <CoreLabel shouldShowLabelAtTop>{label}</CoreLabel> : null}
      </SelectPrimitive.Trigger>
      {typeof error === 'string' && error ? (
        <p className="body-12-regular text-red mt-3 text-left">{error}</p>
      ) : null}
    </div>
  )
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Content
    ref={ref}
    className={cn(
      'relative z-[10000] max-h-60 min-w-16 overflow-hidden rounded-sm bg-white shadow-md',
      // animations
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
      className
    )}
    position="popper"
    {...props}
  >
    <SelectPrimitive.Viewport
      className={cn(
        'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)] py-4'
      )}
    >
      {children}
    </SelectPrimitive.Viewport>
  </SelectPrimitive.Content>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative grid min-h-14 w-full cursor-default grid-cols-[min_content,1fr] items-center gap-4',
      'body-16-regular px-6 py-4 before:hidden after:hidden',
      'hover:bg-cotton data-[highlighted]:bg-cotton',
      'outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <div className="col-start-2 h-6 w-6 self-center justify-self-end">
      <SelectPrimitive.ItemIndicator asChild>
        <Check className="h-full w-full" />
      </SelectPrimitive.ItemIndicator>
    </div>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

export { Select, SelectValue, SelectTrigger, SelectContent, SelectItem };
