import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { Image, NextLinkSingleApp } from '@finn/ui-components';
import { MediaData } from '@finn/ui-cosmic';
import {
  App,
  cn,
  getCloudinaryImgUrl,
  getCloudinarySrcSet,
  type HandleClickFn,
} from '@finn/ui-utils';

type Item = {
  title: string;
  type: string;
  description: string;
  image: MediaData;
  href: string;
  image_alt_text?: string;
};

type MenuBlockInfoCardProps = {
  data: {
    items: Item[];
  };
  handleClick: HandleClickFn;
};

const MenuBlockInfoCard = ({ data, handleClick }: MenuBlockInfoCardProps) => {
  return (
    <div>
      {data.items.map((card, idx) => {
        const cloudinaryURL = getCloudinaryImgUrl(card.image.url, {
          w: 296,
          dpr: 2,
        });
        const srcSet = getCloudinarySrcSet(card.image.url, [296]);

        return (
          <NextLinkSingleApp
            app={App.UA}
            key={card.title + idx}
            href={card.href}
            // we want relative links to open in the same tab and external links to open in a new tab
            target={card.href.startsWith('/') ? '_self' : '_blank'}
            className="text-black"
            onClick={(event) =>
              handleClick({
                link: card.href,
                type: NAVIGATION_TRACKING_EVENTS.TEASER,
                e: event,
              })
            }
          >
            <Image
              variant="lazy-loading"
              src={cloudinaryURL}
              srcSet={srcSet}
              alt={card.image_alt_text || card.title}
              className="mb-4 h-[148px] w-full rounded-sm object-cover"
            />
            <div className={cn('mx-4 mb-8 md:m-0')}>
              <span className="global-t6-semibold inline-flex pb-1">
                {card.title}
              </span>
              <p className={cn('body-16-regular pb-1')}>{card.description}</p>
            </div>
          </NextLinkSingleApp>
        );
      })}
    </div>
  );
};

export default MenuBlockInfoCard;
