import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { useCurrentLocale } from '@finn/ui-utils';

export const useDownPayment = () => {
  const hideDownPayment = useIsABVariant(Features.HideDownPayment);
  const { isDE } = useCurrentLocale();

  if (hideDownPayment || !isDE) {
    return { isDownPaymentEnabled: false };
  }

  return { isDownPaymentEnabled: true };
};
