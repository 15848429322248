import { useEffect } from 'react';

import { getAppSDK } from '../helpers';

export const useCheckoutReady = (isLoading: boolean) => {
  useEffect(() => {
    const appSDK = getAppSDK();

    if (!isLoading) {
      appSDK?.sendMessageToAppSDK('checkout_ready');
    }
  }, [isLoading]);
};
