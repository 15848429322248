import { getClosedUserGroupId } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { getShortestKmOption } from '@finn/auto-ui/utils/pdp';
import { CheckoutStep } from '@finn/ua-auth';
import {
  DEFAULT_DOWN_PAYMENT,
  IS_DIRECT_CHECKOUT_ENABLED,
} from '@finn/ua-constants';
import { useDownPayment } from '@finn/ua-featureflags';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName } from '@finn/ua-tracking';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import {
  calculateTotalPrice,
  getEcommerceProduct,
  getLongestTerm,
  triggerEcommerceOrderingTrackingEvent,
} from '@finn/ui-modules';
import {
  CookieKeys,
  getClientCookie,
  setClientBooleanCookie,
  setClientCookie,
  setClientJSONCookie,
  trackEvent,
  useCurrentLocale,
  useSession,
} from '@finn/ui-utils';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { directCheckout, directCheckoutEligibility } from '~/services/checkout';
import { DirectCheckoutEligibilityResult } from '~/types/checkout';
import { getCheckoutCartUrl } from '~/utils/checkout';

import { goToNextCheckoutStep } from '../Checkout/utils/routeHandler';

interface ConfigureActions {
  // Public
  setTerm(term: number): void;
  setKilometerPackage(kmPackage: number): void;
  setDownPayment(downPayment: boolean): void;

  /**
   * Main submit action of the page, will try to do Direct Checkout if available
   * */
  onContinue(): Promise<void>;

  /**
   * Called from within the Direct Checkout Confirm popup
   * */
  onDirectCheckoutContinue(onDirectCheckoutContinue: {
    accepted?: boolean;
  }): Promise<void>;

  // Dependencies
  setUser(contactId: string): Promise<void>;
  setVehicle(vehicle: GenericVehicleDetails, locale: string): Promise<void>;
  setZipCode(zipCode: string): Promise<void>;
  setRouter(router: NextRouter);
  setOpenModal(openModal: (key: ModalKey) => void);

  // Internal
  goToCart(isDirect?: boolean): void;
  verifyDirectCheckout(): Promise<void>;
  setInitialTerm(): void;
}

interface ConfigureState extends ConfigureActions {
  downPaymentEnabled: boolean;
  downPaymentAmount: number;
  isLoading: boolean;
  downPayment: boolean;
  term: number;
  kilometerPackage: number;
  directCheckoutCheckResult?: DirectCheckoutEligibilityResult;
  monthlyPrice: number;

  // Dependencies
  vehicle?: GenericVehicleDetails;
  zipCode?: string;
  contactId?: string;
  locale?: string;
  router?: NextRouter;
  openModal?: (key: ModalKey) => void;
}

const initialState = {
  term: 0,
  monthlyPrice: 0,
  isLoading: false,
  downPayment: true,
  kilometerPackage: 0,
  downPaymentAmount: 1200,
  downPaymentEnabled: true,
};

const STORE_NAME = 'configure';
enum ActionNames {
  SET_TERM = 'setTerm',
  SET_DOWNPAYMENT = 'setDownPayment',
  SET_KILOMETER_PACKAGE = 'setKilometerPackage',
  VERIFY_DIRECT_CHECKOUT = 'verifyDirectCheckout',
  ON_CONTINUE = 'onContinue',
  GO_TO_CART = 'goToCart',
  SET_INITIAL_TERM = 'setInitialTerm',
  ON_DIRECT_CHECKOUT_CONTINUE = 'onDirectCheckoutContinue',
}

export const useConfigureStore = create<ConfigureState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setTerm(term: number) {
        set(
          ({ downPayment }) => ({
            term,
            downPaymentEnabled: term !== 1,
            downPayment: term !== 1 && downPayment,
          }),
          false,
          ActionNames.SET_TERM
        );
      },
      setKilometerPackage(kilometerPackage: number) {
        set(
          () => ({ kilometerPackage }),
          false,
          ActionNames.SET_KILOMETER_PACKAGE
        );
      },

      setDownPayment(downPayment: boolean) {
        set(() => ({ downPayment }), false, ActionNames.SET_DOWNPAYMENT);
      },

      async verifyDirectCheckout() {
        const { contactId, zipCode, vehicle, locale } = get();
        if (!contactId || !IS_DIRECT_CHECKOUT_ENABLED || !vehicle) {
          return;
        }

        const directCheckoutCheckResult = await directCheckoutEligibility(
          {
            vehicleId: vehicle.id,
            zipcode: zipCode,
          },
          { locale }
        );

        set(
          () => ({
            directCheckoutCheckResult,
          }),
          false,
          ActionNames.VERIFY_DIRECT_CHECKOUT
        );
      },

      async onContinue() {
        set(() => ({ isLoading: true }), false, ActionNames.ON_CONTINUE);

        const {
          contactId,
          vehicle,
          zipCode,
          onDirectCheckoutContinue,
          directCheckoutCheckResult,
          term,
          kilometerPackage,
          goToCart,
        } = get();

        const isTesting =
          getClientCookie(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E) === 'yes';

        // reset to show delivery fee modal again for new checkout
        setClientCookie(CookieKeys.DELIVERY_MODAL_SHOWED, 'no');

        const trackingProps = getEcommerceProduct(vehicle, {
          term,
          kmPackage: kilometerPackage,
        });

        if (contactId && !isTesting) {
          if (
            !directCheckoutCheckResult ||
            !directCheckoutCheckResult?.allowDirectCheckout
          ) {
            trackEvent(TrackingEventName.DIRECT_CHECKOUT_INELIGIBLE, {
              directCheckoutCheckResult,
              vehicleId: vehicle?.id,
              zipCode,
              financingType: vehicle?.financing_type,
              contactId,
            });

            goToCart();

            return;
          } else {
            trackEvent(TrackingEventName.DIRECT_CHECKOUT_WITHOUT_MODAL, {
              ...trackingProps,
              directCheckoutStep: directCheckoutCheckResult?.checkoutStep,
            });
            await onDirectCheckoutContinue({ accepted: true });

            return;
          }
        }

        goToCart();
      },

      async onDirectCheckoutContinue() {
        set(
          () => ({ isLoading: true }),
          false,
          ActionNames.ON_DIRECT_CHECKOUT_CONTINUE
        );

        const {
          vehicle,
          term,
          kilometerPackage,
          goToCart,
          zipCode,
          locale,
          router,
          downPayment,
        } = get();

        try {
          const res = await directCheckout(
            {
              vehicleId: vehicle.id,
              term,
              kilometerPackage,
              zipcode: zipCode,
              downpaymentSelected: downPayment,
              cugId: getClosedUserGroupId(),
            },
            { locale }
          );

          if (!res.deal.id) {
            goToCart();
          } else {
            // todo: set default availability and swap
            setClientBooleanCookie(CookieKeys.DC_TRIGGER_DEAL_EVENT, true);
            setClientJSONCookie(CookieKeys.DC_CREDIT_SCORE, res?.creditScore);
            const checkoutStep = res?.checkoutStep;
            const dealData = {
              contactId: res?.contact?.id,
              dealId: res?.deal?.id,
              hash: res?.hash,
              isDirectCheckout: [
                CheckoutStep.PAYMENT,
                CheckoutStep.CONFIRMATION,
              ].includes(checkoutStep as CheckoutStep),
            };
            goToCart(true);
            goToNextCheckoutStep(router, checkoutStep, dealData);
          }
        } catch {
          goToCart();
        }
      },

      goToCart(isDirect = false) {
        set(() => ({ isLoading: false }), false, ActionNames.GO_TO_CART);
        const { vehicle, router, kilometerPackage, term, downPayment } = get();
        const monthlyPrice = calculateTotalPrice({
          vehicle,
          kilometerPackage,
          term,
          isDownPayment: downPayment,
        });
        const monthlyPriceWithoutDownpayment = calculateTotalPrice({
          vehicle,
          kilometerPackage,
          term,
          isDownPayment: false,
        });

        triggerEcommerceOrderingTrackingEvent({
          eventName: TrackingEventName.PRODUCT_ADDED,
          kmPackage: kilometerPackage,
          monthlyPrice,
          monthlyPriceWithoutDownpayment,
          downPaymentAmount: downPayment ? DEFAULT_DOWN_PAYMENT : undefined,
          term,
          vehicle,
        });
        if (isDirect) return;

        const downPaymentAmount = downPayment
          ? DEFAULT_DOWN_PAYMENT
          : undefined;

        const url = getCheckoutCartUrl(
          vehicle.id,
          term,
          kilometerPackage,
          downPaymentAmount
        );

        router.push(url);
      },

      setInitialTerm() {
        const { vehicle, router } = get();

        if (!vehicle || !router) return;

        const defaultTerm = getLongestTerm(vehicle);

        const { term: queryTerm } = router.query;

        const parsedTerm = queryTerm && parseInt(queryTerm as string);
        const isValidTerm = vehicle.available_terms.includes(parsedTerm);

        const term = (isValidTerm && parsedTerm) || defaultTerm;

        set(() => ({ term }), false, ActionNames.SET_INITIAL_TERM);
      },

      // Dependencies
      setOpenModal(openModal) {
        set(() => ({ openModal }));
      },

      async setVehicle(vehicle: GenericVehicleDetails, locale: string) {
        const kilometerPackage = getShortestKmOption(vehicle);

        set(() => ({
          vehicle,
          locale,
          kilometerPackage,
          isLoading: false,
        }));
        await get().verifyDirectCheckout();

        get().setInitialTerm();
      },

      async setUser(contactId: string) {
        set(() => ({ contactId }));
        await get().verifyDirectCheckout();
      },

      async setZipCode(zipCode: string) {
        set(() => ({ zipCode }));
        await get().verifyDirectCheckout();
      },

      async setRouter(router: NextRouter) {
        set(() => ({ router }));

        get().setInitialTerm();
      },
    }),
    { name: STORE_NAME }
  )
);

/**
 * Initializes the Configure Store while loading its dependencies (location store, vehicle context, modal ...)
 * */
export const useInitializeConfigureStore = (
  vehicle?: GenericVehicleDetails
) => {
  const { setUser, setRouter, setVehicle, setOpenModal, setDownPayment } =
    useConfigureStore();
  const { isDownPaymentEnabled } = useDownPayment();

  const { locale } = useCurrentLocale();

  const [session] = useSession();
  const contactId = session?.user?.hs_contact_id;

  const openModal = useOpenModal();

  const router = useRouter();

  useEffect(() => {
    setDownPayment(isDownPaymentEnabled);
  }, [isDownPaymentEnabled, setDownPayment]);

  useEffect(() => {
    setRouter(router);
  }, [router, setRouter]);

  useEffect(() => {
    setOpenModal(openModal);
  }, [openModal, setOpenModal]);

  useEffect(() => {
    if (vehicle) {
      setVehicle(vehicle, locale);
    }
  }, [locale, setVehicle, vehicle]);

  useEffect(() => {
    setUser(contactId);
  }, [contactId, setUser]);
};
