import type {
  AddressComponent,
  GeocodeResult,
  LatLng,
  Place as GenericPlace,
  PlaceAutocompleteRequest,
  PlaceAutocompleteResponseData,
  PlaceAutocompleteResult,
  PlaceDetailsRequest,
  PlacesNearbyRequest,
  ReverseGeocodeRequest,
  ReverseGeocodeResponseData,
} from '@googlemaps/google-maps-services-js';
import {
  AddressType,
  PlaceAutocompleteType,
} from '@googlemaps/google-maps-services-js';

type MapsParams<T extends { params: unknown }> = Omit<T['params'], 'language'>;

type MapsResponse<T> = {
  status: string;
} & T;

// Could not patch the type, so I had to copy it from the library
// and make some amends there. I added `AddressType` to `types`,
// so one can use `AddressType.postal_code` as a type. Google can interpret it
// correctly, but types don't support it.
//
// GitHub issue: https://github.com/googlemaps/google-maps-services-js/issues/974

export type ClientPlaceAutocompleteParams = {
  input: string;
  sessiontoken?: string;
  offset?: number;
  origin?: LatLng;
  location?: LatLng;
  radius?: number;
  types?: PlaceAutocompleteType | AddressType;
  components?: string[];
  strictbounds?: boolean;
};

export type PlaceAutocompleteParams = MapsParams<PlaceAutocompleteRequest>;

export type PlaceAutocompleteResponse =
  MapsResponse<PlaceAutocompleteResponseData>;

export type ReverseGeocodeParams = MapsParams<ReverseGeocodeRequest>;

export type ReverseGeocodeResponse = MapsResponse<ReverseGeocodeResponseData>;

export type PlaceDetailsParams = MapsParams<PlaceDetailsRequest>;

export type Place = GenericPlace & { address_components: AddressComponent[] };

export type PlaceDetailsResponse = MapsResponse<{
  result: Place;
}>;

export type PlacesNearbyParams = MapsParams<PlacesNearbyRequest>;

export type PlaceNearby = Omit<GenericPlace, 'address_components'>;

export type PlacesNearbyResponse = MapsResponse<{
  results: PlaceNearby[];
}>;

export { AddressType };

export type {
  AddressComponent,
  GeocodeResult,
  PlaceAutocompleteResult,
  LatLng,
};
