const FALLBACK_URL = 'https://staging.finn.com';

export const getTestUrl = () => {
  const url = process.env.ENVIRONMENT_URL || FALLBACK_URL;
  if (url[url.length - 1] === '/') {
    return url.slice(0, -1);
  }

  return url;
};

export const getDomainForCookie = (url?: string) =>
  url?.replace('https://', '').replace(':3001', '');

const commonTestCookies = [
  {
    name: 'E2E-test-in-progress',
    value: 'yes',
    path: '/',
    domain: getDomainForCookie(getTestUrl()),
  },
  {
    name: 'E2E-disable-direct-checkout',
    value: 'yes',
    path: '/',
    domain: getDomainForCookie(getTestUrl()),
  },
  {
    name: 'en-US_fullstory',
    value: 'disabled',
    path: '/',
    domain: getDomainForCookie(getTestUrl()),
  },
  {
    name: 'de-DE_fullstory',
    value: 'disabled',
    path: '/',
    domain: getDomainForCookie(getTestUrl()),
  },
  {
    name: 'e2e-secret',
    value: process.env.SECRET_E2E_COOKIE || '',
    path: '/',
    domain: getDomainForCookie(getTestUrl()),
  },
];

const getCookiesForFeatures = (
  locale: string,
  disabledFeatures: string[],
  enabledFeatures?: string[]
) => {
  const featureCookies: any = [];
  disabledFeatures.forEach((feature) => {
    featureCookies.push({
      name: `${locale}_${feature}`,
      value: 'a',
      path: '/',
      domain: getDomainForCookie(getTestUrl()),
    });
  });

  (enabledFeatures || []).forEach((feature) => {
    featureCookies.push({
      name: `${locale}_${feature}`,
      value: 'b',
      path: '/',
      domain: getDomainForCookie(getTestUrl()),
    });
  });

  return featureCookies;
};

const disabledFeaturesDE: string[] = [
  'exp-pcpdropdown2',
  'exp-plp-grid-v1',
  'exp-hp-heroswap',
  'exp-pdp-carinfo',
  'exp-pcp-multiplesteps',
  'exp-pcp-multiplesteps2',
  'exp-plp-sortandtags',
  'exp-pcp-downpaymenttooltip',
  'exp-pcp-login-v3',
  'exp-pdp-availabilitylabel-relaunch',
  'exp-hp-herofilters',
  'exp-plp-popular-filters-v3',
  'exp-plp-modelcards',
  'exp-relatedconfigs',
  'exp-hp-herofilters-v2',
];
const forceBVariantFeaturesDE: string[] = [];

export const getCookiesForFeaturesByLocale = (
  locale: string,
  disabledFeatures: string[],
  enabledFeatures?: string[]
) => {
  return getCookiesForFeatures(locale, disabledFeatures, enabledFeatures);
};

export const getTestCookies = (locale: string, features: string[] = []) => {
  return [
    ...commonTestCookies,
    ...getCookiesForFeaturesByLocale(
      locale,
      disabledFeaturesDE.concat(features),
      forceBVariantFeaturesDE
    ),
  ];
};
