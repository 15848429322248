import { Button } from '@finn/design-system/atoms/button';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn, type HandleClickFn, parseToHtml } from '@finn/ui-utils';
import React, { MouseEventHandler } from 'react';

type IntroBlock = {
  title: string;
  type: string;
  cta_button: {
    href: string;
    title: string;
  };
  description: string;
};

interface IProps {
  data: IntroBlock;
  handleClick: HandleClickFn;
}

const MenuIntro: React.FunctionComponent<IProps> = ({ data, handleClick }) => {
  const hasDescription = data.description !== '';

  return (
    <div className="mx-4 mb-10 mt-8 md:m-0">
      <div className="global-t5-semibold mb-5">{data.title}</div>
      {data.description && (
        <p className="body-16-regular mt-2">{data.description}</p>
      )}
      {data.cta_button && (
        <NextLinkSingleApp
          app={App.UA}
          href={data.cta_button.href}
          withoutAnchorTag
        >
          <Button
            size="lg"
            className={cn(hasDescription ? '!mt-6' : '', 'my-2 w-full')}
            onClick={
              ((event) =>
                handleClick({
                  link: data.cta_button.href,
                  type: NAVIGATION_TRACKING_EVENTS.CTA,
                  e: event,
                })) as MouseEventHandler<HTMLButtonElement>
            }
          >
            <span>{parseToHtml(data.cta_button.title)}</span>
          </Button>
        </NextLinkSingleApp>
      )}
    </div>
  );
};

export default MenuIntro;
