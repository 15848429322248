require('intersection-observer');

import { ReactNode, RefObject, useEffect } from 'react';
import handleViewport from 'react-in-viewport';

import { useTrackingStore } from '../stores/useTrackingStore';
import { TrackingEventName } from '../types';

type Props = {
  name: string;
  additionalProps?: Record<string, string | number | undefined | null>;
  children: ReactNode;
  // react-in-viewport injected props
  enterCount?: number;
  leaveCount?: number;
  forwardedRef?: RefObject<HTMLDivElement>;
};
export const TrackComponentView = handleViewport<any, any>(
  ({
    name,
    additionalProps,
    children,
    enterCount,
    leaveCount,
    forwardedRef,
  }: Props) => {
    const track = useTrackingStore((state) => state.track);
    const firstTimeInViewport = enterCount === 1 && leaveCount === 0;

    useEffect(() => {
      track(TrackingEventName.COMPONENT_LOADED, {
        name,
        additionalProps,
        once: true,
      });
      if (firstTimeInViewport) {
        track(TrackingEventName.COMPONENT_VIEWED, {
          name,
          additionalProps,
          once: true,
        });
      }
    }, [name, additionalProps, track, firstTimeInViewport]);

    return <div ref={forwardedRef}>{children}</div>;
  },
  { threshold: 0.3 },
  { disconnectOnLeave: true }
);
