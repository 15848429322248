export enum CountryCode {
  US = 'US',
  DE = 'DE',
}

// should be based on locales.json
// TODO find a way to generate enum from JSON
// TODO generate locales JSON from cosmic
export enum Locale {
  ENGLISH_USA = 'en-US',
  // TODO enable support for it
  ENGLISH_GERMANY = 'en-DE',
  GERMAN_GERMANY = 'de-DE',
  FAKE_DEFAULT = 'default',
}

export const DefaultLocale = Locale.GERMAN_GERMANY;
export const FakeDefaultLocale = Locale.FAKE_DEFAULT;

type Lang = {
  metadata: {
    label: string;
    locale: Locale;
  };
};

export type Country = {
  name: string;
  code: CountryCode;
  languages: Array<Lang>;
};

export type Region = { region?: CountryCode };

export const isLocaleSupported = (locale: string) => {
  return Object.values(Locale).some((value) => value === locale);
};

export const DATE_FORMAT_MAPPINGS_LONG: Record<string, string> = {
  [Locale.GERMAN_GERMANY]: 'dddd, DD.MM.YYYY',
  // TODO en_DE support
  // [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
  [Locale.ENGLISH_USA]: 'dddd, MM/DD/YYYY',
};

export const DATE_FORMAT_MAPPINGS: Record<string, string> = {
  [Locale.GERMAN_GERMANY]: 'DD.MM.YYYY',
  // TODO en_DE support
  // [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
  [Locale.ENGLISH_USA]: 'MM/DD/YYYY',
};

export const DATE_FORMAT_LABELS: Record<string, string> = {
  [Locale.GERMAN_GERMANY]: 'TT.MM.JJJJ',
  // TODO en_DE support
  // [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
  [Locale.ENGLISH_USA]: 'MM/DD/YYYY',
};

export const DATE_FORMAT_MASKS: Record<string, string> = {
  [Locale.GERMAN_GERMANY]: '__.__.____',
  // TODO en_DE support
  // [Locale.ENGLISH_GERMANY]: '__/__/____',
  [Locale.ENGLISH_USA]: '__/__/____',
};

export const DATE_FORMAT_MONTH_YEAR: Record<string, string> = {
  [Locale.GERMAN_GERMANY]: 'MMMM YYYY',
  // [Locale.ENGLISH_GERMANY]: 'MMMM, YYYY',
  [Locale.ENGLISH_USA]: 'MMMM, YYYY',
};
