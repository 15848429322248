export * from '@finn/ua-tracking';
import { TrackingEventName } from '@finn/ua-tracking';
import { GenericVehicleDetails } from '@finn/ua-vehicle';

import { CheckoutStepOrder } from './checkout';

// General interaction event
export type TrackingEventProperties = GACustomDimensions & {
  category: string;
  action: string;
  label: string;
  value?: string | number;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-clicked
export type EcommerceProduct = GACustomDimensions & {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand: string;
  make?: string;
  model?: string;
  variant: string;
  price: number;
  quantity: number;
  coupon?: string;
  position: number;
  related_configs: number;
  url?: string;
  image_url?: string;
  preDiscountPrice?: string;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-viewed
export type EcommerceCheckoutStep = GACustomDimensions & {
  eventName: TrackingEventName;
  checkout_id: number | string; // Deal ID
  step?: CheckoutStepOrder;
  subStep?: string; // step is numeric, 1 denotes contact, 2 denotes payment so introducing a subStep to distinguish between contact and address
  shipping_method?: string;
  payment_method?: string;
};

export type SelectedProductInfo = GACustomDimensions & {
  term?: number;
  kmPackage?: number;
  position?: number;
  preDiscountPrice?: string;
};

// Param type of triggerEcommerceOrderingTrackingEvent
export type EcommerceOrderingEvent = GACustomDimensions & {
  eventName: TrackingEventName;
  vehicle: GenericVehicleDetails;
  productInfo?: SelectedProductInfo;
  coupon?: string;
  downPaymentAmount?: number;
  monthlyPriceWithoutDownpayment?: number;
};

export type ReferralModalEvent = {
  eventName: TrackingEventName;
};

export type ReferralOptionEvent = {
  eventName: TrackingEventName;
  channel: string;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
export type EcommerceCheckout = GACustomDimensions & {
  order_id: string | number; // dealId
  affiliation?: string; // Store or affiliation from which this transaction occurred
  value: number; // Revenue with discounts and coupons added in
  shipping: number; // Delivery cost
  tax: number; // tax for the whole term  DE: Price / (1 + TAX_RATE), US: Tax from tax api * term
  monthlyTax?: number;
  coupon?: string; // Voucher code
  discount?: number; // For example: spacial offers
  currency?: number; // EURO | USD
  productInfo?: SelectedProductInfo;
  vehicles: Array<GenericVehicleDetails | undefined>;
  insurance?: string;
  isProlongation?: boolean;
};

export type GACustomDimensions = {
  kmPackage?: number;
  productId?: string;
  monthlyPrice?: number;
  dealId?: string | number;
  customerType?: string;
  term?: number;
  coupon_name?: string;
  coupon_id?: string;
  discount?: string | number;
  discount_type?: string;
  email?: string | null;
  directCheckout?: string | boolean;
  skippedPaymentMethod?: string | boolean;
  downPaymentAmount?: number;
  source_flow?: string;
  creditCheckStatus?: string;
  hasGclid?: string | boolean;
  paymentElementUsed?: boolean;
  available?: boolean;
  insurance?: string;
};
