const { v4: uuid } = require('uuid');

const normalizeCosmicJSON = (json, fieldsToDelete) => {
  // we use a stack to keep track of the nodes we need to visit
  const stack = [{ node: json, parent: null, nodeKeyInParent: null }];

  const res = {
    modules: {},
    pages: {},
  };

  // We visit each node in JSON with DFS
  // We delete the fieldsToDelete from the nodes
  // We replace modules used on pagea and in modules with their ids
  while (stack.length > 0) {
    const { node, parent, nodeKeyInParent } = stack.pop() || {
      node: null,
      parent: null,
      nodeKeyInParent: null,
    };

    if (typeof node === 'object' && node !== null) {
      if (!Array.isArray(node)) {
        for (const key in node) {
          // we delete fieldsToDelete from the node
          // but only for cosmic objects, not for custom json objects
          if (fieldsToDelete.includes(key) && node.id) {
            delete node[key];
          }
          // we delete cosmic level title from root node of module only
          // which we determing by checking for other system props
          if (node?.slug && node?.id && node?.locale) {
            delete node.title;
          }
        }
      }

      if ('id' in node && 'slug' in node) {
        if (node.type === 'self-service-pages' && typeof node.id === 'string') {
          res.pages[node.id] = node;
        } else if (typeof node.id === 'string') {
          res.modules[node.id] = {
            ...node,
            nodeKeyInParent: nodeKeyInParent ?? null,
          };
          if (
            parent &&
            typeof nodeKeyInParent !== 'undefined' &&
            typeof nodeKeyInParent !== 'object'
          ) {
            parent[nodeKeyInParent] = node.id;
          }
        }
      }

      if (Array.isArray(node)) {
        for (const element of node) {
          stack.push({
            node: element,
            parent: node,
            nodeKeyInParent: node.indexOf(element),
          });
        }
      } else {
        for (const key in node) {
          stack.push({
            node: node[key],
            parent: node,
            nodeKeyInParent: key,
          });
        }
      }
    }
  }

  return res;
};

const setModuleType = (module) => {
  if (!module?.type) {
    return;
  }

  module.type =
    module.type === 'self-service-modules' ||
    module.type === 'self-service-components'
      ? module?.slug
      : module.type;
};

// we revert normalization back, replacing ids with modules
const denormalizeCosmicJSON = (data) => {
  const stack = Object.values(data);
  while (stack.length > 0) {
    const node = stack.pop();

    if (typeof node === 'object' && node !== null) {
      if (Array.isArray(node)) {
        for (let i = 0; i < node.length; i++) {
          const module =
            typeof node[i] === 'string' ? data?.modules[node[i]] : null;
          if (module) {
            setModuleType(module);
            node[i] = module;
            stack.push(node[i]);
          } else if (node && typeof node[i] !== 'string') {
            stack.push(node[i]);
          }

          if (!node[i].type && typeof node[i] === 'object' && node[i]) {
            node[i].id = uuid();

            if (node[i].fields) {
              node[i].type = 'fields';
              node[i].slug = 'fields';
              node[i].metadata = {
                fields: node[i].fields,
              };
            }
            if (node[i].text) {
              node[i].type = 'textblock';
              node[i].slug = 'textblock';
              node[i].metadata = {
                text: node[i].text,
              };
            }
          }
        }
      } else {
        for (const key in node) {
          const module =
            typeof node[key] === 'string' ? data?.modules[node[key]] : null;
          if (key !== 'id' && module) {
            setModuleType(module);
            node[key] = module;
            if (key === 'data') {
              Object.assign(node, module, node.config);
              Object.assign(node, {
                // this is hack, old hack, so we use any
                metadata: {
                  ...node?.metadata,
                  ...node.config,
                },
              });
              // very dirty hack, we need to remove this
              // done for backcompability, as on nested level sometimes we read
              // from metadata, sometimes from item direct
              Object.assign(node, node.metadata);
            }
            stack.push(node[key]);
          } else if (node && typeof node[key] !== 'string') {
            stack.push(node[key]);
          }
        }
      }
    }
  }

  return data;
};

module.exports = { normalizeCosmicJSON, denormalizeCosmicJSON };
