import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { Logo } from '@finn/design-system/icons/logo';
import { cn } from '@finn/ui-utils';
import get from 'lodash/get';
import { useState } from 'react';

import {
  IComparisonTable,
  IComparisonTableItemBaseValue,
  IComparisonTableMetadata,
  IComparisonTableMetadataDimension,
} from '~/types/comparisonTable';

import Action from '../Action';
import TableCellData from '../ComparisonTable/Body/TableCellData';
import Price from '../Price';

const ComparisonAccordion = ({ data }: { data: IComparisonTable }) => {
  const { metadata = {} as IComparisonTableMetadata, items = [] } = data;

  const [openedItems, setOpenedItems] = useState(['featured']);

  return (
    <Accordion
      type="multiple"
      value={openedItems}
      onValueChange={(val) => {
        setOpenedItems(val);
      }}
    >
      {items.map((item, idx) => (
        <AccordionItem
          key={idx}
          value={item.featured ? 'featured' : `regular-${idx}`}
        >
          <AccordionTrigger
            className={cn('px-2', { 'bg-black fill-white': item.featured })}
          >
            <div className="flex min-h-6 items-center gap-2">
              {item.featured ? (
                <>
                  {item.header.price ? (
                    <Price
                      price={item.header.price}
                      className="mt-2 flex items-baseline"
                    />
                  ) : (
                    <Logo className="fill-white" />
                  )}
                </>
              ) : (
                <span className="global-t5-semibold">{item.header.title}</span>
              )}
            </div>
          </AccordionTrigger>
          <AccordionContent
            className={cn('px-2', { 'bg-black text-white': item.featured })}
          >
            <ul className="p-0">
              {metadata.dimensions.map(
                (
                  dimension: IComparisonTableMetadataDimension,
                  dIdx: number
                ) => (
                  <li key={dIdx} className="flex items-center px-0 py-3">
                    <TableCellData
                      featured={item.featured}
                      data={
                        get(
                          item,
                          `values.${get(dimension, 'id', '')}`,
                          {}
                        ) as IComparisonTableItemBaseValue
                      }
                    />
                    <span
                      className={cn('global-t6-semibold ml-5', {
                        'text-black': !item.featured,
                        'text-white': item.featured,
                      })}
                    >
                      {dimension.name}
                    </span>
                  </li>
                )
              )}
            </ul>
            {item.footer?.price && (
              <Price
                price={item.footer.price}
                className="mt-2 flex items-baseline"
              />
            )}
            {item.footer?.action && (
              <div className="mt-4">
                <Action action={item.footer.action} fullWidth />
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ComparisonAccordion;
