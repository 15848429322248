import { useDownPayment } from '@finn/ua-featureflags';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { getLongestTerm } from '@finn/ui-modules';
import {
  App,
  getFormattedPrice,
  useCurrentApp,
  useCurrentLocale,
} from '@finn/ui-utils';
import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { usePreDiscount } from '../ProductDiscount';
import { useForBusinessCookie } from './forBusiness';

interface IProps {
  vehicle: GenericVehicleDetails;
  forceB2BPrice?: boolean;
  selectedTerm?: number;
  partnerDiscount?: number;
}

const ProductPrice: FunctionComponent<IProps> = ({
  vehicle,
  forceB2BPrice,
  selectedTerm,
  partnerDiscount = 0,
}) => {
  const { isDownPaymentEnabled } = useDownPayment();

  const term = selectedTerm || getLongestTerm(vehicle);

  const { isUSA } = useCurrentLocale();
  const i18n = useIntl();

  const forBusinessCookie = useForBusinessCookie();
  const isForBusiness = (!isUSA && forceB2BPrice) || forBusinessCookie;

  const priceKey = isDownPaymentEnabled
    ? `downpayment_prices.available_price_list`
    : `price.available_price_list`;

  let price = isForBusiness
    ? get(vehicle, `${priceKey}.b2b_${term}`, 0)
    : get(vehicle, `${priceKey}.b2c_${term}`, 0);

  if (useCurrentApp() === App.B2B) {
    price = get(vehicle, `${priceKey}.b2c_${term}`, 0);
  }

  if (isUSA && forBusinessCookie) {
    // for USA we have only one price for customers so we show it and remove the insurance amount
    price = get(vehicle, `${priceKey}.b2c_${term}`, 0);
  }

  let preDiscountPrice = usePreDiscount({
    vehicle,
    price,
    term,
    isForBusiness,
    isDownPayment: isDownPaymentEnabled,
  });

  let priceTotal = price;

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = getFormattedPrice(i18n, priceTotal, 0);
    }
    priceTotal = priceTotal - priceTotal * partnerDiscount;
  }

  const priceFormatted = getFormattedPrice(i18n, priceTotal, 0);

  const perMonthLabel = i18n.formatMessage({
    id: !isForBusiness ? 'general.perMonth' : 'general.perMonthWithoutVat',
  });

  // To prevent showing 0 euro prices for cars that dont have the selected term.
  if (!price) {
    return null;
  }

  return (
    <div className="body-14-semibold sm:body-16-semibold">
      <div className="row-y-2 mb-1 flex flex-wrap items-center pb-2 md:mb-1.5">
        {!selectedTerm && (
          <div>{i18n.formatMessage({ id: 'plp.priceFrom' })}&nbsp;</div>
        )}
        {preDiscountPrice && (
          <div className="text-iron mr-1 line-through">{preDiscountPrice}</div>
        )}
        {priceFormatted}&nbsp;
        <div className="body-12-light sm:body-14-light">{perMonthLabel}</div>
      </div>
    </div>
  );
};

export default ProductPrice;
