import { captureMessage } from '@finn/ui-utils';
import { isServer } from '@finn/ui-utils/lib/server';

import { getGlobalObject } from '../sdk/helpers/globalObject';
import { NativeSDK } from '../types/general';

const DELAY_BEOFORE_CHECKING_SDK = 1000;
const MAX_ATTEMPTS = 30;

export const getAppSDK = (
  cb?: (sdk: NativeSDK | undefined) => void
): NativeSDK | undefined => {
  if (isServer()) {
    return undefined;
  }

  if (getGlobalObject().nativeAppSDK) {
    return getGlobalObject().nativeAppSDK;
  }

  if (cb) {
    let callsCounter = 0;

    const timer = setInterval(() => {
      callsCounter++;
      if (callsCounter > MAX_ATTEMPTS) {
        clearInterval(timer);
      }

      if (getGlobalObject().nativeAppSDK) {
        clearInterval(timer);
        cb(getGlobalObject().nativeAppSDK);
      } else {
        captureMessage(new Error('Native SDK not found in callback'));
      }
    }, DELAY_BEOFORE_CHECKING_SDK);
  }

  return undefined;
};
