import {
  GenericVehicleDetails,
  Review,
  VehicleContext,
} from '@finn/ua-vehicle';
import { FearAwayData } from '@finn/ui-cosmic';
import React, { ReactNode } from 'react';

import { ApiResponse } from '../types/api';

export * from '@finn/ua-vehicle';

type Props = {
  vehicle: GenericVehicleDetails;
  fearAwayItems: FearAwayData[];
  reviews?: ApiResponse<Review>;
  children: ReactNode;
};

export const VehicleProvider = ({
  children,
  vehicle,
  fearAwayItems,
  reviews,
}: Props) => {
  const contextValues = {
    vehicle,
    fearAwayItems,
    reviews,
    // B2B using these values, we're using the ConfigureStore
    monthlyPrice: {
      distance: 0,
      price: 0,
      duration: 0,
    },
    monthlyPriceWithFees: 0,
    oneTimePrice: {
      duration: 0,
      price: 0,
    },
    setOneTimePrice: () => {},
    setMonthlyPrice: () => {},
  };

  return (
    <VehicleContext.Provider value={contextValues}>
      {children}
    </VehicleContext.Provider>
  );
};
export default VehicleContext;
