import {
  cloneElement,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import { twMerge } from 'tailwind-merge';

// Motivation https://www.jacobparis.com/content/react-as-child
export type AsChildProps<DefaultElementProps> =
  | ({ asChild?: false } & DefaultElementProps)
  | { asChild: true; children: ReactNode };

export const Slot = ({
  children,
  ...props
}: HTMLAttributes<HTMLElement> & {
  children?: ReactNode;
}) => {
  if (isValidElement(children)) {
    const childrenTyped = children as ReactElement<{
      className?: string;
      style?: Record<string, string>;
    }>;

    return cloneElement(children, {
      ...props,
      ...childrenTyped.props,
      style: {
        ...props.style,
        ...childrenTyped.props.style,
      },
      className: twMerge(props.className, childrenTyped?.props?.className),
      // TODO fix any
    } as any);
  }

  return null;
};
