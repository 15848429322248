import { cn } from '@finn/ui-utils';
import { forwardRef, ReactNode } from 'react';

import { Button, ButtonProps } from '../button';

export type AvatarProps = {
  children?: ReactNode;
  size?: ButtonProps['size'];
  state?: 'on' | 'off';
  variant?: 'warning';
  className?: string;
  id?: string;
  onClick?: () => void;
};

export const Avatar = forwardRef<HTMLButtonElement, AvatarProps>(
  (
    {
      children,
      variant,
      state = 'off',
      size = 'sm',
      className,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        {...props}
        className={cn(
          // we want to fix the size of first child, usually it should be image
          '[&>.content]:h-full [&>.content]:w-full [&_>.content>*]:h-full [&_>.content>*]:w-full [&_>.content>*]:rounded-full',
          state === 'on'
            ? 'bg-lightBlue border-lightBlue text-trustedBlue hover:bg-lightBlue'
            : 'bg-pearl border-pearl hover:bg-pearl',
          {
            'cursor-default': !onClick,
            'bg-lightRed border-lightRed hover:bg-lightRed fill-red hover:fill-red':
              variant === 'warning',
          },
          className
        )}
        variant="circle"
        size={size}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
);
