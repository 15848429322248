import {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStore } from 'zustand';

import { Portal } from '../portal';
import { Toast } from './toast';
import { toastManagerStore } from './toast-store';

const ANIM_TIMEOUT = {
  appear: 500,
  enter: 500,
  exit: 300,
};

const DISMISS_TIMEOUT = 6000;

/**
 * ToastManager controls showing and dismissing toasts, both on timeout & on click.
 * It pauses auto dismiss if hovered, and resumes when the cursor is moved out.
 * Should be in a single instance per app & should not be re-mounted on route transitions.
 */
export const ToastManager = memo(() => {
  const { toastIds, toastsById, dismissToast } = useStore(toastManagerStore);
  // Enables dismissing toast on timeout one by one starting from the least recently added one
  const [shouldAutoDismiss, setShouldAutoDismiss] = useState(false);
  // An index of a toast that is currently getting dismissed
  const [dismissedToastIndex, setDismissedToastIndex] = useState<number | null>(
    null
  );

  const clearDismissedToastIndex = useCallback(
    () => setDismissedToastIndex(null),
    []
  );

  // A timeout after which a toast will be dismissed
  const dismissTimeoutId = useRef<number | null>(null);

  const clearDismissTimeout = useCallback(() => {
    if (dismissTimeoutId.current) {
      window.clearTimeout(dismissTimeoutId.current);
      dismissTimeoutId.current = null;
    }
  }, []);

  const handleClose = useCallback(
    (toastId: string) => {
      // Prevents dismissing other toasts while dismissing the current one
      clearDismissTimeout();
      setShouldAutoDismiss(false);

      // Removes a toast from the store
      dismissToast(toastId);
      // Sets dismissedToastIndex so we know what toast should we start uplift animation from
      setDismissedToastIndex(toastIds.indexOf(toastId));
    },
    [clearDismissTimeout, dismissToast, toastIds]
  );

  // Closes a toast if "x" is clicked,
  // but prevents closing if another toast is being closed
  const handleCloseByClick = useCallback(
    (toastId: string) => {
      if (dismissedToastIndex !== null) {
        return;
      }

      handleClose(toastId);
    },
    [handleClose, dismissedToastIndex]
  );

  // If toast list got updated, triggers setting a timer for removing the least recent one
  useEffect(() => {
    if (toastIds.length === 0) {
      return;
    }

    setShouldAutoDismiss(true);
  }, [toastIds]);

  // If removing the least recent toast is triggered, sets a timeout for removing one.
  // Clears any previously set timer
  useEffect(() => {
    if (!shouldAutoDismiss) {
      return;
    }

    const toastId = toastIds[0];
    const toast = toastsById[toastId];
    const delta = DISMISS_TIMEOUT - (Date.now() - toast?.createdAt);

    clearDismissTimeout();
    dismissTimeoutId.current = window.setTimeout(
      () => {
        handleClose(toastId);
      },
      // Giving it some extra 100ms time helps to prevent animation clashes
      // istanbul ignore next
      delta > ANIM_TIMEOUT.exit + 100 ? delta : ANIM_TIMEOUT.exit + 100
    );

    setShouldAutoDismiss(false);
    // Here we need it to re-run if and only if `shouldDismissByTimeout` is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoDismiss]);

  // Pauses auto dismiss when mouse hovered over the toast manager
  const handlePointerEnter = useCallback(() => {
    clearDismissTimeout();
    setShouldAutoDismiss(false);
  }, [clearDismissTimeout]);

  // Resumes auto dismiss when mouse is not hovered over the toast manager anymore
  const handlePointerLeave = useCallback(() => setShouldAutoDismiss(true), []);
  const toastsWithRefs = useMemo(
    () =>
      toastIds.map((toastId) => ({
        toastId,
        nodeRef: createRef<HTMLLIElement>(),
      })),
    [toastIds]
  );

  return (
    <Portal>
      <TransitionGroup
        component="ol"
        className="fixed left-2 right-2 top-2 z-[10001] flex flex-col gap-3 sm:left-auto sm:w-[360px]"
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        {toastsWithRefs.map(({ toastId, nodeRef }, index) => (
          <CSSTransition
            key={toastId}
            classNames={{
              enter: 'opacity-0 scale-0',
              enterActive:
                'opacity-100 scale-100 transition-transform duration-300 easy-in',
              exit: 'opacity-100 scale-100 easy-in-out transition-transform duration-500',
              exitActive: '!opacity-0 !scale-0',
            }}
            nodeRef={nodeRef}
            timeout={ANIM_TIMEOUT}
            onExited={clearDismissedToastIndex}
          >
            <Toast
              className={
                dismissedToastIndex !== null && index >= dismissedToastIndex
                  ? 'translate-y-[calc(-100%-8px)] transition-transform duration-300 ease-in-out'
                  : ''
              }
              {...toastsById[toastId]}
              onClose={handleCloseByClick}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Portal>
  );
});
