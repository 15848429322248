import { UrlFilter } from '~/types/checkout';
import { InitialFilterKeysMap } from '~/types/filter';

import { firstLvlPathParamsKeys } from './constants';

// This read the url filter group and return the result
// example "alfa-romeo.fiat.mercedes-benz" => {brands:['alfa-romeo','fiat','mercedes-benz']}
const readFilterGroup = (
  filtersGroup: string,
  filterKey: string,
  initialFilterKeysMap: InitialFilterKeysMap
) => {
  const filtersGroupValues = filtersGroup.split('.');
  const isValidGroup = filtersGroupValues.every(
    (filterValue) => initialFilterKeysMap[filterValue]?.type === filterKey
  );
  if (isValidGroup) {
    return filtersGroupValues.map(
      (filterValue) => initialFilterKeysMap[filterValue].name
    );
  }
};

// This read lvl1 structure
// /brands_models | /brands | /models
// First it will check if it's multiple features if yes it should be /brands_models
// if one feature it will check if it's one of (/brands | /models)
export const readFirstPathLevel = (
  firstLevelGroups: string,
  initialFilterKeysMap: InitialFilterKeysMap
) => {
  const levelGroups = firstLevelGroups.split('_');
  const levelFilter: UrlFilter = {};
  if (levelGroups.length === 1) {
    const brands = readFilterGroup(
      levelGroups[0],
      firstLvlPathParamsKeys.BRANDS,
      initialFilterKeysMap
    );

    if (brands) {
      levelFilter.brands = brands;
    } else {
      const models = readFilterGroup(
        levelGroups[0],
        firstLvlPathParamsKeys.MODELS,
        initialFilterKeysMap
      );

      if (models) {
        levelFilter.models = models;
      }
    }
  } else {
    const brands = readFilterGroup(
      levelGroups[0],
      firstLvlPathParamsKeys.BRANDS,
      initialFilterKeysMap
    );

    if (brands) {
      const models = readFilterGroup(
        levelGroups[1],
        firstLvlPathParamsKeys.MODELS,
        initialFilterKeysMap
      );

      if (models) {
        levelFilter.brands = brands;
        levelFilter.models = models;
      }
    }
  }

  return levelFilter;
};

// This will check lvl2 structure
// lvl2 structure => /feature1a.feature1b.feature1c_feature2a , etc..
// First will split the lvl by "_" then it will have array of groups features
// Then it will go through all groups and use readFilterGroup to get group data
// Also it will check the order of the groups because it should be in specific order
export const readSecondPathLevel = (
  firstLevelGroups: string,
  initialFilterKeysMap: InitialFilterKeysMap
) => {
  const secondLevelGroupsOrder = {
    cartypes: 1,
    gearshifts: 2,
    fuels: 3,
    colors: 4,
    terms: 5,
    'coming-soon': 6, // TODO: Remove after launching retention
    retention: 7,
  };
  const levelGroups = firstLevelGroups.split('_');
  const levelFilter: UrlFilter = {};
  let currentOrder = 0;
  for (let i = 0; i < levelGroups.length; i++) {
    const filtersGroup = levelGroups[i];
    const filters = filtersGroup.split('.');
    const filterType = initialFilterKeysMap[filters[0]]?.type;
    const groupOrder =
      secondLevelGroupsOrder[filterType as keyof typeof secondLevelGroupsOrder];
    if (groupOrder > currentOrder) {
      const group = readFilterGroup(
        filtersGroup,
        filterType,
        initialFilterKeysMap
      );

      if (group) {
        levelFilter[filterType] = group;
        currentOrder = groupOrder;
      } else {
        break;
      }
    } else {
      break;
    }
  }

  return levelFilter;
};
