export enum CheckoutStep {
  CART = 'cart',
  CONTACT = 'contact',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
  PROLONGATION = 'prolongation',
  CREDITSCORE = 'creditscore',
  THANK_YOU = 'thank_you',
}

const checkoutSteps = [
  CheckoutStep.CART,
  CheckoutStep.CONTACT,
  CheckoutStep.PAYMENT,
  CheckoutStep.CONFIRMATION,
  CheckoutStep.PROLONGATION,
  CheckoutStep.CREDITSCORE,
  CheckoutStep.THANK_YOU,
];
export const getSlugsWithoutStep = (
  slugs: string | string[] | undefined
): string[] => {
  if (!slugs) {
    return [];
  }
  if (typeof slugs === 'string') {
    slugs = slugs.split('/');
  }
  if (checkoutSteps.includes(slugs[0] as CheckoutStep)) {
    return slugs.slice(1);
  }

  return slugs;
};
