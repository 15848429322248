import { cn } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

// TODO share this between similar components
const titleHeading = {
  h1: '!mobile-t1-semibold md:!web-t1-semibold',
  h2: '!mobile-t2-semibold md:!web-t2-semibold',
  h3: '!mobile-t3-semibold md:!web-t3-semibold',
  h4: '!mobile-t4-semibold md:!web-t4-semibold',
  h5: '!global-t5-semibold',
  h6: '!global-t6-semibold',
};

export type TypographyWithAutoLinkProps = {
  className?: React.HTMLAttributes<HTMLSpanElement>['className'];
  linkText: string;
  variant: keyof typeof titleHeading;
  children?: ReactNode;
};

export const TypographyWithAutoLink = ({
  linkText,
  variant,
  children,
  className,
  ...rest
}: TypographyWithAutoLinkProps) => {
  const titleClassName = titleHeading[variant] || titleHeading.h3;
  const TitleEl = variant || 'h3';

  return (
    <>
      {variant === 'h2' && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          data-testid="link"
          aria-hidden
          // @ts-expect-error: we relay on the `name` attr for autoLinks
          name={linkText}
          className="blog-header"
        />
      )}
      <TitleEl className={cn(titleClassName, className)} {...rest}>
        {children}
      </TitleEl>
    </>
  );
};
