// Global CSS Must Be in the Custom APP
import 'nprogress/nprogress.css';
import 'resources/override.css';
import 'resources/fonts.css';
import 'keen-slider/keen-slider.min.css';
// TODO: Remove the next two (react slick) imports when this issue is solved: https://github.com/vercel/next.js/issues/37749
// import them again in: apps/finn-auto-ui/components/ProductDetails/ImageGallery/ImageGallery.tsx
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@finn/design-system/setup';

import { ToastManager } from '@finn/design-system/atoms/toast';
import { UserAccountProvider } from '@finn/ua-auth';
import { NavigationMenuProvider, SessionContext } from '@finn/ui-modules';
import { config, isServer, useSession } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SessionProvider as NextAuthContext } from 'next-auth/react';
import React, { useEffect } from 'react';

import { BuildApp } from '~/components/App';

import filterBrandsModelsData from '../localization/available.filters.json';
import allFeatures from '../localization/features2.json';

type ScrollPositionValues = { x: number; y: number };

if (!isServer()) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push([
    'consent',
    'default',
    {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 2000, // milliseconds to wait for update
    },
  ]);
}

export default BuildApp(
  () => {
    const router = useRouter();

    useEffect(() => {
      // Restore scroll position when the user go back to the previous page
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
        const cachedScrollPositions: Array<number[]> = [];
        let shouldScrollRestore: boolean | ScrollPositionValues = false;

        router.events.on('routeChangeStart', () => {
          if (!shouldScrollRestore) {
            cachedScrollPositions.push([
              window.pageXOffset,
              window.pageYOffset,
            ]);
          }
        });

        router.events.on('routeChangeComplete', () => {
          if (shouldScrollRestore) {
            const { x, y } = shouldScrollRestore as ScrollPositionValues;
            window.scrollTo(x, y);
            shouldScrollRestore = false;
          }
        });

        router.beforePopState(() => {
          if (cachedScrollPositions.length > 0) {
            const [x, y] = cachedScrollPositions.pop();
            shouldScrollRestore = { x, y };
          }

          return true;
        });

        if (window.UC_Integrations && window.dataLayer) {
          const ucAnalyticsService = 'Google Analytics';
          const ucAdService = 'Adwords Remarketing Lists';
          const ucAdWords = 'AdWords';
          window.dataLayer.push([
            'consent',
            'update',
            {
              ad_storage:
                window.UC_Integrations[ucAdService] ||
                window.UC_Integrations[ucAdWords]
                  ? 'granted'
                  : 'denied',
              analytics_storage: window.UC_Integrations[ucAnalyticsService]
                ? 'granted'
                : 'denied',
            },
          ]);
        }
        window.dispatchEvent(new Event('afterSegmentLoad'));
      }
    }, []);
  },
  ({ pageProps, children }) => {
    const initDD = () => {
      if (!config.DATADOG_CLIENT_TOKEN) return;

      const botPattern =
        '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon)';

      const regex = new RegExp(botPattern, 'i');
      const isBot = regex.test(window.navigator.userAgent);

      const sessionSampleRate = isBot ? 0 : 10;
      const sessionReplaySampleRate = isBot ? 0 : 5;
      (window as any).DD_RUM.init({
        clientToken: config.DATADOG_CLIENT_TOKEN,
        applicationId: config.DATADOG_APPLICATION_ID,
        site: 'datadoghq.eu',
        service: 'finn.com',
        env: config.DEPLOY_URL,
        sessionSampleRate,
        sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    };

    return (
      <>
        <Script
          src="https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js"
          async
          onLoad={initDD}
        />
        <Script
          src="https://accounts.google.com/gsi/client"
          strategy="afterInteractive"
        />
        <NextAuthContext
          session={pageProps.session}
          // since we open a new tab for each product card click, this causes a lot of unnecessary session fetches
          // setting refetchOnWindowFocus to false will prevent this
          refetchOnWindowFocus={false}
        >
          <SessionContext.Provider value={useSession}>
            <UserAccountProvider>
              <NavigationMenuProvider brandModels={filterBrandsModelsData}>
                {children}
                <ToastManager />
              </NavigationMenuProvider>
            </UserAccountProvider>
          </SessionContext.Provider>
        </NextAuthContext>
      </>
    );
  },
  allFeatures
);
