import { CookieKeys } from '@finn/ui-utils';
import { getCookies, setCookies } from 'cookies-next';
import { IncomingHttpHeaders } from 'http';

import { NextServerSideContext } from '~/types/nextjs';

const vercelCookieKey = '_vercel_jwt';
export const getVercelCookie = (ctx: NextServerSideContext) => {
  const vercelCookie = getCookies(ctx, vercelCookieKey);

  return vercelCookie ? `${vercelCookieKey}=${vercelCookie};` : undefined;
};

export const isBooleanCookieSet = (
  ctx: NextServerSideContext,
  key: CookieKeys
) => {
  const cookieValue = getCookies(ctx, key);

  return cookieValue === 'yes';
};

const AUTH_SESSION_COOKIE_KEY = 'next-auth.session-token';
const AUTH_SECURE_SESSION_COOKIE_KEY = '__Secure-next-auth.session-token';

export const getAuthCookie = (ctx: NextServerSideContext) => {
  const secureCookie = getCookies(ctx, AUTH_SECURE_SESSION_COOKIE_KEY);
  const normalCookie = getCookies(ctx, AUTH_SESSION_COOKIE_KEY);
  const cookie = secureCookie ?? normalCookie ?? '';
  const isSecure = !!secureCookie;
  const cookieKey = isSecure
    ? AUTH_SECURE_SESSION_COOKIE_KEY
    : AUTH_SESSION_COOKIE_KEY;

  return cookie ? `${cookieKey}=${cookie}` : undefined;
};

export { setLocaleCookie } from '@finn/ui-utils';

export const setBlacklistCookie = (ctx: NextServerSideContext) => {
  setCookies(ctx, CookieKeys.BLACKLISTED, 'true', {
    expires: 365 * 50, // 50 years
  });
};

export const removeNextAuthCookies = (
  headers: IncomingHttpHeaders
): IncomingHttpHeaders => {
  const requestHeaders = { ...headers };
  if (requestHeaders.cookie) {
    const cookies = requestHeaders.cookie
      .split(';')
      .map(function (c) {
        return c.trim().split('=').map(decodeURIComponent);
      })
      .reduce(function (a, b) {
        try {
          a[b[0]] = JSON.parse(b[1]);
        } catch (e) {
          a[b[0]] = b[1];
        }

        return a;
      }, {});
    let cookie = '';
    for (const [key, values] of Object.entries(cookies)) {
      if (!key.includes('next-auth')) {
        cookie += `${key}=${values}; `;
      }
    }
    requestHeaders.cookie = cookie.trim();
  }

  return requestHeaders;
};
