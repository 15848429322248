import {
  clearClientCookie,
  CookieKeys,
  DEFAULT_COOKIE_EXPIRATION_DAYS,
  NextServerSideContext,
} from '@finn/ui-utils';
import { removeCookies, setCookies } from 'cookies-next';
import Cookies from 'js-cookie';

const DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS = 60 * 10;

export {
  getClientCookie,
  setClientCookie,
  isCookieExist,
  setClientJSONCookie,
  getClientJSONCookie,
  clearClientCookie,
} from '@finn/ui-utils';

export const setNextServerCookie = (
  ctx: NextServerSideContext,
  key: CookieKeys,
  value: string,
  expires: number = DEFAULT_COOKIE_EXPIRATION_DAYS
) => {
  setCookies(ctx, key, value, { expires });
};

// todo: replace usage with the copy on ui-utils and then delete this
export const setClientBooleanCookie = (
  key: CookieKeys,
  value: boolean,
  expires: number | Date = DEFAULT_COOKIE_EXPIRATION_DAYS
) => Cookies.set(key, value?.toString() || 'false', { expires });

// todo: replace usage with the copy on ui-utils and then delete this
export const getClientBooleanCookie = (key: CookieKeys): boolean =>
  Cookies.get(key) === 'true';

export const clearServerCookie = (
  ctx: NextServerSideContext,
  key: CookieKeys
) => {
  removeCookies(ctx, key);
};

export const setNextServerBooleanCookie = (
  ctx: NextServerSideContext,
  key: CookieKeys,
  value: boolean,
  expires: number = DEFAULT_COOKIE_EXPIRATION_DAYS
) => {
  setCookies(ctx, key, value.toString(), { expires });
};

export const setIsFiltersDialogOpenCookie = (
  seconds: number = DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS
) => {
  const expiration = new Date(new Date().getTime() + seconds * 1000);
  setClientBooleanCookie(CookieKeys.IS_MOBILE_FILTER_OPEN, true, expiration);
};

export const refreshIsMobileFilterOpenCookie = (
  seconds: number = DEFAULT_MOBILE_FILTERS_EXPIRATION_IN_SECONDS
) => {
  clearClientCookie(CookieKeys.IS_MOBILE_FILTER_OPEN);
  setIsFiltersDialogOpenCookie(seconds);
};
