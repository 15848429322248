import { getGlobalObject } from '../helpers/globalObject';

const getViewportMetaTag = () => {
  const viewportTagEl = document.createElement('meta');
  viewportTagEl.name = 'viewport';
  viewportTagEl.content = 'initial-scale=1.0, maximum-scale=1.0';

  return viewportTagEl;
};

export const injectStyles = ({
  isNativeNavigationFirst,
  bottom,
}: {
  isNativeNavigationFirst?: boolean;
  bottom: string;
}) => {
  const globalObject = getGlobalObject();
  // we inject some variables for css
  const rootElement = document.querySelector<HTMLElement>(':root');
  rootElement?.style.setProperty('--bottom-inset', `${bottom}px`);
  // we need to "fix" the window height because it changes if keyboard
  // is toggled which causes some content to jump
  rootElement?.style.setProperty(
    '--webview-height',
    `${globalObject.innerHeight}px`
  );

  if (!isNativeNavigationFirst) {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      [data-appid="sticky-bar"], [data-appid="direct-checkout-button-group"] {
        box-sizing: content-box;
        padding-bottom: ${bottom}px;
      }
      [data-appid="close-icon"] {
        visibility: hidden;
      }
    `;
    document.head.appendChild(styleEl);
  }

  document.head.appendChild(getViewportMetaTag());
};
