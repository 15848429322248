import { isServer } from './server';

export const mobileViewDetection = (ctx?: any) => {
  const userAgent = isServer()
    ? ctx?.req?.headers['user-agent']
    : navigator?.userAgent;
  const isMobileView = (userAgent ?? '').match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  );

  return !!isMobileView;
};
