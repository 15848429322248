import { CheckCircleOutlined } from '@finn/design-system/icons/check-circle-outlined';
import { YesNoNo } from '@finn/design-system/icons/yes-no-no';
import { cn } from '@finn/ui-utils';
import React from 'react';

type Props = {
  featured: boolean;
  value: boolean;
};

const CheckboxTableCell = ({ featured, value }: Props) => {
  return value ? (
    <CheckCircleOutlined
      className={cn({
        'min-h-8 min-w-8 fill-white': featured,
        'min-h-8 min-w-8 fill-black': !featured,
      })}
    />
  ) : (
    <YesNoNo className="fill-pewter min-h-8 min-w-8" />
  );
};

export default CheckboxTableCell;
