import { ModalActionTypes } from '@finn/ua-constants';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import {
  Context as ReactContext,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import CurrentModal, { CurrentModalProps } from './Modals/CurrentModal';
import { UserAccountModal } from './Modals/modalTypes';
import { useUserIdentifierGeneralPages } from './useUserIdentifierGeneralPages';

type UserAccountOptions = {
  contactId?: string;
  initialEmail?: string;
  isRegistrationLink?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  onClose?: () => void;
  onAffirmativeAction?: () => void;
};

type modalOptions<T = UserAccountModal> = {
  isModalOpen: boolean;
  modalType: T;
  title?: string;
  description?: string | Element;
  affirmativeBtnText?: string;
  negativeBtnText?: string;
  extraInfo?: string;
  extraProps?: object;
};

export interface IModal<T> {
  isModalOpen: boolean;
  modalType: T;
  options?: UserAccountOptions;
  title?: string;
  description?: string | Element;
  affirmativeBtnText?: string;
  negativeBtnText?: string;
  extraInfo?: string;
  extraProps?: object;
}

export type UserAccount<T> = {
  modalStatus: UserAccountOptions & modalOptions<T>;
  setModalStatus: ({
    isModalOpen,
    modalType,
    options,
    title,
    description,
    affirmativeBtnText,
    negativeBtnText,
    extraInfo,
    extraProps,
  }: IModal<T>) => void;
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const UserAccountContext = createContext<UserAccount<UserAccountModal>>({
  modalStatus: {
    isModalOpen: false,
    modalType: UserAccountModal.DEFAULT,
    initialEmail: undefined,
    contactId: undefined,
    isRegistrationLink: false,
    extraProps: {},
    onSuccess: () => {},
    onError: () => {},
    onClose: () => {},
    onAffirmativeAction: () => {},
  },
  setModalStatus: () => {},
});

type UserAccountProviderProps<T = UserAccountModal> = {
  children?: ReactNode;
  ModalComponent?: FC<CurrentModalProps<T>>;
  Context?: ReactContext<UserAccount<T>>;
};

export const UserAccountProvider = <T = UserAccountModal,>({
  children,
  ModalComponent = CurrentModal as FC<CurrentModalProps<T>>,
  Context = UserAccountContext as unknown as ReactContext<UserAccount<T>>,
}: UserAccountProviderProps<T>) => {
  const openModal = useOpenModal();
  useUserIdentifierGeneralPages();
  const [modalStatus, setModalStatus] = useState<UserAccount<T>['modalStatus']>(
    {
      isModalOpen: false,
      modalType: UserAccountModal.DEFAULT as T,
      initialEmail: undefined,
      contactId: undefined,
      isRegistrationLink: false,
      extraProps: {},
      onSuccess: () => {},
      onError: () => {},
      onClose: () => {},
      onAffirmativeAction: () => {},
    }
  );
  const [emailResent, setEmailResent] = useState(false);

  /*
    We do not want this modal to show on the server because we would need to use the
    'getInitialProps' from the '_app'. This would disable all static optimisation for
    the UA site.
  */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('login') === 'true') {
      openModal(ModalKey.LOGIN);
    }
  }, [openModal]);

  /* eslint-enable @typescript-eslint/no-empty-function */

  const handleStatusChange = useCallback(
    ({
      isModalOpen,
      modalType,
      options,
      title,
      description,
      affirmativeBtnText,
      negativeBtnText,
      extraInfo,
      extraProps,
    }: IModal<T>) => {
      setModalStatus({
        isModalOpen,
        modalType: modalType as T,
        ...options,
        title,
        description,
        affirmativeBtnText,
        negativeBtnText,
        extraInfo,
        extraProps,
      });
    },
    []
  );

  const handleClose = () => {
    modalStatus.onClose?.();
    setModalStatus({
      isModalOpen: false,
      modalType: modalStatus.modalType,
    });
  };

  const handleAction = (actionType: string) => {
    if (actionType === ModalActionTypes.AFFIRMATIVE) {
      modalStatus.onAffirmativeAction?.();
    } else {
      modalStatus.onClose?.();
    }
    setModalStatus({
      isModalOpen: false,
      modalType: modalStatus.modalType,
    });
  };

  return (
    <Context.Provider
      value={{
        modalStatus,
        setModalStatus: handleStatusChange as UserAccount<T>['setModalStatus'],
      }}
    >
      {modalStatus.isModalOpen && (
        <ModalComponent
          open={modalStatus.isModalOpen}
          onClose={handleClose}
          onAction={handleAction}
          modalType={modalStatus.modalType as T}
          title={modalStatus.title}
          description={modalStatus.description}
          affirmativeBtnText={modalStatus.affirmativeBtnText}
          negativeBtnText={modalStatus.negativeBtnText}
          extraProps={modalStatus.extraProps}
          emailResent={emailResent}
          setEmailResent={setEmailResent}
        />
      )}

      {children}
    </Context.Provider>
  );
};
