import { supportNavbarClicked } from '@finn/ua-tracking';
import { Image } from '@finn/ui-components';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';
import get from 'lodash/get';

type Props = {
  pageData: any;
};

const CheckoutHeader = ({ pageData }: Props) => {
  // Get cosmic icons
  const securePaymentIcon = getCloudinaryImgUrl(
    get(pageData, 'metadata.header.metadata.secure_payment.icon.url', '')
  );
  const helpIcon = getCloudinaryImgUrl(
    get(pageData, 'metadata.header.metadata.need_help.icon.url', '')
  );
  const phoneNumber = get(
    pageData,
    'metadata.header.metadata.need_help.phone_number',
    []
  );

  return (
    <div
      className={cn('z-50 w-full bg-black py-1 sm:py-2')}
      data-nativeappid="checkout-header"
    >
      <div className="container">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Image
              src={securePaymentIcon}
              alt=""
              className="mr-1 h-6 w-6 sm:h-8 sm:w-8"
            />
            <p className="body-12-light md:body-14-light text-white">
              {get(
                pageData,
                'metadata.header.metadata.secure_payment.title',
                ''
              )}
            </p>
          </div>
          <a
            href={get(phoneNumber, 'metadata.href')}
            onMouseDown={() => {
              supportNavbarClicked();
            }}
            className="flex cursor-pointer items-center"
          >
            <Image
              src={helpIcon}
              alt=""
              className="mr-1 h-6 w-6 sm:h-8 sm:w-8"
            />
            <p className="body-12-light md:body-14-light text-white">
              {get(phoneNumber, 'metadata.label')}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
