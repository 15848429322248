import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { Brand } from '@finn/ua-vehicle';
import { NextLinkSingleApp } from '@finn/ui-components';
import { NavigationMenuContext } from '@finn/ui-modules';
import { App, cn, type HandleClickFn, slugify } from '@finn/ui-utils';
import { useContext } from 'react';

type PopularModelsProps = {
  data: {
    title: string;
    type: string;
    offer_type: string;
  };
  handleClick: HandleClickFn;
};

type ParsedModel = {
  href: string;
  label: string;
};

type ModelParsed = { id: string; brandId: string };
type ModelBrandMap = { [x: string]: ModelParsed };

const PopularModels = ({ data, handleClick }: PopularModelsProps) => {
  const { filterBrandsModels } = useContext(NavigationMenuContext);

  const modelsBrandMap =
    filterBrandsModels?.brands
      ?.map((brand: Brand) =>
        brand.models.map((model) => ({ id: model.id, brandId: brand.id }))
      )
      .flat()
      .reduce((acc: ModelBrandMap, cur: ModelParsed) => {
        acc[cur.id] = cur;

        return acc;
      }, {}) || [];

  const modelsList =
    filterBrandsModels?.models
      ?.map((model: string) => {
        const { brandId, id } = modelsBrandMap[model];

        return {
          href: `/${BASE_SUBSCRIPTION_PATH}/${slugify(brandId)}_${slugify(id)}`,
          label: `${brandId} ${id}`,
        };
      })
      .slice(0, 5) || [];

  return (
    <>
      <div className="global-t6-semibold mb-5 mt-2 h-8">{data.title}</div>
      {modelsList.map((model: ParsedModel) => (
        <NextLinkSingleApp
          app={App.UA}
          key={model.href + model.label}
          href={model.href}
          className={cn(
            'body-16-regular flex items-center rounded-sm p-3',
            'md:hover:border-pearl md:hover:bg-snow border border-solid border-white text-black'
          )}
          onClick={(event) =>
            handleClick({
              link: model.href,
              type: NAVIGATION_TRACKING_EVENTS.LIST_ITEM,
              e: event,
            })
          }
        >
          {model.label}
        </NextLinkSingleApp>
      ))}
    </>
  );
};

export default PopularModels;
