import { ProgressActivity } from '@finn/design-system/icons/progress-activity';
import { cn } from '@finn/ui-utils';

export type SpinnerProps = {
  className?: string;
  testId?: string;
};

export const Spinner = ({ className, testId }: SpinnerProps) => {
  return (
    <ProgressActivity
      aria-live="polite"
      aria-label="Loading"
      aria-busy="true"
      className={cn('animate-spin', className)}
      data-testid={testId}
    />
  );
};
