import 'lazysizes';

import { breakpointValues } from '@finn/design-system/tokens/sizes';
import { cn } from '@finn/ui-utils';
import React from 'react';

type Props = {
  src?: string;
  srcSet?: string;
  sizes?: string;
  alt?: string;
  className?: string;
  dataExpand?: number;
  onLoad?: () => void;
  mdImage?: string;
  lgImage?: string;
  smImage?: string;
  lazyLoad?: boolean;
  variant?: 'lazy-loading' | 'responsive' | 'default';
};

export const Image = React.forwardRef<HTMLImageElement, Props>(
  (
    {
      src,
      srcSet,
      sizes,
      alt = '',
      className,
      dataExpand,
      onLoad = () => {},
      mdImage,
      lgImage,
      smImage,
      lazyLoad = true,
      variant = 'default',
    },
    ref
  ) => {
    switch (variant) {
      case 'lazy-loading':
        return (
          <img
            data-src={src}
            data-srcset={srcSet}
            alt={alt}
            className={cn('lazyload', className)}
            data-expand={dataExpand}
            onLoad={onLoad}
          />
        );
      case 'responsive':
        if (lazyLoad) {
          return (
            <picture className="flex justify-center">
              {lgImage && (
                <source
                  media={`(min-width: ${breakpointValues.md}px)`}
                  data-srcset={lgImage}
                />
              )}
              {mdImage && (
                <source
                  media={`(min-width: ${breakpointValues.sm}px)`}
                  data-srcset={mdImage}
                />
              )}
              <img
                data-src={smImage}
                alt={alt}
                className={`lazyload ${className}`}
              />
            </picture>
          );
        }

        return (
          <picture className="flex justify-center">
            {lgImage && (
              <source
                media={`(min-width: ${breakpointValues.md}px)`}
                srcSet={lgImage}
              />
            )}
            {mdImage && (
              <source
                media={`(min-width: ${breakpointValues.sm}px)`}
                srcSet={mdImage}
              />
            )}
            <img src={smImage} alt={alt} className={className} />
          </picture>
        );

      default:
        return (
          <img
            ref={ref}
            src={src}
            srcSet={srcSet}
            alt={alt}
            sizes={sizes}
            className={className}
          />
        );
    }
  }
);
Image.displayName = 'Image';
