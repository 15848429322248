import { CountryCode, Locale } from '@finn/ui-utils';

import { ModuleType } from './baseTypes';
import { CTAData } from './cta';

export type BasePageObject = {
  published: boolean;
  header: HeaderData;
  footer: FooterData;
  modules: Array<ModuleType>;
  seo_header: SeoHeaderData;
  [key: string]: any;
};

export type BasePageData = BaseCosmicData & BaseCosmicObject<BasePageObject>;

export type CosmicParams = {
  slug: string;
  locale: string;
  fields?: string;
  showMetafields?: boolean;
  isFallbackCall?: boolean;
};

/* eslint-disable max-lines */
export enum CosmicMetafieldType {
  text = 'text',
  textarea = 'textarea',
  htmlTextarea = 'html-textarea',
  selectDropdown = 'select-dropdown',
  object = 'object',
  objectList = 'objects',
  file = 'file',
  date = 'date',
  radioButtonList = 'radio-buttons',
  checkboxList = 'check-boxes',
  repeater = 'repeater',
  parent = 'parent',
  markdown = 'markdown',
  json = 'json',
  switch = 'switch',
  number = 'number',
}

export enum SocialPlatforms {
  Facebook = 'Facebook',
  LinkedIn = 'LinkedIn',
  Instagram = 'Instagram',
  YouTube = 'YouTube',
}

type CosmicMetafieldOption = {
  key: string;
  value: string;
};

export type CosmicMetafield = {
  id: string;
  type: CosmicMetafieldType;
  object_type?: string;
  object: any;
  title: string;
  key: string;
  value?: string;
  required?: boolean;
  regex?: string;
  regex_message?: string;
  minlength?: number;
  maxlength?: number;
  options?: CosmicMetafieldOption[];
  children?: CosmicMetafield[];
};

export type BaseCosmicData = {
  _id: string;
  slug: string;
  title: string;
  content: string;
  type_slug: string;
  status: string;
  locale: string;
};

export type BaseCosmicMetadata = {
  [key: string]: any;
};

export type BaseCosmicObject<T> = BaseCosmicData & {
  metafields: CosmicMetafield[];
  metadata: T;
};

export type CosmicObject = BaseCosmicObject<BaseCosmicMetadata>;

export type ListType = BaseCosmicData & {
  metafields: CosmicMetafield[];
  bucket: string;
  created_at: string;
  created_by: string;
  modified_at: string;
  created: string;
  published_at: string;
  modified_by: string;
  publish_at?: string;
};

export type LocaleSwitcherCosmicData = {
  save_region_and_language_decision: string;
  confirm_detected_location: string;
  countries: { metadata: { countries: Country[] } };
};

export type RibbonData = {
  background_color: string | undefined;
  text_color: string | undefined;
  enabled: boolean;
  text: string;
  link_target: string;
  icon: { key: string };
};

export type HeaderData = BaseCosmicData & {
  metadata: {
    logo_title?: string;
    user_accounts: any;
    ribbon?: RibbonData;
    links: HeaderLinkData[];
    disable_logo_link: boolean;
    custom_logo: {
      url: string;
    };
    custom_logo_link: string;
    navigation_labels?: {
      skipToContent: string;
      main: string;
      secondary: string;
      menu: string;
      mobileOnly: string;
    };
  };
};

type FooterColumn = BaseCosmicData & {
  metadata: FooterSection;
};

type AppStoreBadge = {
  url: string;
  image: MediaData;
  alt: string;
};

type Language = {
  metadata: {
    locale: Locale;
    label: string;
  };
};

export type Country = {
  metadata: {
    code: CountryCode;
    name: string;
    languages: Language[];
  };
};

export type FooterSocialLink = {
  platform_name: {
    key: string;
    value: string;
  };
  link_to_the_platform: string;
};

export type FooterData = BaseCosmicData & {
  metadata: {
    apple_store_badge: AppStoreBadge;
    google_play_badge: AppStoreBadge;
    columns: FooterColumn[];
    footer_blurb_line_1: string;
    footer_blurb_line_2: string;
    footer_cta: CTAData;
    locale_switcher: LocaleSwitcherCosmicData;
    social_links: FooterSocialLink[];
  };
};

export type FooterSection = {
  title: string;
  links: LinkData[];
};

export type FooterLinkItem = BaseCosmicData & {
  metadata: { label: string; href: string };
};

export type MinimisedFooterData = BaseCosmicData & {
  metadata: {
    privacy: Array<FooterLinkItem>;
    imprint: Array<FooterLinkItem>;
    copyright: string;
  };
};

export type PageHeroData = BaseCosmicData & {
  metadata: ConfigHeroData;
};

type HeroImage = {
  value: string;
  img: string;
  maxWidth: number;
};

export type ConfigHeroData = {
  subtitle: string;
  title: string;
  label?: string;
  button_color?: string;
  buttontext?: string;
  button_url?: string;
  hero_pic_gravity: {
    md: string;
    sm: string;
    lg: string;
  };

  smHero: HeroImage;
  mdHero: HeroImage;
  lgHero: HeroImage;

  hero_text_color?: string;
  background_gradient: {
    color: string;
    transparency: string | number;
    top_gradient_transparency: string | number;
  };
  headline_position: string;
  cover_height_sizes: {
    sm: number;
    md: number;
    lg: number;
  };
};

export type ConfigFeaturedSectionData = {
  title: string;
  subtitle?: string;
  cover: {
    sm: MediaData;
    md: MediaData;
    lg: MediaData;
  };
  button: {
    color: string;
    url: string;
    text: string;
  };
  label: string;
  text_color: string;
  background_color: string;
};

export type MediaData = {
  url: string;
  [key: string]: string;
};
export type LinkData = BaseCosmicData & {
  metadata: {
    label: string;
    href: string;
  };
};

export type HeaderMenuSection = BaseCosmicData & {
  metadata: {
    benefit: FearAwayItem[];
    cta_button: {
      href: string;
      title: string;
    };
    type: string;
  };
};

type B2BMenuItem = {
  href: string;
  label: string;
};

export type B2BHeaderMenuSection = BaseCosmicData & {
  metadata: {
    cta_button: {
      href: string;
      title: string;
    };
    type: string;
    b2b_programs: {
      items: B2BMenuItem[];
      title: string;
    };
  };
};

export type HeaderMenu = BaseCosmicData & {
  metadata: {
    sections: HeaderMenuSection[] | B2BHeaderMenuSection[];
  };
};

export type HeaderLinkData = BaseCosmicData & {
  metadata: {
    label: string;
    href: string;
    appearance?: { key: 'text' | 'button'; value: string };
    menu?: HeaderMenu;
  };
};

export type IconCard = BaseCosmicData & {
  metadata: {
    icon: {
      url: string;
    };
    title: string;
    content: string;
  };
};

export type BenefitData = {
  title: string;
  icon: {
    url: string;
  };
  label: string;
  content: string;
};

export type CustomerQuoteType = {
  image: MediaData;
  name: string;
  content: string;
  stars: number;
};

export type CarStepData = BaseCosmicData & {
  metadata: {
    title: string;
    content: string;
    stepnumber: number;
  };
};

export type SeoHeaderData = BaseCosmicData & {
  metadata: {
    multi_locale?: boolean;
    og_image?: MediaData;
    og_url?: string;
    og_type?: string;
    og_description: string;
    og_site_name: string;
    og_title: string;
    og_image_type?: string;
    og_image_width?: string;
    og_image_height?: string;
    no_index?: string;
    keywords?: string;
    description: string;
    title: string;
  };
};

export type FearAwayData = {
  icon: MediaData;
  title: string;
  description: string;
};

export type FearAwayItem = ListType & {
  metadata: FearAwayData;
};

export type QuestionData = ListType & {
  metadata: {
    answer: string;
    question: string;
  };
};

export type PromoBannersObject = {
  promo_banners: PromoBannersData;
};

export type TopCampaignBannerMetadata = {
  title: string;
  linkurl: string;
  call_to_action_text: string;
  imageurl: { url: string; imgix_url: string };
  background_color?: string;
};

export type TopCampaignBannerData = {
  id: string;
  slug: string;
  title: string;
  content: string;
  locale: string;
  type: string;
  metadata: TopCampaignBannerMetadata;
};

export type PromoBannersData = {
  promo_mobile: BaseCosmicData;
  promo_desktop: BaseCosmicData;
  top_campaign_banner_test?: TopCampaignBannerData;
};

export type Metadata<T> = {
  metadata: T;
};

export type HowItWorksItemData = {
  title: string;
  subtitle: string;
  image: {
    sm: MediaData;
    md: MediaData;
    lg: MediaData;
  };
};

export type BackgroundImage = {
  sm: MediaData;
  md: MediaData;
  lg: MediaData;
};
