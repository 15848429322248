import { trim } from 'lodash';

import { DefaultLocale, Locale } from '../types/localization';

const extractLocale = (splitPath: string[]) => {
  let locale = DefaultLocale;
  const firstPath = splitPath[0];
  if (firstPath === Locale.ENGLISH_USA || firstPath === Locale.GERMAN_GERMANY) {
    locale = firstPath;
    splitPath.splice(0, 1);
  }

  return locale;
};

// avoiding using URL / window.URL as we need to support both browser and node
export const parsePathAndLocale = (
  url: string
): { locale: Locale; path: string } | null => {
  if (typeof url !== 'string') return null;

  const urlWithoutQuery = trim(url).split('?')[0];
  const splitURL = urlWithoutQuery.split('/').filter((item) => Boolean(item));

  if (url.startsWith('http')) {
    const splitPath = splitURL.slice(2);
    const locale = extractLocale(splitPath);

    return { locale, path: trim(splitPath.join('/'), '/') };
  } else if (url.startsWith('www') || url.startsWith('finn.')) {
    const splitPath = splitURL.slice(1);
    const locale = extractLocale(splitPath);

    return { locale, path: trim(splitPath.join('/'), '/') };
  } else {
    const splitPath = splitURL;
    const locale = extractLocale(splitPath);

    return { locale, path: trim(splitPath.join('/'), '/') };
  }
};
