import { ComponentType, FunctionComponent, ReactElement } from 'react';

export const getComponentDisplayName = (element: ReactElement) => {
  if (!element) {
    return '';
  }

  const node = element as ReactElement<ComponentType<unknown>>;
  const type = (node as unknown as ReactElement<FunctionComponent>).type;

  if (typeof type === 'string') {
    return type;
  }

  try {
    if (type && 'displayName' in type) {
      return type?.displayName as string;
    }
    if (type && 'name' in type) {
      return type?.name as string;
    }
  } catch {
    return '';
  }

  return '';
};
