import {
  interactionTrackingEvent,
  TrackingEventName,
  USER_ACCOUNT_EVENTS,
} from '@finn/ua-tracking';
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { EmailVerificationModal } from './EmailVerificationModal';
import { MessageModal } from './MessageModal';
import { UserAccountModal } from './modalTypes';

export interface CurrentModalProps<T = UserAccountModal> {
  open: boolean;
  onClose(): void;
  modalType: T;
  onAction(actionType: string): void;
  title?: string;
  description?: string | Element;
  affirmativeBtnText?: string;
  negativeBtnText?: string;
  extraProps?: object;
  emailResent?: boolean;
  setEmailResent?: Dispatch<SetStateAction<boolean>>;
}

const MessageModalContent = {
  [UserAccountModal.EMAIL_VERIFICATION_CHECKOUT]: 'email_sent',
  [UserAccountModal.RESET_PASSWORD_EMAIL]: 'operation_pending',
  [UserAccountModal.PASSWORD_RESET_SUCCESSFULLY]: 'operation_success',
  [UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY]: 'operation_success',
  [UserAccountModal.PASSWORD_CHANGED_SUCCESSFULLY]: 'operation_success',
} as const;

const CurrentModal: FunctionComponent<CurrentModalProps> = ({
  open,
  onClose,
  modalType,
  emailResent,
  setEmailResent,
}) => {
  useEffect(() => {
    if (modalType === UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY && open) {
      interactionTrackingEvent(TrackingEventName.USER_ACCOUNT, {
        action: USER_ACCOUNT_EVENTS.action.ACCOUNT_MANAGEMENT,
        category: USER_ACCOUNT_EVENTS.category,
        label: USER_ACCOUNT_EVENTS.label.MAIL_VERIFIED,
      });
    }
  });

  const i18n = useIntl();

  if (modalType === UserAccountModal.EMAIL_VERIFICATION) {
    return (
      <EmailVerificationModal
        open={open}
        onClose={onClose}
        modalType={modalType as string}
        emailResent={emailResent}
        setEmailResent={setEmailResent}
      />
    );
  }

  const messageModal =
    MessageModalContent[modalType as keyof typeof MessageModalContent];
  if (messageModal) {
    return (
      <MessageModal
        open={open}
        onClose={onClose}
        title={i18n.formatMessage({
          id: `userAccount.messageModal.${modalType}.title`,
        })}
        description={i18n.formatMessage({
          id: `userAccount.messageModal.${modalType}.description`,
        })}
        btnText={i18n.formatMessage({
          id: `userAccount.messageModal.${modalType}.affirmativeBtnText`,
          defaultMessage: 'Ok',
        })}
        iconType={messageModal}
      />
    );
  }

  return null;
};

export default CurrentModal;
