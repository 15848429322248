import { FUEL } from '@finn/ua-vehicle';
import { NextLinkSingleApp } from '@finn/ui-components';
import { FooterSection, LinkData } from '@finn/ui-cosmic';
import { App, cn, parseToHtml, useSession } from '@finn/ui-utils';
import { memo, useEffect, useState } from 'react';

type Props = {
  data: FooterSection;
};

const CUSTOMERS = 'Kunden';

const Section = ({ data }: Props) => {
  const [session] = useSession();
  const [links, setLinks] = useState(data?.links);
  const customerId = session?.user?.hs_contact_id;

  // TODO we overide data that comes from cosmic to be backcompatible
  const electricFuel = FUEL.ElectricDE;
  const migrateToEnRoutesRedirects: { [key: string]: string } = {
    '/fahrzeuge/elektro': `/subscribe/${electricFuel.toLocaleLowerCase()}`,
  };

  useEffect(() => {
    if (customerId && data.title === CUSTOMERS) {
      const footerLinks = links.map((link) => {
        if (link?.metadata?.href === 'https://support.finn.com/hc/de-de') {
          return {
            ...link,
            metadata: {
              ...link.metadata,
              href: `https://support.finn.com/hc/de-de?customer_id=${customerId}`,
            },
          };
        }

        return link;
      });
      setLinks(footerLinks);
    }
  }, [customerId]);

  return (
    <ul className={cn('m-0 mb-8 mr-6 p-0 last:mr-0 sm:mb-14')}>
      <li className="global-t6-semibold mb-2 list-none">{data.title}</li>
      {links
        .filter((link) => link?.metadata?.href && link?.metadata?.label)
        .map(({ metadata }: LinkData) => (
          <li key={metadata.href + metadata.label} className="mb-2 list-none">
            <NextLinkSingleApp
              app={App.UA}
              href={migrateToEnRoutesRedirects[metadata.href] || metadata.href}
              className="body-14-regular mb-2 text-white before:hidden after:hidden"
            >
              {parseToHtml(metadata.label)}
            </NextLinkSingleApp>
          </li>
        ))}
    </ul>
  );
};

export default memo(Section);
