import { ToggleItem } from '@finn/design-system/atoms/toggle';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { ToggleModule } from '@finn/design-system/modules/ToggleModule';
import { getForBusinessCookie } from '@finn/ui-modules';
import { getFormattedPrice, getTermLabel } from '@finn/ui-utils';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext, { OneTimePrice } from '~/contexts/Vehicle';
import { getTermsPrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';

interface IProps {
  className?: string;
}

const ContractDuration: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ className }) => {
  const i18n = useIntl();

  const { vehicle } = useContext(VehicleContext);

  const { setTerm, term, downPayment } = useConfigureStore();

  const isForBusiness = getForBusinessCookie();

  const durations = getTermsPrices(
    vehicle,
    isForBusiness ? 'b2b' : 'b2c',
    downPayment
  );

  const tooltipContent = i18n.formatMessage({
    id: 'checkout.pageData.term_tooltip_content',
  });

  const isDisplayTooltip = durations.some(
    (duration) => duration.duration === 1
  );

  const flexTermLabel = i18n.formatMessage({
    id: 'checkout.pageData.flex_term_label',
  });

  const contractTermLabel = i18n.formatMessage({
    id: 'pdp.contractTerm',
  });

  const trackingData = useTrackingData();

  const trackDurationChange = useCallback(
    (duration: string) => {
      PDPTracking.contractTermClicked({ ...trackingData, term: duration });
    },
    [trackingData]
  );

  const onChangeDuration = useCallback(
    (oneTimeValue: OneTimePrice) => {
      trackDurationChange(String(oneTimeValue.duration));

      setTerm(oneTimeValue.duration);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [trackDurationChange]
  );

  const durationsMap = useMemo(
    () =>
      durations.reduce(
        (acc, duration) => {
          acc[String(duration.duration)] = duration;

          return acc;
        },
        {} as Record<string, OneTimePrice>
      ),
    [durations]
  );

  return (
    <div className={className}>
      <ToggleModule
        title={contractTermLabel}
        titleAs={'h6'}
        tooltipIcon={<InfoOutlined />}
        tooltipText={isDisplayTooltip ? tooltipContent : null}
        cols={durations.length > 1 ? 2 : 1}
        defaultValue={String(term)}
        value={String(term)}
        onValueChange={(value) => {
          if (value) {
            return onChangeDuration(durationsMap[value]);
          }
        }}
      >
        {durations.map((oneTimeValue) => (
          <ToggleItem
            key={oneTimeValue.duration}
            variant="regular"
            title={getTermLabel(oneTimeValue.duration, flexTermLabel, i18n)}
            value={String(oneTimeValue.duration)}
            description={getFormattedPrice(i18n, oneTimeValue.price, 0)}
          />
        ))}
      </ToggleModule>
    </div>
  );
};

export default ContractDuration;
