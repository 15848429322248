import { Button } from '@finn/design-system/atoms/button';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { Recaptcha } from '@finn/ui-components';
import { isMobileApp, useCurrentLocale } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { Ref, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { BusinessDetectedModal } from './BusinessDetectedModal';
import { EmailField } from './EmailField';
import { ExtendedAccountFields } from './ExtendedAccountFields';
import { useLoginText } from './hooks/useLoginText';
import { useSubmitHandler } from './hooks/useSubmitHandler';
import { useTrackScreenSwitching } from './hooks/useTrackScreenSwitching';
import { PasswordField } from './PasswordField';
import { SocialLoginButtons } from './SocialLoginButtons';
import { LoginScreen, useLoginStore } from './store/useLoginStore';
import { SwitchToLogin } from './SwitchToLogin';

const MAX_ALLOWED_MAGIC_LINK_SUBMISSION = 3;

export const LoginForm: React.FC<{
  isLoadingRestore?: boolean;
  isAttemptingDirectCheckout?: boolean;
  cartURL: string;
  createLead?: (data) => Promise<void>;
  setExtendedAccountInProgress?: (value: boolean) => void;
  loginHeaderComponent?: React.ReactNode;
}> = ({
  loginHeaderComponent,
  isLoadingRestore,
  isAttemptingDirectCheckout,
  cartURL,
  createLead,
  setExtendedAccountInProgress,
}) => {
  const i18n = useIntl();
  useTrackScreenSwitching();
  const { isDE } = useCurrentLocale();
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const hasBusinessAccount = useLoginStore((state) => state.hasBusinessAccount);
  const magicLinkSentCount = useLoginStore((state) => state.magicLinkSentCount);
  const serverErrorCode = useLoginStore((state) => state.serverErrorCode);
  const setHasBusinessAccount = useLoginStore(
    (state) => state.setHasBusinessAccount
  );
  const track = useTrackingStore((state) => state.track);
  const resetTrackedCount = useTrackingStore(
    (state) => state.resetTrackedCount
  );

  const shouldHideCta =
    magicLinkSentCount >= MAX_ALLOWED_MAGIC_LINK_SUBMISSION &&
    [LoginScreen.magicLinkSuccess, LoginScreen.forgotPasswordSuccess].includes(
      loginScreen
    );
  const form = useFormContext();
  const submitHandler = useSubmitHandler({
    createLead,
    setExtendedAccountInProgress,
    cartURL,
  });
  const errorHandler = useCallback(
    (error) => {
      track(TrackingEventName.FORM_VALIDATION_ERROR, {
        location: loginScreen,
        additionalProps: { error },
      });
    },
    [loginScreen, track]
  );
  const openModal = useOpenModal();
  const email = form.watch('email');
  useEffect(() => {
    setHasBusinessAccount(email);
  }, [email, setHasBusinessAccount]);
  useEffect(() => {
    if (hasBusinessAccount) {
      track(TrackingEventName.BUSINESS_ACCOUNT_DETECTED, {
        location: loginScreen,
      });
      openModal(ModalKey.BUSINESS_ACCOUNT_DETECTED);
    }
  }, [hasBusinessAccount, openModal]);

  const parentRef = useAutoAnimate({});

  const isInitialScreen = loginScreen === LoginScreen.emailCheckForm;
  const shouldShowLogin = loginScreen === LoginScreen.loginForm;
  const shouldShowRegistration = loginScreen === LoginScreen.registrationForm;
  const shouldShowPasswordField = !!(shouldShowLogin || shouldShowRegistration);
  const shouldShowBackButton = [
    LoginScreen.magicLinkForm,
    LoginScreen.magicLinkSuccess,
    LoginScreen.forgotPasswordForm,
    LoginScreen.forgotPasswordSuccess,
  ].includes(loginScreen);

  const loginText = useLoginText();

  const href = window.location.href;
  const isAppLoginScreen =
    isMobileApp() &&
    href.includes('mobile/auth') &&
    !href.includes('mobile/authv2');

  return (
    <>
      <div className="flex flex-col gap-4">
        <form
          onSubmit={form.handleSubmit(submitHandler, errorHandler)}
          data-testid="login-registration-form"
          className="flex flex-col gap-4"
        >
          <div
            ref={parentRef as Ref<HTMLDivElement>}
            className="flex flex-col gap-4"
          >
            {loginHeaderComponent || (
              <div className="mb-4 flex flex-col gap-4">
                <h2 className="mobile-t4-semibold md:web-t4-semibold">
                  {loginText.title}
                </h2>
                <p className="body-16-light">{loginText.subtitle}</p>
              </div>
            )}

            <EmailField label={loginText.emailLabel} />
            {shouldShowRegistration && <ExtendedAccountFields />}
            {shouldShowPasswordField && (
              <PasswordField label={loginText.forgotPasswordLabel} />
            )}
            {shouldShowRegistration && <Recaptcha />}
          </div>
          {serverErrorCode && (
            <p className="text-red body-14-light m-0">
              {loginText.errorMessage}
            </p>
          )}
          {!shouldHideCta && (
            <Button
              data-cy="submit"
              loading={
                form.formState.isSubmitting ||
                isLoadingRestore ||
                isAttemptingDirectCheckout
              }
              className="w-full sm:min-w-44"
              size="lg"
              type="submit"
              onClick={() => {
                resetTrackedCount();
                track(TrackingEventName.CTA_CLICKED, { location: loginScreen });
              }}
            >
              {loginText.buttonLabel ??
                i18n.formatMessage({
                  id: 'checkout.pageData.next_step_button',
                })}
            </Button>
          )}
        </form>
        {shouldShowBackButton && (
          <Button
            variant="outline"
            className="w-full"
            disabled={form.formState.isSubmitting}
            onClick={() => {
              resetTrackedCount();
              track(TrackingEventName.CTA_CLICKED, {
                location: loginScreen,
                additionalProps: { cta_type: 'secondary' },
              });
              setLoginScreen(LoginScreen.emailCheckForm);
            }}
          >
            {loginText.backButtonLabel}
          </Button>
        )}
        <SwitchToLogin />
        {isInitialScreen && !isAppLoginScreen && isDE && (
          <SocialLoginButtons
            socialButtonSeparator={loginText.socialButtonSeparator}
            cartURL={cartURL}
          />
        )}
      </div>
      <ModalContainer
        modalKey={ModalKey.BUSINESS_ACCOUNT_DETECTED}
        email={email}
        ModalComponent={BusinessDetectedModal}
      />
    </>
  );
};
