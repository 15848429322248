import { FlipperCosmicMetadata } from '@finn/ui-cosmic';
import { cn, useCurrentApp } from '@finn/ui-utils';
import { ReactNode } from 'react';

import FlipperContent from './FlipperContent';
import FlipperImage from './FlipperImage';

type FlipperProps = {
  slug?: string;
  data: FlipperCosmicMetadata;
  addProps?: any;
  children?: ReactNode;
};

const Flipper = ({ slug, data, addProps, children }: FlipperProps) => {
  const app = useCurrentApp();

  const hasImage = addProps?.hasImage || !!data.image?.url;
  const props = {
    title: data.title,
    description: data.description,
    cta_text: data.cta_text,
    cta_href: data.cta_href,
    cta_type: data.cta_type,
    reverse: data.reverse,
    squared: data.squared,
    header_size: data.header_size.key,
    cta_tracking_location: data.cta_tracking_location ?? slug,
    trackOnButtonClick: data.trackOnButtonClick,
  };

  return (
    <>
      <div
        className={cn(
          'raw-html-global container flex flex-col-reverse sm:gap-6 md:flex-col',
          app === 'cp'
            ? 'before:hidden after:hidden'
            : 'sm:grid sm:grid-cols-[1fr,1fr]'
        )}
      >
        {hasImage && (
          <FlipperImage
            image={data.image}
            altText={data?.image_alt_text || data.title}
            reverse={data.reverse}
            squared={data.squared}
          />
        )}
        <FlipperContent {...props} {...addProps} />
        {children}
      </div>
    </>
  );
};

export default Flipper;
