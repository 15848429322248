import { CheckboxChecked } from '@finn/design-system/icons/checkbox-checked';
import { cn } from '@finn/ui-utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';

export type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  label?: string | React.ReactNode;
  selected?: boolean;
  subtitle?: string;
  error?: boolean;
  testId?: string;
};

const gridLayoutClassNames =
  'grid grid-cols-[min-content,1fr] grid-rows-[minmax(1.25rem,max-content)] gap-x-2';
const accessabilityClassNames =
  'focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-0';

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, error, ...props }, ref) => {
  const id = props.id ?? `${props.label}-${props.value}`;

  return (
    <div
      className={cn(
        props.label
          ? gridLayoutClassNames
          : 'flex h-5 min-w-5 items-center justify-center',
        {
          'grid-rows-[1.25rem,max-content] gap-y-3': props.subtitle,
        }
      )}
      data-testid={props.testId ?? `checkbox-${props.name ?? id}`}
    >
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'border-1 border-pewter hover:ring-pearl focus group peer row-span-2 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-transparent p-0 hover:ring-1 active:border-black active:ring-1 active:ring-black disabled:pointer-events-none data-[state=checked]:border-0',
          accessabilityClassNames,
          {
            '!border-red !ring-red ring-1': error,
            'grid-rows-[1.25rem,max-content] gap-y-3': props.subtitle,
          },
          className
        )}
        checked={props.selected}
        id={id}
        data-testid={`checkbox-button-${props.name ?? id}`}
        {...props}
      >
        <CheckboxPrimitive.Indicator>
          <CheckboxChecked
            className={cn('group-disabled:fill-steel h-5 w-5 align-top', {
              'fill-red': error,
            })}
          />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {props.label ? (
        <LabelPrimitive.Root
          className={cn(
            'peer-disabled:text-steel inline-block cursor-pointer self-center peer-disabled:pointer-events-none',
            props.subtitle ? 'body-14-semibold' : 'body-14-regular',
            {
              'text-red peer-disabled:text-red': error,
            }
          )}
          htmlFor={id}
        >
          {props.label}
        </LabelPrimitive.Root>
      ) : null}
      {props.subtitle ? (
        <LabelPrimitive.Root
          className={cn(
            // according to latest figma, checkbox subtitle should be 12px below title text and not first row
            // so we need to calculate negative margin for this case by formula ((ROW_HEIGHT - TITLE_HEIGHT) / 2) or (20px - 10px) / 2 = 5px
            'body-14-regular peer-disabled:text-steel -mt-[5px] inline-block cursor-pointer peer-disabled:pointer-events-none',
            {
              'text-red': error,
            }
          )}
          htmlFor={id}
        >
          {props.subtitle}
        </LabelPrimitive.Root>
      ) : null}
    </div>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
