import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage';
import { DEFAULT_DOWN_PAYMENT } from '@finn/ua-constants';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { getForBusinessCookie } from '@finn/ui-modules';
import {
  CookieKeys,
  getClientJSONCookie,
  setClientJSONCookie,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useEffect } from 'react';

import { useGetDownPaymentInCart } from '~/modules/checkout/hooks/useGetDownPaymentInCart';
import { getTotalPrice } from '~/modules/checkout/utils/getTotalPrice';
import { CartInfo, PreviousCartInfo } from '~/types/checkout';

type Props = {
  isCart?: boolean;
  isPDP?: boolean;
  isConfigure?: boolean;
  cartInfo: Partial<CartInfo>;
  vehicle: GenericVehicleDetails;
};
export const useSaveCartCookie = ({
  isCart,
  isPDP,
  cartInfo,
  vehicle,
}: Props) => {
  const { kilometerPackage: kmPackage, term, vehicleId } = cartInfo || {};
  const { locale } = useCurrentLocale();
  const isDownPaymentInConfigStore = useConfigureStore(
    (state) => state.downPayment
  );
  const fromValidPage = isCart || isPDP;
  const { hasValidDownPaymentInCartUrl } = useGetDownPaymentInCart();

  const isDownPayment = isCart
    ? hasValidDownPaymentInCartUrl
    : isDownPaymentInConfigStore;

  useEffect(() => {
    if (!fromValidPage || !kmPackage || !term || !vehicleId || !vehicle) {
      return;
    }
    if (isPDP) {
      const existingCookie = getClientJSONCookie(CookieKeys.RETURN_TO_CKO);
      if (existingCookie?.isCart) {
        // if cart already visited don't store pdp/configure
        return;
      }
    }
    const isForBusiness = getForBusinessCookie();
    const amount = getTotalPrice(
      vehicle,
      term,
      kmPackage,
      isForBusiness,
      isDownPayment
    );
    const payload: PreviousCartInfo = {
      kmPackage,
      term,
      vehicleId,
      locale,
      vehicleImage: vehicle.picture?.url,
      brand: vehicle.brand?.id,
      model: vehicle.model,
      amount: amount as number,
      isCart,
      isPDP,
      downPaymentAmount: isDownPayment ? DEFAULT_DOWN_PAYMENT : undefined,
      isConfigure: window.location.pathname.includes('/configure'),
    };
    setClientJSONCookie(CookieKeys.RETURN_TO_CKO, payload);
  }, [
    cartInfo,
    isCart,
    kmPackage,
    locale,
    term,
    vehicleId,
    vehicle,
    isPDP,
    fromValidPage,
    isDownPayment,
  ]);
};
