import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { cn } from '@finn/ui-utils';

type ErrorBoxParams = {
  className?: string;
  contentMain?: string;
  contentSub?: string;
};

export const ErrorBox = ({
  className,
  contentMain,
  contentSub,
}: ErrorBoxParams) => (
  <>
    <InfoOutlined className="size-48" />
    <h4 className={cn(className, 'mobile-t4-semibold md:web-t4-semibold')}>
      {contentMain}
    </h4>
    <p className={cn(className, 'body-16-regular')}>{contentSub}</p>
  </>
);
