import { ConfigureVehicleModal } from '@finn/auto-ui/modules/products/details/components/ConfigureVehicleModal/ConfigureVehicleModal';
import { useWebViewNavigationEnded } from '@finn/ua-app';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { GenericVehicleDetails, isVehicleComingSoon } from '@finn/ua-vehicle';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import NotifyMeModal from '~/components/UserAccount/Modals/NotifyMeModal';
import { useSaveCartCookie } from '~/modules/checkout/hooks/useSaveCartCookie';

import { useConfigureStore } from '../ConfigurePage';

interface IAddToCartContext {
  onAddToCart?: () => void;
  isLoading: boolean;
  initialized: boolean;
}

export const AddToCartContext = React.createContext<IAddToCartContext>({
  isLoading: false,
  initialized: false,
});

export const AddToCartProvider: React.FC<{
  vehicle: GenericVehicleDetails;
  children?: ReactNode;
}> = ({ children, vehicle }) => {
  const { term, kilometerPackage } = useConfigureStore();

  const isComingSoon = isVehicleComingSoon(vehicle);

  const dealInfo = useMemo(
    () => ({
      vehicleId: vehicle.id,
      term,
      kilometerPackage,
    }),
    [kilometerPackage, term, vehicle.id]
  );

  useSaveCartCookie({
    isPDP: true,
    cartInfo: dealInfo,
    vehicle,
  });

  const openModal = useOpenModal();

  const [isLoading, setLoading] = useState(false);
  // used to disable loading in webview after navigation is done
  useWebViewNavigationEnded(isLoading, setLoading);

  const onAddToCart = useCallback(async () => {
    if (isComingSoon || vehicle.availability === null) {
      interactionTrackingEvent(TrackingEventName.COMING_SOON_MODAL_OPENED, {
        contentIds: [vehicle.id],
        make: vehicle.brand.id,
        model: vehicle.model,
      });

      openModal(ModalKey.NOTIFY_ME);

      return;
    }

    openModal(ModalKey.CONFIGURE_VEHICLE);
    setLoading(false);
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: 'configure',
    });
  }, [
    isComingSoon,
    vehicle.availability,
    vehicle.id,
    vehicle.brand.id,
    vehicle.model,
    openModal,
  ]);

  return (
    <>
      <AddToCartContext.Provider
        value={{
          onAddToCart,
          isLoading,
          initialized: true,
        }}
      >
        {children}
      </AddToCartContext.Provider>

      <ModalContainer
        modalKey={ModalKey.NOTIFY_ME}
        ModalComponent={NotifyMeModal}
        vehicle={vehicle}
      />

      <ModalContainer
        modalKey={ModalKey.CONFIGURE_VEHICLE}
        ModalComponent={ConfigureVehicleModal}
      />
    </>
  );
};
