import { devtools } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

const FINN_UA_MODALS_DEVTOOLS_KEY = '@finn/ua-modals';

export type GenericModalKey = string | number | symbol;

export type ModalStore<ModalKey extends GenericModalKey> = {
  openModalKeys: Record<ModalKey, true>;
  openModal(modalKey: ModalKey): void;
  closeModal(modalKey: ModalKey): void;
  closeAllModals(): void;
};

export function createModalStore<ModalKey extends GenericModalKey>() {
  return createStore<ModalStore<ModalKey>>()(
    devtools(
      (set, get) => ({
        openModalKeys: {} as Record<ModalKey, true>,

        openModal(modalKey: ModalKey): void {
          const openModalKeys = { ...get().openModalKeys, [modalKey]: true };

          set(
            () => ({ openModalKeys }),
            false,
            `${FINN_UA_MODALS_DEVTOOLS_KEY}/openModal`
          );
        },

        closeModal(modalKey: ModalKey): void {
          const openModalKeys = { ...get().openModalKeys };
          delete openModalKeys[modalKey];

          set(
            () => ({ openModalKeys }),
            false,
            `${FINN_UA_MODALS_DEVTOOLS_KEY}/closeModal`
          );
        },

        closeAllModals(): void {
          set(
            () => ({ openModalKeys: {} as Record<ModalKey, true> }),
            false,
            `${FINN_UA_MODALS_DEVTOOLS_KEY}/closeAllModals`
          );
        },
      }),
      { name: FINN_UA_MODALS_DEVTOOLS_KEY }
    )
  );
}

export type ZustandModalStore<ModalKey extends GenericModalKey> = ReturnType<
  typeof createModalStore<ModalKey>
>;
