import { CLOSED_USER_GROUP_COOKIE_EXPIRATION } from '@finn/ua-constants';
import {
  clearClientCookie,
  CookieKeys,
  getClientCookie,
  isServer,
  setClientCookie,
} from '@finn/ui-utils';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ClosedUserGroupContextProps {
  closedUserGroupId?: string;
}

const defaultClosedUserGroupContext: ClosedUserGroupContextProps = {
  closedUserGroupId: undefined,
};

const ClosedUserGroupContext = createContext<ClosedUserGroupContextProps>(
  defaultClosedUserGroupContext
);

const CUG_PARAM = 'cug_id';

export const ClosedUserGroupProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [closedUserGroupId, setClosedUserGroupId] = useState<string>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const storedID = getClosedUserGroupId();
    const _cugId = urlParams.get(CUG_PARAM) || storedID;

    if (_cugId) {
      setClientCookie(
        CookieKeys.CLOSED_USER_GROUP_ID,
        _cugId,
        CLOSED_USER_GROUP_COOKIE_EXPIRATION
      );
      setClosedUserGroupId(_cugId);
      // CUG_ID and REFERRAL_VOUCHER_CODE/closed_user_group are mutually exclusive
      clearClientCookie(CookieKeys.REFERRAL_VOUCHER_CODE);
    }
  }, []);

  const value = useMemo(() => ({ closedUserGroupId }), [closedUserGroupId]);

  return (
    <ClosedUserGroupContext.Provider value={value}>
      {children}
    </ClosedUserGroupContext.Provider>
  );
};

export const getClosedUserGroupId = (): string => {
  if (isServer()) {
    return;
  }

  return getClientCookie(CookieKeys.CLOSED_USER_GROUP_ID);
};

export const useClosedUserGroup = (): ClosedUserGroupContextProps => {
  const context = useContext(ClosedUserGroupContext);
  if (!context) {
    return defaultClosedUserGroupContext;
  }

  return context;
};
