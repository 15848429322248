import { EmitEvent } from '../../types/general';
import { getGlobalObject } from '../helpers/globalObject';

/* eslint no-implicit-globals: "error" */

export const setupAuth = (emitEvent: EmitEvent) => {
  const globalObject = getGlobalObject();
  const originalFetch = globalObject.fetch;

  globalObject.fetch = (...args) => {
    const url = args?.[0];
    if (typeof url === 'string' && url?.includes('/login')) {
      emitEvent({
        type: 'event:login',
      });
    }

    if (typeof url === 'string' && url?.includes('/register')) {
      // we doing register, after that sending message that will allow native app to login
      return originalFetch(...args).then((res) => {
        emitEvent({
          type: 'event:login',
        });

        return res;
      });
    }

    return originalFetch(...args);
  };
};
