import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { Image, NextLinkSingleApp } from '@finn/ui-components';
import { MediaData } from '@finn/ui-cosmic';
import {
  App,
  getCloudinaryImgUrl,
  getCloudinarySrcSet,
  type HandleClickFn,
} from '@finn/ui-utils';

type Item = {
  title: string;
  type: string;
  description: string;
  image: MediaData;
  href: string;
};

const migrationToENHrefMap: { [key: string]: string } = {
  '/faq-kaufen': '/faq-buy',
};

const MenuBlockInfoCard = ({
  data,
  handleClick,
}: {
  data: {
    items: Item[];
  };
  handleClick: HandleClickFn;
}) => {
  return (
    <div className="flex h-full flex-col justify-between">
      {data.items.map((card, idx) => {
        const cloudinaryURL = getCloudinaryImgUrl(card.image.url, {
          w: 296,
          dpr: 2,
        });
        const srcSet = getCloudinarySrcSet(card.image.url, [296]);

        return (
          <NextLinkSingleApp
            app={App.UA}
            key={card.title + idx}
            href={migrationToENHrefMap[card.href] || card.href}
            className="text-black"
            onClick={(event) =>
              handleClick({
                link: card.href,
                type: NAVIGATION_TRACKING_EVENTS.TEASER,
                e: event,
              })
            }
          >
            <Image
              src={cloudinaryURL}
              srcSet={srcSet}
              alt={card.title}
              className="mb-1 max-h-20 w-full rounded-sm object-cover"
            />
            <p className="body-16-semibold">{card.title}</p>
            <div className="body-16-light my-2 line-clamp-1 overflow-hidden">
              {card.description}
            </div>
          </NextLinkSingleApp>
        );
      })}
    </div>
  );
};

export default MenuBlockInfoCard;
