import { isServer, setAllFeatures, useCurrentLocale } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

import { computeFeatureValues } from './computeFeatureValues';
import { RemovedExperiments } from './constants';
import { FeatureContext } from './FeatureContext';
import { AllFeatures } from './types';

const daysInSixMonths = 180;
type SelectedVariants = { [key: string]: string };
// Using a static hashmap to prevent unnecessary re-rendering
const selectedVariants: SelectedVariants = {};

/**
 * Can only be called in root component (eg. page), before any component using it has been rendered
 *
 * @param key the feature to override
 * @param value the value to use
 */
export const useFeatureOverrides = (key: string, value: string) => {
  selectedVariants[key] = value;
};

/**
 * For testing purpose only! Do not use!
 */
export const reset = () => {
  Object.keys(selectedVariants).forEach((key) => {
    delete selectedVariants[key];
  });
};

export const FeatureProvider: React.FC<
  React.PropsWithChildren<{ allFeatures: AllFeatures }>
> = ({ allFeatures, children }) => {
  const { isReady, query } = useRouter();
  const { locale } = useCurrentLocale();

  // Only allow values to be initialized once for page-speed
  const preloaded = useRef(false);
  if (!preloaded.current) {
    // Initial page load with default values
    const featuresForLocale = allFeatures[locale] || {};
    Object.entries(featuresForLocale).forEach(([key, value]) => {
      if (value.enabled && !(key in selectedVariants)) {
        selectedVariants[key] = value.buckets[0];
      }
    });

    preloaded.current = true;
  }

  const [currentVariants, setCurrentVariants] =
    React.useState(selectedVariants);

  // Sets all values for current user
  const initialized = useRef<string | undefined>(null);
  useEffect(() => {
    if (initialized.current === locale || isServer()) {
      return;
    }

    const { updatedBuckets, bucketValues } = computeFeatureValues(
      allFeatures,
      locale,
      Cookies.get,
      navigator.userAgent
    );

    // Set cookies for future page loads
    for (const [bucketName, bucketValue] of Object.entries(updatedBuckets)) {
      Cookies.set(`${locale}_${bucketName}`, bucketValue, {
        expires: daysInSixMonths,
      });
    }

    for (const removedExp of RemovedExperiments) {
      Cookies.remove(`${locale}_${removedExp}`);
    }

    setAllFeatures(bucketValues);
    setCurrentVariants(bucketValues);

    initialized.current = locale;
  }, [locale]);

  // Allow users to override a feature value using the page URL
  useEffect(() => {
    if (isReady) {
      const featuresForLocale = allFeatures[locale] || {};

      Object.keys(featuresForLocale).forEach((featureKey) => {
        const featureValue = query[featureKey] as string;

        if (featureValue) {
          const currentValue = Cookies.get(`${locale}_${featureKey}`);
          if (currentValue !== featureValue) {
            Cookies.set(`${locale}_${featureKey}`, featureValue);

            setCurrentVariants((current) => ({
              ...current,
              [featureKey]: featureValue,
            }));
          }
        }
      });
    }
  }, [isReady]);

  return (
    <FeatureContext.Provider value={currentVariants}>
      {children}
    </FeatureContext.Provider>
  );
};
