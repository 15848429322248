// @Afzal: just triggering a build
export { default as Flipper } from './features/Flipper';
export { Chat } from './features/Chat/index';
export type { ChatRef } from './features/Chat/index';

export { default as SpacingWrapper } from './components/SpacingWrapper';
export { hasNoPadding } from './helpers/hasNoPadding';
export { Accordion } from './features/Accordion/Accordion';
export { Collapse, CollapseTypes } from './features/Accordion/Collapse';
export { Alert } from './components/Alert';

export { Dictionary } from './services/dictionary';
export { withCosmicModule } from './services/cosmic';
export { getPlatformFeatures, globalChatApi } from './features';
export { ProlongationTerm } from './components/ProlongationTerm';

// TODO temporary re-exported directly, as UA do not support new cosmic modules
// yet
export { StepsModule } from './features/Steps';
// TODO action button is deprecated, use Button instead
// we re-export it for back compability as current goal share StepsModule
export { ActionButton } from './features/Steps';

export * from './types';

export { useCosmicModule } from './hooks/useCosmicModule';
export { useDeals } from './hooks/useDeals';
export { useCarSaleInfo } from './hooks/useCarSaleInfo';
export { useLoginText } from './features/login/hooks/useLoginText';

export {
  useDeal,
  type Deal,
  parseSubscriptionEndDate,
  getSwapDateRange,
} from './hooks/useDeal';
export * from './hooks/useGetLoyaltyInfo';
export {
  useRemoteData,
  type RequestStatus,
  getRequestStatus,
  getDataStatus,
} from './hooks/useRemoteData';
export * from './components/CosmicHelper';
export { LoginModal } from './features/login/LoginModal';
export { Login } from './features/login/Login';
export * from './components/DatePicker';
export { HookFormPhoneInput } from './components/HookFormPhoneInput';
export { checkEmailExistence } from './features/login/emailCheck';
export {
  useLoginStore,
  LoginScreen,
} from './features/login/store/useLoginStore';
export { getActionItem } from './helpers/getActionItem';
