import {
  HELP_CENTER_EVENTS,
  HelpCenterTrackingProps,
  NavItemEventProps,
  USER_ACCOUNT_EVENTS,
} from '@finn/ua-tracking';
import { trackEvent } from '@finn/ui-utils';

export const helpCenterIconClicked = (props: HelpCenterTrackingProps) =>
  trackEvent(HELP_CENTER_EVENTS.HELP_CENTER_ICON_CLICKED, {
    ...props,
  });

export const trackNavItemClicked = (props: NavItemEventProps) => {
  trackEvent(USER_ACCOUNT_EVENTS.label.NAVITEM_CLICKED, {
    ...props,
  });
};
