import { EmitEvent } from '../../types/general';
import { notifyAboutStripeModals } from './stripe';

export const setupScrollListener = (emitEvent: EmitEvent) => {
  if (!MutationObserver) {
    return;
  }
  // we are listening for a moment when some library adding overflow hidden
  // to body (for example modals) and share this info with native app
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      emitEvent({
        type: 'scroll',
        value: document.body.style.overflow !== 'hidden',
      });

      notifyAboutStripeModals(emitEvent);
    });
  });

  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['style'],
  });
};
