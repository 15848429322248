import { CheckCircleOutlined } from '@finn/design-system/icons/check-circle-outlined';
import { RadioToggleDefault } from '@finn/design-system/icons/radio-toggle-default';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn, parseToHtml } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';

import { ActionButton } from './ActionButton';

export type NextStepType = {
  icon?: string;
  title: string;
  description: string;
  buttonText?: string;
  success?: boolean;
  inProgress?: boolean;
  buttonUrl?: { [x: string]: string };
  buttonCallback?: () => void;
  dataCy?: string;
  customBodyElement?: ReactNode;
  shouldExpand?: boolean;
  step?: string;
};

// Steps allowed to show the CustomBodyModule even after the state is successful
const stepsAllowed = ['id_verifiction_step'];

export const NextStep: React.FunctionComponent<NextStepType> = ({
  title,
  description,
  buttonText,
  success,
  buttonUrl,
  buttonCallback,
  dataCy,
  customBodyElement = null,
  shouldExpand,
  step,
}) => {
  const router = useRouter();
  const isUpcoming = !success && !shouldExpand;
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (!step || !shouldExpand) {
      return;
    }
    track(TrackingEventName.VERIFICATION_STEP_VIEWED, { name: step });
  }, [step, shouldExpand, track]);

  return (
    <>
      <hr className="border-pearl my-8 border-0 border-t border-solid" />
      <div className="flex flex-col gap-4 md:gap-6">
        <div className={cn('flex gap-4', { 'text-steel': isUpcoming })}>
          {success ? (
            <CheckCircleOutlined />
          ) : (
            <RadioToggleDefault
              className={cn({
                'text-steel': isUpcoming,
              })}
            />
          )}
          <div className="body-16-semibold self-center">{title}</div>
        </div>
        {shouldExpand && (
          <div className="flex flex-col gap-4 md:gap-6">
            <div className="body-14-light">{parseToHtml(description)}</div>
            {!success && (
              <>
                {buttonText && (
                  <div className="w-full">
                    {buttonUrl && (
                      <ActionButton
                        className="mb-0 w-full"
                        label={buttonText}
                        onClick={() => {
                          track(TrackingEventName.CTA_CLICKED, {
                            location: step,
                          });
                          router.push(buttonUrl.href);
                        }}
                        variant="outline"
                      />
                    )}
                    {!buttonUrl && buttonCallback && (
                      <ActionButton
                        label={buttonText}
                        className="my-0 w-full"
                        onClick={buttonCallback}
                        variant="primary"
                        testId={`cta-${dataCy}`}
                      />
                    )}
                  </div>
                )}
                {customBodyElement}
              </>
            )}
            {success &&
              step &&
              stepsAllowed.includes(step) &&
              customBodyElement}
          </div>
        )}
      </div>
    </>
  );
};
