import { REQUEST_ID_HEADER_NAME } from '@finn/ui-utils';
import axios, { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';

import { getFirstResponseThatFits } from './helpers';

export type Response<T> = {
  data: T;
  status: number;
};

export type GetEndpoint = () => string | string[];

export const dataFetcher =
  (getEndpoint: GetEndpoint) =>
  async <T>(): Promise<Response<T>> => {
    const paths = getEndpoint();
    const apiPaths = Array.isArray(paths) ? paths : [paths];

    // here we have all the responses in parallel
    // we do this to speed up, because sometimes we need to get subscriptions/id and leads/id
    // and the only way to know which to select is wait for response
    const responsesPromises = apiPaths.map((path) => {
      const controller = new AbortController();

      return {
        request: axios(`/${path}`, {
          headers: {
            [REQUEST_ID_HEADER_NAME]: uuid(),
          },
          withCredentials: true,
        }).catch((error) => error?.response as AxiosResponse<T>),
        cancel: () => controller?.abort(),
      };
    });

    return getFirstResponseThatFits(responsesPromises);
  };
