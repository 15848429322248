import { config } from '..';

type GoogleTagFunction = (
  command: string,
  targetId: string,
  eventName: string,
  eventCallback?: (value: string) => void
) => void;

declare global {
  interface Window {
    gtag?: GoogleTagFunction;
  }
}

let currentSessionId: string | undefined;
let currentSessionNumber: string | undefined;

async function fetchSessionInfoFromGTag(gtag: GoogleTagFunction) {
  // If we have already retrieved the session data, return it
  if (currentSessionId !== undefined && currentSessionNumber !== undefined) {
    return {
      session_id: currentSessionId,
      session_number: currentSessionNumber,
    };
  }

  const sessionIdPromise = new Promise<string>((resolve) => {
    gtag('get', config.GOOGLE_ANALYTICS_4_WEB, 'session_id', resolve);
  });

  const sessionNumberPromise = new Promise<string>((resolve) => {
    gtag('get', config.GOOGLE_ANALYTICS_4_WEB, 'session_number', resolve);
  });

  const [session_id, session_number] = await Promise.all([
    sessionIdPromise,
    sessionNumberPromise,
  ]);

  // Save session info in global variables
  currentSessionId = session_id;
  currentSessionNumber = session_number;

  return {
    session_id,
    session_number,
  };
}

export const getCurrentSessionData = async (gtag: GoogleTagFunction) => {
  try {
    return await fetchSessionInfoFromGTag(gtag);
  } catch (error) {
    console.error('Error retrieving session data:', error);

    return { session_id: undefined, session_number: undefined };
  }
};
