import {
  MODAL_UNMOUNT_DELAY_MS,
  ModalHeaderVariant,
  ModalKey,
} from './constants';
import { createModalContainer, CreateModalContainerOptions } from './container';
import { createModalHooks } from './hooks';
import { createModalStore, GenericModalKey } from './store';

type CreateModalOptions = {
  container?: CreateModalContainerOptions;
};

export function createModals<ModalKey extends GenericModalKey>(
  options?: CreateModalOptions
) {
  const store = createModalStore<ModalKey>();
  const hooks = createModalHooks<ModalKey>(store);
  const ModalContainer = createModalContainer<ModalKey>(
    hooks,
    options?.container
  );

  return { ...hooks, ModalContainer };
}

const {
  ModalContainer,
  useOpenModal,
  useCloseModal,
  useCloseAllModals,
  useIsModalOpen,
  useIsAnyModalOpen,
  useOnModalOpen,
  useOnModalClose,
} = createModals<ModalKey>({
  container: { unmountDelay: MODAL_UNMOUNT_DELAY_MS },
});

export {
  ModalKey,
  ModalHeaderVariant,
  ModalContainer,
  useOpenModal,
  useCloseModal,
  useCloseAllModals,
  useIsModalOpen,
  useIsAnyModalOpen,
  useOnModalOpen,
  useOnModalClose,
};
