import { useEffect } from 'react';

import { getAppSDK } from '../helpers';

export const useWebViewNavigationEnded = (
  loading: boolean,
  setLoading: (value: boolean) => void
) => {
  useEffect(() => {
    const appSDK = getAppSDK();

    const stopCTALoading = () => {
      if (loading) {
        setLoading(false);
      }
    };

    if (loading) {
      const unsubscribe = appSDK?.on('navigation_ended', stopCTALoading);

      return () => {
        unsubscribe?.();
      };
    }

    return () => {
      // empty
    };
  }, [loading, setLoading]);
};
