import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn } from '@finn/ui-utils';
import React from 'react';

type DropdownElementProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  href: string;
  label: string;
  newTagLabel?: string;
};

const DropdownElement: React.FunctionComponent<DropdownElementProps> = ({
  onClick,
  href,
  label,
  newTagLabel,
}) => {
  const DropdownElementContent = () => (
    <div
      className={cn(
        'hover:border-pearl hover:bg-snow box-content flex min-h-8 cursor-pointer items-center justify-between rounded-sm border border-solid border-white p-2'
      )}
    >
      <span className="body-16-semibold">{label}</span>
      {newTagLabel && (
        <span
          className={cn(
            'body-12-semibold bg-trustedBlue justify-self-center rounded px-3 py-2 text-white'
          )}
        >
          {newTagLabel}
        </span>
      )}
    </div>
  );

  return (
    <NextLinkSingleApp app={App.CP} href={href} onClick={onClick}>
      <DropdownElementContent />
    </NextLinkSingleApp>
  );
};

export default DropdownElement;
