import { ComponentProps, memo, ReactNode } from 'react';

import { FormatConfig, VisibilityConfig } from '../types';

export const withCosmicModule = <T,>(
  Component: (data: T) => ReactNode | null
) =>
  memo(
    (props: {
      params?: string[];
      data: {
        slug: string;
        metadata: ComponentProps<typeof Component>;
        config?: {
          visibilityConfig?: VisibilityConfig;
          formatConfig: FormatConfig;
        };
      };
    }) => {
      return (
        <Component
          params={props.params}
          slug={props.data?.slug}
          {...props.data?.config}
          {...props.data.metadata}
        />
      );
    }
  );
