import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import React, { useCallback } from 'react';

import { InfoModal, InfoModalData } from '../modals/InfoModal';
import { HowToCollectPoints } from './HowToCollectPoints';

type Props = {
  data?: LoyaltyCosmicMetadata;
};

export const HowToCollectPointsStandaloneModule: React.FC<Props> = ({
  data,
}) => {
  const [infoModalData, setInfoModalData] = React.useState<InfoModalData>({});
  const openModal = useOpenModal();
  const openInfoModal = useCallback(
    (infoData: InfoModalData) => {
      setInfoModalData(infoData);
      openModal(ModalKey.INFO_MODAL);
    },
    [openModal]
  );

  return (
    <div className="container">
      <HowToCollectPoints
        openInfoModal={openInfoModal}
        data={data}
        standalone
      />
      <ModalContainer
        modalKey={ModalKey.INFO_MODAL}
        ModalComponent={InfoModal}
        infoModalData={infoModalData}
      />
    </div>
  );
};
