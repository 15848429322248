// import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
// import { getCheckoutCartUrl } from '@finn/auto-ui/utils/checkout';
import { showToast } from '@finn/design-system/atoms/toast';
// import { DEFAULT_DOWN_PAYMENT } from '@finn/ua-constants';
import { usePCPLoginExp } from '@finn/ua-featureflags';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { isMobileApp, isServer } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { LoginScreen, useLoginStore } from '../store/useLoginStore';

export const useMagicLinkHandler = ({
  withResetLink,
  cartURL,
}: {
  withResetLink: boolean;
  cartURL: string;
}) => {
  const form = useFormContext();
  const i18n = useIntl();
  const magicLinkSentCount = useLoginStore((state) => state.magicLinkSentCount);
  const setMagicLinkSentCount = useLoginStore(
    (state) => state.setMagicLinkSentCount
  );
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const track = useTrackingStore((state) => state.track);

  const { isEnabled: isExpPCPLogin } = usePCPLoginExp();

  return useCallback(async () => {
    if (isServer()) return;

    const formData = form.getValues();
    const isApp = isMobileApp();

    let nextUrl = '';
    if (window.location.href.includes('authv2')) {
      nextUrl = '/';
    } else {
      if (isExpPCPLogin) {
        nextUrl = cartURL;
      } else {
        nextUrl = window.location.href;
      }
    }

    await axios.post('/api/auth/custom/magicLink', {
      email: formData.email,
      nextUrl,
      origin: window.location.origin,
      isApp,
      withResetLink,
    });
    setLoginScreen(
      withResetLink
        ? LoginScreen.forgotPasswordSuccess
        : LoginScreen.magicLinkSuccess
    );
    if (!isApp) {
      sessionStorage.setItem('magicLinkSent', 'yes');
    }
    if (magicLinkSentCount) {
      showToast({
        status: 'success',
        description: i18n.formatMessage({
          id: 'userAccount.magicLinkModal.sent.resentToast',
        }),
      });
    }
    track(
      magicLinkSentCount
        ? withResetLink
          ? TrackingEventName.FORGOT_PASSWORD_FORM_RE_SUBMITTED
          : TrackingEventName.MAGIC_LINK_FORM_RE_SUBMITTED
        : withResetLink
          ? TrackingEventName.FORGOT_PASSWORD_FORM_SUBMITTED
          : TrackingEventName.MAGIC_LINK_FORM_SUBMITTED,
      {
        additionalProps: {
          values: formData,
          retryCount: magicLinkSentCount,
        },
      }
    );
    setMagicLinkSentCount(magicLinkSentCount + 1);
  }, [
    form,
    i18n,
    magicLinkSentCount,
    setLoginScreen,
    setMagicLinkSentCount,
    withResetLink,
  ]);
};
