import { isServer } from './server';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    FS?: any;
  }
}

export const isFullStoryLoaded = () => {
  return !isServer() && window.FS;
};
