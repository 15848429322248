import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type AppStore = {
  /** `true` if client-side-rendered and the hydration is over */
  isReady: boolean;

  /** Set `isReady` to `true` to enable functionality that runs only after hydration */
  setReady(): void;
};

export const useAppStore = create<AppStore>()(
  devtools(
    (set) => ({
      isReady: false,

      setReady() {
        set(() => ({ isReady: true }), false, 'app/setReady');
      },
    }),
    { name: 'app' }
  )
);
