import { BrowserContext, Page } from '@playwright/test';

import { getTestCookies, getTestUrl } from '../helpers/app';
import { generateMockOfNativeSDK } from '../helpers/nativeSDK';

export const getWebViewUrl = (url: string, locale: string, isPortal: boolean) =>
  `${url}${isPortal ? '/portal' : ''}/${locale}`;

export type Message = {
  type: string;
  url?: string;
  value?: string;
  event?: string;
  payload?: any;
};

const configureRequestHeaders = async (page: Page) => {
  await page.route('**/*', async (route) => {
    const url = route.request().url();
    const isPreviewUrl = url.includes('deploy-preview');
    const isProductionUrl =
      url.includes('finn.com') || url.includes('finn.auto');
    const isStagingUrl = url.includes('staging');

    if (url.includes('widget.ultimate.ai')) {
      route.abort();

      return;
    }

    if (isPreviewUrl || isProductionUrl || isStagingUrl) {
      route.continue();
    } else {
      const headers = await route.request().allHeaders();
      // we delete our authorization header to avoid stripe issue
      delete headers.authorization;
      route.continue({ headers });
    }
  });
};

export const createNativeDriver = (
  page: Page,
  screenName: string,
  locale: string,
  context: BrowserContext
) => {
  let messages: Message[] = [];
  const onMessage = (message: string) => {
    const msg = JSON.parse(message);
    // we need to store all received messages to be able to check them
    if (msg.event) {
      console.log(msg.event);
    }
    messages.push(msg);
  };

  const nativeDriver = {
    setup: async (path?: string) => {
      page.setDefaultTimeout(15000);

      await context.addCookies(getTestCookies(locale));

      await configureRequestHeaders(page);

      // iphone SE (temp change to test if 375 px works)
      await page.setViewportSize({ width: 375, height: 812 });
      try {
        await page.exposeFunction('__test__onMessage', onMessage);
      } catch (e) {
        // ignore error
      }
      await page.addInitScript({
        content: await generateMockOfNativeSDK(screenName),
      });
      if (path) {
        await nativeDriver.navigate(path);
      }
    },
    cleanup: () => {
      messages = [];
    },
    navigate: async (path: string) => {
      const isPortal = path.includes('mycars') || path.includes('myaccount');
      const url = `${getWebViewUrl(getTestUrl(), locale, isPortal)}${
        path.includes('/auth') ? `/mobile${path}` : path
      }`;

      await page.goto(url, { timeout: 60000 });
      await page.waitForLoadState();
    },
    get: {
      currentUrl: () => page.url(),
      currentUrlPath: () => page.url().split(locale)[1],
      header: () => page.getByTestId('header'),
      footer: () => page.locator('[data-nativeappid="footer"]'),
      messages: (type?: string) => {
        if (!type) {
          return messages;
        }

        return messages.filter((m) => m.type === type);
      },
      navigationMessages: (url: string | RegExp) => {
        return nativeDriver.get.messages('navigation').filter((m) => {
          if (!m.url) {
            return false;
          }

          if (typeof url === 'string') {
            return m.url?.includes(url);
          }

          return url.test(m.url);
        });
      },
      openDialogMessage: (modalType: string) => {
        return messages.find(
          (m) => m.type === 'event:open_modal' && m.value?.includes(modalType)
        );
      },
    },
    is: {
      ok: (name: string, pageSpecificMessages?: string[]) => {
        try {
          if (messages.length === 0) {
            throw new Error('No messages received');
          }
          const messagesToSee = [
            'ready',
            'event:storage_set_item',
            'event:sdk_initialized',
          ].concat(pageSpecificMessages || []);

          messagesToSee.forEach((type) => {
            if (!messages.some((message) => message.type === type)) {
              throw new Error(`Message ${type} not received`);
            }
          });
        } catch (e) {
          const error = e as Error;
          console.log('Messages received:', messages);
          error.message = `${name}: ${error.message}`;
          throw error;
        }

        return true;
      },
      messagesReceivedInOrder: <T = string>(
        orderedMessages: T[],
        checkEqual: (msg: Message, t: T) => boolean = (msg, t) => msg.type === t
      ) => {
        const messageIndexes = new Set<number>();

        for (const orderedMessage of orderedMessages) {
          const messageIndex = messages.findIndex(
            (recentMessage, index) =>
              checkEqual(recentMessage, orderedMessage) &&
              !messageIndexes.has(index)
          );

          if (messageIndex === -1) {
            console.log('Messages received:', messages);
            throw new Error(
              `Unable to find the ordered message: ${orderedMessage}`
            );
          }

          messageIndexes.add(messageIndex);
        }

        const sortedMessageIndexes = Array.from(messageIndexes).sort(
          (a, b) => b - a
        );

        for (let idx = 0; idx < sortedMessageIndexes.length - 1; idx++) {
          if (sortedMessageIndexes[idx] < sortedMessageIndexes[idx + 1]) {
            throw new Error(
              `Messages received out of order. Expected ${
                orderedMessages[idx]
              } before ${orderedMessages[idx + 1]}.`
            );
          }
        }

        return true;
      },
      eventsReceivedInOrder: (orderedEvents: string[]) =>
        nativeDriver.is.messagesReceivedInOrder(
          orderedEvents,
          (message, event) => message.event === event
        ),
    },
  };

  return nativeDriver;
};
