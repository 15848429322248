import { BaseCosmicData, CosmicMetafield, FearAwayData } from '@finn/ui-cosmic';
import { ApiResponse } from '@finn/ui-utils';
import { createContext } from 'react';

import { Review } from '../types/review';
import {
  FirstVehicleImageAsBase64,
  GenericVehicleDetails,
} from '../types/vehicle';

export type OneTimePrice = { duration: number; price: number };
export type MonthlyPrice = {
  distance: number;
  price: number;
  duration: number;
};

export type ListType = BaseCosmicData & {
  metafields: CosmicMetafield[];
  bucket: string;
  created_at: string;
  created_by: string;
  modified_at: string;
  created: string;
  published_at: string;
  modified_by: string;
  publish_at?: string;
};

type Vehicle = {
  vehicle: GenericVehicleDetails;
  monthlyPrice: MonthlyPrice;
  /** This naming is not accurate. This value represents the monthly base price
   * based on a fixed subscription length (aka "subscription term"). E.g., 800
   * EUR per month based on a 6 months subscription */
  monthlyPriceWithFees: number;
  oneTimePrice: OneTimePrice;
  setOneTimePrice: (value: OneTimePrice) => void;
  setMonthlyPrice: (value: MonthlyPrice) => void;
  fearAwayItems: FearAwayData[];
  firstVehicleImageAsBase64?: FirstVehicleImageAsBase64;
  term?: number;
  kmPackage?: number;
  reviews?: ApiResponse<Review>;
};

export const VehicleContext = createContext<Vehicle>({} as Vehicle);
export type VehicleContextType = Vehicle;
