const { join } = require('path');
const colors = require('./src/tokens/colors/colors');
const {
  fontSize,
  fontWeight,
  fontFamily,
  typography,
} = require('./src/atoms/typography/fonts');
const { defineContainer } = require('./src/atoms/container/container');
const plugin = require('tailwindcss/plugin');

/** @type {import('tailwindcss').Config} */
module.exports = {
  // TODO mid term we want this theme to become source of truth
  theme: {
    screens: {
      xs: 0,
      mobile: '375px',
      sm: '600px',
      md: '1120px',
      lg: '1440px',
      xl: '1920px',
      max: '2100px',
    },
    colors,
    // TODO after typography migration
    // we need to disable possibility to set predefined
    // sizes/weight/lineHeight because we want to use our own predefined
    // combinations of this values that you can find in plugin section
    fontSize,
    fontWeight,
    lineHeight: {},
    fontFamily,
    container: {
      center: true,
    },
    extend: {
      scale: {
        98: '0.98',
      },
      spacing: {
        ckoLeftCol: '404px',
        ckoRightCol: '516px',
      },
      gap: {
        19: '4.5rem',
      },
      boxShadow: {
        black: `0 0 0 1px ${colors.black}`,
        pearl: `0 0 0 1px ${colors.pearl}`,
        pewter: `0 0 0 1px ${colors.pewter}`,
        red2: `0 0 0 2px ${colors.red}`,
        iron2: `0 0 0 2px ${colors.iron}`,
        black2: `0 0 0 2px ${colors.black}`,
        inset1: `inset 0 0 0 1px ${colors.black}`,
        inset1White: `inset 0 0 0 1px ${colors.white}`,
        inset2: `inset 0 0 0 2px ${colors.black}`,
        inset1Red: `inset 0 0 0 1px ${colors.red}`,
        inset1Iron: `inset 0 0 0 1px ${colors.iron}`,
        inset1Pearl: `inset 0 0 0 1px ${colors.pearl}`,
        inset1Steel: `inset 0 0 0 1px ${colors.steel}`,
        inset1Cotton: `inset 0 0 0 1px ${colors.cotton}`,
        inset1Pewter: `inset 0 0 0 1px ${colors.pewter}`,
        inset2Black: `inset 0 0 0 2px ${colors.black}`,
        inset2TrustedBlue: `inset 0 0 0 2px ${colors.trustedBlue}`,

        // TODO shadows for highlight not yet defined in DS APP-1445
        highlight: `0px 0px 15px rgba(112, 112, 112, 0.08)`,
        highlightPewter: `0px 0px 15px ${colors.pewter}`,

        // pricing hack, to be remove after design aligned TODO
        pricing: `0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1)`,
      },
      gridTemplateAreas: {
        // edge case because rows 2-3 are merged in 1st column
        'myaccount-5': ['contact company', 'payment account', 'payment email'],
        'slim-myaccount-5': [
          'contact',
          'company',
          'payment',
          'account',
          'email',
        ],
        'myaccount-4': ['contact section', 'account email'],
        'slim-myaccount-4': ['contact', 'section', 'account', 'email'],
        // edge because rows 1-2 are merged in 1st column
        'myaccount-3': ['contact account', 'contact email'],
        'slim-myaccount-3': ['contact', 'account', 'email'],
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        scale: {
          '0%': { transform: 'scale(0)' },
          '100%': { transform: 'scale(1)' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        scale: 'scale 0.5s ease-in-out',
      },
    },
  },
  css: './tailwind.setup.css',
  content: [
    join(__dirname, '../../apps', '*/**/*!(*.stories|*.spec).{ts,tsx,html}'),
    join(__dirname, '..', '*/**/*!(*.stories|*.spec).{ts,tsx,html}'),
    join(__dirname, '..', 'ua-header/src/**/!(*.stories|*.spec).{ts,tsx,html}'),
    join(__dirname, '..', 'ua-footer/src/**/!(*.stories|*.spec).{ts,tsx,html}'),
    join(
      __dirname,
      '..',
      'ui-modules/src/**/!(*.stories|*.spec).{ts,tsx,html}'
    ),
  ],
  plugins: [
    require('tailwindcss-animate'),
    require('@savvywombat/tailwindcss-grid-areas'),
    plugin(({ addUtilities }) => {
      addUtilities(typography());
    }),
    defineContainer,
  ],
};
