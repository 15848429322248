import { getGlobalObject } from './globalObject';

export const isColorChangedFromTheSameVehicle = (
  params: string | { pathname?: string }
) => {
  const globalObject = getGlobalObject();
  const url = typeof params === 'string' ? params : params?.pathname;
  const currentUrl = globalObject?.next?.router?.state?.asPath;

  if (!url || !currentUrl) {
    return false;
  }

  const [url1WithoutColor, url2WithoutColor] = [url, currentUrl].map((id) => {
    const urlWithoutColor = id.split('-');
    urlWithoutColor.pop();

    return urlWithoutColor.join('-').replace('/mobile', '');
  });

  return url1WithoutColor === url2WithoutColor;
};
