'use client';
// https://www.figma.com/design/pBYx9LLywGHZ4bfaRMQnaA/005-Accordion?node-id=816-5809&node-type=frame&t=Q00KUGd3Ul63bc6Q-0
import { cn } from '@finn/ui-utils';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import * as React from 'react';

import { Slot } from '../../internal/Slot';
import { ExpandMore } from '../icons/generated/expand-more';

const Accordion = AccordionPrimitive.Root;

const AccordionTitle = ({
  children,
  asChild,
  ...props
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'h3';

  return (
    <Comp className="mobile-t3-semibold md:web-t3-semibold" {...props}>
      {children}
    </Comp>
  );
};

const AccordionSubtitle = ({
  children,
  asChild,
  ...props
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'h4';

  return (
    <Comp className="body-16-light" {...props}>
      {children}
    </Comp>
  );
};

const AccordionHeader = ({
  children,
  asChild,
  ...props
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp className="mb-6 flex flex-col gap-4" {...props}>
      {children}
    </Comp>
  );
};

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={className} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    withBorder?: boolean;
  }
>(({ className, children, withBorder = true, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'data-[state=closed]:border-pearl body-16-semibold disabled:text-steel disabled:fill-steel box-border flex flex-1 items-center justify-between border-0 border-solid border-transparent bg-transparent px-0 py-[26px] text-left transition-transform before:hidden after:hidden [&>.expand-more]:disabled:hidden [&[data-state=open]>svg]:rotate-180',
        withBorder && 'border-b',
        className
      )}
      {...props}
    >
      {children}
      <ExpandMore className="expand-more min-h-6 min-w-6 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    alwaysMounted?: boolean;
  }
>(({ className, children, alwaysMounted = true, ...props }, ref) => {
  const refFallback = React.useRef<HTMLDivElement>(null);
  const rootRef = (ref || refFallback) as React.RefObject<HTMLDivElement>;

  // sadly radix accordion does not support animations on the content height
  // when forceMount used, so we emulate it with mutation observer by listening
  // to state attribute and manually updateing css var https://github.com/radix-ui/primitives/discussions/1481
  React.useEffect(() => {
    const element = rootRef.current;
    const handleStateChange = () => {
      if (element?.getAttribute('data-state') === 'open') {
        element?.style?.setProperty?.(
          '--radix-accordion-content-height',
          `${element?.scrollHeight - 5}px`
        );
      }
    };

    const observer = new MutationObserver(handleStateChange);
    if (element && alwaysMounted) {
      handleStateChange();
      observer.observe(element, {
        attributes: true,
        attributeFilter: ['data-state'],
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [rootRef, alwaysMounted]);

  return (
    <AccordionPrimitive.Content
      ref={rootRef}
      forceMount={alwaysMounted || undefined}
      className={cn(
        'body-16-light data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden transition-all',
        { 'data-[state=closed]:h-0': alwaysMounted }
      )}
      {...props}
    >
      <div className={cn('pb-4 pt-1', className)}>{children}</div>
    </AccordionPrimitive.Content>
  );
});

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export {
  Accordion,
  AccordionTitle,
  AccordionSubtitle,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
};
