import { Spinner } from '@finn/design-system/atoms/spinner';
import { cn } from '@finn/ui-utils';

type LoadingBoxParams = {
  className?: string;
  content?: string;
};

export const LoadingBox = ({ className, content }: LoadingBoxParams) => (
  <>
    <Spinner className="h-12 w-12 fill-inherit" />
    <h4 className={cn(className, 'mobile-t4-semibold md:web-t4-semibold')}>
      {content}
    </h4>
  </>
);
