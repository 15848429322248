import { IntlShape } from 'react-intl';

import { ContractTermType } from '../types/finance';

export const getContractTermType = (term: number) =>
  term === 1 ? ContractTermType.OPEN : ContractTermType.FIXED;

export const getLabeledTerm = (term: number, intl: IntlShape): string => {
  const label = term === 1 ? 'general.termLabelOne' : 'general.termLabelOther';

  return intl.formatMessage(
    { id: label },
    {
      duration: term,
    }
  );
};

export const getTermLabel = (
  term: number,
  flexTermLabel: string,
  intl: IntlShape
): string => {
  const isFixedTerm = getContractTermType(term) === ContractTermType.FIXED;

  const termLabel = isFixedTerm
    ? getLabeledTerm(term, intl)
    : String(flexTermLabel || term);

  return termLabel;
};
