import { EmitEvent } from '../../types/general';

export const dom = (emitEvent: EmitEvent) => ({
  all: {
    closeTooltip: () => {
      document
        .querySelector<HTMLButtonElement>('[role=tooltip] button')
        ?.click?.();
    },
    closeDialog: () => {
      document
        .querySelector<HTMLButtonElement>(
          'div:not([style*="visibility: hidden"]) [role=dialog] button'
        )
        ?.click?.();
      document
        .querySelector<HTMLButtonElement>(
          '[data-appid="image-gallery-mobile-close-button"]'
        )
        ?.click?.();
    },
    fecthCachedZipcode: () => {
      try {
        const text = JSON.parse(window.localStorage.getItem('location') || '')
          ?.state?.zipCode;
        if (text) {
          const zipcode = parseInt(text, 10);
          emitEvent({ type: 'cached_zipcode', value: zipcode });
        }
      } catch (e) {
        emitEvent({ type: 'cached_zipcode', value: undefined });
        // ignore
      }
    },
    fetchZipcode: () => {
      const text = document?.querySelector?.('button p')?.innerHTML;
      if (text) {
        const zipcode = parseInt(text, 10);
        emitEvent({ type: 'zipcode', value: zipcode });
      } else {
        emitEvent({ type: 'zipcode' });
      }
    },
  },
  pdp: {
    showContinueFooter: () => {
      const el = document.querySelector<HTMLElement>(
        '[data-appid="sticky-bar"]'
      );
      if (el) {
        el.style.display = 'block';
      }
    },
    hideContinueFooter: () => {
      const el = document.querySelector<HTMLElement>(
        '[data-appid="sticky-bar"]'
      );
      if (el) {
        el.style.display = 'none';
      }
    },
    closeFullScreenGallery: () => {
      document
        .querySelector<HTMLButtonElement>(
          "[data-appid='image-gallery-mobile-close-button']"
        )
        ?.click?.();
    },
  },
});
