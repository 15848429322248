import { DEFAULT_DOWN_PAYMENT } from '@finn/ua-constants';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import CheckoutContext from '~/contexts/Checkout';
import { isValidDownPaymentAmount } from '~/modules/checkout/utils/downpayment';

// when called from outside of checkout context, we need to send isCartExt = true if it's cart
export const useGetDownPaymentInCart = (isCartExt = false) => {
  const { isCart } = useContext(CheckoutContext);
  const router = useRouter();
  const downPaymentAmountInUrl = router.query.downPaymentAmount as string;
  const hasValidDownPaymentInCartUrl =
    (isCart || isCartExt) && isValidDownPaymentAmount(downPaymentAmountInUrl);

  return {
    hasValidDownPaymentInCartUrl,
    downPaymentAmountInCart: hasValidDownPaymentInCartUrl
      ? DEFAULT_DOWN_PAYMENT
      : undefined,
  };
};
