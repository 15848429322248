import { createContext, FC, ReactNode } from 'react';

export type HeaderContextData = {
  CartButton?: FC;
};

export type HeaderProviderProps = {
  CartButton?: FC;
  children?: ReactNode;
};

export const HeaderContext = createContext<HeaderContextData>({});

// this context sohuld help solve circular dependency issues.
// inject dependencies from applications using this context
export const HeaderProvider = ({
  children,
  CartButton,
}: HeaderProviderProps) => {
  return (
    <HeaderContext.Provider
      value={{
        CartButton,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
