import { Button } from '@finn/design-system/atoms/button';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { CtaType } from '@finn/ui-cosmic';
import { cn, parseToHtml, useCurrentApp } from '@finn/ui-utils';
import React from 'react';

import {
  TypographyWithAutoLink,
  TypographyWithAutoLinkProps,
} from '../../components/TypographyWithAutoLink';

type FlipperContentProps = {
  title: string;
  description: string;
  notice?: string;
  cta_text: string;
  cta_href: string;
  cta_type: CtaType;
  reverse: boolean;
  header_size: string;
  trackOnButtonClick?(): void;
  row?: boolean;
  isLoading?: boolean;
  hasImage: boolean;
  cta_tracking_location?: string;
};

const FlipperContent = ({
  title,
  description,
  notice,
  cta_text,
  cta_href,
  cta_type,
  reverse,
  header_size,
  trackOnButtonClick,
  row,
  isLoading,
  hasImage,
  cta_tracking_location,
}: FlipperContentProps) => {
  const app = useCurrentApp();
  const onClick = trackOnButtonClick
    ? trackOnButtonClick
    : () => {
        interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
          location: cta_tracking_location,
        });
      };

  const buildFlipperContent = () => {
    return (
      <>
        <div className="body-16-light" data-appid="flipper-module-content">
          {parseToHtml(description)}
        </div>
        {!hasImage && (
          <>
            {notice && <div className="body-16-light mt-8">{notice}</div>}
            {cta_text && (
              <div className="mt-8">
                {/* Need to lowercase here, since cosmic is wrongly returning key_value */}
                {cta_type.value.toLowerCase() === 'button' ? (
                  <Button
                    variant="primary"
                    href={cta_href}
                    onClick={onClick}
                    loading={isLoading}
                  >
                    {cta_text}
                  </Button>
                ) : (
                  cta_text //Insert link component here instead
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={cn(
          'mb-14 md:order-1 md:grid md:grid-cols-[5fr,1fr]',
          reverse ? 'md:order-2 md:grid-cols-[1fr,5fr]' : ''
        )}
      >
        <div className={cn('col-start-1', reverse ? 'col-start-2' : '')}>
          <TypographyWithAutoLink
            className={cn('mb-6 sm:mb-8', app === 'cp' ? 'md:!mt-0' : '')}
            linkText={title}
            variant={
              (header_size as TypographyWithAutoLinkProps['variant']) || 'h3'
            }
          >
            {parseToHtml(title)}
          </TypographyWithAutoLink>
          {(!row || hasImage) && buildFlipperContent()}
        </div>
      </div>

      {row && !hasImage && (
        <div className={cn('mb-14 md:order-2 md:grid md:grid-cols-[1fr]')}>
          {buildFlipperContent()}
        </div>
      )}
    </>
  );
};

export default FlipperContent;
