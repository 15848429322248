export const savePushToken = async ({
  contactId,
  expoToken,
  deviceToken,
}: {
  contactId: string;
  expoToken: string;
  deviceToken: string;
}) => {
  await fetch(`/api/savePushToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      contact_id: contactId,
      push_token: expoToken,
      device_push_token: deviceToken,
    }),
  });
};
