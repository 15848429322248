import { MutableRefObject, useEffect, useState } from 'react';

require('intersection-observer');

export const MAX_SCREEN_SIZE = '4000px'; // not importing from finn atom as it's creating a circular dependency

export const useIntersectionObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  threshold = 0,
  rootMargin = `${MAX_SCREEN_SIZE} ${MAX_SCREEN_SIZE} ${MAX_SCREEN_SIZE} ${MAX_SCREEN_SIZE}`,
  unobserve = false
) => {
  const [isInView, setInView] = useState(false);

  useEffect(() => {
    const current = ref.current;
    if (!current) {
      return;
    }

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const isIntersecting = entries.length > 0 && entries[0].isIntersecting;
        setInView(isIntersecting);
        if (entries.length > 0 && !entries[0].isIntersecting) return;
        if (unobserve) intersectionObserver.unobserve(current);
      },
      { threshold, rootMargin }
    );
    intersectionObserver.observe(current);

    return () => {
      if (current) {
        intersectionObserver.unobserve(current);
      }
    };
  }, [ref, threshold, rootMargin]);

  return isInView;
};
