import Parser from 'html-react-parser';
import { ReactNode } from 'react';

// Parser method throw an error when the content not a string
// This function jus to catch the error to prevent the broken pages
export const parseToHtml = (
  htmlContent: string
): ReactNode | ReactNode[] | string => {
  try {
    return Parser(htmlContent) as unknown as ReactNode | ReactNode[];
  } catch (e) {
    return '';
  }
};

export const getAnchorTarget = (href: string) => {
  return href.startsWith('http') ? '_blank' : '_self';
};

const SCROLL_TOP_OFFSET = 30;
export const scrollToTarget = (target: HTMLElement | null) => {
  if (!target) return;
  const targetPosition = target.getBoundingClientRect().top;
  const offsetPosition = targetPosition + window.scrollY - SCROLL_TOP_OFFSET;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const scrollToTopWithoutAnimation = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
};

export const targetElementTopAboveViewport = (
  target: HTMLElement | null
): boolean | null => {
  if (!target) {
    return null;
  }
  const targetTop = target.getBoundingClientRect().top;
  if (targetTop < 0) {
    return true;
  }

  return false;
};

export const targetElementTopBeyondThreshold = (
  target: HTMLElement | null,
  thresholdPercentage = 50
): boolean | null => {
  if (!target) {
    return null;
  }
  const targetTop = target.getBoundingClientRect().top;
  const windowHeight = window.innerHeight;
  const targetTopPercentage = (targetTop * 100) / windowHeight;

  // if thresholdPercentage is 50, then targetTopPercentage needs to be
  // greater than 50 to be beyond the threshold which will trigger the auto scroll
  return targetTopPercentage > thresholdPercentage;
};
