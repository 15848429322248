import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import Cookies from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

import config from './config';
import { CookieKeys, getServerCookie } from './cookies';
import { isServer } from './server';

type TeaceabilityHeadersParams = {
  finnSessionID?: string;
  finnActor?: string;
};
const constructTraceabilityHeaders = ({
  finnSessionID = '',
  finnActor = config.UA_FE_ACTOR,
}: TeaceabilityHeadersParams): Record<string, string> => {
  return {
    'X-Finn-Session-Id': finnSessionID,
    'X-Finn-Actor': finnActor,
  };
};
export const traceabilityHeadersBrowser = (): Record<string, string> => {
  if (isServer())
    return constructTraceabilityHeaders({ finnActor: config.UA_FE_SSR_ACTOR });
  const finnSessionID = Cookies.get(CookieKeys.X_FINN_SESSION_ID);

  return constructTraceabilityHeaders({ finnSessionID: finnSessionID || '' });
};
export const traceabilityHeadersContext = (
  ctx: GetServerSidePropsContext
): Record<string, string> => {
  const finnSessionID = getServerCookie(
    CookieKeys.X_FINN_SESSION_ID,
    ctx?.req?.headers
  );

  return constructTraceabilityHeaders({ finnSessionID: finnSessionID || '' });
};

export const REQUEST_ID_HEADER_NAME = 'X-Finn-Request-Id';

export class APIError<R> extends Error {
  responseBody: R | null;
  responseText: string | null;
  requestId?: string;

  public constructor(
    message: string,
    responseBody: R | null,
    responseText: string | null,
    requestId?: string
  ) {
    super(message);
    this.responseBody = responseBody;
    this.responseText = responseText;
    this.requestId = requestId;
  }
}

const DEFAULT_REQUEST_TIMEOUT = 30000;

/**
 * Generate a random number that is used as an ID of a request
 * to make it traceable in Datadog.
 *
 * @returns a string that represents a random ID
 */
export function generateRequestId(): string {
  const date = new Date().getTime().toString(36);
  const seed = Math.random().toString(36).substring(2, 9);

  return `${date}${seed};`;
}

export type FetcherRequestPayload = {
  /**
   * String is always expected to have an origin,
   * however when passed into an instance of `createFetcher`, it can be omitted.
   */
  url: string | URL;
  method?: Method;
  headers?: Record<string, unknown>;
  query?: Record<string, unknown>;
  body?: Record<string, unknown>;
  rawBody?: unknown;
} | null;

export function makeAxiosArgs(
  requestPayload: FetcherRequestPayload
): AxiosRequestConfig {
  if (requestPayload === null) return {};
  const requestId = generateRequestId();
  const requestURL = new URL(requestPayload.url);

  // requestId is used to track a request in Datadog and Sentry
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    [REQUEST_ID_HEADER_NAME]: requestId,
  };

  if (requestPayload.headers) {
    Object.keys(requestPayload.headers).map((headerKey) => {
      const headerValue = requestPayload?.headers?.[headerKey];

      if (headerValue !== undefined && headerValue !== null) {
        headers[headerKey] = String(requestPayload.headers?.[headerKey]);
      }
    });
  }

  if (requestPayload.query) {
    Object.keys(requestPayload.query).map((queryKey) => {
      const queryValue = requestPayload.query?.[queryKey];

      if (queryValue !== undefined && queryValue !== null) {
        requestURL.searchParams.set(queryKey, String(queryValue));
      }
    });
  }

  return {
    url: requestURL.toString(),
    method: requestPayload.method ?? 'GET',
    headers,
    timeout: DEFAULT_REQUEST_TIMEOUT,
    data:
      (requestPayload.rawBody as BodyInit) ??
      (requestPayload.body ? JSON.stringify(requestPayload.body) : null),
  };
}

/**
 * Fetcher is a function that returns a Promise with requested value.
 * It is expected to be used either with SWR or without it.
 * Currently, it uses the browser Fetch API to implement calls,
 * however the Fetcher API is abstracted from a specific library,
 * and it can be replaced in the future.
 */
type Fetcher = {
  <Response>(requestPayload: FetcherRequestPayload): Promise<Response>;
  (requestPayload: null): Promise<null>;
};

type FetcherConfig = {
  baseURL: string;

  /**
   * Credential is not supported if the CORS header 'Access-Control-Allow-Origin' is '*'
   * More here: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
   */
  withCredentials: boolean;
};

export function createFetcher({
  baseURL,
  withCredentials,
}: FetcherConfig): Fetcher {
  const isRemoteService = baseURL.indexOf('http') === 0;
  const urlOrigin =
    (!isRemoteService && !isServer() ? window.origin : '') + baseURL;

  return async <Response, Error = unknown>(
    requestPayload: FetcherRequestPayload
  ) => {
    if (requestPayload === null) {
      return null;
    }

    const request = makeAxiosArgs({
      ...requestPayload,
      headers: {
        ...requestPayload.headers,
        ...traceabilityHeadersBrowser(),
      },
      url: new URL(requestPayload.url, urlOrigin).toString(),
    });

    const requestId = request.headers[REQUEST_ID_HEADER_NAME] as string;

    try {
      const { data } = await axios.request<Response>({
        ...request,
        withCredentials,
      });

      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      if (request.headers['Content-Type'] === 'application/json') {
        throw new APIError<Error>('APIError', response?.data, null, requestId);
      } else {
        throw new APIError<Error>('APIError', null, response?.data, requestId);
      }
    }
  };
}
