import { useDeals } from '@finn/platform-modules';
import { UserAccountContext, UserAccountModal } from '@finn/ua-auth';
import { SelfServiceRoutes, SHOW_LOYALTY_MENU_ITEM } from '@finn/ua-constants';
import {
  interactionTrackingEvent,
  NavItem,
  TrackingEventName,
  USER_ACCOUNT_EVENTS,
} from '@finn/ua-tracking';
import { NavbarData } from '@finn/ui-cosmic';
import { useCurrentLocale, useSession } from '@finn/ui-utils';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { trackNavItemClicked } from '../../tracking';
import SubMenuElement from './SubMenuElement';

declare module 'next-auth' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface Session {
    user: {
      name: string;
      email: string;
      image: string;
      email_verified: string;
      hs_contact_id?: string;
    };
    expires?: string;
  }
}

export const DropdownLinksList: React.FunctionComponent<{
  data: NavbarData['userAccountLinks']['dropdownOptions'];
}> = ({ data }) => {
  const [session] = useSession();
  const i18n = useIntl();

  const isVerified = !!session?.user.email_verified;
  const contactId = session?.user?.hs_contact_id;
  const shouldFetch = !!contactId && isVerified;
  const { deals } = useDeals({ shouldFetch });
  const { setModalStatus } = useContext(UserAccountContext);
  const { isDE, locale } = useCurrentLocale();
  const LoyaltyMenuLabel = i18n.formatMessage({
    id: 'userAccount.dropdown.loyalty',
  });

  const portalBaseUrl = `https://${window.location.host}/portal/${locale}`;
  const isUserVerified = Boolean(session?.user.email_verified);
  const hasDeals = Boolean(deals?.length);
  const shouldShowLoyaltyLink = SHOW_LOYALTY_MENU_ITEM && isDE && hasDeals;
  const hasActiveOrExpiredSubs = useMemo(
    () => Boolean(deals?.some((deal) => deal.variant === 'SUBSCRIPTION')),
    [deals]
  );
  const shouldShowMyProfileLink = isUserVerified;
  const shouldShowMyDriversLink =
    isUserVerified && isDE && hasActiveOrExpiredSubs;
  const shouldShowServicesLink =
    isUserVerified && isDE && hasActiveOrExpiredSubs;

  const handleMyOrderClick = (
    label: string,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    if (!isUserVerified) {
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFICATION,
      });
      e.preventDefault();

      return;
    }
    if (isDE) {
      interactionTrackingEvent(TrackingEventName.USER_ACCOUNT, {
        action: USER_ACCOUNT_EVENTS.action.ACCOUNT_INTERACTION,
        category: USER_ACCOUNT_EVENTS.category,
        label,
      });
    }
  };

  return (
    <>
      {data.my_cars && (
        <SubMenuElement
          href={`${portalBaseUrl}${SelfServiceRoutes.MY_CARS}`}
          onClick={(e) => {
            handleMyOrderClick(USER_ACCOUNT_EVENTS.label.MY_ORDER_CLICKED, e);

            trackNavItemClicked({
              option: NavItem.MY_CARS,
              is_customer: hasDeals,
            });
          }}
          label={data.my_cars}
          weight="semibold"
        />
      )}
      {shouldShowServicesLink && data.technical_support && (
        <SubMenuElement
          href={`${portalBaseUrl}${SelfServiceRoutes.SERVICES}`}
          onClick={(e) => {
            handleMyOrderClick(USER_ACCOUNT_EVENTS.label.SUPPORT_CLICKED, e);

            trackNavItemClicked({
              option: NavItem.TECHNICAL_SUPPORT,
              is_customer: hasDeals,
            });
          }}
          label={data.technical_support}
          weight="semibold"
        />
      )}
      {shouldShowMyProfileLink && data.my_profile && (
        <SubMenuElement
          href={`${portalBaseUrl}${SelfServiceRoutes.MYACCOUNT}`}
          onClick={() => {
            trackNavItemClicked({
              option: NavItem.MY_PROFILE,
              is_customer: hasDeals,
            });
          }}
          label={data.my_profile}
          weight="semibold"
        />
      )}
      {shouldShowMyDriversLink && data.my_drivers && (
        <SubMenuElement
          href={`${portalBaseUrl}${SelfServiceRoutes.DRIVER_MGMT}`}
          onClick={() => {
            trackNavItemClicked({
              option: NavItem.MY_DRIVERS,
              is_customer: hasDeals,
            });
          }}
          label={data.my_drivers}
          weight="semibold"
        />
      )}
      {shouldShowLoyaltyLink && (
        <SubMenuElement
          href={`${portalBaseUrl}${SelfServiceRoutes.LOYALTY}`}
          onClick={() => {
            trackNavItemClicked({
              option: NavItem.LOYALTY,
              is_customer: hasDeals,
            });
          }}
          label={LoyaltyMenuLabel}
          weight="semibold"
        />
      )}
    </>
  );
};

export default DropdownLinksList;
