export { checkPhoneNumberValidation } from './lib/Forms/yup';
export { ResetPasswordForm } from './lib/Forms/ResetPasswordForm';
export { passwordRegex, validatePassword } from './lib/Forms/validation';
export {
  PasswordInput,
  PasswordStrengthCheck,
} from './lib/Forms/PasswordInput';
export {
  isValidPhone,
  validNameRegex,
  validCompanyNameRegex,
  validGermanZipCode,
  validateFile,
} from './lib/Forms/yup';
export { useSignOut } from './lib/useSignOut';
export {
  clearServerCookie,
  setNextServerCookie,
  setNextServerBooleanCookie,
} from './lib/cookies';
export { checkAccountSwitch } from './lib/checkAccountSwitch';
export { getHubspotDealInfo } from './lib/hubspot';
export { getSlugsWithoutStep } from './lib/pathHelper';
export {
  refreshCode,
  verifyCode,
  fetchAuthToken,
  login,
  register,
  loginWithCode,
  resendVerification,
  loginWithToken,
  signOut,
  loginWithMagicLink,
  fetchSession,
  changePassword,
} from './lib/auth';
export { CheckoutStep } from './lib/pathHelper';
export { UserAccountModal } from './lib/Modals/modalTypes';
export { GoogleOneTap } from './lib/GoogleOneTap';
export {
  UserAccountContext,
  UserAccountProvider,
  type UserAccount,
  type IModal,
} from './lib/UserAccount';
export { SessionContext } from './lib/session';
export * from './lib/profile';
export type Breadcrumb = {
  title: string;
  url?: string;
};
