import '../../sdk';

import { LoadingBox } from '@finn/ua-app/components/LoadingBox';
import { savePushToken } from '@finn/ua-app/helpers/savePushToken';
import { adjustLinkTargetsforWebView } from '@finn/ua-app/helpers/webViewHelpers';
import {
  refreshCode,
  UserAccountContext,
  UserAccountModal,
  verifyCode,
} from '@finn/ua-auth';
import { useOnModalClose, useOnModalOpen } from '@finn/ua-modals';
import { captureMessage, UserAccountSignIn, useSession } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { SignOutResponse } from 'next-auth/react';
import { parse } from 'query-string';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { getAppSDK } from '../../helpers/mobileApp';
import { NativeSDK } from '../../types/general';
import { AppStyles } from './AppStyles';

const getAuthTokenFromQueryByPathname = (
  nextQuery: string,
  pathname: string
) => {
  if (nextQuery) {
    const parsedQuery = parse(nextQuery);

    if (pathname?.includes('subscribe')) {
      return parsedQuery.urlParams;
    }

    return parsedQuery.authToken;
  }

  return null;
};

const sendAuthenticationStatus = (
  session: ReturnType<typeof useSession>['0'] | null
) => {
  const appSDK = getAppSDK();
  if (appSDK) {
    appSDK.sendMessageToApp({
      type: 'event:user_authenticated',
      value: session,
    });
  }
};

const sendUserSession = (
  session: ReturnType<typeof useSession>['0'] | null
) => {
  const appSDK = getAppSDK();
  if (appSDK) {
    appSDK.sendMessageToApp({
      type: 'event:user_session',
      value: session,
    });
  }
};

const sendSignOutSuccessful = () => {
  const appSDK = getAppSDK();
  if (appSDK) {
    appSDK.sendMessageToApp({
      type: 'event:signout_successful',
    });
  }
};

const sendLoginWithTokenSuccessful = () => {
  const appSDK = getAppSDK();
  if (appSDK) {
    appSDK.sendMessageToApp({
      type: 'event:login_with_token_successful',
    });
  }
};

type MobileAppRootLayoutProps = {
  modal?: string | null;
  login?: NativeSDK['signIn'];
  signOut?: () => Promise<SignOutResponse>;
  loginWithCode?: NativeSDK['signInWithCode'];
  loginWithToken?: (token: string) => Promise<UserAccountSignIn>;
  children: ReactNode | string;
};

export const MobileAppRootLayout = ({
  modal,
  login,
  signOut,
  loginWithCode,
  loginWithToken,
  children,
}: MobileAppRootLayoutProps) => {
  const i18n = useIntl();
  const [session, isSessionLoading] = useSession();
  const { modalStatus, setModalStatus } = useContext(UserAccountContext);
  const isModalOpenRef = useRef(false);
  const { pathname, reload } = useRouter();
  const [signInWithTokenLoading, setSignInWithTokenLoading] = useState(false);

  const userContextModalName =
    modalStatus?.isModalOpen && modalStatus?.modalType;
  const modalName = modal || userContextModalName;

  const handleSignOut = useCallback(async () => {
    if (signOut) {
      await signOut();
      sendSignOutSuccessful();
    }
  }, [signOut]);

  const handleSignInWithToken = useCallback(
    async (token: string) => {
      if (loginWithToken) {
        const res = await loginWithToken(token);
        if (res.ok) {
          sendLoginWithTokenSuccessful();
        }
      }
    },
    [loginWithToken]
  );

  useEffect(() => {
    const appSDK = getAppSDK();
    // on android app links with target _blank will open in a new browser window
    // which is not desired behaviour, as we want to have native navigation
    // in order to avoid this we are changing target for all links on a page
    if (appSDK?.getTrackingProps()?.app_platform === 'android') {
      adjustLinkTargetsforWebView();
    }
    appSDK?.sendMessageToApp({ type: 'event:sdk_initialized' });

    if (!appSDK) {
      captureMessage(new Error('Native SDK not found in MobileAppRootLayout'));
      getAppSDK((sdk) => {
        sdk?.sendMessageToApp({ type: 'event:sdk_initialized' });
      });
    }
  }, []);

  useEffect(() => {
    const appSDK = getAppSDK();
    if (appSDK) {
      appSDK.signIn = login;
      appSDK.signOut = handleSignOut;
      appSDK.signInWithCode = loginWithCode;
      appSDK.signInWithToken = handleSignInWithToken;
      appSDK.savePushToken = savePushToken;
    }
  }, [login, loginWithCode, handleSignOut, handleSignInWithToken]);

  useEffect(() => {
    const autoLogin = async () => {
      const queryStr = window.location.href.split('?')?.[1];

      if (queryStr) {
        const authToken = getAuthTokenFromQueryByPathname(queryStr, pathname);

        if (authToken && typeof authToken === 'string') {
          setSignInWithTokenLoading(true);
          await handleSignInWithToken(authToken);
          reload();
          setSignInWithTokenLoading(false);
        }
      }
    };
    if (!session && !isSessionLoading) {
      autoLogin();
    }
  }, [handleSignInWithToken, isSessionLoading, pathname, reload, session]);

  useEffect(() => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    appSDK.getAuthenticationStatus = () => {
      sendAuthenticationStatus(session);
    };
    appSDK.fetchUserSession = () => {
      sendUserSession(session);
    };

    appSDK.showVerifyEmailModal = () => {
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFICATION,
      });
    };

    appSDK.verifyEmail = async (verificationCode: string) => {
      const result = await verifyCode(verificationCode);

      if (result.ok) {
        await loginWithCode?.(verificationCode);
        await refreshCode();
        setModalStatus({
          isModalOpen: true,
          modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY,
        });
      }
    };
  }, [loginWithCode, session, setModalStatus]);

  useEffect(() => {
    if (!isSessionLoading) {
      sendAuthenticationStatus(session);
    }
  }, [session, isSessionLoading]);

  useOnModalOpen((modalKey) => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    appSDK.sendMessageToApp?.({
      type: 'event:open_modal',
      value: modalKey || userContextModalName,
    });

    isModalOpenRef.current = true;
  });

  useOnModalClose((modalKey) => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    appSDK.sendMessageToApp?.({
      type: 'event:close_modal',
      value: modalKey || userContextModalName,
    });

    isModalOpenRef.current = false;
  });

  useEffect(() => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    if (modalName && !isModalOpenRef.current) {
      appSDK.sendMessageToApp?.({
        type: 'event:open_modal',
        value: modalName,
      });

      isModalOpenRef.current = true;
    } else if (isModalOpenRef.current) {
      appSDK.sendMessageToApp?.({
        type: 'event:close_modal',
      });

      isModalOpenRef.current = false;
    }
  }, [modalName]);

  return (
    <>
      <AppStyles />
      {signInWithTokenLoading ? (
        <div className="flex justify-center">
          <div className="fixed flex h-full w-full max-w-[400px] flex-col items-center justify-between">
            <div className="mx-10 mb-0 mt-32 flex flex-col items-center justify-center text-black">
              <LoadingBox
                className="mt-6 text-center"
                content={i18n.formatMessage({
                  id: 'mobile.screenLoading',
                })}
              />
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
