import { cn } from '@finn/ui-utils';
import { ComponentType, forwardRef, useCallback } from 'react';

import { EmptyComponent } from '../../internal/EmptyComponent';
import { Button } from '../button';
import { CheckCircleOutlined } from '../icons/generated/check-circle-outlined';
import { Close } from '../icons/generated/close';
import { ErrorOutlined } from '../icons/generated/error-outlined';
import { InfoOutlined } from '../icons/generated/info-outlined';
import { WarningFilled } from '../icons/generated/warning-filled';

const statusIconMap = {
  success: CheckCircleOutlined,
  error: ErrorOutlined,
  warning: WarningFilled,
  info: InfoOutlined,
};

export type ToastProps = {
  id?: string;
  status: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  description?: string;
  Icon?: ComponentType<{ className?: string }>;
  className?: string;
  onClose?(id?: string): void;
};

export const Toast = forwardRef<HTMLLIElement, ToastProps>(
  (
    { id, className, status, Icon, title, description, onClose, ...props },
    ref
  ) => {
    const IconComponent = Icon || statusIconMap[status] || EmptyComponent;

    const handleClose = useCallback(() => onClose?.(id), [onClose, id]);

    return (
      <li
        ref={ref}
        aria-live="polite"
        aria-atomic="true"
        role="status"
        className={cn(
          'pointer-events-auto flex items-center gap-4 rounded bg-black p-4 text-white',
          className
        )}
        {...props}
      >
        <IconComponent className="min-h-6 min-w-6 fill-white" />
        <div className="flex w-full flex-col gap-3">
          {title ? <p className="body-14-semibold">{title}</p> : null}
          {description ? (
            <p className="body-12-regular col-start-2">{description}</p>
          ) : null}
        </div>
        <Button
          aria-label="toast-close"
          variant="ghost"
          className="h-max !bg-transparent p-1"
          onClick={handleClose}
        >
          <Close className="fill-steel" />
        </Button>
      </li>
    );
  }
);
