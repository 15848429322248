import { Portal } from '@finn/design-system/atoms/portal';
import React, { useCallback, useEffect, useState } from 'react';

import { ModalHooks } from './hooks';
import { GenericModalKey } from './store';

export type CreateModalContainerOptions = {
  unmountDelay?: number;
};

type ModalComponentCommonProps = {
  open: boolean;
  onClose(): void;
};

type ModalComponent<P extends {}> = React.ComponentType<
  P & ModalComponentCommonProps
>;

type ModalContainerProps<P extends {}, K> = {
  /** One of the names of modals defined in ModalKey */
  modalKey: K;

  /**
   * A component which renders the modal.
   *
   * An instance of this component is rendered and mounted when the state
   * for the given key is changed to `true`, and gets unmounted otherwise.
   */
  ModalComponent: ModalComponent<P>;

  /** If true, renders a modal in a portal */
  portal?: boolean;

  /** A delay in ms after which a modal is unmounted */
  unmountDelay?: number;
} & P;

export function createModalContainer<ModalKey extends GenericModalKey>(
  { useIsModalOpen, useCloseModal }: ModalHooks<ModalKey>,
  options?: CreateModalContainerOptions
) {
  function ModalContainer<P extends {}>({
    modalKey,
    ModalComponent,
    portal,
    ...componentProps
  }: Omit<ModalContainerProps<P, ModalKey>, 'open' | 'onClose'>) {
    const isOpen = useIsModalOpen(modalKey);
    const [isMounted, setIsMounted] = useState(false);

    const closeModal = useCloseModal();

    const handleClose = useCallback(() => {
      closeModal(modalKey);
    }, [closeModal, modalKey]);

    // Mount a modal when its state changes in the store
    useEffect(() => {
      const isMounting = isOpen && !isMounted;
      const isUnmounting = isMounted && !isOpen;

      if (isMounting) {
        setIsMounted(true);

        return;
      }

      if (isUnmounting) {
        const unmountDelay =
          componentProps.unmountDelay ?? options?.unmountDelay ?? 0;

        if (unmountDelay) {
          setTimeout(() => setIsMounted(false), unmountDelay);
        } else {
          setIsMounted(false);
        }
      }
    }, [isOpen, isMounted]);

    // Close modal when ModalContainer is unmounted
    useEffect(() => {
      return () => {
        if (!isOpen) {
          handleClose();
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isOpen && !isMounted) {
      return null;
    }

    const Component = ModalComponent as React.ComponentType<object>;

    return (
      <Portal disablePortal={!portal}>
        <Component
          {...componentProps}
          open={isOpen && isMounted}
          onClose={handleClose}
        />
      </Portal>
    );
  }

  return ModalContainer;
}
