import { shouldShowFieldError } from '@finn/ui-utils';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { FocusEventHandler } from 'react';
import { Controller, ControllerProps, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import DatePicker from './DatePicker';

dayjs.extend(customParseFormat);

// value and onChange are set manually
type Props = Omit<
  Parameters<ControllerProps['render']>['0']['field'],
  'onBlur' | 'ref' | 'value' | 'onChange'
> & {
  disableInput?: boolean;
  className?: string;
  variant?: 'dialog' | 'inline' | 'static';
  onChange?: (date: Dayjs) => void;
  dateFormatVariant?: 'short' | 'long';
  // support external value and error for non-formik usage
  name: string;
  label: string;
  form: UseFormReturn;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const HookFormDatePicker: React.FC<Props> = ({
  disableInput,
  name,
  form,
  ...rest
}) => {
  const i18n = useIntl();

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div style={{ position: 'relative' }}>
          <DatePicker
            disableInput={disableInput}
            error={shouldShowFieldError(fieldState, form.formState)}
            {...rest}
            {...field}
            value={field?.value || null}
            onChange={(event) => {
              const value = event.target.value;
              const isDateValid = dayjs(value, 'DD.MM.YYYY').isValid();

              return field.onChange(
                isDateValid ? dayjs(value, 'DD.MM.YYYY').toDate() : value
              );
            }}
          />
          {shouldShowFieldError(fieldState, form.formState) && (
            <p className="body-12-regular text-red mt-2">
              {i18n.formatMessage({
                id: fieldState?.error?.message?.includes('Invalid Date')
                  ? 'yup.validDate'
                  : fieldState?.error?.message,
              })}
            </p>
          )}
        </div>
      )}
    />
  );
};
