import { cn } from '@finn/ui-utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const badgeVariants = cva(
  'border-solid border-0 outline-transparent inline-flex gap-x-1 text-nowrap h-6 w-min rounded items-center px-1 body-12-regular focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        monochrome: 'border border-pearl border-solid bg-white fill-black',
        blue: 'bg-lightBlue text-trustedBlue border-trustedBlue fill-trustedBlue',
        red: 'bg-lightRed text-red border-red fill-red',
        orange: 'bg-lightOrange text-orange border-orange fill-orange',
        green: 'bg-lightGreen text-green border-green fill-green',
        grey: 'bg-pearl text-iron border-iron fill-iron',
        monochromeBlue:
          'bg-white text-trustedBlue border-trustedBlue fill-trustedBlue rounded-sm',
      },
    },
    defaultVariants: {
      variant: 'monochrome',
    },
  }
);

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & { withStroke?: boolean };

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          badgeVariants({ variant }),
          props.withStroke ? '!border' : '',
          className
        )}
        {...props}
      />
    );
  }
);

export { Badge, badgeVariants };
