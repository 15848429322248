import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({
  children,
  disablePortal,
}: {
  children: ReactNode;
  disablePortal?: boolean;
}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  if (disablePortal) {
    return <>{children}</>;
  }

  return ready ? createPortal(children, document.body) : null;
};
