export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toString(value: any): string {
  if (typeof value === 'string') {
    return value;
  }
  try {
    if (typeof value === 'object') {
      if (value instanceof Map) {
        return JSON.stringify([...value.entries()]);
      }
      if (value instanceof Set) {
        return JSON.stringify([...value]);
      }
      if (value instanceof Error) {
        return value?.message;
      }

      return JSON.stringify(value);
    }

    return value?.toString?.() || value || '';
  } catch {
    return 'exception while converting to string';
  }
}
