import { CosmicImageData, UIBaseCosmicObject } from './commons';

export type PLPCardData = {
  title: string;
  link_text: string;
  modal_content: string;
  default_position: number;
};

type CampaignCardVariant = {
  image: CosmicImageData;
  icon: CosmicImageData;
  title: string;
  link: string;
  default_position?: number;
  background_color?: string;
  campaign_id?: string;
};

type EducationCardVariant = {
  title: string;
  link_text: string;
  modal_content: string;
  default_position: number;
};

enum PLPCardVariant {
  CAMPAIGN_CARD = 'campaign-card',
  EDUCATION_CARD = 'educaiton-card',
}

export type PLPCardCosmicMetadata = {
  variant?: PLPCardVariant.CAMPAIGN_CARD | PLPCardVariant.EDUCATION_CARD;
  campaign_card?: CampaignCardVariant;
  education_card?: EducationCardVariant;
};

export type PLPCardCosmicData = UIBaseCosmicObject<PLPCardCosmicMetadata>;
