import { Badge } from '@finn/design-system/atoms/badge';
import { parseToHtml } from '@finn/ui-utils';
import React, { ReactElement } from 'react';

import { NextStep, NextStepType } from './NextStep';

export type StepModuleType = {
  title: string;
  description: string;
  note?: string;
  stepList: Array<NextStepType>;
  extraComponent?: ReactElement;
  completionComponent?: ReactElement;
  MobilePictureComponent?: ReactElement;
  finaleInfo?: string;
  dealInfo?: {
    contactId: number;
    dealId: number;
    hash: string;
  };
  survey?: {
    shouldShowSurveyForm: boolean;
    dealId: number;
    contactId: number;
  };
};

export const StepsModule = ({
  title,
  description,
  finaleInfo = '',
  stepList,
  dealInfo,
  extraComponent,
  completionComponent,
  MobilePictureComponent,
}: StepModuleType) => {
  const hasIncompleteStep = stepList.some((step) => !step.success);
  const firstUncompletedStep = stepList.findIndex((step) => !step.success);

  return (
    <div data-cy="steps-module" className="flex flex-col">
      <div className="flex flex-col gap-4 md:gap-6">
        <h2 className="md:web-t4-semibold mobile-t4-semibold">
          {parseToHtml(title)}
        </h2>
        <Badge variant="monochrome">{`Vertragsnummer: ${dealInfo?.dealId}`}</Badge>
        {MobilePictureComponent}
        {hasIncompleteStep && (
          <div className="body-14-light">{parseToHtml(description)}</div>
        )}
      </div>
      <div>
        {hasIncompleteStep &&
          stepList.map((step, index) => (
            <NextStep
              {...step}
              key={step.description}
              shouldExpand={index === firstUncompletedStep}
            />
          ))}
        {extraComponent}
        {!hasIncompleteStep && completionComponent}
        {finaleInfo && (
          <div className="body-14-light mt-24">{parseToHtml(finaleInfo)}</div>
        )}
      </div>
    </div>
  );
};
