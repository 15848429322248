import { FC, Fragment } from 'react';

/**
  This is a small helper component that can be used to render nothing(just proxy children).
  It can be useful in several places, for example, when we render Icon in most 
  cases it is optional prop, and it is readable and convinient to use fallback or
  default prop when Icon not defined and pass the same props to the Icon component.

  But if we will use just Fragment as fallback, as we did before, we will receive
  a bunch of warnings that Fragment can not accept className or some other prop.
  By using this dummy component that can accept anything and render nothing we can
  avoid DX issues and keept convinient API for the component and it internals
*/

// We are ok with any here, as component should be able to receive anything
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmptyComponent: FC<any> = (props) => (
  <Fragment>{props.children}</Fragment>
);
