import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { HeaderData, HeaderLinkData, Link, NavbarData } from '@finn/ui-cosmic';
import { useCurrentLocale } from '@finn/ui-utils';
import get from 'lodash/get';
import { useMemo } from 'react';

export const useMenuData = (cosmicPageData: HeaderData): NavbarData => {
  const isJobAutoExp = useIsABVariant(Features.ExpJobAutoLink);
  const { isDE } = useCurrentLocale();

  return useMemo(() => {
    const newLinks: HeaderLinkData[] = [...cosmicPageData.metadata.links];
    const hasJobAutoLink = newLinks.find(
      (item) => item.metadata.href === '/b2b/job-auto'
    );

    if (isJobAutoExp && !hasJobAutoLink && isDE) {
      const jobAutoLink: HeaderLinkData = {
        _id: '',
        slug: '',
        title: '',
        content: '',
        type_slug: 'link',
        status: '',
        locale: '',
        metadata: {
          href: '/b2b/job-auto',
          label: 'JobAuto',
        },
      };
      newLinks.splice(2, 0, jobAutoLink);
    }

    const links = newLinks.map((link) => link.metadata as Link);

    const { ribbon } = cosmicPageData.metadata;
    const userAccountLinks =
      cosmicPageData.metadata.user_accounts?.navbar_user_account_links || {};

    userAccountLinks.dropdownOptions = get(
      cosmicPageData,
      'metadata.user_accounts.dropdown_options'
    );
    userAccountLinks.helpLink = get(
      cosmicPageData,
      'metadata.help_link.metadata'
    );

    return {
      ribbon,
      links,
      userAccountLinks,
    };
  }, [cosmicPageData, isDE, isJobAutoExp]);
};
