import axios, { AxiosRequestConfig } from 'axios';

import { getOrigin } from './origin';
import { isServer } from './server';

export const maskPaymentInfo = (last4: string) => {
  return `•••• ${last4}`;
};

export const replaceJSRenderTemplate = async (
  template: string,
  data: { [key: string]: unknown }
) => {
  try {
    if (isServer()) {
      const { replaceJSRenderTemplateOnServer } = await import('./server/text');

      return replaceJSRenderTemplateOnServer(template, data);
    } else {
      if (!template || template.length === 0) return null;

      const options: AxiosRequestConfig = {
        method: 'POST',
        data: { template, data },
        url: `${getOrigin()}/api/jsrender`,
      };

      const axiosResult = await axios(options);

      return axiosResult.data.result;
    }
  } catch (e) {
    return '';
  }
};

export const convertToEnglish = (str: string): string => {
  const lettersMap = {
    ä: 'ae',
    ë: 'e',
    ü: 'ue',
    é: 'e',
    ö: 'oe',
    ß: 'ss',
  };

  return str.replace(
    /ä|ë|é|ü|ö|ß/gi,
    (matched) => lettersMap[matched as keyof typeof lettersMap]
  );
};

export const slugify = (str: string): string =>
  convertToEnglish(str)
    .replace(/[\s.#]/g, '-')
    .toLowerCase();

export const getUnderscoreCount = (str: string) =>
  (str.match(/_/g) || []).length;

export const hashStr = (str: string) => {
  const hashedStr = isServer()
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

  return hashedStr;
};

export const parseBoolean = (value: string) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const replaceStrKeys = (str: string, hash: any) => {
  let replacedStr = str;

  for (const key in hash) {
    replacedStr = replacedStr?.replace(
      new RegExp(`\\{${key}\\}`, 'gm'),
      hash[key]
    );
  }

  return replacedStr;
};

export const removeSpacesAndDashes = (str: string) => {
  const spacesAndDashes = /[\s-]/g;

  return str?.replace(spacesAndDashes, '');
};
