import difference from 'lodash/difference';
import { useCallback, useEffect } from 'react';
import { useStore } from 'zustand';

import { GenericModalKey, ZustandModalStore } from './store';

export function createModalHooks<ModalKey extends GenericModalKey>(
  modalStore: ZustandModalStore<ModalKey>
) {
  return {
    useModalStore() {
      return useStore(modalStore);
    },

    useOpenModal() {
      return useCallback((modalKey: ModalKey) => {
        modalStore.getState().openModal(modalKey);
      }, []);
    },

    useCloseModal() {
      return useCallback((modalKey: ModalKey) => {
        modalStore.getState().closeModal(modalKey);
      }, []);
    },

    useCloseAllModals() {
      return useCallback(() => modalStore.getState().closeAllModals(), []);
    },

    useIsModalOpen(modalKey: ModalKey) {
      return Boolean(useStore(modalStore).openModalKeys[modalKey]);
    },

    useIsAnyModalOpen() {
      return Boolean(
        Object.keys(useStore(modalStore).openModalKeys).length > 0
      );
    },

    useOnModalOpen(callback: (modalKey: ModalKey) => void) {
      useEffect(() => {
        return modalStore.subscribe((state, prevState) => {
          const addedKeys = difference(
            Object.keys(state.openModalKeys),
            Object.keys(prevState.openModalKeys)
          ) as ModalKey[];

          for (const key of addedKeys) {
            callback(key);
          }
        });
      }, [callback]);
    },

    useOnModalClose(callback: (modalKey: ModalKey) => void) {
      useEffect(() => {
        return modalStore.subscribe((state, prevState) => {
          const removedKeys = difference(
            Object.keys(prevState.openModalKeys),
            Object.keys(state.openModalKeys)
          ) as ModalKey[];

          for (const key of removedKeys) {
            callback(key);
          }
        });
      }, [callback]);
    },
  };
}

export type ModalHooks<ModalKey extends GenericModalKey> = ReturnType<
  typeof createModalHooks<ModalKey>
>;
