import { ReactNode } from 'react';

const aTag = (chunks: ReactNode[]) => <a>{chunks}</a>;
const bTag = (chunks: ReactNode[]) => <b>{chunks}</b>;
const strongTag = (chunks: ReactNode[]) => <strong>{chunks}</strong>;
const brTag = () => <br />;
const pTag = (chunks: ReactNode[]) => <p>{chunks}</p>;
const SubscriptionLinkTag = (chunks: ReactNode[]) => (
  <a href="https://www.finn.com/subscribe" target="_blank" rel="noreferrer">
    {chunks}
  </a>
);

const EmailLinkTag = (chunks: ReactNode[]) => (
  <a href="mailto:support@finn.auto" target="_blank" rel="noreferrer">
    {chunks}
  </a>
);

const TelLinkTag = (chunks: ReactNode[]) => (
  <a href="tel:+4989143770064" target="_blank" rel="noreferrer">
    {chunks}
  </a>
);

const EONChargingLinkTag = (chunks: ReactNode[]) => (
  <a href="https://charge.eon-drive.de/#shop" target="_blank" rel="noreferrer">
    {chunks}
  </a>
);

const EONWallboxLinkTag = (chunks: ReactNode[]) => (
  <a
    href="https://www.eon.de/de/pk/e-mobility/produkt-drive/eon-drive-powerbox-comfort.html"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const RecaptchaPrivacyTagDE = (chunks: ReactNode[]) => (
  <a
    href="https://policies.google.com/privacy?hl=de"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const RecaptchaPrivacyTagEN = (chunks: ReactNode[]) => (
  <a
    href="https://policies.google.com/privacy?hl=en"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const RecaptchaTermsTagDE = (chunks: ReactNode[]) => (
  <a
    href="https://policies.google.com/terms?hl=de"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const RecaptchaTermsTagEN = (chunks: ReactNode[]) => (
  <a
    href="https://policies.google.com/terms?hl=en"
    target="_blank"
    rel="noreferrer"
  >
    {chunks}
  </a>
);

const PrivacyLinkDETag = (chunks: ReactNode[]) => (
  <a
    href="/de-DE/privacy"
    target="_blank"
    rel="noreferrer"
    style={{ color: '#2856C1' }}
  >
    {chunks}
  </a>
);
const PrivacyLinkUSTag = (chunks: ReactNode[]) => (
  <a
    href="/en-US/privacy"
    target="_blank"
    rel="noreferrer"
    style={{ color: '#2856C1' }}
  >
    {chunks}
  </a>
);

const JobAutoCalculatorLinkTag = (chunks: ReactNode[]) => (
  <a href="https://one.finn.auto/p/jobauto" target="_blank" rel="noreferrer">
    <u>
      <strong>{chunks}</strong>
    </u>
  </a>
);

export const getDefaultRichTextElements = () => {
  // We can later pass IntlShape to adjust properties accordinly

  return {
    a: aTag,
    b: bTag,
    strong: strongTag,
    br: brTag,
    p: pTag,
    SubscriptionLink: SubscriptionLinkTag,
    EmailLink: EmailLinkTag,
    TelLink: TelLinkTag,
    EONChargingLink: EONChargingLinkTag,
    EONWallboxLink: EONWallboxLinkTag,
    PrivacyLinkDE: PrivacyLinkDETag,
    PrivacyLinkUS: PrivacyLinkUSTag,
    JobAutoCalculatorLink: JobAutoCalculatorLinkTag,
    RecaptchaPrivacyLinkDE: RecaptchaPrivacyTagDE,
    RecaptchaPrivacyLinkEN: RecaptchaPrivacyTagEN,
    RecaptchaTermsLinkDE: RecaptchaTermsTagDE,
    RecaptchaTermsLinkEN: RecaptchaTermsTagEN,
  };
};
