import { cn } from '@finn/ui-utils';
import { ElementType, forwardRef, InputHTMLAttributes, ReactNode } from 'react';

export type CoreInputProps = {
  label?: ReactNode;
  defaultValue?: string;
  ExternalInputComponent?: ElementType;
} & InputHTMLAttributes<HTMLInputElement>;

// we extracted inout to separate component to be able to
// re-use between different input types (like tel and others)

export const CoreInput = forwardRef<HTMLInputElement, CoreInputProps>(
  ({ id, type, label, ExternalInputComponent, ...props }, ref) => {
    if (ExternalInputComponent) {
      return (
        <ExternalInputComponent
          id={id}
          ref={ref}
          className={cn(
            // layout
            'placeholder-iron peer flex h-full w-full px-0 pb-2 pt-6 focus:placeholder-opacity-100',
            // reset styles
            'box-border border-none outline-none file:border-0 file:bg-transparent',
            `body-16-regular disabled:bg-snow disabled:text-iron`,
            {
              'opacity-0': type === 'file',
              'py-0': !label,
              'placeholder-opacity-0': type !== 'tel',
            }
          )}
          {...props}
        />
      );
    }

    return (
      <input
        id={id}
        type={type}
        className={cn(
          // layout
          'placeholder-iron peer flex h-full w-full px-0 pb-2 pt-6 focus:placeholder-opacity-100',
          // reset styles
          'box-border border-none outline-none file:border-0 file:bg-transparent',
          `body-16-regular disabled:bg-snow disabled:text-iron`,
          {
            'opacity-0': type === 'file',
            'py-0': !label,
            'placeholder-opacity-0': type !== 'tel',
          }
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
