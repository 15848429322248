import { getAppSDK } from './mobileApp';

export const isServer = () => typeof window === 'undefined';

export const getRequestMetadataForWebViewApp = () => {
  if (isServer()) {
    return {};
  }

  const trackingProps = getAppSDK()?.getTrackingProps?.();

  if (!trackingProps?.app_platform) {
    return {};
  }

  const appHeaders = {
    'X-Version-Tag': trackingProps.app_version,
    'X-Platform-Tag': trackingProps.app_platform,
  };

  const appQueryParams = {
    utm_source: `app-${trackingProps.app_platform}`,
    app_version: trackingProps.app_version,
  };

  return {
    appHeaders,
    appQueryParams,
  };
};

export const adjustLinkTargetsforWebView = () => {
  document.querySelectorAll('a').forEach((tag) => {
    tag.target = '';
  });
};
