import { PageType } from '../../types/general';

export const Pages: Record<string, PageType> = {
  PLP: 'plp',
  PDP: 'pdp',
  Checkout: 'checkout',
  Home: 'home',
  ThankYou: 'thank_you',
  OrderStatus: 'order_status',
  MyCars: 'my_cars',
  MyCar: 'my_car',
  Auth: 'auth',
  Landing: 'landing',
  ReportDamage: 'report_damage',
  ScheduleService: 'schedule_service',
  Return: 'return',
};

const PagePathForScreen: Record<string, string> = {
  PLP: 'subscribe',
  PDP: 'pdp',
  Checkout: 'checkout',
  Home: '',
  ThankYou: 'thank_you',
  OrderStatus: 'order_status',
  MyCars: 'mycars',
  MyCar: 'mycars/',
  Landing: 'landing',
};

export const getCurrentPageType = (screenName: string) => Pages[screenName];
export const getCurrentPagePath = (screenName: string) =>
  PagePathForScreen[screenName];
