import { HeaderData } from '@finn/ui-cosmic';
import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { memo, useEffect, useState } from 'react';

import GeneralDesktopHeader from './GeneralDesktopHeader';
import GeneralMobileHeader from './GeneralMobileHeader';
import { HeaderProvider, HeaderProviderProps } from './HeaderContext';
import { Ribbon } from './Ribbon';

export type HandleMobileHeaderParams = {
  isOpen: boolean;
  isOpenUser: boolean;
};

type HeaderProps = HeaderProviderProps & {
  data: HeaderData;
};

const Header = ({ data, CartButton }: HeaderProps) => {
  const [isRibbonDismissed, setIsRibbonDismissed] = useState(false);
  const [hideRibbon, setHideRibbon] = useState(false);

  useEffect(() => {
    setIsRibbonDismissed(!!Cookies.get(CookieKeys.RIBBON_DISMISSED));
  }, []);

  const onRibbonDismiss = () => {
    setIsRibbonDismissed(true);
  };

  return (
    <HeaderProvider CartButton={CartButton}>
      <header data-testid="header">
        {!isRibbonDismissed && !hideRibbon && (
          <Ribbon data={data} onDismiss={onRibbonDismiss} />
        )}
        <GeneralDesktopHeader
          data={data}
          ribbonDismissed={isRibbonDismissed}
          className="hidden md:block"
        />
        <GeneralMobileHeader
          data={data}
          setHideRibbon={setHideRibbon}
          className="flex md:hidden"
        />
      </header>
    </HeaderProvider>
  );
};

export default memo(Header);
