import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { cn } from '@finn/ui-utils';
import { FunctionComponent, RefObject, useEffect } from 'react';
import handleViewport from 'react-in-viewport';

import { addNewProductListView } from '../tracking';
import AttributesRow from './AttributesRow';
import Label from './Label';
import ProductImage from './ProductImage';
import ProductLink from './ProductLink';
import ProductPrice from './ProductPrice';

interface IProps {
  incentiveLabel?: string;
  vehicle: GenericVehicleDetails;
  parentTitle?: string;
  enterCount?: number;
  leaveCount?: number;
  shouldPreload?: boolean;
  shouldShowPlaceholder?: boolean;
  forwardedRef?: RefObject<HTMLDivElement>;
  position?: number;
  baseURL?: string;
  urlParams?: string;
  isForIframe?: boolean;
  hidePrice?: boolean;
  showCompare?: boolean;
  forceB2BPrice?: boolean;
  selectedTerm?: number;
  partnerDiscount?: number;
}

const ProductCard: FunctionComponent<IProps> = ({
  vehicle,
  parentTitle,
  enterCount,
  leaveCount,
  shouldPreload,
  shouldShowPlaceholder = true,
  forwardedRef,
  position,
  baseURL = '',
  urlParams,
  isForIframe = false,
  hidePrice = false,
  forceB2BPrice = false,
  selectedTerm,
  partnerDiscount = 0,
  incentiveLabel,
}) => {
  const shouldShowPrice = !hidePrice;
  const productLabel =
    !incentiveLabel && !partnerDiscount && vehicle?.product_label?.label;
  const impressionTitle = parentTitle || 'PLP - Filter Results';

  useEffect(() => {
    const isAppearFirstTimeInViewport = enterCount === 1 && leaveCount === 0;
    if (isAppearFirstTimeInViewport) {
      addNewProductListView({
        vehicle,
        list: impressionTitle,
        partnerDiscount,
      });
    }
  }, [enterCount, impressionTitle, leaveCount, vehicle]);

  const brandModelLabel = `${vehicle.brand?.id} ${vehicle.model}`;

  const vehiclePictureURL = vehicle.picture?.url || vehicle.picture?.[0]?.url;
  const vehicleAltText = `${vehicle.brand?.id} ${vehicle.model}`;

  urlParams = urlParams || (selectedTerm && `?term=${selectedTerm}`) || '';

  return (
    <ProductLink
      vehicle={vehicle}
      baseURL={baseURL}
      urlParams={urlParams}
      isForIframe={isForIframe}
      position={position}
    >
      <div
        className="relative flex h-full w-full flex-col overflow-hidden hover:cursor-pointer"
        ref={forwardedRef}
      >
        <div className="bg-snow relative rounded">
          <ProductImage
            vehiclePictureURL={vehiclePictureURL}
            shouldPreload={shouldPreload}
            shouldShowPlaceholder={shouldShowPlaceholder}
            altText={vehicleAltText}
          />
        </div>

        <div className="mt-3 grid gap-3">
          {incentiveLabel && !partnerDiscount && (
            <Label highlight>{incentiveLabel}</Label>
          )}
          {productLabel && <Label>{productLabel}</Label>}
          <h5
            className={cn(
              'body-14-semibold sm:body-16-semibold overflow-hidden text-ellipsis py-1 sm:text-nowrap'
            )}
          >
            {brandModelLabel}
          </h5>
          <AttributesRow vehicle={vehicle} />
          {shouldShowPrice && (
            <ProductPrice
              forceB2BPrice={forceB2BPrice}
              vehicle={vehicle}
              selectedTerm={selectedTerm}
              partnerDiscount={partnerDiscount}
            />
          )}
        </div>
      </div>
    </ProductLink>
  );
};

export default handleViewport<any, any>(
  ProductCard,
  { threshold: 0.8 },
  { disconnectOnLeave: true }
);
