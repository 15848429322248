export enum CosmicModuleType {
  WEB_MODULE_CAR_PROMO = 'web-module-carpromos',
  WEB_MODULE_VERTICAL_TABS = 'web-module-verticaltabs',
  WEB_MODULE_HERO = 'web-module-heroes',
  WEB_MODULE_HERO_2 = 'web-module-heroes-2',
  WEB_MODULE_TEXT = 'web-module-texts',
  WEB_MODULE_FLIPPER = 'web-module-flippers',
  WEB_MODULE_SLIDER = 'web-module-sliders',
  WEB_MODULE_CUSTOMS = 'web-module-customs',
  WEB_MODULE_PROMO = 'web-module-promos',
  WEB_MODULE_DYNAMIC_PROMO = 'web-module-dynamic-promos',
  WEB_MODULE_CUSTOM_PLACEHOLDER = 'web-module-custom-placeholder',
  WEB_MODULE_STEPPER = 'web-module-steppers',
  WEB_MODULE_DATA_DASHBOARD = 'web-module-data-tables',
  WEB_MODULE_BRAND_SLIDER = 'web-module-brandsliders',
  WEB_MODULE_CUSTOM_BRAND_SLIDER = 'web-module-custom-brandsliders',
  WEB_MODULE_TRUSTPILOT = 'web-module-trustpilots',
  WEB_MODULE_ACCORDION = 'web-module-accordions',
  WEB_MODULE_ACCORDION_OLD = 'faqs',
  WEB_MODULE_COMPARISON = 'web-module-comparison',
  PRODUCT_SLIDER = 'sliders',
  WEB_MODULE_GALLERY = 'web-module-galleries',
  WEB_MODULE_VIDEOS = 'web-module-videos',
  WEB_MODULE_VERTICAL_LIST = 'web-module-verticallists',
  MENU_INTRO = 'intro',
  MENU_BENEFITS = 'benefits',
  MENU_POPULAR_BRANDS = 'popular-brands',
  MENU_POPULAR_MODELS = 'popular-models',
  MENU_LIST = 'list',
  MENU_CARD = 'card',
  WEB_MODULE_HTMLS = 'web-module-htmls',
  WEB_MODULE_FORMS = 'web-module-forms',
  WEB_MODULE_SUSTAINABILITY = 'web-module-sustainability',
  WEB_MODULE_CHOICE_PAIR = 'web-module-choice-pair',
  WEB_MODULE_HERO_STICKY_NAVS = 'web-module-hero-sticky-navs',
  WEB_MODULE_STEP_COUNTER = 'web-module-steps-counters',
  WEB_MODULE_EMAIL_COLLECTION_FORMS = 'email-collection-forms',
  MENU_B2B_INTRO = 'b2b-intro',
  MENU_B2B_PROGRAMS_LIST = 'b2b-programs-list',
  MENU_B2B_PROGRAMS_CARDS = 'b2b-programs-cards',
  WEB_MODULE_SUBSCRIPTION_SECTION = 'web-module-subscription-section',
  WEB_MODULE_BLURB = 'web-module-blurb',
  WEB_MODULE_LOYALTY = 'web-module-loyalties',
  WEB_MODULE_LOYALTY_EARNING_POINTS = 'loyalty-earning-points',
  WEB_MODULE_CAMPAIGN_CARD = 'web-module-campaign-card',
  WEB_MODULE_TESTIMONIALS = 'web-module-testimonials',
  WEB_MODULE_PROMO_BANNERS = 'web-module-promo-banners',
  WEB_MODULE_PRODUCT_GRID = 'web-module-productgrid',
  WEB_MODULE_CONTENT_EXPERIMENT = 'web-module-content-experiment',
  WEB_MODULE_TABLE_OF_CONTENTS = 'web-module-table-of-contents',
  WEB_MODULE_STICKY_CTA_PANEL = 'web-module-sticky-cta-panel',
  WEB_SELF_SERVICE_MODULES = 'self-service-modules',
  WEB_SELF_SERVICE_COMPONENTS = 'self-service-components',
  WEB_SELF_SERVICE_TEXTBLOCK = 'textblock',
  WEB_SELF_SERVICE_BANNERS = 'banners',
  WEB_SELF_SERVICE_FIELDS = 'fields',
}

export type UIBaseCosmicData = {
  id: string;
  slug: string;
  title: string;
  type_slug: string;
  status: string;
  locale: string;
  type: CosmicModuleType;
  url: string;
};

export type UIBaseCosmicObject<T> = UIBaseCosmicData & {
  metadata: T & {
    html_id?: string;
  };
};

export type InputFieldCosmicMetadata = {
  placeholder: string;
};

export type InputFieldCosmicData = UIBaseCosmicObject<InputFieldCosmicMetadata>;

export type TrackingEventCosmicMetadata = {
  category: string;
  action: string;
  label: string;
};
export type TrackingDataNewFormat = {
  name?: string;
  link: string;
  location: string;
};

export type TrackingEventData = UIBaseCosmicObject<TrackingEventCosmicMetadata>;

export type CosmicImageData = {
  url: string;
  cloudinary?: boolean;
};

export type CosmicVideoData = {
  url: string;
};

export enum ResponsiveImageSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type ResponsiveCosmicImage = UIBaseCosmicObject<{
  [ResponsiveImageSizes.SMALL]: CosmicImageData;
  [ResponsiveImageSizes.MEDIUM]: CosmicImageData;
  [ResponsiveImageSizes.LARGE]: CosmicImageData;
  alt: string;
}>;

export type HTMLContentData = UIBaseCosmicObject<{
  html_content: string;
  centered?: boolean;
}>;

export type ImageObjectData = {
  image: CosmicImageData;
  alt_text?: string;
};
