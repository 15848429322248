import {
  CookieKeys,
  getClientBooleanCookie,
  setClientBooleanCookie,
} from '@finn/ui-utils';

import { useReadyValue } from '../app/utils';

const EXPIRATION_DAYS = 30;

export const setForBusinessCookie = (value: boolean) =>
  setClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS, value, EXPIRATION_DAYS);

export const getForBusinessCookie = () =>
  getClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS);

export const useForBusinessCookie = () => {
  return useReadyValue(getForBusinessCookie(), false);
};
