import { IntlShape } from 'react-intl';

import { CountryCode, Locale } from '../types/localization';
import { removeSpacesAndDashes } from './text';

export const twoDecimalPlaces = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;
export const isNumberBetweenRange = (
  num: number,
  upper: number,
  lower: number
): boolean => {
  return lower <= num && num <= upper;
};

export const formatDEPhoneNumber = (phoneNumber: string) => {
  const finalePhoneNumber = removeSpacesAndDashes(phoneNumber);

  if (!finalePhoneNumber) {
    return finalePhoneNumber;
  }

  const first2Elements = finalePhoneNumber.substring(0, 2);

  // If the phone number starts with '00', replace '00' with '+'
  if (first2Elements === '00') {
    return `+${finalePhoneNumber.substring(2)}`;
  }

  //if the phone number does not start with a '+' or '00', but with a '0', replace '0' with '+49'
  if (finalePhoneNumber[0] === '0') {
    return `+49${finalePhoneNumber.substring(1)}`;
  }

  //If the phone number starts with '+' do nothing
  return finalePhoneNumber;
};

export const formatPhoneNumber = (
  phoneNumber: string,
  country: CountryCode | Locale
) => {
  // if it already has country code, don't do country specific formatting. Just return cleaned up number
  const hasCountryCode = phoneNumber?.[0] === '+';
  if (hasCountryCode) {
    return `+${phoneNumber.replace(/[^0-9]/g, '')}`;
  }
  switch (country) {
    case Locale.FAKE_DEFAULT:
    case Locale.GERMAN_GERMANY:
    case CountryCode.DE: {
      return formatDEPhoneNumber(phoneNumber);
    }
    default:
      return phoneNumber;
  }
};

export const formatNumberParts = (parts: Intl.NumberFormatPart[]) =>
  parts
    .map(({ type, value }) => {
      switch (type) {
        case 'literal':
          return '';
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);

// small JS trick to ensure that the date is valid
export const isNumberValid = (num: string | number) => !isNaN(Number(num));

export const formatEuroDecimal = (value: number, intl: IntlShape): string => {
  return intl.formatNumber(value, {
    currency: 'EUR',
    style: 'currency',
    maximumFractionDigits: 2,
  });
};
