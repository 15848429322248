import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

export type CoreLabelProps = {
  id?: string;
  children?: ReactNode;
  type?: string;
  shouldShowLabelAtTop?: boolean;
};

// we extracted label to separate component to be able to animate it
// and re-use between different input types (like tel and others)

export const CoreLabel = ({
  id,
  children,
  type,
  shouldShowLabelAtTop,
}: CoreLabelProps) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        // layout
        'absolute top-1/2 -translate-y-1/2',
        // focus styles
        'peer-focus:body-12-regular peer-focus:top-3 peer-focus:translate-y-0',
        // animation
        'transition-all delay-0',
        'peer-disabled:text-iron body-16-regular',
        {
          'peer-focus:!body-16-regular peer-focus:top-1/2 peer-focus:-translate-y-1/2':
            type === 'file' && !shouldShowLabelAtTop,
          'body-12-regular top-3 translate-y-0': shouldShowLabelAtTop,
        }
      )}
    >
      {children}
    </label>
  );
};
