export const sha256 = async (message: string): Promise<string | undefined> => {
  try {
    // encode as UTF-8
    const msgBuffer = new window.TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new window.Uint8Array(hashBuffer));

    // convert bytes to hex string
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  } catch {
    return undefined;
  }
};
