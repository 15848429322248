import { cn } from '@finn/ui-utils';
import dynamic from 'next/dynamic';
import {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Upload } from '../icons/generated/upload';
import {
  CoreInputWithLabel,
  CoreInputWithLabelProps,
} from './CoreInputWithLabel';
import { TelInputProps } from './TelInput';

// we import TelInput dynamically to reduce impact on bundle size
// during loading time there should be no major isssues besides
// label not being visible
const TelInput = dynamic(
  () => import('./TelInput').then((res) => res.TelInput),
  { ssr: true }
);

/* eslint-disable jsx-a11y/label-has-associated-control */

export type InputProps = {
  endAdornment?: ReactNode;
  error?: string | boolean;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  onContainerClick?: () => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  testId?: string;
} & (Omit<CoreInputWithLabelProps, 'localValue'> | TelInputProps);

const generateId = () => Math.random().toString(36).substring(7);

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id: idProp,
      startAdornment,
      endAdornment,
      error,
      className,
      type = 'text',
      label,
      containerRef,
      onContainerClick,
      onChange,
      testId,
      ...props
    },
    ref
  ) => {
    const id = useMemo(() => idProp || generateId(), [idProp]);
    const [localValue, setValue] = useState(
      String(props.value || props.defaultValue || '')
    );
    const fallbackRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || fallbackRef;

    // we keep local value in local state just to run some animations
    // and have proper state for label, but input is controlled from outside
    const handleChange = (
      event: ChangeEvent<HTMLInputElement> & { value?: string }
    ) => {
      if (type === 'tel') {
        setValue(event?.value || '');
      } else if (type === 'file' && event.target.files?.length) {
        setValue(event.target.files[0]?.name);
      } else {
        setValue(event.target.value);
      }
      onChange?.(event);
    };

    // we need to calculate if label should be at top or not
    // for example if value is present, label should be at top(as in material)
    const shouldShowLabelAtTop =
      localValue.length > 0 || String(props.value || '')?.length > 0;

    // for type file endAdornment is predefined
    const endAdornmentToRender = type === 'file' ? <Upload /> : endAdornment;

    return (
      <div
        ref={containerRef}
        className="relative w-full"
        onClick={onContainerClick}
        data-testid={testId}
      >
        <div
          className={cn(
            // layout
            'relative flex h-14 w-full items-center bg-white px-4 text-black',
            // border
            'border-pearl box-border border border-solid',
            // focus styles
            'has-[:focus-visible]:outline has-[:focus-visible]:outline-2 has-[:focus-visible]:outline-black',
            // disabled styles
            'has-[:disabled]:bg-snow has-[:disabled]:text-iron has-[:disabled]:fill-iron has-[:disabled]:pointer-events-none has-[:disabled]:cursor-default',
            className,
            {
              'outline-red border-red has-[:focus-visible]:outline-red outline outline-1 has-[:focus-visible]:outline-1':
                error,
            }
          )}
        >
          {startAdornment ? (
            <div className="relative flex items-center pr-3">
              {startAdornment}
            </div>
          ) : null}
          {
            // for tel input we are using external component
            // and need to render it separatly and differently
          }
          {type === 'tel' ? (
            <TelInput
              id={id}
              ref={inputRef}
              type={type}
              label={label}
              onChange={handleChange as TelInputProps['onChange']}
              {...props}
            />
          ) : (
            <CoreInputWithLabel
              id={id}
              ref={inputRef}
              type={type}
              label={label}
              startAdornment={startAdornment}
              localValue={localValue}
              shouldShowLabelAtTop={shouldShowLabelAtTop}
              onChange={handleChange}
              {...props}
            />
          )}
          {endAdornmentToRender ? (
            <div className="relative flex items-center pl-3">
              {endAdornmentToRender}
            </div>
          ) : null}
          {
            // for type file, we want to keep input hidden to be aligned with DS
            // but we want customer to be able to upload file by clicking anywhere
            // to get this behavior we use label trick, label covers whole input and attached to input
            // so when user clicks on label, it triggers input
          }
          {type === 'file' ? (
            <label
              htmlFor={id}
              className="absolute h-full w-full cursor-pointer"
            />
          ) : null}
        </div>
        {
          // error can be boolean or string, depending do we want just red input or red input + red text below
        }
        {typeof error === 'string' && error ? (
          <p className="body-12-regular text-red mt-3 text-left">{error}</p>
        ) : null}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
