import { Logo } from '@finn/design-system/icons/logo';
import { NextLinkSingleApp } from '@finn/ui-components';
import { FooterData } from '@finn/ui-cosmic';
import { App, cn, parseToHtml } from '@finn/ui-utils';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import AppStoresBadges from './AppStoresBadges';
import FooterBlurb from './FooterBlurb';
import FooterSection from './FooterSection';
import SocialLink from './SocialLink';

type FooterProps = {
  data: FooterData;
  variant?: 'minimal' | 'default';
};

export const Footer = memo(function Footer({
  data,
  variant = 'default',
}: FooterProps) {
  const i18n = useIntl();

  if (!data?.metadata) return null;
  const footerColumns = data.metadata?.columns;

  if (variant === 'minimal') {
    // legal column always has privacy link
    const legalColumn = footerColumns?.find((column) =>
      column?.metadata?.links?.some((link) => link.slug === 'link-privacy')
    );

    return (
      <footer className="bg-black px-4 pb-5 pt-6 text-white">
        <div
          className="container flex flex-col justify-between gap-6 md:flex-row"
          data-nativeappid="footer"
          data-cy="footer"
        >
          <Logo className="h-4 w-11 fill-white" />
          <div className="flex flex-col justify-between gap-5 sm:flex-row">
            <div className="flex flex-col gap-3.5 sm:flex-row">
              {legalColumn.metadata.links
                .filter((link) => link.slug !== 'link-privacy-careers')
                .map((link) => (
                  <NextLinkSingleApp
                    app={App.UA}
                    key={link?.slug}
                    href={link?.metadata.href}
                    className="body-12-light before:hidden after:hidden"
                  >
                    {parseToHtml(link?.metadata.label)}
                  </NextLinkSingleApp>
                ))}
            </div>
            <p className="body-12-light whitespace-nowrap before:hidden after:hidden">
              {i18n.formatMessage({ id: 'general.copyright' })}
            </p>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="mt-24 bg-black p-4 pb-16 text-white md:mt-40">
      <div className="container" data-nativeappid="footer" data-cy="footer">
        <FooterBlurb data={data} />
        <div className="grid grid-cols-[1fr] sm:grid-cols-[repeat(2,1fr)] md:grid-cols-[repeat(4,1fr)]">
          {footerColumns?.map((section, index) => (
            <FooterSection
              data={section.metadata}
              key={`${section.title}-${index}`}
            />
          ))}
        </div>
        <div className="flex flex-col flex-wrap items-start justify-between pt-4 md:flex-row md:items-end md:pt-14">
          <div className="mr-8">
            <Logo className="fill-white" />
            <p className="body-12-regular mt-4">
              {i18n.formatMessage({ id: 'general.copyright' })}
            </p>
          </div>
          <div className="mt-8 flex flex-wrap items-center md:mt-0">
            {data.metadata.social_links?.length ? (
              <div
                className={cn(
                  'mb-8 mt-4 inline-flex w-full gap-4 md:m-0 md:mr-8 md:w-auto'
                )}
              >
                {data.metadata.social_links.map((socialLink) => (
                  <SocialLink
                    key={socialLink.platform_name.key}
                    socialLink={socialLink}
                  />
                ))}
              </div>
            ) : null}
            <AppStoresBadges data={data} />
          </div>
        </div>
      </div>
    </footer>
  );
});
