import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn, type HandleClickFn } from '@finn/ui-utils';

type B2BProgramsListProps = {
  data: {
    b2b_programs: {
      items: {
        href: string;
        label: string;
      }[];
      title: string;
    };
  };
  handleClick: HandleClickFn;
};

const B2BProgramsList = ({ data, handleClick }: B2BProgramsListProps) => {
  const {
    b2b_programs: { items, title },
  } = data;

  return (
    <div className="mb-6 mt-5">
      <div className="global-t6-semibold mb-5 pl-3">{title}</div>
      {items.map((program) => (
        <div key={program.label}>
          <NextLinkSingleApp
            app={App.UA}
            href={program.href}
            // we want relative links to open in the same tab and external links to open in a new tab
            target={program.href.startsWith('/') ? '_self' : '_blank'}
            className="text-black"
            onClick={(event) =>
              handleClick({
                link: program.href,
                type: NAVIGATION_TRACKING_EVENTS.LIST_ITEM,
                e: event,
              })
            }
          >
            <div
              className={cn(
                'body-16-regular flex items-center rounded-sm p-3',
                'md:hover:bg-snow mg:hover:border-pearl'
              )}
            >
              {program.label}
            </div>
          </NextLinkSingleApp>
        </div>
      ))}
    </div>
  );
};

export default B2BProgramsList;
