import { Features } from '@finn/ua-featureflags';
import {
  FilterKey,
  FILTERS_RESPONSE_SWR_FALLBACK_KEY,
  FiltersResponse,
  FilterValuesObject,
  Model,
  useGetFilters,
} from '@finn/ua-vehicle';
import { isServer, useCurrentLocale } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import { useRef } from 'react';
import { useSWRConfig } from 'swr';

import { useFilterValues, useMergeFilterValues } from './context';
import { PathFilterMapper } from './mappers/PathFilterMapper';

export function useFiltersResponse(isOutOfStock = false): FiltersResponse {
  // Ensure that always a value is returned
  const { fallback } = useSWRConfig();
  const fallbackValue = fallback[FILTERS_RESPONSE_SWR_FALLBACK_KEY];

  const { locale } = useCurrentLocale();

  const { data } = useGetFilters({
    filters: {},
    locale,
    zipCode: undefined,
  });

  // For out-of-stock pages, we want to use the Displayed Cars filters, which is already in the page-data
  return !isOutOfStock ? (data ?? fallbackValue) : fallbackValue;
}

export function deserializeFiltersFromPath(
  urlPath: string,
  basePathname: string,
  locale: string,
  filtersResponse: FiltersResponse
): FilterValuesObject {
  const pathFilterMapper = new PathFilterMapper(
    basePathname,
    locale,
    filtersResponse
  );

  return pathFilterMapper.deserialize(urlPath);
}

export function serializeFiltersToPath(
  filterValues: FilterValuesObject,
  basePathname: string,
  locale: string,
  filtersResponse: FiltersResponse
): string {
  const pathFilterMapper = new PathFilterMapper(
    basePathname,
    locale,
    filtersResponse
  );

  return pathFilterMapper.serialize(filterValues);
}

export function getIsOutOfStock(
  filterValues: FilterValuesObject,
  filtersResponse: FiltersResponse
): boolean {
  const selectedBrandIds = filterValues[FilterKey.BRANDS] ?? null;

  if (selectedBrandIds === null) {
    return false;
  }

  const allBrands = filtersResponse[FilterKey.BRANDS];
  const responseBrands = allBrands.filter((brand) =>
    selectedBrandIds.includes(brand.id)
  );

  if (!responseBrands) {
    return false;
  } else {
    if (responseBrands.every((responseBrand) => !responseBrand?.available)) {
      return true;
    }
  }

  const selectedModelIds = filterValues[FilterKey.MODELS] ?? null;

  if (selectedModelIds === null) {
    return false;
  }

  const responseModels: (Model | undefined)[] = [];
  responseBrands.forEach((responseBrand) => {
    responseBrand?.models.forEach((model) => {
      if (selectedModelIds?.includes(model.id) && model.available) {
        responseModels.push(model);
      }
    });
  });

  return !responseModels.length;
}

export function useIsOutOfStock(): boolean {
  const filterValues = useFilterValues();
  const filtersResponse = useFiltersResponse();

  return getIsOutOfStock(filterValues, filtersResponse);
}

type AvailableFiltersProps = {
  filters?: FilterValuesObject;
  isOutOfStockPage?: boolean;
  ssrFilters?: FiltersResponse;
};

/**
 * Returns the next set of filters you could apply, based on already applied filters. (eg. only type SUV when filtering for Humvee)
 *
 * @param filtersObject Object containing currently filters applied in the client and when initially loading the page (ssr)
 * @returns The next set of filters that can be applied
 */
export function useAvailableFilters(
  filtersObject: AvailableFiltersProps
): FiltersResponse {
  const { locale } = useCurrentLocale();
  const filterValues = useFilterValues();
  const filterResponse = useFiltersResponse();
  const responseRef = useRef<FiltersResponse>(null);

  const filters = filtersObject?.filters;
  const ssrFilters = filtersObject?.ssrFilters;

  const selectedFilters = filters || filterValues || {};

  const hide_related =
    !isServer() &&
    Cookies.get(`${locale}_${Features.ExpRelatedConfigs}`) === 'b';

  const { data: nextFilterResponse } = useGetFilters(
    {
      filters: { ...selectedFilters }, // need to spread to create a copy otherwise a bug appears
      locale,
      zipCode: undefined,
      hide_related,
    },
    filtersObject?.isOutOfStockPage
  );

  responseRef.current =
    nextFilterResponse ?? ssrFilters ?? responseRef.current ?? filterResponse;

  return responseRef.current!;
}

export function useAddFilterValues() {
  const filterValues = useFilterValues();
  const mergeFilterValues = useMergeFilterValues();

  return (newValues: FilterValuesObject) => {
    const nextFilterValues: FilterValuesObject = {};
    const instrArray = Object.entries(newValues) as [FilterKey, any][];

    instrArray.forEach(([key, instruction]) => {
      let existingValue = filterValues[key];

      if (!existingValue) {
        nextFilterValues[key] = instruction as never;

        return;
      }

      const type = typeof existingValue;

      if (type !== 'object') {
        existingValue = [existingValue as string];
      }
      const newArray = existingValue as string[];

      const newItems = (instruction as string[]).filter(
        (item: string) => !(existingValue as string[]).includes(item)
      );

      nextFilterValues[key] = newArray.concat(newItems) as never;
    });

    mergeFilterValues(nextFilterValues);
  };
}

export const haveFilterValuesChanged = (
  currentValues: FilterValuesObject,
  previousValues: FilterValuesObject
): boolean => {
  return !isEqual(currentValues, previousValues);
};
