import { cn } from '@finn/ui-utils';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ReactElement,
  ReactNode,
} from 'react';

import { getComponentDisplayName } from '../../internal/react';
import { Slot } from '../../internal/Slot';

type BannerModuleTitleProps = {
  asChild?: boolean;
  children?: ReactNode;
  className?: string;
};

export const BannerModuleTitle = ({
  asChild,
  className,
  ...props
}: BannerModuleTitleProps) => {
  const Comp = asChild ? Slot : 'h2';

  return <Comp className={cn('body-14-semibold', className)} {...props} />;
};

export const BannerModuleDescription = ({
  asChild,
  className,
  ...props
}: BannerModuleTitleProps) => {
  const Comp = asChild ? Slot : 'p';

  return <Comp className={cn('body-14-light', className)} {...props} />;
};

export const BannerModuleIcon = ({
  className,
  ...props
}: BannerModuleTitleProps) => (
  <Slot
    className={cn('row-span-3 h-6 w-6 self-center', className)}
    {...props}
  />
);

BannerModuleIcon.displayName = 'BannerModuleIcon';

type BannerModuleProps = {
  asChild?: boolean;
  children: ReactNode;
} & ComponentPropsWithoutRef<'section'>;

export const BannerModule = ({
  asChild,
  children,
  className,
  ...props
}: BannerModuleProps) => {
  const Comp = asChild ? Slot : 'section';
  let icon: ReactElement | null = null;
  const restChildren: ReactElement[] = [];

  // we need to render trigger separatly from the rest of the children
  Children.forEach(children as ReactElement[], (child) => {
    if (getComponentDisplayName(child) === 'BannerModuleIcon') {
      icon = child;
    } else {
      restChildren.push(child);
    }
  });

  return (
    <Comp
      className={cn('bg-snow grid gap-4 rounded-sm px-4 py-6', className)}
      aria-live="polite"
      {...props}
    >
      <>
        {icon}
        {Children.map(
          restChildren,
          (child) =>
            child &&
            cloneElement(child, {
              className: cn(
                icon ? 'col-start-2' : '',
                (child as ReactElement<{ className?: string }>)?.props
                  ?.className
              ),
              // TODO fix any
            } as any)
        )}
      </>
    </Comp>
  );
};
