import { MenuBlock } from '@finn/ua-header';
import {
  B2BHeaderMenuSection,
  HeaderMenu,
  HeaderMenuSection,
} from '@finn/ui-cosmic';
import { cn, type HandleClickFn, useIsomorphicIsServer } from '@finn/ui-utils';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';

const RIBBON_HEIGHT = 42;

// TODO dirty hack to overcome wrong menu position during scroll
// instead we probably should refactor menu to position: absolute
const useHeaderScrollTopOffset = (hasRibbon: boolean) => {
  const [scrollY, setScrollY] = useState(0);
  const isServer = useIsomorphicIsServer();
  const cosmicHelperContainer = isServer
    ? null
    : window.document.getElementById('cosmic-helper-container');
  const cosmicHelperHeight = cosmicHelperContainer?.clientHeight || 0;

  useEffect(() => {
    const CHECK_SCROLL_POSITION_DELAY = 500;
    setScrollY(window?.scrollY);

    const refreshScrollY = throttle(() => {
      setScrollY(window?.scrollY);
    }, CHECK_SCROLL_POSITION_DELAY);
    window.addEventListener('scroll', refreshScrollY, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', refreshScrollY);
    };
  }, []);

  return useMemo(
    () => ({
      top: 64 - scrollY + cosmicHelperHeight + (hasRibbon ? RIBBON_HEIGHT : 0),
    }),
    [cosmicHelperHeight, hasRibbon, scrollY]
  );
};

const DesktopMenu = ({
  data,
  hasRibbon,
  handleClick,
}: {
  data: HeaderMenu;
  handleClick: HandleClickFn;
  hasRibbon: boolean;
}) => {
  const sections = useMemo(
    () =>
      data.metadata.sections.map(
        (section: HeaderMenuSection | B2BHeaderMenuSection) => section.metadata
      ),
    [data.metadata.sections]
  );

  const scrollStyleHack = useHeaderScrollTopOffset(hasRibbon);

  return (
    <nav
      id="menu"
      className={cn(
        'fixed top-16 z-[1200] mx-auto my-0 hidden w-full max-w-[calc(100vw-2rem)] bg-transparent pt-2 md:left-6 md:max-w-[calc(100vw-3rem)] lg:left-[calc(((100vw-1260px)/2)-40px)] lg:max-w-[calc(1260px+5rem)]'
      )}
      style={scrollStyleHack}
    >
      <div className="shadow-highlight grid min-h-96 w-full grid-cols-[repeat(4,1fr)] rounded bg-white p-10">
        {/* ANY type because I want a wild card */}
        {sections.map((sectionData: any, idx: number) => (
          <div
            key={sectionData.type + idx}
            className={cn('mr-4 pr-6 last:mx-0 last:border-r-0 last:pr-0')}
          >
            <MenuBlock data={sectionData} handleClick={handleClick} />
          </div>
        ))}
      </div>
    </nav>
  );
};

export default DesktopMenu;
