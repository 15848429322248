import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { CheckCircleOutlined } from '@finn/design-system/icons/check-circle-outlined';
import { ErrorOutlined } from '@finn/design-system/icons/error-outlined';
import { MailOutlined } from '@finn/design-system/icons/mail-outlined';
import { ProgressActivity } from '@finn/design-system/icons/progress-activity';
import { WarningFilled } from '@finn/design-system/icons/warning-filled';

const iconsMap = {
  email_sent: MailOutlined,
  operation_success: CheckCircleOutlined,
  operation_pending: ProgressActivity,
  operation_failed: ErrorOutlined,
  alert: WarningFilled,
};

type MessageModalProps = {
  open: boolean;
  onClose(): void;
  title: string;
  description: string;
  btnText: string;
  iconType?: keyof typeof iconsMap;
};

export const MessageModal = ({
  open,
  onClose,
  title,
  description,
  btnText,
  iconType,
}: MessageModalProps) => {
  const ModalIcon = (iconType && iconsMap[iconType]) || MailOutlined;

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(nextOpen) => {
        if (!nextOpen) {
          onClose();
        }
      }}
    >
      <ModalContent>
        {iconType && <ModalIcon className="mx-auto min-h-14 min-w-14" />}
        <ModalHeader>
          <ModalTitle className="text-center">{title}</ModalTitle>
          <ModalSubtitle className="text-center">{description}</ModalSubtitle>
        </ModalHeader>
        <ModalFooter>
          <Button onClick={onClose}>{btnText}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
