import { CheckCircleOutlined } from '@finn/design-system/icons/check-circle-outlined';
import { RadioButtonCircleDefault } from '@finn/design-system/icons/radio-button-circle-default';
import { cn } from '@finn/ui-utils';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  value: string;
};
export const PasswordStrengthCheck: FunctionComponent<Props> = ({ value }) => {
  const password = value ?? '';
  const i18n = useIntl();
  const passwordStrengthCheck = [
    {
      message: i18n.formatMessage({ id: 'userAccount.passwordStrength.min8' }),
      validation(val: string) {
        // Minimum 8 characters
        return val.length > 7;
      },
    },
    {
      message: i18n.formatMessage({
        id: 'userAccount.passwordStrength.containLetter',
      }),
      validation(val: string) {
        // Contains a letter
        const letterRegExp = /[a-zA-Z]/g;

        return letterRegExp.test(val);
      },
    },
    {
      message: i18n.formatMessage({
        id: 'userAccount.passwordStrength.containNumber',
      }),
      validation(val: string) {
        //Contains a number

        const numberRegExp = /\d/g;

        return numberRegExp.test(val);
      },
    },
  ];

  return (
    <div className="mt-4">
      {passwordStrengthCheck.map((strengthLevel) => {
        const achieved = strengthLevel.validation(password);

        return (
          <div key={strengthLevel.message} className="mb-2 flex items-center">
            {achieved ? (
              <CheckCircleOutlined />
            ) : (
              <RadioButtonCircleDefault className="fill-pearl" />
            )}
            <p className={cn('body-14-regular ml-2 text-black')}>
              {strengthLevel.message}
            </p>
          </div>
        );
      })}
    </div>
  );
};
