import dayjs from 'dayjs';

export const AVAILABILITY_KEY = 'availability';

export enum FilterKey {
  SORT = 'sort',
  BRANDS = 'brands',
  CAR_TYPES = 'cartypes',
  MODELS = 'models',
  GEARSHIFTS = 'gearshifts',
  FUELS = 'fuels',
  COLORS = 'colors',
  HAS_HITCH = 'has_hitch',
  VIEW = 'view',
  IS_FOR_BUSINESS = 'is_for_business',
  IS_YOUNG_DRIVER = 'is_young_driver',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  MIN_PRICE_MSRP = 'min_price_msrp',
  MAX_PRICE_MSRP = 'max_price_msrp',
  AVAILABLE_FROM = 'available_from',
  AVAILABLE_TO = 'available_to',
  TERMS = 'terms',
  OFFER_TYPE = 'offer_type',
  SORT_BY_POPULARITY = 'sort_by_popularity',
  RETENTION = 'retention',
  HAS_DEALS = 'has_deals',
  PRODUCT_GROUP = 'product_group',
  HAS_RETENTION_DEALS = 'has_retention_deals',
  FEATURES = 'features',
  TOTAL_RESULTS = 'total_results',
  POPULAR = 'popular_filters',
}

export const enum SortKey {
  ASC = 'asc',
  DESC = 'desc',
  AVAILABILITY = 'availability',
  DEFAULT = 'default',
  LAST_ADDED = 'last_added',
}

export const enum VehicleViewKey {
  AVAILABLE = 'available_cars',
  AVAILABLE_AND_COMING_SOON = 'available-and-coming-soon',
  COMING_SOON = 'coming_soon',
  DISPLAYED_CARS = 'displayed_cars',
}

export const FILTERS_RESPONSE_SWR_FALLBACK_KEY = 'filtersResponse';
export const SHORT_DEALS_FILTER = 'deals';
export const SHORT_HITCH_FILTER = 'hitch';
export const SHORT_YOUNG_DRIVER_FILTER = 'young-driver';
export const SHORT_14_DAYS_FILTER = '14-days';
export const SHORT_30_DAYS_FILTER = '30-days';

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

const today = dayjs();
export const DATE_IN_14_DAYS = today.add(14, 'day').format(FILTER_DATE_FORMAT);
export const DATE_IN_30_DAYS = today.add(30, 'day').format(FILTER_DATE_FORMAT);

export const PRICING_TYPE = {
  NORMAL: 'normal',
  DOWNPAYMENT: 'downpayment',
} as const;

export type PricingType = (typeof PRICING_TYPE)[keyof typeof PRICING_TYPE];
