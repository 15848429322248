import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { cn } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

export enum CollapseTypes {
  BORDERED = 'bordered',
  BORDERLESS = 'borderless',
}

type CollapseProps = {
  isExpanded?: boolean;
  title: string | React.ReactElement;
  index?: number;
  type?: CollapseTypes;
  className?: string;
  onValueChange: (expanded?: string[]) => void;
  children?: ReactNode;
};

export const Collapse = ({
  children,
  title,
  type,
  index,
  className,
  isExpanded,
  onValueChange,
}: CollapseProps) => {
  return (
    <Accordion
      onValueChange={onValueChange}
      defaultValue={isExpanded ? [String(index)] : []}
      type="multiple"
      className={cn('w-full', className)}
    >
      <AccordionItem value="0">
        <AccordionTrigger withBorder={type !== CollapseTypes.BORDERLESS}>
          {title}
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
