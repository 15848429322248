import { CountryCode, useCurrentLocale } from '@finn/ui-utils';
import dayjs, { Dayjs } from 'dayjs';
import keys from 'lodash/keys';
import { ChangeEvent, FocusEvent, useState } from 'react';

import { validateEmail, validatePassword } from './validation';
import { checkPhoneNumberValidation, isValidAge, validNameRegex } from './yup';

type Error = string | null | boolean;

type Form = {
  errors: {
    email?: Error;
    password?: Error;
    firstname?: Error;
    lastname?: Error;
    phone?: Error;
    birthday?: Error;
  };
  values: {
    email?: string;
    password?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    birthday?: Date;
  };
};

export enum Fields {
  Email = 'email',
  Password = 'password',
  FirstName = 'firstname',
  LastName = 'lastname',
  Phone = 'phone',
  Birthday = 'birthday',
}
export const useForm = ({
  defaultValues,
  hasNewPassword = false,
  isRegistration = false,
}: {
  defaultValues: Form['values'];
  hasNewPassword?: boolean;
  isRegistration?: boolean;
}) => {
  const { region } = useCurrentLocale();
  const [{ values, errors }, setFormState] = useState<Form>({
    values: defaultValues,
    errors: {
      email: null,
      password: null,
      firstname: null,
      lastname: null,
      phone: null,
      birthday: null,
    },
  });
  const setValues = (newValues: Form['values']) => {
    setFormState((previousState: Form) => ({
      ...previousState,
      values: {
        ...previousState.values,
        ...newValues,
      },
    }));
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValues({ [event.target.name]: event.target.value });
  };

  const handlePhoneChange = (phone: string) => {
    setValues({ [Fields.Phone]: phone });
  };

  const handleBirthdayChange = (birthday: Dayjs) => {
    setValues({ [Fields.Birthday]: birthday?.toDate() });
  };

  const setError = (field: Fields, error: Error) => {
    setFormState((previousState) => ({
      ...previousState,
      errors: {
        ...previousState.errors,
        [field]: error,
      },
    }));
  };

  /**
   * Validate field
   * @param field
   * @returns {boolean} true if field is valid
   */
  const validate = (field: Fields) => {
    switch (field) {
      case Fields.Email: {
        const emailValue = values[Fields.Email];
        const isValidEmail = validateEmail(emailValue || '');

        if (isValidEmail) {
          setError(Fields.Email, null);

          return true;
        } else {
          setError(
            Fields.Email,
            emailValue?.length ? 'yup.email' : 'yup.required'
          );
        }
        break;
      }
      case Fields.Password: {
        const passwordValue = values[Fields.Password];
        // force strength check validation only for registration. login should be able to submit any non-empty password
        const isValidPassword = hasNewPassword
          ? validatePassword(passwordValue || '')
          : !!passwordValue;
        if (isValidPassword) {
          setError(Fields.Password, null);

          return true;
        } else {
          setError(
            Fields.Password,
            passwordValue?.length ? hasNewPassword : 'yup.required'
          );
        }
        break;
      }
      // additional validation for extended account experiment,
      // todo: Whole login/registration form should be converted to Formik or React-hook-form and yup validation in the future
      case Fields.FirstName: {
        if (!isRegistration) {
          return true;
        }
        const firstNameValue = values[Fields.FirstName];
        if (!firstNameValue) {
          setError(Fields.FirstName, 'yup.required');
        } else if (!validNameRegex.test(firstNameValue)) {
          setError(Fields.FirstName, 'yup.string');
        } else {
          setError(Fields.FirstName, null);

          return true;
        }
        break;
      }
      case Fields.LastName: {
        if (!isRegistration) {
          return true;
        }
        const lastNameValue = values[Fields.LastName];
        if (!lastNameValue) {
          setError(Fields.LastName, 'yup.required');
        } else if (!validNameRegex.test(lastNameValue)) {
          setError(Fields.LastName, 'yup.string');
        } else {
          setError(Fields.LastName, null);

          return true;
        }
        break;
      }
      case Fields.Birthday: {
        if (!isRegistration) {
          return true;
        }
        const birthdayValue = values[Fields.Birthday];
        const validDate = dayjs(birthdayValue).isValid();
        const validAge = birthdayValue && isValidAge(birthdayValue, region);
        if (!birthdayValue) {
          setError(Fields.Birthday, 'yup.required');
        } else if (!validDate) {
          setError(Fields.Birthday, 'yup.validDate');
        } else if (!validAge) {
          setError(
            Fields.Birthday,
            region === CountryCode.US ? 'yup.age>=25' : 'yup.age>=18'
          );
        } else {
          setError(Fields.Birthday, null);

          return true;
        }
        break;
      }
      case Fields.Phone: {
        if (!isRegistration) {
          return true;
        }
        const phoneValue = values[Fields.Phone];
        const isValidNumber = checkPhoneNumberValidation(
          phoneValue || '',
          region
        );
        if (!phoneValue) {
          setError(Fields.Phone, 'yup.required');
        } else if (!isValidNumber) {
          setError(Fields.Phone, 'yup.validPhoneNumber');
        } else {
          setError(Fields.Phone, null);

          return true;
        }
        break;
      }
      default: {
        console.error('unknown field');
        break;
      }
    }
  };

  const handleBlur = (
    evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const field = evt.target.name as Fields;
    validate(field);
  };

  const onSubmit = async (callback: () => void) => {
    const fields = keys(defaultValues);
    let shouldSubmit: boolean | undefined = true;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      shouldSubmit = shouldSubmit && validate(field as Fields);
    }

    if (shouldSubmit) {
      await callback();
    }
  };

  return {
    handleChange,
    handlePhoneChange,
    handleBirthdayChange,
    handleBlur,
    onSubmit,
    values,
    errors,
    setValues,
    setError,
  };
};
