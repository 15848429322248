import { config } from '@finn/ui-utils';

type Params = {
  link: string;
  stage: string | string[];
  prefix?: string | string[];
};

enum ENVS {
  DEV = 'dev',
  PREVIEW = 'preview',
}

export const getDeepLink = ({ link, stage, prefix }: Params) => {
  if (stage === ENVS.DEV) {
    return `${prefix}${link}`;
  } else if (stage === ENVS.PREVIEW) {
    return `${config.APP_PREVIEW_PREFIX}${link}`;
  }

  return `${config.APP_PREFIX}${link}`;
};
