import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { type HandleClickFn } from '@finn/ui-utils';
import { ReactNode } from 'react';

export type HandleAccordionParams = {
  isOpen: boolean;
};

type Props = {
  title: string;
  titleHref?: string;
  onClick?: HandleClickFn;
  expandInitially?: boolean;
  children?: ReactNode;
};

const SubMenuAccordion = ({ children, title, titleHref, onClick }: Props) => {
  return (
    <Accordion type="multiple" defaultValue={[]}>
      <AccordionItem value="item">
        <AccordionTrigger
          className="max-h-16 border-none"
          onClick={(event) =>
            onClick?.({
              link: titleHref || title,
              type: NAVIGATION_TRACKING_EVENTS.MAIN,
              e: event,
              isAccordion: true,
            })
          }
        >
          {title}
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
export default SubMenuAccordion;
