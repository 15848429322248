import { ResponsiveCosmicImage, UIBaseCosmicObject } from './commons';
import { CTAData } from './cta';

export enum HeroType {
  primary = 'primary', // Text overlay the image.
  secondary = 'secondary', // Image separated on the text.
  tertiary = 'tertiary', // Only text without image
}

type HeroLevel = {
  key: HeroType;
  value: HeroType;
};

export type GuideMetaData = {
  date: string;
  photos_by: string;
  reading_time: string;
  written_by: string;
};

export type GuideMetaDataCosmic = {
  metadata: GuideMetaData;
};

export type HeroCosmicMetadata = {
  title: string;
  subtitle: string;
  button_text: string;
  button_link: string;
  level: HeroLevel;
  image: ResponsiveCosmicImage;
  cta?: CTAData;
  secondary_cta?: CTAData;
  guide_meta_data: GuideMetaDataCosmic;
  label: string;
};

export type HeroCosmicData = UIBaseCosmicObject<HeroCosmicMetadata>;
