export { getDefaultRichTextElements } from './Localization';

import Cookies from 'js-cookie';
import { GetStaticPropsContext, NextPageContext } from 'next';
import { IntlShape, useIntl } from 'react-intl';

import { Locale } from '../../types/localization';
import { CookieKeys } from '../cookies';
import { getLocaleFromContext } from '../locale';
import { getCorrectLocale } from '../localization';
import { formatNumberParts } from '../number';

export const generateLocalizationHeaders = (locale?: string) => ({
  'X-Language-Tag': locale || Locale.GERMAN_GERMANY,
});

export const CURRENCIES = {
  EURO: 'EUR',
  USD: 'USD',
};

export const LOCALE_CURRENCIES: { [key: string]: string } = {
  [Locale.GERMAN_GERMANY]: CURRENCIES.EURO,
  // TODO en_DE support
  [Locale.ENGLISH_GERMANY]: CURRENCIES.EURO,
  [Locale.ENGLISH_USA]: CURRENCIES.USD,
};

export const getCorrectLocaleString = (locale?: string): Locale => {
  const { lang, region } = getCorrectLocale(locale);

  return `${lang}-${region}` as Locale;
};

export const constructFormattedPriceResponse = (
  intl: IntlShape,
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const parsedLocale = getCorrectLocaleString(intl.locale);
  const currency = LOCALE_CURRENCIES[parsedLocale];

  return formatNumberParts(
    intl.formatNumberToParts(value, {
      style: 'currency',
      currency,
      minimumFractionDigits: forceDigits ? maximumFractionDigits : 0,
      maximumFractionDigits,
    })
  );
};

export const getFormattedPrice = (
  intl: IntlShape,
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  return constructFormattedPriceResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};

export const constructFormattedNumberResponse = (
  intl: IntlShape,
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const roundedValue = maximumFractionDigits ? value : Math.ceil(value);

  return intl.formatNumber(roundedValue, {
    minimumFractionDigits: forceDigits ? maximumFractionDigits : 0,
    maximumFractionDigits,
  });
};

export const getFormattedNumber = (
  intl: IntlShape,
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  return constructFormattedNumberResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};

export const generateLocalizationHeadersFromCookie = () => {
  const localeCookie = Cookies.get(CookieKeys.NEXT_LOCALE);

  return generateLocalizationHeaders(localeCookie);
};

export const generateLocalizationHeadersFromContext = (
  ctx: NextPageContext | GetStaticPropsContext
) => {
  return generateLocalizationHeaders(getLocaleFromContext(ctx));
};

// TODO hack to support multilocales
export const withLocaleRedirect = (url: string, locale: string) =>
  locale === Locale.GERMAN_GERMANY || !locale ? url : `/${locale}${url}`;

export const useFormattedPrice = (
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const intl = useIntl();

  return constructFormattedPriceResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};
