import {
  isValidPhone,
  validCompanyNameRegex,
  validNameRegex,
} from '@finn/ua-auth';
import {
  ageBoundaries,
  LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE,
  SESSION_STORAGE_LAST_INVALID_EMAIL,
} from '@finn/ua-constants';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { ClientType } from '~/types/checkout';
import { CountryCode } from '~/types/localization';
import { getAgeInYears } from '~/utils/time';

import { addressShape } from '../../checkoutConfig';

type ContactFormValidationProps = {
  region?: CountryCode;
  vehicle?: GenericVehicleDetails;
};

const getAddressShapeNext = () => {
  return addressShape;
};

export const initialValues = {
  contact: {
    type: ClientType.PRIVATE,
    firstname: null,
    lastname: null,
    street: null,
    housenumber: null,
    zipcode: null,
    city: null,
    phone: null,
    birthday: null,
    email: null,
    // TODO probably should be dynamic based on geo
    state: null,
  },
  b2bContact: {
    company: '',
    organizationType: '',
    fleetSize: '',
  },
  deliveryAddress: {
    firstname: null,
    lastname: null,
    street: null,
    housenumber: null,
    zipcode: null,
    city: null,
    // TODO probably should be dynamic based on geo
    state: null,
    extra: '',
  },
  preferences: {
    emailSubscription: false,
    sameDeliveryAddress: false,
  },
};

const leadContactShape = ({ region, vehicle }: ContactFormValidationProps) => ({
  firstname: Yup.string()
    .matches(validNameRegex, {
      message: 'yup.string',
      excludeEmptyString: true,
    })
    .nullable()
    .required('yup.required'),
  lastname: Yup.string()
    .matches(validNameRegex, {
      message: 'yup.string',
      excludeEmptyString: true,
    })
    .nullable()
    .required('yup.required'),
  phone: isValidPhone('phoneNumber', region),
  birthday: Yup.date()
    .test('Valid date', 'yup.validDate', (value) => dayjs(value).isValid())
    .test('DOB', 'yup.age>=18', (value) => {
      return getAgeInYears(value) >= ageBoundaries.MIN_EXCEPTIONAL;
    })
    .test('DOB High Power Car', 'yup.age>=23', (value) => {
      if (vehicle?.power > LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE) {
        return getAgeInYears(value) >= ageBoundaries.MIN_NORMAL;
      } else {
        return true;
      }
    })
    .nullable()
    .required('yup.required'),
  email: Yup.string()
    .email('yup.email')
    .required('yup.required')
    .test('email', 'yup.email', (value) => {
      return (
        value !==
        window.sessionStorage.getItem(SESSION_STORAGE_LAST_INVALID_EMAIL)
      );
    }),
});

const contactShape = ({ region, vehicle }: ContactFormValidationProps) => ({
  ...leadContactShape({ region, vehicle }),
  ...getAddressShapeNext(),
  type: Yup.mixed<ClientType>()
    .oneOf(Object.values(ClientType))
    .required('yup.required'),
});

const deliveryAddressShape = {
  ...addressShape,
  firstname: Yup.string()
    .matches(validNameRegex, {
      message: 'yup.string',
      excludeEmptyString: true,
    })
    .nullable()
    .required('yup.required'),
  lastname: Yup.string()
    .matches(validNameRegex, {
      message: 'yup.string',
      excludeEmptyString: true,
    })
    .nullable()
    .required('yup.required'),
  extra: Yup.string().max(100, 'yup.characters<=100').nullable(),
};

const b2bContactShape = {
  company: Yup.string()
    .matches(validCompanyNameRegex, {
      message: 'yup.string',
      excludeEmptyString: true,
    })
    .nullable()
    .required('yup.required'),
  organizationType: Yup.string().nullable().required('yup.required'),
  fleetSize: Yup.number().nullable(),
};

const preferencesShape = {
  emailSubscription: Yup.bool().required('yup.required'),
  sameDeliveryAddress: Yup.bool().required('yup.required'),
};

export const deliveryAddressValidationScheme =
  Yup.object().shape(deliveryAddressShape);
const b2bContactValidationScheme = Yup.object().shape(b2bContactShape);

export const validationSchemaEmailPrompt = Yup.object({
  contact: Yup.object({
    type: Yup.mixed<ClientType>()
      .oneOf(Object.values(ClientType))
      .required('yup.required'),
    email: Yup.string().email('yup.email').required('yup.required'),
  }),
});

export const createValidationSchemaLead = ({
  region,
  vehicle,
}: ContactFormValidationProps = {}) => {
  return Yup.object({
    contact: Yup.object().shape(leadContactShape({ region, vehicle })),
  });
};

export const createValidationSchema = ({
  region,
  vehicle,
}: ContactFormValidationProps = {}) => {
  return Yup.object({
    contact: Yup.object().shape(contactShape({ region, vehicle })),
    deliveryAddress: Yup.mixed<
      Yup.InferType<typeof deliveryAddressValidationScheme>
    >().when('preferences.sameDeliveryAddress', {
      is: false,
      then: Yup.object().shape(deliveryAddressShape),
    }),
    b2bContact: Yup.mixed<
      Yup.InferType<typeof b2bContactValidationScheme>
    >().when('contact.type', {
      is: ClientType.BUSINESS,
      then: b2bContactValidationScheme,
    }),
    preferences: Yup.object().shape(preferencesShape),
  });
};

// TODO re-export for back compatibility
export const validationSchema = createValidationSchema();
export const validationSchemaLead = createValidationSchemaLead();

export type ContactValues = Yup.InferType<typeof validationSchema>;
export type ContactValuesLead = Yup.InferType<typeof validationSchemaLead>;
export type ContactValuesSignupEXP = Yup.InferType<
  typeof validationSchemaEmailPrompt
>;
