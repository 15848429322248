import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import { parse, ParsedQuery, stringify } from 'query-string';
import { ParsedUrlQuery } from 'querystring';

import {
  firstLvlPathParamsKeys,
  secondLvlPathParamsKeys,
  thirdLvlQueryParamsKeys,
} from '~/utils/filter';

export function stringToArray(query: ParsedQuery<string | boolean>) {
  const optimizedQuery = { ...query };

  Object.keys(query).forEach((key) => {
    const isSingleSelect = thirdLvlQueryParamsKeys.find(
      (element) => element === key
    );
    const isArray = Array.isArray(optimizedQuery[key]);

    // brands, cartypes etc. are handled as arrays in UI
    if (!isSingleSelect && !isArray) {
      const currValue = optimizedQuery[key] as string | null | undefined;

      optimizedQuery[key] = currValue ? [currValue] : null;
    }
  });

  return optimizedQuery;
}

export function getFilterConfigByUrlParams(
  query?: ParsedUrlQuery,
  useQueryParamsOnly?: boolean
) {
  if (!query) return {};

  const routeQuery = useQueryParamsOnly
    ? pick(query, [
        ...Object.values(firstLvlPathParamsKeys),
        ...secondLvlPathParamsKeys,
        ...thirdLvlQueryParamsKeys,
      ])
    : pick(query, thirdLvlQueryParamsKeys);

  // convert stringified booleans to real booleans
  const lowerQuery = toLower(stringify(routeQuery));
  const queryWithBool = parse(lowerQuery, {
    parseBooleans: true,
    arrayFormat: 'comma',
  });
  // convert strings to arrays if they're not single-select
  const queryWithArrays = stringToArray(queryWithBool);

  return queryWithArrays;
}
