import { CosmicModuleType } from '@finn/ui-cosmic';
import { type HandleClickFn } from '@finn/ui-utils';
import React, { memo } from 'react';

import B2BMenuBlockInfoCards from './B2BMenuBlockInfoCards';
import B2BMenuIntro from './B2BMenuIntro';
import B2BProgramsList from './B2BProgramsList';
import MenuBlockBenefits from './MenuBlockBenefits';
import MenuBlockInfoCard from './MenuBlockInfoCard';
import MenuIntro from './MenuIntro';
import MenuList from './MenuList';
import PopularBrands from './PopularBrands';
import PopularModels from './PopularModels';

type Props = {
  data: any;
  handleClick: HandleClickFn;
};

const MenuBlock: React.FunctionComponent<Props> = ({ data, handleClick }) => {
  if (data.type === CosmicModuleType.MENU_INTRO)
    return <MenuIntro data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_BENEFITS)
    return <MenuBlockBenefits data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_POPULAR_BRANDS)
    return <PopularBrands data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_POPULAR_MODELS)
    return <PopularModels data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_LIST)
    return <MenuList data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_CARD)
    return <MenuBlockInfoCard data={data} handleClick={handleClick} />;

  // Business Customers Menu.
  if (data.type === CosmicModuleType.MENU_B2B_INTRO)
    return <B2BMenuIntro data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_B2B_PROGRAMS_LIST)
    return <B2BProgramsList data={data} handleClick={handleClick} />;
  if (data.type === CosmicModuleType.MENU_B2B_PROGRAMS_CARDS)
    return <B2BMenuBlockInfoCards data={data} handleClick={handleClick} />;

  return null;
};

export default memo(MenuBlock);
