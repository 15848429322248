import { HookFormInput } from '@finn/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { HookFormDatePicker } from '../../components/DatePicker/HookFormDatePicker';
import { HookFormPhoneInput } from '../../components/HookFormPhoneInput';

const placeholderPrefix = 'checkout.pageData.input_field_placeholders';

export const ExtendedAccountFields: React.FC = () => {
  const form = useFormContext();
  const i18n = useIntl();
  const firstNameLabel = i18n.formatMessage({
    id: `${placeholderPrefix}.firstname`,
  });
  const lastNameLabel = i18n.formatMessage({
    id: `${placeholderPrefix}.lastname`,
  });

  return (
    <>
      <HookFormInput
        name="firstname"
        label={firstNameLabel}
        form={form}
        autoFocus={true}
      />
      <HookFormInput name="lastname" label={lastNameLabel} form={form} />
      <HookFormDatePicker
        name="birthday"
        label={i18n.formatMessage({
          id: `${placeholderPrefix}.birthday`,
        })}
        form={form}
      />
      <HookFormPhoneInput
        name="phone"
        label={i18n.formatMessage({
          id: `${placeholderPrefix}.phone`,
        })}
        placeholder={i18n.formatMessage({
          id: `${placeholderPrefix}.phone_number_format`,
        })}
        form={form}
      />
    </>
  );
};
