import { useMediaQuery } from '@finn/design-system/helpers/media';
import { cn, parseToHtml, useIntersectionObserver } from '@finn/ui-utils';
import { ElementType, MutableRefObject, ReactNode, useRef } from 'react';

import { SliderArrow } from './SliderArrow';

const MAX_SCREEN_SIZE = '4000px';
export type NumericHeadingSizes = 1 | 2 | 3 | 4 | 5 | 6;

const titleHeadingSizeFonts = {
  1: 'mobile-t1-semibold md:web-t1-semibold',
  2: 'mobile-t2-semibold md:web-t2-semibold',
  3: 'mobile-t3-semibold md:web-t3-semibold',
  4: 'mobile-t4-semibold md:web-t4-semibold',
  5: 'global-t5-semibold',
  6: 'global-t6-semibold',
};

type SliderProps = {
  children: ReactNode;
  isGallery?: boolean;
  isForIframe?: boolean;
  title?: string;
  className?: string;
  verticalSpacing?: 1 | 2 | 3;
  titleHeadingSize?: NumericHeadingSizes;
  firstItemForwardedRef?: MutableRefObject<HTMLElement | null>;
  lastItemForwardedRef?: MutableRefObject<HTMLElement | null>;
  testId?: string;
};
export const Slider = ({
  children,
  title,
  className,
  titleHeadingSize = 3,
  isGallery = false,
  isForIframe = false,
  verticalSpacing,
  firstItemForwardedRef,
  lastItemForwardedRef,
  testId,
}: SliderProps) => {
  let isMobile = useMediaQuery('sm');
  const isMobileIframe = useMediaQuery('md');
  if (isForIframe) {
    isMobile = isMobileIframe;
  }

  const sliderRef = useRef<HTMLUListElement | null>(null);
  const firstItemRef = useRef<HTMLLIElement | null>(null);
  const lastItemRef = useRef<HTMLLIElement | null>(null);

  const rootMargin = `${MAX_SCREEN_SIZE} 0px ${MAX_SCREEN_SIZE} 0px`; // To get observer only on X axis
  const isFirstItemInView = useIntersectionObserver(
    isGallery ? firstItemForwardedRef : firstItemRef,
    1.0,
    rootMargin
  );
  const isLastItemInView = useIntersectionObserver(
    isGallery ? lastItemForwardedRef : lastItemRef,
    0.9,
    rootMargin
  );

  const isLeftArrowEnabled = !isFirstItemInView;
  const isRightArrowEnabled = !isLastItemInView;

  // TitleElement is a dynamic element based on titleHeadingSize
  // it is created for back compability with Heading component during DS migration
  // as previously we allowed this to be dynamic for some reason
  const TitleElement = `h${titleHeadingSize}` as ElementType;

  return (
    <>
      {title && (
        <div
          className="container mb-10 flex justify-between"
          data-testid={testId}
        >
          <TitleElement
            className={cn(
              'whitespace-pre-line',
              titleHeadingSizeFonts[titleHeadingSize]
            )}
          >
            {parseToHtml(title)}
          </TitleElement>
          {!isMobile && (
            <div className="flex gap-2">
              <SliderArrow
                direction="left"
                isArrowEnabled={isLeftArrowEnabled}
                sliderRef={sliderRef}
              />
              <SliderArrow
                direction="right"
                isArrowEnabled={isRightArrowEnabled}
                sliderRef={sliderRef}
              />
            </div>
          )}
        </div>
      )}
      <div className={cn('relative', className)}>
        <ul
          className={cn(
            'm-0 w-full overflow-x-auto p-0 [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar]:w-0',
            isGallery ? 'block' : 'flex'
          )}
          ref={sliderRef}
        >
          {isGallery ? null : <li ref={firstItemRef} />}
          {isGallery ? (
            <li className="list-none">{children}</li>
          ) : (
            (children as ReactNode[]).map((component, index) => (
              <li
                className={cn(
                  'list-none pr-6',
                  verticalSpacing === 1 && 'pr-2',
                  verticalSpacing === 2 && 'pr-4',
                  isForIframe
                    ? ''
                    : 'translate-x-[calc(100vw/2-((100vw-32px)/2))] md:translate-x-[calc(100vw/2-((100vw-128px)/2))] lg:translate-x-[calc(100vw/2-630px)]'
                )}
                key={index}
              >
                {component}
              </li>
            ))
          )}
          {isGallery ? null : (
            <li
              ref={lastItemRef}
              className="translate-x-[calc(100vw/2-((100vw-32px)/2))] list-none md:translate-x-[calc(100vw/2-((100vw-128px)/2))] lg:translate-x-[calc(100vw/2-630px)]"
            />
          )}
        </ul>
      </div>
    </>
  );
};
