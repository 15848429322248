'use client';

import { cn } from '@finn/ui-utils';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useContext,
} from 'react';

import { Checkbox } from '../checkbox';
import { ExpandMore } from '../icons/generated/expand-more';
import { Menu, MenuContext, MenuItem, MenuList, MenuTrigger } from '../menu';

const MultiSelect = ({
  value,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof Menu>, 'value' | 'onValueChange'> & {
  value?: string[];
  onValueChange?: (value?: string[]) => void;
}) => {
  const handleValueChange = (nextValue: string) => {
    if (props.onValueChange) {
      props.onValueChange(nextValue?.split?.(',')?.filter(Boolean));
    }
  };

  return (
    <Menu
      className="max-h-max"
      value={value?.join?.(',')}
      {...props}
      onValueChange={handleValueChange}
    />
  );
};
MultiSelect.displayName = 'MultiSelect';

const MultiSelectValue = ({
  placeholder,
  className,
  ...props
}: {
  placeholder?: string;
  className?: string;
}) => (
  <span className={cn('w-full text-start', className)} {...props}>
    {placeholder}
  </span>
);

MultiSelectValue.displayName = 'MultiSelectValue';

const MultiSelectTrigger = forwardRef<
  ElementRef<typeof MenuTrigger>,
  ComponentPropsWithoutRef<typeof MenuTrigger> & {
    size?: 'lg' | 'md';
  }
>(({ className, children, size = 'lg', ...props }, ref) => {
  const { value } = useContext(MenuContext);

  return (
    <MenuTrigger
      ref={ref}
      className={cn(
        'group flex w-max cursor-pointer items-center justify-between gap-2',
        'border-pearl hover:bg-cotton rounded-sm border border-solid bg-white before:hidden after:hidden',
        'px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50',
        'fill-black data-[state=open]:border-black',
        'active:bg-pearl',
        'focus-visible:ring-trustedBlue focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
        {
          // according to design we have 2 primary size variant for select
          'body-16-regular h-14': size === 'lg',
          'body-14-regular h-10': size === 'md',
          'body-14-semibold': value && size === 'md',
          'body-16-semibold': value && size === 'lg',
        },
        className
      )}
      {...props}
    >
      {children}
      {value ? (
        <div className="bg-pearl text-iron body-12-regular flex min-h-6 min-w-6 items-center justify-center rounded">
          {(value as string)?.split(',').length}
        </div>
      ) : null}
      <ExpandMore className="min-h-[18px] min-w-[18px] transition-transform group-data-[state=open]:rotate-180" />
    </MenuTrigger>
  );
});

MultiSelectTrigger.displayName = 'MenuTrigger';

const MultiSelectContent = forwardRef<
  ElementRef<typeof MenuList>,
  ComponentPropsWithoutRef<typeof MenuList>
>(({ className, children, ...props }, ref) => (
  <MenuList
    ref={ref}
    className={cn(
      'relative z-[10000] max-h-[468px] min-w-16 overflow-y-auto rounded-sm bg-white',
      // animations
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
      className
    )}
    {...props}
  >
    {children}
  </MenuList>
));
MultiSelectContent.displayName = 'MultiSelectContent';

const MultiSelectItem = forwardRef<
  ElementRef<typeof MenuItem>,
  ComponentPropsWithoutRef<typeof MenuItem>
>(({ className, children, value, ...props }, ref) => {
  const { value: selectedValue, setValue } = useContext(MenuContext);
  const selectedValues =
    typeof selectedValue === 'string'
      ? selectedValue?.split(',')
      : selectedValue;
  const isSelected = selectedValues?.includes?.(String(value));

  return (
    <MenuItem
      ref={ref}
      className={cn(
        'relative flex w-full cursor-default items-center gap-4',
        'body-16-regular px-6 py-4 before:hidden after:hidden',
        'hover:bg-cotton data-[highlighted]:bg-cotton',
        'outline-none data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
        { 'bg-cotton': isSelected },
        className
      )}
      value={value?.toString()}
      onSelect={() => {
        const nextValues =
          selectedValues
            ?.filter?.((v) => v !== String(value))
            ?.filter(Boolean) || [];
        if (!isSelected) {
          nextValues?.push(String(value));
        }

        setValue?.(nextValues?.join(',') || '');
      }}
      {...props}
    >
      <Checkbox checked={isSelected} className="pointer-events-none" />
      {children}
    </MenuItem>
  );
});
MultiSelectItem.displayName = 'MultiSelectItem';

export {
  MultiSelect,
  MultiSelectValue,
  MultiSelectTrigger,
  MultiSelectContent,
  MultiSelectItem,
};
