import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  CookieKeys,
  getClientCookie,
  IFinnSession,
  isServer,
  setClientCookie,
} from '@finn/ui-utils';

const HUBSPOT_ID_COOKIE_EXPIRATION_DAYS = 90;

export const checkAccountSwitch = async ({
  session,
}: {
  session: IFinnSession | null;
}) => {
  // note: don't call useSession() here as it will trigger a session fetch while logging out making the logout process unreliable
  // instead pass the session from the callee
  if (!session || isServer()) {
    return;
  }
  const idFromCookie = String(getClientCookie(CookieKeys.HUBSPOT_ID) || '');
  const idFromSession = String(session.user?.hs_contact_id);
  if (!idFromCookie) {
    setClientCookie(
      CookieKeys.HUBSPOT_ID,
      idFromSession,
      HUBSPOT_ID_COOKIE_EXPIRATION_DAYS
    );
  }
  if (idFromCookie && idFromSession && idFromCookie !== idFromSession) {
    interactionTrackingEvent(TrackingEventName.ACCOUNT_SWITCHED, {
      previousAccountId: idFromCookie,
      newAccountId: idFromSession,
    });
    setClientCookie(
      CookieKeys.HUBSPOT_ID,
      idFromSession,
      HUBSPOT_ID_COOKIE_EXPIRATION_DAYS
    );
  }
};
