import { isServer } from './server';

declare global {
  interface Window {
    nativeAppSDK?: {
      closeFullScreenGallery(): void;
      sendMessageToApp(props: any): void;
      closeTooltip(): void;
      getTrackingProps(): any;
      on(event: string, cb: () => void): () => void;
    };
  }
}

export const isMobileApp = () => {
  if (isServer()) {
    return undefined;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  return !!window.nativeAppSDK;
};
