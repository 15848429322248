import axios from 'axios';

// netlify function logs are not reliably drained to datadog, so when needed we can use this function to send logs directly to datadog
// this should be logged under the service name 'debug-finn-web'
export const datadogLog = async (data: Record<string, any>) => {
  try {
    await axios.post(
      'https://http-intake.logs.datadoghq.eu/v1/input',
      {
        service: 'debug-finn-web',
        ddsource: 'custom',
        attributes: data,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'DD-API-KEY': process.env.DATADOG_API_KEY as string,
        },
      }
    );
  } catch (err) {
    console.log('calling datadog failed', err);
  }
};
