import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

type Props = {
  noPaddingTop?: boolean;
  className?: string;
  html_id?: string;
  children?: ReactNode;
};

const SpacingWrapper = ({
  children,
  noPaddingTop,
  className,
  html_id,
}: Props) => (
  <div
    className={cn(noPaddingTop ? 'pt-0' : 'pt-24 md:pt-40', className)}
    id={html_id}
  >
    {children}
  </div>
);

export default SpacingWrapper;
