import { Check } from '@finn/design-system/icons/check';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';

const BenefitItem = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center gap-2">
      <Check width={16} height={16} />
      <span className="body-16-light">{text}</span>
    </div>
  );
};

type Props = {
  levelIndex: number;
  data?: LoyaltyCosmicMetadata;
  blur?: boolean;
};

export const BenefitItemList = ({ levelIndex, data, blur }: Props) => {
  const currentLevel = data?.levels?.[levelIndex];
  const moreLabel = currentLevel?.more_label;
  const allBenefits = data?.loyalty_benefits_all || [];
  const benefitsCurrentLevel = allBenefits.filter(
    (benefit) => Number(benefit.metadata.minimum_level.value) - 1 === levelIndex
  );
  const benefitsPreviousLevel = allBenefits.filter(
    (benefit) => Number(benefit.metadata.minimum_level.value) - 1 < levelIndex
  );
  const filteredBenefitsPreviousLevel = benefitsPreviousLevel
    .filter((benefit) => !benefit.metadata.only_for_current_level)
    .slice(0, Number(currentLevel?.max_item_from_previous_level));

  return (
    <div className={cn('flex flex-col gap-6', { 'blur-[5px]': !!blur })}>
      {filteredBenefitsPreviousLevel.map((benefit) => (
        <BenefitItem key={benefit.id} text={benefit.metadata.promo_title} />
      ))}
      {!!(benefitsPreviousLevel?.length && benefitsCurrentLevel?.length) && (
        <div className={cn('body-16-semibold mt-4')}>{moreLabel}</div>
      )}
      {benefitsCurrentLevel.map((benefit) => (
        <BenefitItem
          key={benefit.id}
          text={benefit.metadata.promo_title || benefit.metadata.display_title}
        />
      ))}
    </div>
  );
};
