import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useTrackingStore } from '../stores/useTrackingStore';
import { TrackingEventName } from '../types';

// find all indices of array which are different from previous array
const findChangedIndices = (arr1: string[], arr2: string[]) => {
  return arr1.reduce<number[]>((acc, value, index) => {
    if (value !== arr2?.[index]) {
      acc.push(index);
    }

    return acc;
  }, []);
};

// usually we track when first character is entered, in case we want to override this behavior, we can define it here
const isValidForTrackingEnteredEvent = (fieldName: string, value: any) => {
  switch (fieldName) {
    case 'phone':
      return value?.length > 7;
    case 'birthday':
      return !!value;
    default:
      return !!(value || '')?.trim?.();
  }
};

const isValidForTrackingCheckedEvent = (value: any) => {
  return value === true;
};

// at the moment it tracks only when field is entered in a form made with react hook form
// can be extended to track other events (e.g. validation)
// for validation failures should be tracked in onSubmit handler
export const useHookFormTracking = (
  form: UseFormReturn<any>,
  location?: string
) => {
  const track = useTrackingStore((state) => state.track);
  const [lastValues, setLastValues] = useState<any[]>([]);
  const allFieldValues = form.watch();
  const fieldNames = Object.keys(allFieldValues);
  const values = fieldNames?.map((fieldName) => allFieldValues[fieldName]);
  useEffect(() => {
    const differentIndices = findChangedIndices(values, lastValues);
    fieldNames?.forEach((fieldName, index) => {
      if (!differentIndices?.includes?.(index)) {
        return;
      }
      const value = allFieldValues[fieldName];
      if (isValidForTrackingEnteredEvent(fieldName, value)) {
        track(TrackingEventName.FORM_FIELD_ENTERED, {
          fieldName,
          once: true,
          location,
        });
      }
      if (isValidForTrackingCheckedEvent(value)) {
        track(TrackingEventName.CHECKBOX_CLICKED, {
          fieldName,
          once: true,
          location,
        });
      }
    });
    setLastValues(values);
  }, [...values]);
};
