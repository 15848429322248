import { CountryCode, getFormattedNumber } from '@finn/ui-utils';
import { IntlShape } from 'react-intl';

// TODO extract product related things to separate lib
export const convertKwToPs = (powerInKw: number): number =>
  Math.round(powerInKw * 1.35962);

export const convertKwToHP = (powerInKw: number): number =>
  Math.round(powerInKw * 1.34102);

export const getPowerLabel = (
  intl: IntlShape,
  powerInKw: number,
  region: string,
  isElectric: boolean
): string => {
  const isUSA = region === CountryCode.US;

  let label = '';

  if (isUSA) {
    const powerInHP = convertKwToHP(powerInKw);
    label = isElectric
      ? `${getFormattedNumber(intl, powerInKw)} kW (${getFormattedNumber(
          intl,
          powerInHP
        )} hp)`
      : `${powerInHP} hp`;
  } else {
    const powerInPs = convertKwToPs(powerInKw);
    label = `${getFormattedNumber(intl, powerInKw)} kW (${getFormattedNumber(
      intl,
      powerInPs
    )} PS)`;
  }

  return label;
};
