import { cn } from '@finn/ui-utils';
import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
} from 'react';

import { getComponentDisplayName } from '../../internal/react';
import { Button } from '../button';
import { ArrowBackIos } from '../icons/generated/arrow-back-ios';
import { Close as CloseIcon } from '../icons/generated/close';

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref}
    className={cn(
      // position
      'fixed inset-0 z-[1300] bg-black/45',
      // overlay animations
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = Overlay.displayName;

const DialogContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {
    onBack?: () => void;
    variant?: 'large' | 'small';
  }
>(({ className, children, variant, onBack, ...props }, ref) => (
  <Portal>
    <DialogOverlay />
    <Content
      ref={ref}
      className={cn(
        // position
        'fixed left-[50%] top-[50%] z-[1300] translate-x-[-50%] translate-y-[-50%]',
        // layout
        'box-border flex flex-col gap-8 overflow-auto bg-white',
        // sizes
        'h-full max-h-full w-full p-4 md:h-max md:w-[832px] md:rounded md:p-14',
        // animations
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] duration-200',
        {
          'md:w-[470px]': variant === 'small',
        },
        className
      )}
      {...props}
    >
      <div className="flex justify-between">
        {onBack ? (
          <Button variant="ghost" className="h-max p-0" onClick={onBack}>
            <ArrowBackIos aria-label="back" />
          </Button>
        ) : (
          <div />
        )}
        <Close asChild>
          <Button variant="ghost" className="h-max p-0">
            <CloseIcon aria-label="close-icon" />
          </Button>
        </Close>
      </div>
      {Children.map(
        children as ReactElement<{ className?: string; variant?: string }>,
        (child) => {
          if (getComponentDisplayName(child) === 'ModalFooter') {
            return cloneElement(child, {
              variant,
            });
          }

          return child;
        }
      )}
    </Content>
  </Portal>
));
DialogContent.displayName = Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col space-y-4', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  children,
  variant,
  ...props
}: HTMLAttributes<HTMLDivElement> & { variant?: 'large' | 'small' }) => (
  <div
    className={cn(
      'flex h-full w-full flex-col items-center justify-end gap-2 md:h-fit md:p-4',
      {
        'md:p-0': variant === 'small',
      },
      className
    )}
    {...props}
  >
    {Children.map(children as ReactElement<{ className?: string }>, (child) =>
      cloneElement(child, {
        className: cn(child?.props?.className, 'w-full md:w-max md:min-w-44', {
          'md:w-full': variant === 'small',
        }),
      })
    )}
  </div>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title
    ref={ref}
    className={cn('mobile-t3-semibold md:web-t4-semibold', className)}
    {...props}
  />
));

DialogTitle.displayName = Title.displayName;

const DialogSubtitle = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref}
    className={cn('body-16-light', className)}
    {...props}
  />
));
DialogSubtitle.displayName = Description.displayName;

export {
  Root as DialogRoot,
  Trigger as DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogSubtitle,
};
