import * as snippet from '@segment/snippet';

import { Locale } from '../types/localization';
import config from './config';
import { isServer } from './server';
import { getCurrentSessionData } from './sessionEvents';

type Features = Record<string, string>;

// TODO: remove/merge this when we migrate to the new feature flags
export const BUCKET_DISABLED = 'disabled';

let allFeatures: Features = {};

const getPageContext = () => {
  if (isServer()) {
    return {};
  }

  return {
    path: window.location.pathname,
    search: window.location.search,
    title: window.document.title,
    url: window.location.href,
  };
};

export const setAllFeatures = (value: Features) => {
  allFeatures = value;
};

export const getActiveFeatures = (features: Features = {}) => {
  const activeFeatures = Object.keys(features).reduce((acc, key) => {
    if (features[key] !== BUCKET_DISABLED) {
      acc[key] = features[key];
    }

    return acc;
  }, {} as Features);

  return activeFeatures;
};

/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    analytics: typeof import('../types/segment').default;
    openSelfServiceModal: (modalKey: string) => void;
    UC_Integrations: {
      [key: string]: string | boolean;
    };
  }
}
/* eslint-enable @typescript-eslint/naming-convention */

const isTestEmail = (email: string | undefined): boolean => {
  return Boolean(
    email?.toLocaleLowerCase().match(/.*\+test.*?@finn\.(auto|com)$/)
  );
};

const shouldTriggerSegmentEvent = () => {
  const hostName = window.location.hostname;
  let userInfo = null;

  try {
    userInfo =
      JSON.parse(localStorage.getItem('ajs_user_traits') || '""') || null;
  } catch (error) {
    console.error('Error reading from localStorage', error);
  }

  const userEmail = userInfo?.email || null;

  const isTestAccount = isTestEmail(userEmail);
  const isProduction = hostName === 'www.finn.com';
  const exceptionForTesting = userEmail === config.TRACKING_TEST_EMAIL;
  const blockTracking = !exceptionForTesting && isTestAccount && isProduction;

  return !isServer() && window.UC_Integrations && !blockTracking;
};

const resolveLocale = () => {
  if (isServer()) {
    return;
  }
  const isUS = window.location.pathname.includes(Locale.ENGLISH_USA);
  if (isUS) return Locale.ENGLISH_USA;

  return Locale.GERMAN_GERMANY;
};

// TODO: this is old code, I do not no which obj type we should use here
// so put any, as it was before
const replaceKey = (
  obj: any,
  toReplace: string,
  replaceWith: string,
  defaultVal?: string | boolean
) => {
  if (obj[replaceWith] === undefined) {
    if (obj[toReplace] !== undefined) {
      obj[replaceWith] = !!obj[toReplace];
      delete obj[toReplace];
    } else {
      obj[replaceWith] = defaultVal;
    }
  }
};

const transformDetail = (detail: object) => {
  const transformedDetail = { ...detail };
  replaceKey(transformedDetail, 'Segment', 'Segment.io', true);
  replaceKey(transformedDetail, 'Google Ads (Classic)', 'AdWords', false);
  replaceKey(transformedDetail, 'Fullstory', 'FullStory', false);

  return transformedDetail;
};

export const updateSegmentIntegration = (detail: object) => {
  const transformedDetail = transformDetail(detail);
  if (!isServer()) {
    window.UC_Integrations = transformedDetail;
  }
};

export const renderSegmentSnippet = () => {
  const opts = {
    apiKey: config.ANALYTICS_WRITE_KEY,
    page: false,
    load: false,
  };

  // In case of mobile app, we don't need to render the snippet
  // as we intercept all the segment events
  // eslint-disable-next-line
  // @ts-ignore
  if (window?.onWebReadyToConnectToApp) {
    return null;
  }

  // Returns the minified version of the snippet on production.
  const isDevEnv = process.env.NODE_ENV === 'development';

  return isDevEnv ? snippet.max(opts) : snippet.min(opts);
};

// TODO in order to avoid cycle dependencies
// we are accessing nativeAppSDK directly instead of getAppSDK method
// in 3-6 months, after native app fully migrated to @finn/ua-app sdk
// this code can be deleted DRI - @andrii.tiertyshnyi
const getNativeAppContext = () => {
  type CustomWindow = {
    nativeAppSDK: {
      getTrackingProps: () => object;
    };
  };

  const globalObject = window as unknown as CustomWindow;

  const nativeAppSDK = globalObject?.nativeAppSDK;

  return nativeAppSDK?.getTrackingProps?.() || {};
};

const trackingActions = {
  identify(userId: string, traits: Object) {
    const locale = resolveLocale();
    const activeFeatures = getActiveFeatures(allFeatures);

    const trackFunc = () => {
      window?.analytics?.identify(
        userId,
        { ...traits, features: activeFeatures },
        {
          integrations: { ...window.UC_Integrations, HubSpot: false },
          context: { locale, ...getNativeAppContext() },
        }
      );
    };
    if (shouldTriggerSegmentEvent()) {
      trackFunc();
    } else if (!isServer()) {
      window.addEventListener('afterSegmentLoad', trackFunc, { once: true });
    }
  },

  identifyAnonymous(traits: Object) {
    const locale = resolveLocale();
    const activeFeatures = getActiveFeatures(allFeatures);

    const trackFunc = () => {
      window?.analytics?.identify(
        { ...traits, features: activeFeatures },
        {
          integrations: { ...window.UC_Integrations, HubSpot: false },
          context: { locale, ...getNativeAppContext() },
        }
      );
    };
    if (shouldTriggerSegmentEvent()) {
      trackFunc();
    } else if (!isServer()) {
      window.addEventListener('afterSegmentLoad', trackFunc, { once: true });
    }
  },

  page(path: string) {
    const locale = resolveLocale();

    const trackFunc = () => {
      window?.analytics?.page(
        path,
        { locale, path },
        {
          integrations: window.UC_Integrations,
          context: {
            locale,
            path,
            ...getNativeAppContext(),
            features: getActiveFeatures(allFeatures),
            page: getPageContext(),
          },
        }
      );
      // FullStory event as it is disabled on Segment
      window?.FS?.event('Page Viewed', { path, locale });
    };
    if (shouldTriggerSegmentEvent()) {
      trackFunc();
    } else if (!isServer()) {
      window.addEventListener('afterSegmentLoad', trackFunc, { once: true });
    }
  },

  track(event: string, properties: Object, integrations = {}) {
    const locale = resolveLocale();

    const trackFunc = async () => {
      if (window.gtag) {
        const { session_id, session_number } = await getCurrentSessionData(
          window.gtag
        );

        if (session_id !== undefined && session_number !== undefined) {
          properties = { ...properties, session_id, session_number };
        }
      }

      window?.analytics?.track(event, properties, {
        integrations: { ...window.UC_Integrations, ...integrations },
        context: {
          locale,
          ...getNativeAppContext(),
          page: getPageContext(),
        },
        'Facebook Pixel': {
          contentType: 'vehicle',
        },
      });
      // FullStory event as it is disabled on Segment
      window?.FS?.event(event, properties);
    };

    if (shouldTriggerSegmentEvent()) {
      trackFunc();
    } else if (!isServer()) {
      window.addEventListener('afterSegmentLoad', trackFunc, { once: true });
    }
  },
};

type EventQueueType = keyof typeof trackingActions;

type EventQueueArgs<T extends EventQueueType> = Parameters<
  (typeof trackingActions)[T]
>;

type EventQueueItem<T extends EventQueueType> = [
  type: T,
  args: EventQueueArgs<T>,
];

type CreateEventQueueParams = {
  pauseTillIdentify: boolean;
};

export function createEventQueue({
  pauseTillIdentify,
}: CreateEventQueueParams) {
  const queue: EventQueueItem<EventQueueType>[] = [];

  return {
    isPaused: pauseTillIdentify,

    enqueue<T extends EventQueueType>(type: T, args: EventQueueArgs<T>) {
      switch (type) {
        case 'identify': {
          this.isPaused = false;
          trackingActions.identify(...(args as EventQueueArgs<'identify'>));

          break;
        }

        case 'identifyAnonymous': {
          this.isPaused = false;
          trackingActions.identifyAnonymous(
            ...(args as EventQueueArgs<'identifyAnonymous'>)
          );

          break;
        }

        case 'page': {
          if (this.isPaused) {
            queue.push([type, args]);
          } else {
            trackingActions.page(...(args as EventQueueArgs<'page'>));
          }

          break;
        }

        case 'track': {
          if (this.isPaused) {
            queue.push([type, args]);
          } else {
            trackingActions.track(...(args as EventQueueArgs<'track'>));
          }

          break;
        }
      }

      if (!this.isPaused) {
        this.dequeue();
      }
    },

    dequeue() {
      if (this.isPaused) {
        return;
      }

      while (queue.length > 0) {
        // TODO not sure what actual types should be here,
        // as ts strict complains, to solve this -> set what seems to be right
        const [type, args] = queue.shift() as [
          keyof typeof trackingActions,
          any,
        ];
        trackingActions[type].apply(null, args);
      }
    },
  };
}

// Importing `getAppSdk` from @finn/ua-app creates circular dependency.
// Directly check for `nativeAppSDK` on `window`.
// Note: @ts-expect-error fails linting (Unused '@ts-expect-error' directive)

export const isMobileAppReady = () =>
  isServer()
    ? false
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Boolean(window?.onWebReadyToConnectToApp);

export const eventQueue = createEventQueue({
  pauseTillIdentify: !isMobileAppReady(),
});

export const trackEvent = (
  event: string,
  properties: Object,
  integrations = {}
) => {
  eventQueue.enqueue('track', [event, properties, integrations]);
};

export const pageEvent = (path: string) => {
  eventQueue.enqueue('page', [path]);
};

export const identifyEvent = (userId: string, traits: Object) => {
  eventQueue.enqueue('identify', [userId, traits]);
};

export const identifyAnonymousUserEvent = (traits: Object) => {
  eventQueue.enqueue('identifyAnonymous', [traits]);
};
