import queryString from 'query-string';

import { getAppSDK } from './mobileApp';

export const PAYMENT_DEEP_LINK = 'checkout/payment/paymentredirect';

type PaymentRedirectProps = {
  webUrl: string;
  stage?: string | [string];
  prefix?: string | [string];
};

export const getPaymentsRedirectDeepLink = ({
  webUrl,
  stage,
  prefix,
}: PaymentRedirectProps) => {
  const nextQueryStr = queryString.stringify({ webUrl, stage, prefix });
  const appSDK = getAppSDK();
  const featureFlags = appSDK?.getTrackingProps().app_feature_flags || [];

  if (!featureFlags.includes('withPaypalWebView')) {
    return webUrl;
  }

  return `${window.origin}/mobile/${PAYMENT_DEEP_LINK}?${nextQueryStr}`;
};
