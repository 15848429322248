import {
  getSession as getSessionNext,
  GetSessionParams,
  useSession as useSessionNext,
} from 'next-auth/react';
import { useEffect, useState } from 'react';

import {
  CookieKeys,
  getClientJSONCookie,
  setClientJSONCookie,
} from '../lib/cookies';

export interface IFinnSession {
  user: {
    name: string;
    email: string;
    email_verified: string;
    image?: string;
    picture?: string;
    hs_contact_id?: string;
    hs_first_name?: string;
    hs_last_name?: string;
    hs_country?: string;
    hs_postalcode?: string;
    provider?: string;
    nextUrl?: string;
  };
}

export enum SOCIAL_LOGIN_PROVIDER {
  GOOGLE = 'google',
  APPLE = 'apple',
}

export const getSession = (options?: GetSessionParams) => {
  const session = getSessionNext(options);

  return session as any as Promise<IFinnSession>;
};

export const useSession = (): [IFinnSession | null, boolean] => {
  const { data: session, status } = useSessionNext() || {};
  const loading = status === 'loading';

  return [session as any as IFinnSession, loading];
};

export const useSessionInCookie = (): IFinnSession | null | undefined => {
  const [savedSession, setSavedSession] = useState<
    IFinnSession | null | undefined
  >(undefined);
  const [sessionNext] = useSession();

  const savedSessionEmail = savedSession?.user?.email;

  // replace session in cookie and state with the new one from next-auth
  useEffect(() => {
    const sessionNextEmail = sessionNext?.user?.email;
    if (sessionNextEmail && sessionNextEmail !== savedSessionEmail) {
      setClientJSONCookie(CookieKeys.SESSION_DATA, sessionNext);
      setSavedSession(sessionNext);
    }
  }, [savedSessionEmail, sessionNext]);

  // put the value in cookie into the state
  useEffect(() => {
    const sessionInCookie = getClientJSONCookie(
      CookieKeys.SESSION_DATA
    ) as IFinnSession;
    const userEmail = sessionInCookie?.user?.email;
    if (userEmail && userEmail !== savedSession?.user?.email) {
      setSavedSession(sessionInCookie);
    } else if (savedSession === undefined) {
      setSavedSession(null);
    }
  }, [savedSession]);

  return sessionNext === undefined ? savedSession : sessionNext;
};

export const getFinnSessionId = (): string => {
  const requestId =
    new Date().getTime().toString(36) +
    Math.random().toString(36).substr(2, 12);

  return requestId;
};
