import { CTAData, UIBaseCosmicObject } from './cosmicjs';

export * from './UIModules/hero';
export * from './UIModules/flipper';
export * from './UIModules/textblock';
export * from './UIModules/verticaltab';
export * from './UIModules/baseTypes';
export * from './UIModules/stepper';
export * from './UIModules/promo';
export * from './UIModules/blurb';
export * from './UIModules/npsSlider';
export * from './UIModules/content';
export * from './UIModules/comparison';
export * from './UIModules/brandSlider';
export * from './UIModules/customBrandSlider';
export * from './UIModules/form';
export * from './UIModules/featuredvehicle';
export * from './UIModules/gallery';
export * from './UIModules/comparison';
export * from './UIModules/B2BHandoverCoordination';
export * from './UIModules/cta';
export * from './UIModules/modal';
export * from './UIModules/accordion';
export * from './UIModules/CO2Calculator';
export * from './UIModules/contactUsForm';
export * from './UIModules/b2bContactForm';
export * from './UIModules/jobAutoContactForm';
export * from './UIModules/partnershipsContactForm';
export * from './UIModules/contentSlider';
export * from './UIModules/voucherCodeForm';
export * from './UIModules/verticalList';
export * from './UIModules/stepsCounter';
export * from './UIModules/errorPage';
export * from './UIModules/commons';
export * from './UIModules/page';
export * from './UIModules/emailCollectionForm';
export * from './UIModules/campaignCard';
export * from './UIModules/plpCard';
export * from './UIModules/B2BLeadGenForm';
export * from './UIModules/prequalifyForm';
export * from './UIModules/promoBanner';
export * from './UIModules/header';
export * from './UIModules/uspBanner';
export * from './UIModules/choicePair';
export * from './UIModules/howItWorksMultiStepModal';
export * from './UIModules/custom';

// TODO delete this one after b2b migrated
export type ProductSliderCosmicMetadata = {
  title: string;
  layout?: { key: 'list' | 'slider' | 'compare' };
  sort: {
    value: string;
  };
  filters: {
    brands?: string[];
    cartypes?: string[];
    models?: string[];
    gearshifts?: string[];
    fuels?: string[];
    min_price?: string;
    max_price?: string;
    has_hitch?: boolean;
  };
  dynamic_content?: boolean;
  hide_price?: boolean;
  always_show_b2b_price?: boolean;
  cta?: CTAData;
};

export type ProductSliderCosmicData =
  UIBaseCosmicObject<ProductSliderCosmicMetadata>;
