import { CancelOutlined } from '@finn/design-system/icons/cancel-outlined';
import { Check } from '@finn/design-system/icons/check';
import { cn } from '@finn/ui-utils';
import React from 'react';

import { HeroFeature } from './metaData';

type Props = {
  features: HeroFeature[];
  isExpHPHeroFilters?: boolean;
};

const getIcon = (feature: HeroFeature) => {
  if (feature.icon.key === 'cross') {
    return <CancelOutlined width={24} height={24} />;
  } else {
    return <Check />;
  }
};

const Features: React.FC<Props> = ({
  features,
  isExpHPHeroFilters = false,
}) => {
  if (!features || features.length === 0) return null;

  let adjustedFeatures = [];
  if (isExpHPHeroFilters) {
    adjustedFeatures = [
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Inklusive Versicherung, Steuer und TÜV',
      },
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Haustürlieferung zum Wunschdatum',
      },
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Breite Fahrzeugauswahl',
      },
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Flexible Laufzeiten für deine Bedürfnisse',
      },
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Sofort verfügbare (E)-Auto Angebote',
      },
      {
        icon: {
          key: 'check',
          value: 'Check',
        },
        feature: 'Bis zu 5.000km pro Monat fahren',
      },
    ];
  } else {
    adjustedFeatures = features;
  }

  return (
    <ul
      className={cn('p-0', {
        'mb-4 sm:mb-6': !isExpHPHeroFilters,
        'container mb-0 ml-0 mt-0 md:grid md:grid-cols-3 md:grid-rows-2':
          isExpHPHeroFilters,
      })}
    >
      {adjustedFeatures.map((feature) => (
        <li key={feature.feature} className="my-2 flex items-center">
          <div className="mr-2 flex items-center">{getIcon(feature)}</div>
          <p className="body-16-light">{feature.feature}</p>
        </li>
      ))}
    </ul>
  );
};
export default Features;
