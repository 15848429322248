import { ComponentProps } from 'react';

import { withCosmicModule } from '../services/cosmic';
import { Dictionary } from '../services/dictionary';
import { Accordion } from './Accordion';
import { Chat } from './Chat';
import Flipper from './Flipper';
import { StepsModule } from './Steps/StepsModule';

const featuresDictionary: Dictionary = new Dictionary();

export const getPlatformFeatures = () => featuresDictionary.getDictionary();

export { globalChatApi } from './Chat/UltimateChat';

type LegacyCosmic<T> = { data: { metadata: T } };

// this is legacy modules, so we register them a little bit differently to preserve the old props API
featuresDictionary.register(
  'web-module-accordions',
  (props: LegacyCosmic<ComponentProps<typeof Accordion>['data']>) => (
    <Accordion data={props.data.metadata} />
  )
);
featuresDictionary.register(
  'referral-selfservice',
  (props: LegacyCosmic<ComponentProps<typeof Flipper>['data']>) => (
    <Flipper data={props.data.metadata} />
  )
);

featuresDictionary.register('chat', withCosmicModule(Chat));
featuresDictionary.register('steps', withCosmicModule(StepsModule));
