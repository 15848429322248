import { COSMIC_SLUGS_UNDER_ACTIVE_DEVELOPMENT } from '@finn/ua-constants';
import {
  config,
  DefaultLocale,
  FakeDefaultLocale,
  getOrigin,
  Locale,
} from '@finn/ui-utils';
import axios, { AxiosRequestConfig } from 'axios';
import Cosmic from 'cosmicjs';
import { IncomingMessage } from 'http';
import get from 'lodash/get';
import values from 'lodash/values';
import omitDeep from 'omit-deep';

import { CosmicParams } from '../types/cosmicjs';
import { promiseTimeout } from './promiseTimeout';
import { validateData } from './validateData';

const timeoutLocal = 2000;
const timeoutProd = 10000;
const S3BaseUrl =
  'https://finn-website-assets.s3.eu-central-1.amazonaws.com/cosmicBackup';

export const getBackupCosmicObject = async ({
  slug,
  locale,
}: CosmicObjectIdentifier) => {
  if (locale === FakeDefaultLocale) {
    locale = DefaultLocale;
  }
  let data = { objects: [] };
  try {
    const response = await axios.get(`${S3BaseUrl}/${locale}/${slug}.json`);
    data = response?.data;
  } catch {
    return null;
  }

  return JSON.parse(JSON.stringify(data?.objects?.[0])) || null;
};

export async function getCosmicObject({
  slug = '',
  locale,
  fields = 'type,metadata,id,slug',
  showMetafields = false,
  isFallbackCall = false,
}: CosmicParams): Promise<any> {
  if (locale === FakeDefaultLocale) {
    locale = DefaultLocale;
  }
  const options = {
    slug,
    locale,
    fields,
    showMetafields,
    isFallbackCall: false,
  };

  // in order to support potential edge cases we put deprecated 'de' or 'en locale as fallback
  // sadly we also put type as any, as it is a hacky fallback at the moment
  // TODO drop support for 'en' and 'de' locales
  const fallbackLocale: any = 'de';

  const api = Cosmic();
  const bucket = api.bucket({
    slug: 'finnauto',
    read_key: config.COSMIC_BUCKET_READ_KEY,
  });

  let data = null;

  // for pages under active development we want to ignore s3 and always fetch from cosmic
  const pageUnderActiveDevelopment =
    COSMIC_SLUGS_UNDER_ACTIVE_DEVELOPMENT.includes(slug);

  try {
    if (config.COSMIC_USE_S3 === 'yes' && !pageUnderActiveDevelopment) {
      console.log('Not requesting cosmic as env var is set to use S3 directly');
      throw new Error();
    }
    const response: any = await promiseTimeout(
      bucket.getObjects({
        query: {
          slug: options?.slug,
          locale: options.locale,
        },
        props: options.fields,
        show_metafields: options.showMetafields,
        status: config.APP_STAGE === 'development' ? 'any' : undefined,
      }) as any,
      config.APP_STAGE === 'development' ? timeoutLocal : timeoutProd
    );
    data = response.objects?.[0];

    if (!data) {
      console.log(
        `Data for cosmic key does not exist ${options?.slug} ${options.locale}`
      );
    }

    validateData(response);
  } catch (error) {
    console.warn(
      `Error while retrieving for cosmic key ${options?.slug} ${
        options.locale
      }: ${JSON.stringify(error)}`
    );
    const backupObject = await getBackupCosmicObject({
      slug: options?.slug,
      locale: options.locale,
    });
    if (backupObject) {
      console.log(
        `Serving backup cosmic data from file for: slug:${options?.slug}, locale:${options.locale}`
      );

      return backupObject;
    }
    if (
      (options.locale !== fallbackLocale && isFallbackCall) ||
      options.locale === Locale.GERMAN_GERMANY
    ) {
      try {
        // second try with default locale
        options.locale = fallbackLocale;
        options.isFallbackCall = true;

        return getCosmicObject(options);
      } catch (defaultLocaleError) {
        console.warn(
          `Error while retrieving for cosmic key ${options?.slug} ${
            options.locale
          }: ${JSON.stringify(defaultLocaleError)}`
        );
      }
    }
  }

  const skimmedData = omitDeep(data, [
    'bucket',
    'created_at',
    'created_by',
    'modified_at',
    'created',
    'status',
    'published_at',
    'modified_by',
    'publish_at',
    'unpublish_at',
    'thumbnail',
  ]);

  return JSON.parse(JSON.stringify(skimmedData));
}

export const getCosmicObjectWithApi = async (
  params: CosmicParams,
  req?: IncomingMessage
) => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: params,
    url: `${getOrigin(req)}/api/cosmic/getObject`,
  };

  try {
    const { data } = await axios(options);

    return data;
  } catch (err) {
    return null;
  }
};

export const buildCosmicPageObjectTree = (data: any): any => {
  if (Array.isArray(data)) {
    return data
      .filter((item) => Array.isArray(item) || item?.id)
      .map((item) => {
        return buildCosmicPageObjectTree(item);
      });
  } else {
    const title = data.title || data?.slug || 'No Title';
    const id = data.id;
    const type = data.type;
    const slug = data?.slug;
    const hasChildren = data?.metadata;
    let children = null;
    if (hasChildren) {
      children = buildCosmicPageObjectTree(values(data.metadata));
    }

    return { title, id, type, slug, children };
  }
};

export const getInputPlaceholder = (pageData: any, inputId: string) =>
  get(pageData, `metadata.input_fields.${inputId}.metadata.placeholder`, '');

export const getInputTooltipText = (pageData: any, inputId: string) => {
  const item = get(pageData, 'metadata.input_hints', []).find(
    (input: object) => get(input, 'metadata.key', '') === inputId
  );

  return get(item, 'metadata.information');
};

export type CosmicObjectIdentifier = {
  slug: string;
  locale: string;
};
