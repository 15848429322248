import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { LoyaltyCosmicMetadata } from '@finn/ui-cosmic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Slider } from '../Slider/Slider';
import { HowItWorksMultiStepModal } from './HowItWorksMultiStepModal';
import { LevelSlide } from './LevelSlide';

export const LoyaltyLevels = ({
  data,
  isUsedAsModule,
  className,
}: {
  data?: LoyaltyCosmicMetadata;
  isUsedAsModule?: boolean;
  className?: string;
}) => {
  const router = useRouter();
  const shouldOpenModal = router.asPath?.includes('#how-loyalty-works');
  const openModal = useOpenModal();
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (shouldOpenModal) {
      track(TrackingEventName.MODAL_OPENED, { location: 'Loyalty Module' });
      openModal(ModalKey.HOW_LOYALTY_WORKS);
      router.push(router.asPath.split('#')[0]);
    }
  }, [openModal, router, shouldOpenModal, track]);

  return (
    <>
      <Slider
        title={data?.our_levels_title}
        isForIframe={isUsedAsModule}
        testId="loyalty-level-slides"
        className={className}
      >
        <LevelSlide levelIndex={0} data={data} />
        <LevelSlide levelIndex={1} data={data} />
        <LevelSlide levelIndex={2} data={data} />
        <LevelSlide levelIndex={3} data={data} />
      </Slider>
      <ModalContainer
        modalKey={ModalKey.HOW_LOYALTY_WORKS}
        ModalComponent={HowItWorksMultiStepModal}
        data={data?.how_it_works_multi_step_modal}
        location="loyalty-module"
      />
    </>
  );
};
