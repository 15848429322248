import { IFinnSession } from '@finn/ui-utils';
import { createContext, useContext } from 'react';

type getSessionFn = () => [IFinnSession | null, boolean];

export const SessionContext = createContext<getSessionFn | undefined>(
  undefined
);

export function useSession() {
  return useContext(SessionContext)();
}
