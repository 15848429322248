import {
  App,
  cn,
  getAnchorTarget,
  localizeUrl,
  useCurrentLocale,
} from '@finn/ui-utils';
import trim from 'lodash/trim';
import React from 'react';

import { NextLinkSingleApp } from './NextLinkSingleApp';

interface IStylesProps {
  color?: 'white' | 'black';
}

interface IProps extends IStylesProps {
  app?: App;
  className?: string;
  href?: string;
  text?: string;
  onClick?: () => void;
}

export const CTALink: React.FunctionComponent<IProps> = ({
  app,
  className,
  href,
  text,
  color,
  onClick,
}) => {
  // When a link is internal, it will start with a protocol
  // while finn.auto links with start with a path such as /careers
  const trimmedHref = trim(href);
  const { locale } = useCurrentLocale();

  return text && trimmedHref ? (
    <NextLinkSingleApp app={app || App.UA} withoutAnchorTag href={trimmedHref}>
      <a
        target={getAnchorTarget(trimmedHref)}
        href={localizeUrl(trimmedHref, locale)}
        onClick={onClick}
      >
        <span
          className={cn(className, 'link-14 md:link-16', {
            'text-white': color === 'white',
            'hover:text-iron text-black': color !== 'white',
          })}
        >
          {text}
        </span>
      </a>
    </NextLinkSingleApp>
  ) : null;
};
