import { useContext } from 'react';

import { Features } from './constants';
import { FeatureContext } from './FeatureContext';

export const useIsFeatureEnabled = (name: Features) => {
  const features = useContext(FeatureContext);

  return features[name] === 'enabled';
};
