import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { Brand } from '@finn/ua-vehicle';
import { Image, NextLinkSingleApp } from '@finn/ui-components';
import { NavigationMenuContext } from '@finn/ui-modules';
import {
  App,
  cn,
  getCloudinaryImgUrl,
  type HandleClickFn,
  slugify,
} from '@finn/ui-utils';
import React, { useContext } from 'react';

interface IProps {
  data: {
    title: string;
    type: string;
    offer_type: string;
  };
  handleClick: HandleClickFn;
}

type ParsedBrand = {
  label: string;
  image: string;
  url: string;
};

const PopularBrands: React.FunctionComponent<IProps> = ({
  data,
  handleClick,
}) => {
  const { filterBrandsModels } = useContext(NavigationMenuContext);

  const brandsList =
    filterBrandsModels?.brands?.slice(0, 5).map((brand: Brand) => ({
      label: brand.id,
      image: brand.helper_brand_logo?.url,
      url: `/${BASE_SUBSCRIPTION_PATH}/${slugify(brand.id)}`,
    })) || [];

  return (
    <div className={'mx-4 my-6 md:m-0'}>
      <div className="global-t6-semibold mb-5 h-6">{data.title}</div>
      {brandsList.map((brand: ParsedBrand) => (
        <NextLinkSingleApp
          app={App.UA}
          key={brand.label + brand.url}
          href={brand.url}
          className={cn(
            'flex items-center rounded-sm py-3 pr-3 no-underline md:p-3',
            'md:hover:border-pearl md:hover:bg-snow border border-solid border-white text-black'
          )}
          onClick={(event) =>
            handleClick({
              link: brand.url,
              type: NAVIGATION_TRACKING_EVENTS.LIST_ITEM,
              e: event,
            })
          }
        >
          <Image
            variant="lazy-loading"
            src={getCloudinaryImgUrl(brand.image, {
              h: 20,
              dpr: 2,
            })}
            alt={brand.label}
            className="mr-4 w-5"
          />
          <p className="body-16-regular">{brand.label}</p>
        </NextLinkSingleApp>
      ))}
    </div>
  );
};

export default PopularBrands;
