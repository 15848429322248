import { NAVIGATION_TRACKING_EVENTS } from '@finn/ua-tracking';
import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn, type HandleClickFn } from '@finn/ui-utils';

type Item = {
  href: string;
  label: string;
};

type MenuListProps = {
  data: {
    b2b_programs: {
      items: Item[];
      title: string;
    };
  };
  handleClick: HandleClickFn;
};

const MenuList = ({ data, handleClick }: MenuListProps) => {
  const {
    b2b_programs: { items, title },
  } = data;

  return (
    <div className="mx-4 mt-5 md:m-0">
      <div className="global-t6-semibold mb-5 md:pl-3">{title}</div>
      {items?.map((program) => (
        <NextLinkSingleApp
          key={program.href}
          app={App.UA}
          href={program.href}
          className={cn(
            'body-16-regular flex items-center rounded-sm py-3 pr-3 md:p-3',
            'md:hover:bg-snow mg:hover:border-pearl text-black'
          )}
          onClick={(event) =>
            handleClick({
              link: program.href,
              type: NAVIGATION_TRACKING_EVENTS.LIST_ITEM,
              e: event,
            })
          }
        >
          {program.label}
        </NextLinkSingleApp>
      ))}
    </div>
  );
};

export default MenuList;
