import { Image } from '@finn/ui-components';
import { CosmicImageData } from '@finn/ui-cosmic';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';
import { HTMLAttributes } from 'react';

type FlipperImageProps = {
  image: CosmicImageData;
  altText?: string;
  reverse: boolean;
  squared: boolean;
  className?: HTMLAttributes<HTMLDivElement>['className'];
};

const FlipperImage = ({
  image,
  reverse,
  squared,
  altText = '',
  className,
}: FlipperImageProps) => {
  const defaultParams = {
    ...(squared && { ar: '1:1', c: 'fill' }), // ar = aspect ration, it will square an image
  };

  return (
    <div className={cn('sm:order-2', reverse && 'sm:order-1', className)}>
      <Image
        className="w-full object-contain object-[center_top] sm:order-2"
        variant="responsive"
        smImage={getCloudinaryImgUrl(image.url, { ...defaultParams, w: 375 })}
        mdImage={getCloudinaryImgUrl(image.url, { ...defaultParams, w: 618 })} // just duplicated desktop as our module design didn't follow design yet
        lgImage={getCloudinaryImgUrl(image.url, { ...defaultParams, w: 618 })}
        alt={altText}
      />
    </div>
  );
};

export default FlipperImage;
