import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { HowItWorksMultiStepModalData } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';
import { useCallback, useState } from 'react';

type Props = {
  data: HowItWorksMultiStepModalData;
  open: boolean;
  onClose(): void;
  size?: 'xs' | 'md' | 'xl';
  location?: string;
};

export const HowItWorksMultiStepModal = ({
  data,
  open,
  onClose,
  location,
}: Props) => {
  const track = useTrackingStore((state) => state.track);
  const [step, setStep] = useState(0);
  const currentScreen = data?.metadata?.modal_screens?.[step];
  const totalScreens = data?.metadata?.modal_screens?.length;
  const isFirstScreen = step === 0;
  const isLastScreen = step === totalScreens - 1;

  const onModalClose = useCallback(() => {
    if (location) {
      track(TrackingEventName.MODAL_CLOSED, { location });
    }
    onClose();
  }, [location, onClose, track]);

  const onNextClick = useCallback(() => {
    track(TrackingEventName.CTA_CLICKED, {
      location: `${location}-continue-${step + 1}`,
    });
    if (!isLastScreen) {
      setStep((currStep) => currStep + 1);
    } else {
      onModalClose();
    }
  }, [isLastScreen, location, onModalClose, step, track]);

  const onPrevClick = useCallback(() => {
    track(TrackingEventName.CTA_CLICKED, {
      location: `${location}-back-${step + 1}`,
    });
    if (!isFirstScreen) {
      setStep((currStep) => currStep - 1);
    } else {
      onModalClose();
    }
  }, [isFirstScreen, location, onModalClose, step, track]);

  return (
    <Modal open={open} onOpenChange={(isOpen) => !isOpen && onModalClose()}>
      <ModalContent onBack={!isFirstScreen ? onPrevClick : undefined}>
        <ModalHeader>
          <ModalTitle>{currentScreen?.title}</ModalTitle>
        </ModalHeader>
        <div className="mb-8 flex flex-col gap-4 md:flex-row">
          <div className="flex-1 md:flex-[0_0_50%]">
            {parseToHtml(currentScreen?.description)}
          </div>
          <div className="flex-1 md:flex-[0_1_50%]">
            <img
              src={currentScreen?.image?.url}
              className="max-w-full"
              alt={currentScreen?.title}
            />
          </div>
        </div>
        <ModalFooter>
          <Button size="lg" onClick={onNextClick} className="w-full sm:w-max">
            {currentScreen?.next_button_label}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
