import { OpenInNew } from '@finn/design-system/icons/open-in-new';
import { useDeals } from '@finn/platform-modules';
import { useSignOut } from '@finn/ua-auth';
import { DropdownLinksList } from '@finn/ua-header';
import {
  NAVIGATION_TRACKING_EVENTS,
  navigationTrackingEvent,
  NavItem,
  TrackingEventName,
} from '@finn/ua-tracking';
import { NavbarData } from '@finn/ui-cosmic';
import { cn, HandleClickParams, isServer, useSession } from '@finn/ui-utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { helpCenterIconClicked, trackNavItemClicked } from '../../tracking';

type Props = {
  data: NavbarData;
  isOpenUser?: boolean;
};

const DrawerFooter = ({ data, isOpenUser }: Props) => {
  const [session] = useSession();
  const { deals, dealsStatus } = useDeals();
  const i18n = useIntl();
  const { handleSignOutClick } = useSignOut({ session });

  const isCustomer =
    dealsStatus === 'loading' ? false : Boolean(deals?.length) || false;

  const helpLink = data?.userAccountLinks?.helpLink;
  const customer_id = session?.user?.hs_contact_id;
  helpLink?.href === 'https://support.finn.com/hc/de-de' &&
    (helpLink.href = `${helpLink.href}${
      customer_id ? `?customer_id=${customer_id}` : ''
    }`);

  const onHelpClick = useCallback(
    ({ link, type }: HandleClickParams) => {
      navigationTrackingEvent({
        eventName: TrackingEventName.NAVITEM_CLICKED,
        link,
        type,
      });

      helpCenterIconClicked({
        is_customer: isCustomer,
      });

      if (!isServer()) {
        setTimeout(() => {
          window.open(link, '_blank')?.focus();
        }, 200);
      }
    },
    [isCustomer]
  );

  return (
    <>
      {helpLink?.href && !isOpenUser && (
        <a
          href={helpLink.href}
          className="border-pearl flex h-16 cursor-pointer items-center border-0 border-t border-solid px-0"
          onClick={() => {
            onHelpClick({
              link: helpLink.href,
              type: NAVIGATION_TRACKING_EVENTS.MAIN,
            });

            return false;
          }}
        >
          <div className="body-16-semibold container flex h-16 cursor-pointer items-center justify-between before:hidden after:hidden">
            <div>{helpLink?.label}</div>
            <OpenInNew width={20} height={20} />
          </div>
        </a>
      )}
      {session && isOpenUser && (
        <>
          <DropdownLinksList data={data.userAccountLinks.dropdownOptions} />
          <a
            className={cn(
              'border-pearl flex h-16 cursor-pointer items-center border-0 border-t border-solid px-0'
            )}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              handleSignOutClick(e);
              trackNavItemClicked({
                option: NavItem.LOG_OUT,
                is_customer: isCustomer,
              });
            }}
          >
            <div
              className={cn(
                'body-16-semibold container flex justify-between before:hidden after:hidden'
              )}
            >
              {data.userAccountLinks.dropdownOptions.sign_out}
            </div>
          </a>
        </>
      )}
      <div className="border-pearl flex h-16 cursor-pointer items-center border-0 border-t border-solid px-0">
        <div className="container">
          <p className="body-12-regular">
            {i18n.formatMessage({ id: 'general.copyright' })}
          </p>
        </div>
      </div>
    </>
  );
};

export default DrawerFooter;
