import { CosmicImageData, UIBaseCosmicObject } from './commons';

export enum CtaValue {
  button = 'button', // CTA shown as button
  link = 'link', // CTA shown as link
}

export type CtaType = {
  key: CtaValue;
  value: CtaValue;
};

export type FlipperCosmicMetadata = {
  title: string;
  description: string;
  cta_text: string;
  cta_href: string;
  cta_type: CtaType;
  image: CosmicImageData;
  image_alt_text?: string;
  reverse: boolean;
  squared: boolean;
  cta_tracking_location?: string;
  header_size: {
    key: string;
    value: string;
  };
  trackOnButtonClick?(): void;
};

export type FlipperCosmicData = UIBaseCosmicObject<FlipperCosmicMetadata>;
