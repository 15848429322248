import { Button } from '@finn/design-system/atoms/button';
import { useEffect, useState } from 'react';

import { ErrorBox } from './ErrorBox';
import { LoadingBox } from './LoadingBox';

export type LoadingStatus =
  | 'unset'
  | 'loading'
  | 'failure'
  | 'success'
  | 'cancel';

type LoadingViewProps = {
  mainText?: string;
  buttonTitle?: string;
  cancelTitle?: string;
  error?: string;
  errorDescription?: string;
  status: LoadingStatus;
  onSubmit?: () => void;
  onCancel?: () => void;
};

// delay before showing footer,
// we need it to not show cancel/retry button during first loading steps
const DELAY_BEFORE_SHOWING_TRY_BUTTON = 6000;

export const LoadingView = ({
  mainText,
  buttonTitle = '',
  cancelTitle = '',
  error,
  errorDescription,
  status,
  onSubmit,
  onCancel,
}: LoadingViewProps) => {
  const [isFooterVisible, setFooterVisible] = useState(false);

  // after loading started we set a timer
  // if loading still available in DELAY_BEFORE_SHOWING_TRY_BUTTON ms
  // we will show cancel/retry button in addition to loading
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (status === 'loading') {
      timer = setTimeout(() => {
        setFooterVisible(true);
      }, DELAY_BEFORE_SHOWING_TRY_BUTTON);
    } else {
      setFooterVisible(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  return (
    <div className="flex justify-center">
      <div className="fixed flex h-full w-full max-w-[400px] flex-col items-center justify-between">
        <div className="mx-10 mb-0 mt-32 flex flex-col items-center justify-center text-black">
          {status === 'loading' ? (
            <LoadingBox className="mt-6 text-center" content={mainText} />
          ) : null}
          {status === 'failure' || status === 'cancel' ? (
            <ErrorBox
              className="mt-4 text-center"
              contentMain={error}
              contentSub={errorDescription}
            />
          ) : null}
        </div>
        {isFooterVisible || status !== 'loading' ? (
          <div className="mb-8 w-full px-4">
            <Button onClick={onSubmit} className="w-full">
              {buttonTitle}
            </Button>
            <Button variant="ghost" onClick={onCancel}>
              {cancelTitle}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
