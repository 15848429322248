import {
  DATE_FORMAT_LABELS,
  DATE_FORMAT_MAPPINGS,
  DATE_FORMAT_MAPPINGS_LONG,
  DATE_FORMAT_MASKS,
  DATE_FORMAT_MONTH_YEAR,
} from '@finn/ui-utils';
import React, { createContext, ReactNode } from 'react';
import { useIntl } from 'react-intl';

type DateInfoContextType = {
  dateFormat: string;
  dateFormatLong: string;
  dateLabel: string;
  dateMask: string;
  dateMonthYear: string;
};

export const DateInfoContext = createContext<DateInfoContextType>(
  {} as DateInfoContextType
);

type DateInfoProviderProps = {
  children: ReactNode;
};

export const DateInfoProvider = ({ children }: DateInfoProviderProps) => {
  const i18n = useIntl();

  const locale = i18n.locale;

  const dateFormat = DATE_FORMAT_MAPPINGS[locale];
  const dateFormatLong = DATE_FORMAT_MAPPINGS_LONG[locale];
  const dateLabel = DATE_FORMAT_LABELS[locale];
  const dateMask = DATE_FORMAT_MASKS[locale];
  const dateMonthYear = DATE_FORMAT_MONTH_YEAR[locale];

  return (
    <DateInfoContext.Provider
      value={{
        dateFormat,
        dateFormatLong,
        dateLabel,
        dateMask,
        dateMonthYear,
      }}
    >
      {children}
    </DateInfoContext.Provider>
  );
};

export default DateInfoContext;
