import { fetchAuthToken } from '@finn/ua-auth';
import { useSession } from '@finn/ui-utils';
import { useCallback, useEffect, useState } from 'react';

const AUTH_TOKEN_LIFETIME =
  Number(process.env.NEXT_PUBLIC_AUTH_TOKEN_LIFETIME) || 1000 * 60 * 10;

export const useBannerDeepLink = ({
  withAuth,
  deepLink,
  autohide,
}: {
  withAuth?: boolean;
  deepLink: string;
  autohide?: boolean;
}) => {
  const [bannerDeepLink, setBannerDeepLink] = useState('');
  const [session, isSessionLoading] = useSession();

  const hideSmartBanner = useCallback(() => {
    (window as any).AF?.('banners', 'hideBanner');
  }, []);

  useEffect(() => {
    const createDeepLink = async () => {
      if (withAuth && session && !isSessionLoading) {
        try {
          const { authToken: fetchedToken } = await fetchAuthToken();

          setBannerDeepLink(
            fetchedToken ? `${deepLink}/${fetchedToken}` : deepLink
          );
        } catch (e) {
          setBannerDeepLink(deepLink);
        }
      } else {
        setBannerDeepLink(deepLink);
      }
    };
    if (deepLink) {
      createDeepLink();
    }
  }, [withAuth, deepLink, session, isSessionLoading]);

  useEffect(() => {
    if (!autohide) {
      return;
    }

    const timerId = setTimeout(() => {
      hideSmartBanner();
    }, AUTH_TOKEN_LIFETIME / 2);

    return () => {
      clearTimeout(timerId);
    };
  }, [autohide, hideSmartBanner]);

  return { hideSmartBanner, bannerDeepLink };
};
