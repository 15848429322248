import { HeaderData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';

import Navbar from './Navbar';

const GeneralDesktopHeader = ({
  data,
  ribbonDismissed,
  className,
}: {
  data: HeaderData;
  ribbonDismissed: boolean;
  className?: string;
}) => (
  <div
    data-nativeappid="header"
    className={cn('container bg-white', className)}
  >
    <Navbar data={data} ribbonDismissed={ribbonDismissed} />
  </div>
);

export default GeneralDesktopHeader;
