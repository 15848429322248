import { TrackingProps } from '../../types/general';

export const tracking = (props: TrackingProps) => ({
  getTrackingProps: () => ({
    app_type: 'native' as const,
    app_locale: props.locale,
    app_platform: props.platform,
    app_version: props.version,
    app_build_number: props.buildNumber,
    app_codepush_id: props.codepushId,
    app_device_model: props.deviceModel,
    app_device_name: props.deviceName,
    app_device_type: props.deviceType,
    app_device_network: props.deviceNetwork,
    app_os: props.os,
    app_os_version: props.osVersion,
    app_screen_width: props.screenWidth,
    app_screen_height: props.screenHeight,
    app_feature_flags: props.features,
    // we also mimic native app behaviour
    locale: props.locale,
    app: {
      build: props.buildNumber,
      version: props.version,
      codepushId: props.codepushId,
    },
    device: {
      model: props.deviceModel,
      name: props.deviceName,
      type: props.platform,
    },
    network: {
      cellular: props.deviceNetwork === 'cellular',
      wifi: props.deviceNetwork === 'wifi',
    },
    os: {
      name: props.os,
      version: props.osVersion,
    },
    screen: {
      height: props.screenHeight,
      width: props.screenWidth,
    },
  }),
  setUserTrackingId: (id: string) => {
    const CHECK_ANALYTICS_TIMER = 50;

    const intervalId = setInterval(() => {
      if (window.analytics?.user) {
        window.analytics.user()?.anonymousId?.(id);
        clearInterval(intervalId);
      }
    }, CHECK_ANALYTICS_TIMER);
  },
});
