import { useSession } from '@finn/ui-utils';
import Script from 'next/script';
import {
  createRef,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ChatRef } from './index';

type ChatMetadata = { key: string; value: string };

type ChatInstance = {
  on: (event: string, callback: (detail: unknown) => void) => void;
  off: (event: string, callback: (detail: unknown) => void) => void;
  destroy: () => void;
  openWidget: () => void;
  closeWidget: () => void;
  setMetadata: (fields: ChatMetadata[]) => void;
  setConfig: (config: unknown) => void;
};

declare global {
  interface Window {
    UltimateChat?: {
      Initialize: (config: unknown) => ChatInstance;
    };
  }
}

type ChatProps = {
  navbar?: {
    avatarUrl: string;
    title: string;
    subtitle: string;
    longSubtitle: string;
  };
  theme?: {
    'brand-1': string;
    'brand-2': string;
    'brand-3': string;
  };
  onChatOpened?: () => void;
  openOnLoad?: boolean;
  onWidgetClosed?: (detail?: unknown) => void;
  hideButton?: boolean;
};

const chatFallbackNavbar = {
  avatarUrl:
    'https://res.cloudinary.com/finn-auto/image/upload/v1708533172/bot_icon_3_ewtu4f.png',
  title: 'FINN',
  subtitle: 'Fragen Sie uns etwas',
  longSubtitle: 'Fragen Sie uns etwas oder teilen Sie Ihr Feedback mit uns.',
};

const chatFallbackTheme = {
  'brand-1': '#1A1A1A',
  'brand-2': '#1A1A1A',
  'brand-3': '#0087EB',
};

// we want to avoid loading whole new chat script on each component remount
// so we store it in global ref to remember it between component mounts
const isScriptLoadedRef = createRef() as MutableRefObject<boolean>;
isScriptLoadedRef.current = false;

export const globalChatApi =
  createRef() as MutableRefObject<ChatInstance | null>;

export const UltimateChat = forwardRef(
  (
    {
      navbar = chatFallbackNavbar,
      theme = chatFallbackTheme,
      openOnLoad,
      hideButton,
      onChatOpened,
      onWidgetClosed,
    }: ChatProps,
    ref: ForwardedRef<ChatRef>
  ) => {
    // we mark chat as ready if we have ref, it means that we initialized it before
    const [isReady, setIsReady] = useState(Boolean(isScriptLoadedRef.current));
    const [session] = useSession();
    const queueRef = useRef<(() => void)[]>([]);
    const chatRef = useRef<ChatInstance>(null);
    const chatConfig = useMemo(
      () => ({
        navbar,
        theme,
        openOnLoad,
        hideButton,
      }),
      [navbar, theme, openOnLoad, hideButton]
    );
    const chatMetadata = useMemo<ChatMetadata[]>(() => {
      const result: ChatMetadata[] = [];
      if (session?.user?.hs_contact_id) {
        result.push({
          key: 'finn_user_id',
          value: session?.user?.hs_contact_id,
        });
      }
      if (session?.user?.email) {
        result.push({ key: 'finn_user_email', value: session?.user?.email });
      }
      if (session?.user?.hs_first_name) {
        result.push({
          key: 'finn_user_name',
          value: session?.user?.hs_first_name,
        });
      }

      return result;
    }, [session?.user]);

    // we sync third party chat with our internal metadata, when it changes
    useEffect(() => {
      chatRef.current?.setMetadata(chatMetadata);
    }, [chatMetadata]);

    // we sync third party chat with our internal config, when it changes
    useEffect(() => {
      chatRef.current?.setConfig(chatConfig);
    }, [chatConfig]);

    // if chat is ready, we initialize it with our config + set listeners
    useEffect(() => {
      if (isReady && window.UltimateChat) {
        // we want to initialize chat only once, as we store it in ref,
        // no need to remount on each prop change
        chatRef.current =
          chatRef.current ||
          window.UltimateChat.Initialize({
            botId: '6551e1a8fe162409798b412f',
            recoverConversation: false,
            metadata: chatMetadata,
            ...chatConfig,
          });

        // we remember global link to chat, so that dynamic modules can call it
        // we kinda think that it is ok, because chat is global per page and any module should be
        // able to collaborate with it
        globalChatApi.current = chatRef.current;
        if (onWidgetClosed) {
          chatRef.current?.on('widget-closed', onWidgetClosed);
        }
        if (onChatOpened) {
          chatRef.current?.on?.('session_start', onChatOpened);
        }
      }

      return () => {
        if (onWidgetClosed) {
          chatRef.current?.off?.('widget-closed', onWidgetClosed);
        }
        if (onChatOpened) {
          chatRef.current?.off?.('session_start', onChatOpened);
        }
      };
    }, [isReady, chatMetadata, chatConfig, onWidgetClosed, onChatOpened]);

    // we setup external API for the chat, to be able to open and close it
    useImperativeHandle(
      ref,
      () => ({
        openWidget: () => {
          // we need to check if widget is initialized before calling open
          // if widget is not initialized we need to queue the open call
          // till moment when it will be initialized
          if (isReady) {
            chatRef.current?.openWidget();
          } else {
            queueRef?.current?.push(() => {
              chatRef.current?.openWidget();
            });
          }
        },
        closeWidget: () => chatRef.current?.closeWidget(),
      }),
      []
    );

    // we destroying chat when component is fully unmounted
    useEffect(() => {
      // for some reason with cosmic helper enabled
      // Script tag onLoad never resolved, likely because of reloading
      // to avoid this and other production issues -> we set timeout
      // after which we consider chat as ready
      const MAX_WAIT_TIME = 10000;
      const timer = setTimeout(() => {
        setIsReady(true);
        if (queueRef?.current?.length) {
          queueRef.current.forEach((fn) => fn());
          queueRef.current = [];
        }
      }, MAX_WAIT_TIME);

      return () => {
        clearTimeout(timer);
        chatRef.current?.destroy();
        chatRef.current = null;
      };
    }, []);

    // no need to render script, as we already have chat
    if (isScriptLoadedRef.current) {
      return null;
    }

    return (
      <Script
        src="https://widget.ultimate.ai/sdk/index.iife.js"
        strategy="lazyOnload"
        onLoad={() => {
          isScriptLoadedRef.current = true;
          // we need to set state, to re-render component and initialize chat
          setIsReady(true);

          if (queueRef?.current?.length) {
            queueRef.current.forEach((fn) => fn());
            queueRef.current = [];
          }
        }}
      />
    );
  }
);
