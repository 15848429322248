import { Button } from '@finn/design-system/atoms/button';
import { KeyboardArrowLeft } from '@finn/design-system/icons/keyboard-arrow-left';
import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { cn } from '@finn/ui-utils';
import React, { MouseEventHandler } from 'react';

export enum ArrowDirection {
  left = 'left',
  right = 'right',
}

type Props = {
  direction: ArrowDirection;
  onClick?: MouseEventHandler | undefined;
  className: string;
  arrowSize: number;
};

const CarouselArrow: React.FC<Props> = ({
  direction,
  onClick,
  className,
  arrowSize,
}) => {
  return (
    <Button
      variant="ghost"
      className={cn(
        className,
        'h-8 w-8 rounded-full p-1 [&_svg]:h-5 [&_svg]:w-5',
        'bg-cotton hover:bg-pearl'
      )}
      onClick={onClick}
    >
      {direction === ArrowDirection.left ? (
        <KeyboardArrowLeft width={arrowSize} height={arrowSize} />
      ) : (
        <KeyboardArrowRight width={arrowSize} height={arrowSize} />
      )}
    </Button>
  );
};

export default CarouselArrow;
