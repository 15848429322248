import { Input } from '@finn/design-system/atoms/input';
import { useCurrentLocale } from '@finn/ui-utils';
import { FormikProps } from 'formik';
import React, { FocusEventHandler, Ref, useMemo } from 'react';
import { ControllerProps } from 'react-hook-form';
import { IMaskMixin } from 'react-imask';

type CustomDatePickerProps = {
  formik?: FormikProps<any>;
  disableInput?: boolean;
  variant?: 'dialog' | 'inline' | 'static';
  dateFormatVariant?: 'short' | 'long';
  disabled?: boolean;
  label?: string;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  ref?: Ref<HTMLDivElement>;
};

const CustomDatePicker: React.FunctionComponent<
  Omit<Parameters<ControllerProps['render']>['0']['field'], 'onBlur' | 'ref'> &
    CustomDatePickerProps
> = (props) => {
  const { locale } = useCurrentLocale();

  const formattedValue =
    props.value instanceof Date
      ? props.value.toLocaleString(locale, {
          day: '2-digit',
          year: 'numeric',
          month: '2-digit',
        })
      : props.value;

  const IMaskInput = useMemo(
    () =>
      IMaskMixin(({ inputRef, defaultValue, ...rest }) => {
        return (
          <Input
            ref={inputRef as Ref<HTMLInputElement>}
            defaultValue={defaultValue as string}
            {...rest}
          />
        );
      }),
    []
  );

  return (
    <IMaskInput
      name={props.name}
      mask="00.00.0000"
      lazy={false}
      placeholderChar="_"
      value={formattedValue}
      shouldShowLabelAtTop
      disabled={props.disabled}
      label={props.label}
      error={props?.error}
      onAccept={(value) => props.onChange({ target: { value } })}
    />
  );
};
export default CustomDatePicker;
