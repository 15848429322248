import { config } from '@finn/ui-utils';
import Cosmic from 'cosmicjs';

export const runCosmicRawQuery = async (
  query: unknown,
  fields = 'content,metadata,locale',
  showMetaFields = false
): Promise<any> => {
  const api = Cosmic();
  const bucket = api.bucket({
    slug: 'finnauto',
    read_key: config.COSMIC_BUCKET_READ_KEY,
  });

  let data: any = [];

  try {
    const response = await bucket.getObjects({
      query,
      props: fields,
      show_metafields: showMetaFields,
    });
    data = response.objects;
  } catch (e) {
    console.log(e);
  }

  return Array.isArray(data) ? data : [];
};
