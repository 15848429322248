import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { useFormattedPrice } from '@finn/ui-utils';
import { useMemo } from 'react';

import { getLongestTerm } from '../SubscriptionPrice';

const TAX_RATE_GERMANY = 0.19;

type UsePreDiscountRawValue = {
  vehicle: GenericVehicleDetails;
  price?: number;
  term?: number;
  km?: number;
  isForBusiness?: boolean;
  isDownPayment?: boolean;
};
export const usePreDiscountRawValue = ({
  vehicle,
  price,
  term,
  km = 0,
  isDownPayment = true,
  isForBusiness = false,
}: UsePreDiscountRawValue): number | undefined => {
  const preDiscount = useMemo(() => {
    const b2bMultiplier = isForBusiness ? 1 + TAX_RATE_GERMANY : 1;

    const prices = isDownPayment ? vehicle.downpayment_prices : vehicle.price;
    const { available_price_list: availablePrices } = prices;

    if (!availablePrices) {
      return undefined;
    }

    term = term || getLongestTerm(vehicle);
    const oldPrice =
      availablePrices[`b2c_${term}_old`] + (prices[`extra_${km}_old`] ?? 0);
    if (oldPrice) {
      return Math.round(oldPrice / b2bMultiplier);
    }

    return undefined;
  }, [
    isForBusiness,
    isDownPayment,
    vehicle.downpayment_prices.available_price_list,
    vehicle.price.available_price_list,
    term,
    km,
  ]);

  return preDiscount > price ? preDiscount : undefined;
};
type UsePreDiscount = {
  vehicle: GenericVehicleDetails;
  price?: number;
  term?: number;
  km?: number;
  isForBusiness?: boolean;
  isDownPayment?: boolean;
};
export const usePreDiscount = ({
  vehicle,
  price,
  term,
  km,
  isForBusiness = false,
  isDownPayment = true,
}: UsePreDiscount) => {
  const discount = usePreDiscountRawValue({
    vehicle,
    price,
    term,
    km,
    isForBusiness,
    isDownPayment,
  });
  const formattedDiscount = useFormattedPrice(discount, 0);

  if (discount === undefined || (price !== undefined && discount <= price)) {
    return undefined;
  }

  return formattedDiscount;
};
