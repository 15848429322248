// Some fancy TS below
// inspired by https://stackoverflow.com/questions/66618981/how-can-i-make-a-map-with-different-types-that-extend-a-base

export class Dictionary<T = {}> {
  private data: T;

  constructor() {
    this.data = {} as T;
  }

  register<K extends PropertyKey, V extends K extends keyof T ? T[K] : unknown>(
    key: K,
    value: V
  ): asserts this is Dictionary<T & { [k in K]: V }> {
    this.data = {
      ...this.data,
      [key]: value,
    };
  }

  getDictionary(): T {
    return this.data;
  }
}
