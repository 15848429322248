import { Button } from '@finn/design-system/atoms/button';
import { KeyboardArrowLeft } from '@finn/design-system/icons/keyboard-arrow-left';
import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import { cn } from '@finn/ui-utils';
import React, { MutableRefObject } from 'react';

type Props = {
  direction: Direction;
  isArrowEnabled: boolean;
  sliderRef: MutableRefObject<HTMLUListElement>;
};

const SCROLLING_STEP = 500;

type Direction = 'left' | 'right';

export const SliderArrow: React.FC<Props> = ({
  direction,
  isArrowEnabled,
  sliderRef,
}) => {
  const scroll = (scrollDirection: Direction) => {
    const stepDirection = scrollDirection === 'left' ? -1 : 1;
    sliderRef.current?.scrollBy({
      top: 0,
      left: SCROLLING_STEP * stepDirection,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      variant="ghost"
      className={cn(
        '!bg-cotton hover:!bg-pearl h-12 w-12 rounded-full p-2 [&_svg]:h-10 [&_svg]:w-10',
        {
          'hover:!bg-cotton cursor-auto opacity-45': !isArrowEnabled,
        }
      )}
      onClick={() => {
        scroll(direction);
      }}
    >
      {direction === 'left' ? (
        <KeyboardArrowLeft width={44} height={44} />
      ) : (
        <KeyboardArrowRight width={44} height={44} />
      )}
    </Button>
  );
};
