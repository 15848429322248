import { AxiosResponse } from 'axios';

export const getFirstResponseThatFits = async <T>(
  responsesPromises: {
    cancel: () => void;
    request: Promise<AxiosResponse<T>>;
  }[]
) => {
  // we wait for the first request, as it has highest priority
  // only if it is failing - we will use fallback
  const response = await responsesPromises[0].request;

  // if success -> return it
  if (response.status < 400) {
    // we cancel all other requests
    responsesPromises.forEach((request) => request.cancel());

    return {
      data: response.data,
      status: response.status,
    };
  }

  // now we wait for the rest of the requests to settle
  const results = await Promise.allSettled(
    responsesPromises.map((request) => request.request)
  )
    .then((res) => [...res.values()])
    .catch(() => []);
  // we take the first successful response
  const secondaryResponse = (
    results as Awaited<{ value: { status: number; data: T } }>[]
  ).find((result) => result?.value?.status < 400);

  if (secondaryResponse) {
    // we cancel all other requests
    responsesPromises.forEach((request) => request.cancel());

    return {
      data: secondaryResponse.value.data,
      status: secondaryResponse.value.status,
    };
  }

  const status = response.status || 500;
  const isError = status >= 400;

  return {
    data: isError ? null : response.data,
    status,
  };
};
