import { ModalKey, useIsModalOpen } from '@finn/ua-modals';
import { isMobileApp } from '@finn/ui-utils';

import { Features } from './constants';
import { useGetVariant } from './useIsABVariant';

export const usePCPLoginExp = () => {
  const isPCPOpen = useIsModalOpen(ModalKey.CONFIGURE_VEHICLE);
  const isApp = isMobileApp();
  const variant = useGetVariant(Features.ExpPCPLogin);

  const isVariantB = variant === 'b';
  const isVariantC = variant === 'c';
  const isControl = !isVariantB && !isVariantC;

  const isEnabled = !isApp && !isControl && isPCPOpen;

  return { isEnabled, isVariantB, isVariantC };
};
