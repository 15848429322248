const LG_CONTAINER_WIDTH = 1260;
const MD_CONTAINER_WIDTH = 726;

// ignoring plugin as it is difficult to test
/* istanbul ignore next */
const defineContainer = ({ addComponents }) => {
  // as it is impossible to override default container maxWidth props in tailwind.config.js, setting maxWidth via function
  addComponents({
    '.container': {
      width: '100%',
      '@screen xs': {
        maxWidth: 'calc(100vw - 2rem)',
      },
      '@screen md': {
        maxWidth: `calc(100vw - 8rem)`,
      },
      '@screen lg': {
        maxWidth: `${LG_CONTAINER_WIDTH}px`,
      },
    },
  });
};

export { defineContainer, LG_CONTAINER_WIDTH, MD_CONTAINER_WIDTH };
