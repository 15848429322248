import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn } from '@finn/ui-utils';
import React from 'react';

const SubMenuElement = ({
  href,
  label,
  onClick,
  newTagLabel,
  weight = 'regular',
}: {
  href: string;
  label: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  newTagLabel?: string;
  weight?: 'regular' | 'semibold';
}) => {
  return (
    <NextLinkSingleApp
      app={App.CP}
      href={href}
      key={label}
      className={cn(
        'flex h-16 items-center justify-between',
        'border-pearl border-0 border-t border-solid'
      )}
      onClick={onClick}
    >
      <span
        className={
          weight === 'semibold' ? 'body-16-semibold' : 'body-16-regular'
        }
      >
        {label}
      </span>
      {newTagLabel && (
        <span
          className={cn(
            'body-12-semibold bg-trustedBlue justify-self-center rounded px-3 py-2 text-white'
          )}
        >
          {newTagLabel}
        </span>
      )}
    </NextLinkSingleApp>
  );
};

export default SubMenuElement;
