import { Drawer, DrawerContent } from '@finn/design-system/atoms/drawer';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import {
  DrawerFooter,
  HandleMobileHeaderParams,
  MenuBlock,
  SubMenuAccordion,
} from '@finn/ua-header';
import {
  NAVIGATION_TRACKING_EVENTS,
  navigationTrackingEvent,
  TrackingEventName,
} from '@finn/ua-tracking';
import {
  B2BHeaderMenuSection,
  HeaderData,
  HeaderMenu,
  HeaderMenuSection,
  NavbarData,
} from '@finn/ui-cosmic';
import {
  cn,
  HandleClickParams,
  updateNavLinkBlockForB2B,
} from '@finn/ui-utils';
import get from 'lodash/get';
import Link from 'next/link';
import { useCallback, useMemo } from 'react';

type HeaderLink = {
  href: string;
  label: string;
  menu: HeaderMenu;
};

type HeaderDrawerProps = {
  isOpen?: boolean;
  isOpenUser?: boolean;
  handleMobileHeader: ({ isOpen }: HandleMobileHeaderParams) => void;
  data: NavbarData;
  headerData?: HeaderData;
};

const HeaderDrawer = ({
  isOpen,
  isOpenUser,
  handleMobileHeader,
  data,
}: HeaderDrawerProps) => {
  const handleClick = useCallback(
    ({ link, type, e, isAccordion, isExpanded }: HandleClickParams) => {
      if (!isAccordion) {
        handleMobileHeader({ isOpen: false, isOpenUser: false });
        e?.stopPropagation();
      }

      if ((isAccordion && isExpanded) || !isAccordion) {
        navigationTrackingEvent({
          eventName: TrackingEventName.NAVITEM_CLICKED,
          link,
          type,
        });
      }
    },
    [handleMobileHeader]
  );

  const buildSections = useCallback((link: HeaderLink) => {
    const sections = get(link, 'menu.metadata.sections', []).map(
      (section: HeaderMenuSection | B2BHeaderMenuSection) => section.metadata
    );

    return sections.map((sectionData: any, idx: number) => (
      <div
        key={sectionData.type + idx}
        className="border-pearl border-0 border-t border-solid"
      >
        <MenuBlock data={sectionData} handleClick={handleClick} />
      </div>
    ));
  }, []);

  const memoizedLinks = useMemo(() => {
    const links = data.links.map((link) =>
      link.href === `/${BASE_SUBSCRIPTION_PATH}`
        ? updateNavLinkBlockForB2B(link)
        : link
    );

    return links.map((link) => {
      const hasMenu = link.menu && Object.keys(link.menu).length > 0;

      return hasMenu ? (
        <div
          className={cn('border-pearl border-0 border-t border-solid')}
          key={link.href}
        >
          <SubMenuAccordion
            titleHref={link.href}
            title={link.label}
            onClick={handleClick}
          >
            {buildSections(link)}
          </SubMenuAccordion>
        </div>
      ) : (
        <Link
          key={link.href}
          href={link.href}
          onClick={() =>
            handleClick({
              link: link.href,
              type: NAVIGATION_TRACKING_EVENTS.LIST_ITEM,
            })
          }
          className={cn(
            'body-16-semibold px-0 py-6',
            'border-pearl border-0 border-t border-solid text-black'
          )}
        >
          {link.label}
        </Link>
      );
    });
  }, [buildSections, data.links, handleClick]);

  return (
    <Drawer direction="top" open={isOpen || isOpenUser}>
      <DrawerContent
        contentClassName="max-h-full"
        renderHeader={() => null}
        className="!duration-200"
      >
        <div className="mx-auto mt-16 flex h-full w-full max-w-[1260px] flex-col">
          {isOpen && memoizedLinks}
          <DrawerFooter data={data} isOpenUser={isOpenUser} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default HeaderDrawer;
