import '../../sdk';

import { UserAccountContext, UserAccountModal } from '@finn/ua-auth';
import {
  ModalKey,
  useOnModalClose,
  useOnModalOpen,
  useOpenModal,
} from '@finn/ua-modals';
import {
  captureException,
  isServer,
  traceabilityHeadersBrowser,
  useSession,
} from '@finn/ui-utils';
import axios from 'axios';
import { useRouter } from 'next/router';
import { signIn, signOut as nextAuthSignOut } from 'next-auth/react';
import { useContext, useEffect, useRef } from 'react';

import { getAppSDK } from '../../helpers/mobileApp';
import { savePushToken } from '../../helpers/savePushToken';
import { adjustLinkTargetsforWebView } from '../../helpers/webViewHelpers';

// TODO extract callSignIn and similar to separate package
// for now we need to copy this code from UA to avoid cycle that causes JS errors
const callSignIn = async (
  provider: string,
  options: { [key: string]: string }
) => {
  const appSDK = getAppSDK();

  try {
    const data = (await signIn(provider, {
      redirect: false,
      ...(appSDK ? { appVersion: appSDK.getTrackingProps().app_version } : {}),
      ...options,
    })) as any;
    data.ok = data.ok && !data.error;

    return data;
  } catch (error) {
    captureException(error);

    return {
      status: 500,
      ok: false,
      error: 'server_error',
      url: null,
    };
  }
};

const BASE_URL = `${
  !isServer() ? window.origin : process.env.NEXTAUTH_URL || ''
}/api/auth`;

const callCustomEndpoint = async (
  endpoint: string,
  payload: { [key: string]: string }
) => {
  let status = 500;
  let data = null;
  try {
    const result = await axios.post(`${BASE_URL}/custom/${endpoint}`, payload, {
      headers: traceabilityHeadersBrowser(),
    });
    status = result.status;
    data = result.data;
  } catch (error) {
    const err = error as any;
    captureException(error);

    return {
      status,
      ok: false,
      error:
        (err.response.status === 404 && err.response?.data?.message) ||
        'server_error',
      url: null,
    };
  }

  return {
    status,
    ok: status === 200 && data.success,
    error: data.message,
    url: null,
  };
};

const login = async (email: string, password: string) =>
  callSignIn('login', { email, password });
const loginWithCode = async (code: string) =>
  callSignIn('loginWithCode', { code });
const loginWithToken = async (token: string) =>
  callSignIn('loginWithToken', { token });
const signOut = async () => nextAuthSignOut({ redirect: false });
const refreshCode = async () => callCustomEndpoint('refreshCode', {});
const verifyCode = async (code: string) =>
  callCustomEndpoint('verifyCode', { code });

export const MobileAppBindings = () => {
  const [session, isSessionLoading] = useSession();
  const { modalStatus, setModalStatus } = useContext(UserAccountContext);
  const isModalOpenRef = useRef(false);
  const modalName = modalStatus?.isModalOpen && modalStatus?.modalType;
  const router = useRouter();
  const openModal = useOpenModal();

  useEffect(() => {
    // on android app links with target _blank will open in a new browser window
    // which is not desired behaviour, as we want to have native navigation
    // in order to avoid this we are changing target for all links on a page
    if (navigator.userAgent.toLowerCase()?.includes('android')) {
      adjustLinkTargetsforWebView();
    }
    const appSDK = getAppSDK();
    if (appSDK) {
      appSDK.signIn = login;
      appSDK.signOut = async () => {
        await signOut?.();
        appSDK.sendMessageToApp({
          type: 'event:signout_successful',
        });
      };
      appSDK.signInWithToken = async (
        token: string,
        responseRequestId?: string
      ) => {
        if (token !== 'token' && token !== 'emptyToken') {
          const res = await loginWithToken?.(token);
          if (responseRequestId) {
            appSDK.sendMessageToApp({
              requestId: responseRequestId,
              type: 'event:login_with_token',
              value: res.ok,
            });
          }
        }
      };
      appSDK.savePushToken = savePushToken;
      appSDK?.sendMessageToApp({ type: 'event:sdk_initialized' });
    }
    // we want to trigger this hook on each route change
    // to send new initialized event
  }, [router.asPath]);

  useEffect(() => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }
    appSDK.userSession = session;
    appSDK.openModal = (key: string) => openModal?.(key as ModalKey);
    appSDK.showVerifyEmailModal = () =>
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFICATION,
      });
    appSDK.verifyEmail = async (verificationCode: string) => {
      const result = await verifyCode(verificationCode);

      if (result.ok) {
        await loginWithCode?.(verificationCode);
        await refreshCode();
        setModalStatus({
          isModalOpen: true,
          modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY,
        });
      }
    };
  }, [session, setModalStatus, openModal]);

  useEffect(() => {
    if (!isSessionLoading) {
      getAppSDK()?.sendMessageToApp({
        type: 'event:user_authenticated',
        value: session,
      });
    }
  }, [session, isSessionLoading]);

  useOnModalOpen((modalKey) => {
    getAppSDK()?.sendMessageToApp?.({
      type: 'event:open_modal',
      value: modalKey,
    });
    isModalOpenRef.current = true;
  });

  useOnModalClose((modalKey) => {
    getAppSDK()?.sendMessageToApp?.({
      type: 'event:close_modal',
      value: modalKey,
    });

    isModalOpenRef.current = false;
  });

  useEffect(() => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    if (modalName && !isModalOpenRef.current) {
      appSDK.sendMessageToApp?.({
        type: 'event:open_modal',
        value: modalName,
      });

      isModalOpenRef.current = true;
    } else if (isModalOpenRef.current) {
      appSDK.sendMessageToApp?.({
        type: 'event:close_modal',
      });

      isModalOpenRef.current = false;
    }
  }, [modalName]);

  return null;
};
