import config from './config';

export const getCloudinaryImgUrl = (url: string, { ...options } = {}) => {
  if (!url) return url;

  /*
    For some reason, CosmicJS is returning imgix urls instead of cdn urls sometimes.
    for this we need to replace imgix with cdn.
  */
  if (url.includes('imgix.cosmicjs')) {
    url = url.replace('imgix.cosmicjs', 'cdn.cosmicjs');
  }

  // Use original svg format and full quality for svg icons
  const fullListOptions =
    url.endsWith('.svg') || url.includes('.svg?')
      ? { q: 100, f: 'svg' }
      : Object.assign(
          {
            q: 'auto',
            f: 'auto',
            c: 'limit',
            dpr: 2,
          },
          options
        );

  // Converts the fullListOptions map into a comma-separated options string.
  // e.g. {h: 200, w: 200, q: 'auto'} becomes h_200,w_200,q_auto.
  const paramsString = Object.entries(fullListOptions)
    .map((option) => `${option[0]}_${option[1]}`)
    .join(',');

  return `${config.CLOUDINARY.REMOTE_IMAGE_FETCH_URL}${paramsString}/${url}`;
};

export const getCloudinarySrcSet = (
  url: string,
  widths: number[],
  dpr: number = 2
) => {
  const srcSet = widths.map(
    (width) => `${getCloudinaryImgUrl(url, { w: width, dpr })} ${width}w`
  );

  return srcSet.join(',\n');
};
