enum CAR_REGISTRATION_STATUS {
  PAPERS_RECEIVED = 'papers_received',
  IN_REGISTRATION = 'in_registration',
  REGISTERED = 'registered',
  DEREGISTERED = 'deregistered',
  LOCKED = 'locked',
  // US statuses
  NOT_REGISTERED = 'not_registered',
}

enum FUEL_TYPES {
  PETROL = 'petrol',
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  HYBRID_DIESEL = 'hybrid_diesel',
  HYBRID_PETROL = 'hybrid_petrol',
  HYBRID_PLUG_IN = 'hybrid_plug_in',
}

// Status values returned by get car by id - Cars Api
// https://apidocs.finn.auto/api/cars/#tag/Car-Queries/paths/~1cars~1{id}/get
export type DECarLifecycleStatus =
  | 'ordered'
  | 'produced'
  | 'arrived_from_supplier'
  | 'tech_preparation_done'
  | 'ready_to_deliver'
  | 'in_subscription'
  // cancelled with 2 l since it's saved as "cancelled" in Cars table
  | 'cancelled'
  | 'draft'
  // Only Valid for old world
  // TODO revisit when they get supported by get car by id - Cars Api
  | 'ready_to_defleet'
  | 'defleeted'
  | 'returned';

export type Car = {
  id: string;
  oem: string;
  model: string;
  fuel_type: FUEL_TYPES;
  registration_data?: {
    license_plate?: string;
    registration_date?: Date;
    status?: CAR_REGISTRATION_STATUS;
  };
  external_product_id: string;
  color: string;
  engine: string;
  equipment_line: string;
  gearbox: string;
  power: string;
  picture: string;
  status: DECarLifecycleStatus;
};
