import { Locale, useCurrentLocale, useSession } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { changePasswordWithCode } from '../../auth';
import { UserAccountModal } from '../../Modals/modalTypes';
import { UserAccountContext } from '../../UserAccount';
import { CTAButton } from '../CTAButton';
import { PasswordInput } from '../PasswordInput';
import { useForm } from '../useForm';

export const ResetPasswordForm = ({
  verificationCode,
}: {
  verificationCode: string;
}) => {
  const router = useRouter();
  const i18n = useIntl();
  const { locale } = useCurrentLocale();
  const { setModalStatus } = useContext(UserAccountContext);
  const { handleBlur, handleChange, onSubmit, values, errors } = useForm({
    defaultValues: {
      password: '',
    },
    hasNewPassword: true,
  });
  const [session] = useSession();
  const [serverErrorCode, setServerErrorCode] = useState<string | null>(null);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    // we reach handleSubmit either from the form submit or from the button click
    // for form submit we need to prevent the default behavior (which is normal html submission)
    e?.preventDefault?.();
    await onSubmit(async () => {
      setServerErrorCode(null);
      const result = await changePasswordWithCode(
        verificationCode,
        values.password || '',
        locale as Locale
      );
      if (result.ok) {
        setModalStatus({
          isModalOpen: true,
          modalType: UserAccountModal.PASSWORD_RESET_SUCCESSFULLY,
          options: {
            onClose: async () => await router.push('/'),
          },
        });
      } else {
        setServerErrorCode('server_error');
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex w-full flex-col"
      method="POST"
    >
      <PasswordInput
        name="password"
        label={i18n.formatMessage({ id: 'userAccount.field.newPassword' })}
        enablePasswordStrengthCheck
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        validationError={errors.password}
      />
      <CTAButton
        label={i18n.formatMessage({
          id: 'userAccount.button.changePasswordNow',
        })}
        onClick={handleSubmit}
        errorCode={serverErrorCode}
        disabled={!session}
      />
    </form>
  );
};
